import React, { Component } from 'react';
import { Promise } from 'core-js';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Header from '../components/Header';
import {Tabs, Button, notification, Tooltip} from 'antd';
import { connect } from 'react-redux';
import SimpleView from './SimpleView';
import DistributionMode from './DistributionMode';
import VsIndustryMode from './VsIndustryMode';
import HelpReadMe from './HelpReadMe';
import UploadSheet from "../components/UploadSheet";
import { Spin } from 'antd';
import {
  companyData,
  companyList,
  submitDataForCreditRating,
  changeActiveTab,
  newCompanyResultEntries, submitDataForBatchRating, allTileBoundsLoaded, currentCompanyChanged
} from '../redux/actions';
import {
    checkEmptyString,
    convertToDecimal,
    mapScoreToRating,
    getCompanyPeersNew, getISOLastDayOfPrevMonth, getIndustrySpecificMacroData, getDDMMYY, prevQuarter, isNewerVersion
} from '../utils';
import { getPeersCreditBars } from '../utils';
import { autoCorrectIncomeStatement, autoCorrectCashFlowStatement, autoCorrectBalanceSheet } from '../utils';
import { DownloadOutlined } from '@ant-design/icons'
import { QuestionCircleOutlined } from '@ant-design/icons';
import axiosInstance from '../utils/axios'
import {newUploadSheetEntries} from "../redux/actions/uploadSheet";
import {mergeDeep} from "../components/UploadSheet/service";

const size="small";

/**
 * Get last 4 quarters' sum of a particular data field, of the given company, counting from the quarter given.
 * @param companyObj The object of the company in question
 * @param quarterYearString The most recent quarter in the last 4 quarters, used to get the other 3 quarters.
 * @param field The data field of which we want to sum its value
 * @returns {string|number} The sum, or 'Error' if any of the 4 quarter contains null value in this field
 */
function getLast4QuarterValueSum(companyObj, quarterYearString, field) {
    const yearString = quarterYearString.substr(quarterYearString.length-4);
    const year = Number(yearString);
    const Q = Number(quarterYearString[1]);
    let accum = 0;
    for (let q=Q+3;q>=Q;q--){
        let currentYear = (q<4) ? year-1:year;
        let currentQuarter = q%4+1;
        let currentQuarterYearString = `Q${currentQuarter}${currentYear}`;
        let currentQuarterData = companyObj[currentQuarterYearString];
        if (!currentQuarterData || isNaN(currentQuarterData[field]))
            return '--'; // if any of the 4 quarters is missing or had null value in this field, return error string
        let value = currentQuarterData[field];
        accum += value;
    }
    return accum;
}

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            downloadPDF: false,
            companyDataLoading: false
        };
    }

    componentDidMount() {
        this.readData();
    }

    componentDidUpdate(prevProps) {    //, prevState){
        console.log("uiPages.Index: ComponentDidUpdate called.");
        const { headerSelected, yearlyReport, autoCorrectData, customisedData, companyResults, macroData, migoResult } = this.props;
        const { companyDataLoading } = this.state;

        // clear the content page if header gets reset
        if (prevProps.headerSelected !== headerSelected && !headerSelected.companyID)
        {
            this.props.updateCompanyData(null); // pass null as argument to reset companyData to initial empty values
            return;
        }

        if (prevProps.headerSelected !== headerSelected || prevProps.yearlyReport !== yearlyReport ||
            prevProps.autoCorrectData !== autoCorrectData || prevProps.customisedData !== customisedData ||
            companyDataLoading) {

            const companyID = headerSelected.companyID;
            const companyRegion = headerSelected.companyRegion;
            const companyIndustry = headerSelected.companyGIC;
            const reportingPeriod = headerSelected.reportingPeriod;
            const companyObj = companyResults[companyID];
            const quarterDataObj = companyObj[reportingPeriod.substr(reportingPeriod.length-6)];
            const lastDayPrevMonth = getISOLastDayOfPrevMonth(quarterDataObj.report_date);
            if (typeof quarterDataObj.agencyRatings==='undefined'||typeof quarterDataObj.roboR8Results==='undefined')
            {
                if (!companyDataLoading)
                    this.setState({companyDataLoading: true});
                return;
            }
            if (typeof macroData[companyRegion]==='undefined'||typeof macroData[companyRegion][lastDayPrevMonth]==='undefined')
            {
                if (!companyDataLoading)
                    this.setState({companyDataLoading: true});
                return;
            }
            if (typeof quarterDataObj.peerData==='undefined')
            {
                if (!companyDataLoading)
                    this.setState({companyDataLoading: true});
                return;
            }
            this.setState({companyDataLoading: false});
            const macroDailyData = macroData[companyRegion][lastDayPrevMonth];

            let peerData = quarterDataObj.peerData;

            let roboR8Check = false;
            let roboR8Result = [];           //roboR8 rating result for the company [Rating, Prob]
            let roboR8Score = 0;             //roboR8 numerical score
            let roboR8Rating = 0;            //roboR8 rating (mode)
            let roboR8target = undefined;
            let roboR8date = '';             //roboR8 rating date

            let zeroResult = [];             //Null rating probs
            let peerResults = [];            //Set of companies with same result date and industry
            let peerCreditBars = [];
            let peerResultsHistory = [];     //Set of companies with same result date (for last 4 quarters) and industry

            for (let i = 1; i <= 24; i++) {
                zeroResult.push({
                    rating: mapScoreToRating(i).migo,
                    prob: 0,
                })
            }

            let quantiles = [];
            if (quarterDataObj.roboR8Results){
                roboR8Check = true;
                quantiles = quarterDataObj.roboR8Results.quantiles;
                roboR8target = quarterDataObj.roboR8Results.target;
                roboR8date = quarterDataObj.roboR8Results.date;
                roboR8date = roboR8date.substr(0, 6) + roboR8date.substr(8);
            }

            let roboR8max = 0;
            if (roboR8Check === true) {
                for (let i = 1; i <= 24; i++) {
                    let ithQuantile = parseFloat(quantiles[i-1]);
                    if (ithQuantile > roboR8max) {
                        roboR8max = ithQuantile;
                        roboR8Rating = i;
                    }
                    roboR8Score += i * ithQuantile;

                    roboR8Result.push({
                        rating: mapScoreToRating(i).migo,
                        prob: ithQuantile * 100,
                    })
                }
            } else {
                roboR8Result = zeroResult;
            }

            roboR8Score = (parseFloat(roboR8Score) * 100) / 24;  //zero if no rating

            //Get all companies with same industry and result date, plus the credit bars
            // peerResults = getCompanyPeers(dFitch[2], roboR8, companyID, companyGIC, peerDt, dMoody[ratingY], dSandP[ratingY], dFitch[ratingY]);
            peerResults = getCompanyPeersNew(companyID, quarterDataObj.peerRatings[quarterDataObj.quarter]);

            //Order by score
            peerResults.sort(function (a, b) {
                return a.score - b.score;
            })

            if (autoCorrectData === true) peerCreditBars = getPeersCreditBars(peerResults, roboR8Rating);
            else peerCreditBars = {
                group: "Rating Score Skew Probabilities",
                type: "EMPTY",
                total: 100,
                "< Credit Score": 0,
                "= Credit Score": 100,
                "> Credit Score": 0
            }

            //Order by name
            peerResults.sort(function (a, b) {
                let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
                if (nameA < nameB) //sort string ascending
                    return -1
                if (nameA > nameB)
                    return 1
                return 0 //default return value (no sorting)
            })

            //Get the peers for the previous 4 reports dates: push current peers
            peerResultsHistory.push({ "peers": peerResults, "reportQ": 0 });

            //Find peer date for prior report dates (each 3 months prior)
            let reportShift, reportShiftMax;

            reportShiftMax = 3;

            for (reportShift = 1; reportShift <= reportShiftMax; reportShift++) {
                const yearString = quarterDataObj.quarter.substr(quarterDataObj.quarter.length-4);
                let y = Number(yearString);
                const Q = Number(quarterDataObj.quarter[1]);
                let q = Q - reportShift;
                if(q<=0){
                    q += 4;
                    y -= 1;
                }
                let newQuarter = `Q${q}${y}`;
                let peerResultsTemp = getCompanyPeersNew(companyID, quarterDataObj.peerRatings[newQuarter]);

                //Update history
                peerResultsHistory.push({ "peers": peerResultsTemp, "reportQ": -reportShift });
            }

            if (!macroDailyData) {
                this.openNotification2()
                return false;
            }

            if (!quarterDataObj.agencyRatings) {
                this.openNotification4()
                return false;
            }

            if (roboR8Check === false) {
                    this.openNotification5()
            }

            let ISvalue = {
                qGrossProfitValue: '',
                qSalesValue: '',
                costGoodsSoldValue: '',
                qEBITValue: '',
                sGandAValue: '',
                qDandAValue: '',
                qGrossInterestValue: '',
                earningBeforeIncomeTaxesValue: '',
                incomeTaxExpenseValue: '',
                qNetIncomeValue: ''
            };

            let ISstatus = {
                checkGrossProfitStatus: '',
                checkSalesValueStatus: '',
                checkCostGoodsSoldStatus: '',
                checkEBITStatus: '',
                checkSGandAStatus: '',
                checkDandAStatus: '',
                checkGrossInterestStatus: '',
                checkEarningBeforeIncomeTaxesStatus: '',
                checkIncomeTaxExpenseStatus: '',
                checkNetIncomeStatus: ''
            };

            let ISlog = {
                checkGrossProfitLog: '',
                checkSalesValueLog: '',
                checkCostGoodsSoldLog: '',
                checkEBITLog: '',
                checkSGandALog: '',
                checkDandALog: '',
                checkGrossInterestLog: '',
                checkEarningBeforeIncomeTaxesLog: '',
                checkIncomeTaxExpenseLog: '',
                checkNetIncomeLog: ''
            };

            let ISdata = { ...ISvalue, ...ISstatus, ...ISlog };

            let CFvalue = {
                qNetIncomeValue: '',
                qDandAValue: '',
                increaseDecreaseInOtherItemsValue: '',
                qOpCashFlowValue: '',
                qCapexValue: '',
                investmentsInOtherAssetsValue: '',
                qCashFromInvestValue: '',
                qDividendValue: '',
                qSaleOfEquityValue: '',
                qIssueLTDebtValue: '',
                qRepayLTDebtValue: '',
                otherFinancingActivitiesValue: '',
                qCashFromFinanceValue: '',
                netIncreaseInCashAndEquivalentsValue: ''
            };

            let CFstatus = {
                checkNetIncomeStatus: '',
                checkDandAStatus: '',
                checkIncreaseDecreaseInOtherItemsStatus: '',
                checkOpCashFlowStatus: '',
                checkCapexStatus: '',
                checkInvestmentsInOtherAssetsStatus: '',
                checkCashFromInvestStatus: '',
                checkDividendStatus: '',
                checkSaleOfEquityStatus: '',
                checkIssueLTDebtStatus: '',
                checkRepayLTDebtStatus: '',
                checkOtherFinancingActivitiesStatus: '',
                checkCashFromFinanceStatus: '',
                checkNetIncreaseInCashAndEquivalentsStatus: ''
            };

            let CFlog = {
                checkNetIncomeLog: '',
                checkDandALog: '',
                checkIncreaseDecreaseInOtherItemsLog: '',
                checkOpCashFlowLog: '',
                checkCapexLog: '',
                checkInvestmentsInOtherAssetsLog: '',
                checkCashFromInvestLog: '',
                checkDividendLog: '',
                checkSaleOfEquityLog: '',
                checkIssueLTDebtLog: '',
                checkRepayLTDebtLog: '',
                checkOtherFinancingActivitiesLog: '',
                checkCashFromFinanceLog: '',
                checkNetIncreaseInCashAndEquivalentsLog: ''
            };

            let CFdata = { ...CFvalue, ...CFstatus, ...CFlog };

            let BSAvalue = {
                qCashValue: '',
                qAcctReceivableValue: '',
                qInventoryValue: '',
                prepaidExpensesValue: '',
                qCurrentAssetsValue: '',
                propertyPlantAndEquipmentValue: '',
                qAccumDepValue: '',
                qNetFixedAssetValue: '',
                qIntangiblesValue: '',
                totalOtherAssetsValue: '',
                qTotalAssetsValue: ''
            };

            let BSAstatus = {
                checkCashStatus: '',
                checkAcctReceivableStatus: '',
                checkInventoryStatus: '',
                checkPrepaidExpensesStatus: '',
                checkCurrentAssetsStatus: '',
                checkPropertyPlantAndEquipmentStatus: '',
                checkAccumDepStatus: '',
                checkNetFixedAssetStatus: '',
                checkIntangiblesStatus: '',
                checkTotalOtherAssetsStatus: '',
                checkTotalAssetsStatus: ''
            };

            let BSAlog = {
                checkCashLog: '',
                checkAcctReceivableLog: '',
                checkInventoryLog: '',
                checkPrepaidExpensesLog: '',
                checkCurrentAssetsLog: '',
                checkPropertyPlantAndEquipmentLog: '',
                checkAccumDepLog: '',
                checkNetFixedAssetLog: '',
                checkIntangiblesLog: '',
                checkTotalOtherAssetsLog: '',
                checkTotalAssetsLog: ''
            };

            let BSLvalue = {
                qAcctPayableValue: '',
                otherCurrentLiabilitiesValue: '',
                qShortTermNotesValue: '',
                otherShortTermLiabValue: '',
                qCurrentLiabValue: '',
                qLongTermDebtValue: '',
                qTotalLiabValue: '',
                nonCurrentLiabValue: '',
                capitalStockValue: '',
                qRetainedEarningsValue: '',
                totalEquityValue: '',
                totalLiabilitiesAndShareholdersEquityValue: ''
            };

            let BSLstatus = {
                checkAcctPayableStatus: '',
                checkOtherCurrentLiabilitiesStatus: '',
                checkShortTermNotesStatus: '',
                checkOtherShortTermLiabStatus: '',
                checkCurrentLiabStatus: '',
                checkLongTermDebtStatus: '',
                checkTotalLiabStatus: '',
                checkNonCurrentLiabStatus: '',
                checkCapitalStockStatus: '',
                checkRetainedEarningsStatus: '',
                checkTotalEquityStatus: '',
                checkTotalLiabilitiesAndShareholdersEquityStatus: ''
            };

            let BSLlog = {
                checkAcctPayableLog: '',
                checkOtherCurrentLiabilitiesLog: '',
                checkShortTermNotesLog: '',
                checkOtherShortTermLiabLog: '',
                checkCurrentLiabLog: '',
                checkLongTermDebtLog: '',
                checkTotalLiablog: '',
                checkNonCurrentLiabLog: '',
                checkCapitalStockLog: '',
                checkRetainedEarningsLog: '',
                checkTotalEquityLog: '',
                checkTotalLiabilitiesAndShareholdersEquityLog: ''
            };

            let BSdata = { ...BSAvalue, ...BSAstatus, ...BSAlog, ...BSLvalue, ...BSLstatus, ...BSLlog };

            ISdata.qSalesValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.sales)
            ) : (
                getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'sales')
            );

            ISdata.qGrossProfitValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.gross_profit)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'gross_profit');

            ISdata.costGoodsSoldValue = ISdata.qSalesValue - ISdata.qGrossProfitValue;

            ISdata.qDandAValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.d_and_a)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'd_and_a');

            ISdata.qEBITValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.EBIT)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'EBIT');

            ISdata.sGandAValue = ISdata.qGrossProfitValue - ISdata.qDandAValue - ISdata.qEBITValue;

            ISdata.qGrossInterestValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.gross_interest)
            ) :getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'gross_interest');


            ISdata.earningBeforeIncomeTaxesValue = ISdata.qEBITValue - ISdata.qGrossInterestValue;

            ISdata.qNetIncomeValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.net_income)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'net_income');

            ISdata.incomeTaxExpenseValue = ISdata.qEBITValue - ISdata.qGrossInterestValue - ISdata.qNetIncomeValue;

            CFdata.qNetIncomeValue = ISdata.qNetIncomeValue;
            CFdata.qDandAValue = ISdata.qDandAValue;

            CFdata.qOpCashFlowValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.op_cash_flow)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'op_cash_flow');

            CFdata.increaseDecreaseInOtherItemsValue = CFdata.qOpCashFlowValue - ISdata.qNetIncomeValue - ISdata.qDandAValue;

            CFdata.qCapexValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.capex)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'capex');

            CFdata.qCashFromInvestValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.cash_from_investment)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'cash_from_investment');

            CFdata.investmentsInOtherAssetsValue = CFdata.qCashFromInvestValue - CFdata.qCapexValue;

            CFdata.qDividendValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.dividend)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'dividend');

            CFdata.qSaleOfEquityValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.sale_of_equity)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'sale_of_equity');

            CFdata.qIssueLTDebtValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.issue_L_T_debt)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'issue_L_T_debt');

            CFdata.qRepayLTDebtValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.repay_long_term_debt)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'repay_long_term_debt');

            CFdata.qCashFromFinanceValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.cash_from_finance)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'cash_from_finance');

            CFdata.otherFinancingActivitiesValue = CFdata.qCashFromFinanceValue - CFdata.qDividendValue - CFdata.qSaleOfEquityValue - CFdata.qIssueLTDebtValue + CFdata.qRepayLTDebtValue;

            CFdata.netIncreaseInCashAndEquivalentsValue = CFdata.qOpCashFlowValue + CFdata.qCashFromInvestValue + CFdata.qCashFromFinanceValue;

            BSdata.qCashValue = checkEmptyString(quarterDataObj.cash);

            BSdata.qAcctReceivableValue = checkEmptyString(quarterDataObj.acct_receivable);

            BSdata.qInventoryValue = checkEmptyString(quarterDataObj.inventory);

            BSdata.qCurrentAssetsValue = checkEmptyString(quarterDataObj.current_assets);

            BSdata.prepaidExpensesValue = BSdata.qCurrentAssetsValue - BSdata.qCashValue - BSdata.qAcctReceivableValue - BSdata.qInventoryValue;

            BSdata.qAccumDepValue = checkEmptyString(quarterDataObj.accum_dep);

            BSdata.qNetFixedAssetValue = checkEmptyString(quarterDataObj.net_fixed_asset);

            BSdata.propertyPlantAndEquipmentValue = BSdata.qNetFixedAssetValue + BSdata.qAccumDepValue;

            BSdata.qIntangiblesValue = checkEmptyString(quarterDataObj.intangibles);

            BSdata.qTotalAssetsValue = checkEmptyString(quarterDataObj.total_assets);

            BSdata.totalOtherAssetsValue = BSdata.qTotalAssetsValue - BSdata.qNetFixedAssetValue - BSdata.qCurrentAssetsValue;

            BSdata.qAcctPayableValue = checkEmptyString(quarterDataObj.acct_payable);

            BSdata.qShortTermNotesValue = checkEmptyString(quarterDataObj.short_term_notes);

            BSdata.otherShortTermLiabValue = 0;

            BSdata.qCurrentLiabValue = checkEmptyString(quarterDataObj.current_liab);

            BSdata.otherCurrentLiabilitiesValue = BSdata.qCurrentLiabValue - BSdata.qAcctPayableValue - BSdata.qShortTermNotesValue - BSdata.otherShortTermLiabValue;

            BSdata.qLongTermDebtValue = checkEmptyString(quarterDataObj.L_T_debt);

            BSdata.qTotalLiabValue = checkEmptyString(quarterDataObj.total_liab);

            BSdata.nonCurrentLiabValue = BSdata.qTotalLiabValue - BSdata.qCurrentLiabValue;

            BSdata.qRetainedEarningsValue = checkEmptyString(quarterDataObj.retained_earnings);

            BSdata.totalEquityValue = BSdata.qTotalAssetsValue - BSdata.qTotalLiabValue;

            BSdata.capitalStockValue = BSdata.totalEquityValue - BSdata.qRetainedEarningsValue;

            BSdata.totalLiabilitiesAndShareholdersEquityValue = BSdata.qTotalLiabValue + BSdata.totalEquityValue;

            //Check debt values add up, otherwise adjust short term debt
            let totalDebt = checkEmptyString(quarterDataObj.total_debt);
            if (parseFloat(BSdata.qShortTermNotesValue) + parseFloat(BSdata.qLongTermDebtValue) !== parseFloat(totalDebt))
                BSdata.qShortTermNotesValue = totalDebt - BSdata.qLongTermDebtValue;

            let qFreeCashFlowValue = yearlyReport === 3 ? (
                checkEmptyString(quarterDataObj.free_cash_flow)
            ) : getLast4QuarterValueSum(companyObj, quarterDataObj.quarter, 'free_cash_flow');

            let netWorkingCapitalValue = BSdata.qCurrentAssetsValue - BSdata.qCurrentLiabValue;

            let debtToEquityValue = BSdata.qTotalLiabValue / BSdata.totalEquityValue;
            let currentRatioValue = BSdata.qCurrentAssetsValue / BSdata.qCurrentLiabValue;
            let interestCoverageRatioValue = ISdata.qEBITValue / ISdata.qGrossInterestValue;


            //Gross Profit = Sales - Cost of Goods Sold
            let grossProfitCal = ISdata.qSalesValue - ISdata.costGoodsSoldValue;
            let grossProfit = ISdata.qGrossProfitValue;

            //EBIT = Gross Profit - S,G and A - Depreciation and Amortization
            let EBITCal = ISdata.qGrossProfitValue - ISdata.sGandAValue - ISdata.qDandAValue;
            let EBIT = ISdata.qEBITValue;

            //EBT = EBIT - GrossInterest
            let EBTCal = ISdata.qEBITValue - ISdata.qGrossInterestValue;
            let EBT = ISdata.earningBeforeIncomeTaxesValue;

            //NetIncome = EBIT - GrossInterest - Income Tax
            let netIncomeCal = ISdata.qEBITValue - ISdata.qGrossInterestValue - ISdata.incomeTaxExpenseValue;
            let netIncome = ISdata.qNetIncomeValue;

            //opCashFlow = Net Income + D and A + Incr in Other Items
            let opCashFlowCal = ISdata.qNetIncomeValue + ISdata.qDandAValue + CFdata.increaseDecreaseInOtherItemsValue;
            let opCashFlow = CFdata.qOpCashFlowValue;

            //Cash From Investing = Capex + Invest in Other Assets
            let cashFromInvestCal = -CFdata.qCapexValue + CFdata.investmentsInOtherAssetsValue;
            let cashFromInvest = CFdata.qCashFromInvestValue;

            //Cash From Finance = Dividends + Sale of Equity + Issue Debt + Repay Debt + Other Finance
            let cashFromFinanceCal = CFdata.qDividendValue + CFdata.qSaleOfEquityValue + CFdata.qIssueLTDebtValue - CFdata.qRepayLTDebtValue + CFdata.otherFinancingActivitiesValue;
            let cashFromFinance = CFdata.qCashFromFinanceValue;

            //Total Current Assets = Cash + Accts Rec + Inventory + Prepaid Exp
            let currentAssetsCal = BSdata.qCashValue + BSdata.qAcctReceivableValue + BSdata.qInventoryValue + BSdata.prepaidExpensesValue;
            let currentAssets = BSdata.qCurrentAssetsValue;

            //Net Fixed Asset = PPE + Accum Dep
            let netFixedAssetCal = BSdata.propertyPlantAndEquipmentValue - BSdata.qAccumDepValue;
            let netFixedAsset = BSdata.qNetFixedAssetValue;

            //Total Assets = Total Current Assets + Total Fixed Assets + Other Assets
            let totalAssetsCal = BSdata.qCurrentAssetsValue + BSdata.qNetFixedAssetValue + BSdata.totalOtherAssetsValue;
            let totalAssets = BSdata.qTotalAssetsValue;

            //Total Current Liab = Accts Pay + STD + Other Current Liab + Other ST Liab
            let currentLiabCal = BSdata.qAcctPayableValue + BSdata.otherCurrentLiabilitiesValue + BSdata.qShortTermNotesValue + BSdata.otherShortTermLiabValue;
            let currentLiab = BSdata.qCurrentLiabValue;

            //Total Equity = Retained Earnings + Stock
            let totalEquityCal = BSdata.qRetainedEarningsValue + BSdata.capitalStockValue;
            let totalEquity = BSdata.totalEquityValue;

            //Total Liab + Equity = Total Current + Total Non-Current + Total Equity
            let totalLiabEquityCal = BSdata.qCurrentLiabValue + BSdata.nonCurrentLiabValue + BSdata.totalEquityValue;
            let totalLiabEquity = BSdata.totalLiabilitiesAndShareholdersEquityValue;

            if (autoCorrectData === true) {

                ISdata = autoCorrectIncomeStatement(ISdata);
                CFdata = autoCorrectCashFlowStatement(CFdata);
                BSdata = autoCorrectBalanceSheet(BSdata);

                //Update ratios, net working cap and total liabilities
                netWorkingCapitalValue = BSdata.qCurrentAssetsValue - BSdata.qCurrentLiabValue;

                if (BSdata.totalEquityValue === 0) debtToEquityValue = 0;
                else debtToEquityValue = BSdata.qTotalLiabValue / BSdata.totalEquityValue;
                if (BSdata.qCurrentLiabValue === 0) currentRatioValue = 0;
                else currentRatioValue = BSdata.qCurrentAssetsValue / BSdata.qCurrentLiabValue;
                if (ISdata.qGrossInterestValue === 0) interestCoverageRatioValue = 0;
                else interestCoverageRatioValue = ISdata.qEBITValue / ISdata.qGrossInterestValue;

                if (isNaN(qFreeCashFlowValue)) qFreeCashFlowValue = CFdata.qOpCashFlowValue - CFdata.qCapexValue;

            } else {
                if (grossProfitCal !== grossProfit) {
                    if (!isNaN(ISdata.qGrossProfitValue)) {
                        ISdata.checkGrossProfitStatus = 'warning';
                        ISdata.checkGrossProfitLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(ISdata.qSalesValue)) {
                        ISdata.checkSalesValueStatus = 'warning';
                        ISdata.checkSalesValueLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(ISdata.costGoodsSoldValue)) {
                        ISdata.checkCostGoodsSoldStatus = 'warning';
                        ISdata.checkCostGoodsSoldLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (EBITCal !== EBIT) {
                    if (!isNaN(ISdata.qEBITValue)) {
                        ISdata.checkEBITStatus = 'warning';
                        ISdata.checkEBITLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(ISdata.sGandAValue)) {
                        ISdata.checkSGandAStatus = 'warning';
                        ISdata.checkSGandALog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(ISdata.qDandAValue)) {
                        ISdata.checkDandAStatus = 'warning';
                        ISdata.checkDandALog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (EBTCal !== EBT) {
                    if (!isNaN(ISdata.earningBeforeIncomeTaxesValue)) {
                        ISdata.checkEarningBeforeIncomeTaxesStatus = 'warning';
                        ISdata.checkEarningBeforeIncomeTaxesLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(ISdata.qGrossInterestValue)) {
                        ISdata.checkGrossInterestStatus = 'warning';
                        ISdata.checkGrossInterestLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (netIncomeCal !== netIncome) {
                    if (!isNaN(ISdata.qNetIncomeValue)) {
                        ISdata.checkNetIncomeStatus = 'warning';
                        ISdata.checkNetIncomeLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(ISdata.incomeTaxExpenseValue)) {
                        ISdata.checkIncomeTaxExpenseStatus = 'warning';
                        ISdata.checkIncomeTaxExpenseLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (opCashFlowCal !== opCashFlow) {
                    if (!isNaN(CFdata.qOpCashFlowValue)) {
                        CFdata.checkOpCashFlowStatus = 'warning';
                        CFdata.checkOpCashFlowLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(CFdata.increaseDecreaseInOtherItemsValue)) {
                        CFdata.checkIncreaseDecreaseInOtherItemsStatus = 'warning';
                        CFdata.checkIncreaseDecreaseInOtherItemsLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (cashFromInvestCal !== cashFromInvest) {
                    if (!isNaN(CFdata.cashFromInvestValue)) {
                        CFdata.checkCashFromInvestStatus = 'warning';
                        CFdata.checkCashFromInvestLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(CFdata.qCapexValue)) {
                        CFdata.checkCapexStatus = 'warning';
                        CFdata.checkCapexLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(CFdata.investmentsInOtherAssetsValue)) {
                        CFdata.checkInvestmentsInOtherAssetsStatus = 'warning';
                        CFdata.checkInvestmentsInOtherAssetsLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (cashFromFinanceCal !== cashFromFinance) {
                    if (!isNaN(CFdata.cashFromFinanceValue)) {
                        CFdata.checkCashFromFinanceStatus = 'warning';
                        CFdata.checkCashFromFinanceLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(CFdata.qDividendValue)) {
                        CFdata.checkDividendStatus = 'warning';
                        CFdata.checkDividendLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(CFdata.qSaleOfEquityValue)) {
                        CFdata.checkSaleOfEquityStatus = 'warning';
                        CFdata.checkSaleOfEquityLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(CFdata.qIssueLTDebtValue)) {
                        CFdata.checkIssueLTDebtStatus = 'warning';
                        CFdata.checkIssueLTDebtLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(CFdata.qRepayLTDebtValue)) {
                        CFdata.checkRepayLTDebtStatus = 'warning';
                        CFdata.checkRepayLTDebtLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(CFdata.otherFinancingActivitiesValue)) {
                        CFdata.checkOtherFinancingActivitiesStatus = 'warning';
                        CFdata.checkOtherFinancingActivitiesLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (BSdata.qAcctPayableValue < 0) {
                    if (!isNaN(BSdata.qAcctPayableValue)) {
                        BSdata.checkAcctPayableStatus = 'warning';
                        BSdata.checkAcctPayableLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (BSdata.qAcctReceivableValue < 0) {
                    if (!isNaN(BSdata.qAcctPayableValue)) {
                        BSdata.checkAcctPayableStatus = 'warning';
                        BSdata.checkAcctPayableLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (BSdata.qTotalAssetsValue !== BSdata.totalLiabilitiesAndShareholdersEquityValue) {
                    if (!isNaN(BSdata.qTotalAssetsValue)) {
                        BSdata.checkTotalAssetsStatus = 'warning';
                        BSdata.checkTotalAssetsLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.totalLiabilitiesAndShareholdersEquityValue)) {
                        BSdata.checkTotalLiabilitiesAndShareholdersEquityStatus = 'warning';
                        BSdata.checkTotalLiabilitiesAndShareholdersEquityLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (currentAssetsCal !== currentAssets) {
                    if (!isNaN(BSdata.qCashValue)) {
                        BSdata.checkCashStatus = 'warning';
                        BSdata.checkCashLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.qAcctReceivableValue)) {
                        BSdata.checkAcctReceivableStatus = 'warning';
                        BSdata.checkAcctReceivableLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.qInventoryValue)) {
                        BSdata.checkInventoryStatus = 'warning';
                        BSdata.checkInventoryLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.prepaidExpensesValue)) {
                        BSdata.checkPrepaidExpensesStatus = 'warning';
                        BSdata.checkPrepaidExpensesLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.qCurrentAssetsValue)) {
                        BSdata.checkCurrentAssetsStatus = 'warning';
                        BSdata.checkCurrentAssetsLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (netFixedAssetCal !== netFixedAsset) {
                    if (!isNaN(BSdata.propertyPlantAndEquipmentValue)) {
                        BSdata.checkPropertyPlantAndEquipmentStatus = 'warning';
                        BSdata.checkPropertyPlantAndEquipmentLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.qAccumDepValue)) {
                        BSdata.checkAccumDepStatus = 'warning';
                        BSdata.checkAccumDepLog = 'Data Inconsistent';
                    }
                    if (!isNaN(BSdata.qNetFixedAssetValue)) {
                        BSdata.checkNetFixedAssetStatus = 'warning';
                        BSdata.checkNetFixedAssetLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (totalAssetsCal !== totalAssets) {
                    if (!isNaN(BSdata.qCurrentAssetsValue)) {
                        BSdata.checkCurrentAssetsStatus = 'warning';
                        BSdata.checkCurrentAssetsLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.qNetFixedAssetValue)) {
                        BSdata.checkNetFixedAssetStatus = 'warning';
                        BSdata.checkNetFixedAssetLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.totalOtherAssetsValue)) {
                        BSdata.checkTotalOtherAssetsStatus = 'warning';
                        BSdata.checkTotalOtherAssetsLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.qTotalAssetsValue)) {
                        BSdata.checkTotalAssetsStatus = 'warning';
                        BSdata.checkTotalAssetsLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }

                if (currentLiabCal !== currentLiab) {
                    if (!isNaN(BSdata.otherCurrentLiabilitiesValue)) {
                        BSdata.checkOtherCurrentLiabilitiesStatus = 'warning';
                        BSdata.checkOtherCurrentLiabilitiesLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.qShortTermNotesValue)) {
                        BSdata.checkShortTermNotesStatus = 'warning';
                        BSdata.checkShortTermNotesLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.otherShortTermLiabValue)) {
                        BSdata.checkOtherShortTermLiabStatus = 'warning';
                        BSdata.checkOtherShortTermLiabLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.qAcctPayableValue)) {
                        BSdata.checkAcctPayableStatus = 'warning';
                        BSdata.checkAcctPayableLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.qCurrentLiabValue)) {
                        BSdata.checkCurrentLiabStatus = 'warning';
                        BSdata.checkCurrentLiabLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (totalEquityCal !== totalEquity) {
                    if (!isNaN(BSdata.qRetainedEarningsValue)) {
                        BSdata.checkRetainedEarningsStatus = 'warning';
                        BSdata.checkRetainedEarningsLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.capitalStockValue)) {
                        BSdata.checkCapitalStockStatus = 'warning';
                        BSdata.checkCapitalStockLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.totalEquityValue)) {
                        BSdata.checkTotalEquityStatus = 'warning';
                        BSdata.checkTotalEquityLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (totalLiabEquityCal !== totalLiabEquity) {
                    if (!isNaN(BSdata.qCurrentLiabValue)) {
                        BSdata.checkCurrentLiabStatus = 'warning';
                        BSdata.checkCurrentLiabLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.nonCurrentLiabValue)) {
                        BSdata.checkNonCurrentLiabStatus = 'warning';
                        BSdata.checkNonCurrentLiabLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.totalEquityValue)) {
                        BSdata.checkTotalEquityStatus = 'warning';
                        BSdata.checkTotalEquityLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.totalLiabilitiesAndShareholdersEquityValue)) {
                        BSdata.checkTotalLiabilitiesAndShareholdersEquityStatus = 'warning';
                        BSdata.checkTotalLiabilitiesAndShareholdersEquityLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
                if (BSdata.qTotalAssetsValue !== BSdata.totalLiabilitiesAndShareholdersEquityValue) {
                    if (!isNaN(BSdata.qTotalAssetsValue)) {
                        BSdata.checkTotalAssetsStatus = 'warning';
                        BSdata.checkTotalAssetsLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                    if (!isNaN(BSdata.totalLiabilitiesAndShareholdersEquityValue)) {
                        BSdata.checkTotalLiabilitiesAndShareholdersEquityStatus = 'warning';
                        BSdata.checkTotalLiabilitiesAndShareholdersEquityLog = 'Data Inconsistent. Switch to auto-correct';
                    }
                }
            }

            let displayDataSetTemp = {

                qSales: {
                    log: ISdata.checkSalesValueLog,
                    status: ISdata.checkSalesValueStatus,
                    value: ISdata.qSalesValue,
                },
                costGoodsSold: {
                    log: ISdata.checkCostGoodsSoldLog,
                    status: ISdata.checkCostGoodsSoldStatus,
                    value: ISdata.costGoodsSoldValue,
                },
                qGrossProfit: {
                    log: ISdata.checkGrossProfitLog,
                    status: ISdata.checkGrossProfitStatus,
                    value: ISdata.qGrossProfitValue,
                },
                sGandA: {
                    log: ISdata.checkSGandALog,
                    status: ISdata.checkSGandAStatus,
                    value: ISdata.sGandAValue,
                },
                qDandA: {
                    log: ISdata.checkDandALog,
                    status: ISdata.checkDandAStatus,
                    value: ISdata.qDandAValue,
                },
                qEBIT: {
                    log: ISdata.checkEBITLog,
                    status: ISdata.checkEBITStatus,
                    value: ISdata.qEBITValue,
                },
                qGrossInterest: {
                    log: ISdata.checkGrossInterestLog,
                    status: ISdata.checkGrossInterestStatus,
                    value: ISdata.qGrossInterestValue,
                },
                earningBeforeIncomeTaxes: {
                    log: ISdata.checkEarningBeforeIncomeTaxesLog,
                    status: ISdata.checkEarningBeforeIncomeTaxesStatus,
                    value: ISdata.earningBeforeIncomeTaxesValue, //extra
                },
                incomeTaxExpense: {
                    log: ISdata.checkIncomeTaxExpenseLog,
                    status: ISdata.checkIncomeTaxExpenseStatus,
                    value: ISdata.incomeTaxExpenseValue, //extra
                },
                qNetIncome: {
                    log: ISdata.checkNetIncomeLog,
                    status: ISdata.checkNetIncomeStatus,
                    value: ISdata.qNetIncomeValue,
                },
                increaseDecreaseInOtherItems: {
                    log: CFdata.checkIncreaseDecreaseInOtherItemsLog,
                    status: CFdata.checkIncreaseDecreaseInOtherItemsStatus,
                    value: CFdata.increaseDecreaseInOtherItemsValue, //extra
                },
                qOpCashFlow: {
                    log: CFdata.checkOpCashFlowLog,
                    status: CFdata.checkOpCashFlowStatus,
                    value: CFdata.qOpCashFlowValue,
                },
                qCapex: {
                    log: CFdata.checkCapexLog,
                    status: CFdata.checkCapexStatus,
                    value: CFdata.qCapexValue,
                },
                investmentsInOtherAssets: {
                    log: CFdata.checkInvestmentsInOtherAssetsLog,
                    status: CFdata.checkInvestmentsInOtherAssetsStatus,
                    value: CFdata.investmentsInOtherAssetsValue, //extra
                },
                qCashFromInvest: {
                    log: CFdata.checkCashFromInvestLog,
                    status: CFdata.checkCashFromInvestStatus,
                    value: CFdata.qCashFromInvestValue,
                },
                qDividend: {
                    log: CFdata.checkDividendLog,
                    status: CFdata.checkDividendStatus,
                    value: CFdata.qDividendValue,
                },
                qSaleOfEquity: {
                    log: CFdata.checkSaleOfEquityLog,
                    status: CFdata.checkSaleOfEquityStatus,
                    value: CFdata.qSaleOfEquityValue,
                },
                qIssueLTDebt: {
                    log: CFdata.checkIssueLTDebtLog,
                    status: CFdata.checkIssueLTDebtStatus,
                    value: CFdata.qIssueLTDebtValue,
                },
                qRepayLTDebt: {
                    log: CFdata.checkRepayLTDebtLog,
                    status: CFdata.checkRepayLTDebtStatus,
                    value: CFdata.qRepayLTDebtValue,
                },
                otherFinancingActivities: {
                    log: CFdata.checkOtherFinancingActivitiesLog,
                    status: CFdata.checkOtherFinancingActivitiesStatus,
                    value: CFdata.otherFinancingActivitiesValue, //extra
                },
                qCashFromFinance: {
                    log: CFdata.checkCashFromFinanceLog,
                    status: CFdata.checkCashFromFinanceStatus,
                    value: CFdata.qCashFromFinanceValue,
                },
                netIncreaseInCashAndEquivalents: {
                    log: CFdata.checkNetIncreaseInCashAndEquivalentsLog,
                    status: CFdata.checkNetIncreaseInCashAndEquivalentsStatus,
                    value: CFdata.netIncreaseInCashAndEquivalentsValue, //extra
                },
                qCash: {
                    log: BSdata.checkCashLog,
                    status: BSdata.checkCashStatus,
                    value: BSdata.qCashValue,
                },
                qAcctReceivable: {
                    log: BSdata.checkAcctReceivableLog,
                    status: BSdata.checkAcctReceivableStatus,
                    value: BSdata.qAcctReceivableValue,
                },
                qInventory: {
                    log: BSdata.checkInventoryLog,
                    status: BSdata.checkInventoryStatus,
                    value: BSdata.qInventoryValue,
                },
                prepaidExpenses: {
                    log: BSdata.checkPrepaidExpensesLog,
                    status: BSdata.checkPrepaidExpensesStatus,
                    value: BSdata.prepaidExpensesValue, //extra
                },
                qCurrentAssets: {
                    log: BSdata.checkCurrentAssetsLog,
                    status: BSdata.checkCurrentAssetsStatus,
                    value: BSdata.qCurrentAssetsValue,
                },
                propertyPlantAndEquipment: {
                    log: BSdata.checkPropertyPlantAndEquipmentLog,
                    status: BSdata.checkPropertyPlantAndEquipmentStatus,
                    value: BSdata.propertyPlantAndEquipmentValue, //extra
                },
                qAccumDep: {
                    log: BSdata.checkAccumDepLog,
                    status: BSdata.checkAccumDepStatus,
                    value: BSdata.qAccumDepValue,
                },
                qNetFixedAsset: {
                    log: BSdata.checkNetFixedAssetLog,
                    status: BSdata.checkNetFixedAssetStatus,
                    value: BSdata.qNetFixedAssetValue,
                },
                qIntangibles: {
                    log: BSdata.checkIntangiblesLog,
                    status: BSdata.checkIntangiblesStatus,
                    value: BSdata.qIntangiblesValue,
                },
                totalOtherAssets: {
                    log: BSdata.checkTotalOtherAssetsLog,
                    status: BSdata.checkTotalOtherAssetsStatus,
                    value: BSdata.totalOtherAssetsValue, //extra
                },
                qTotalAssets: {
                    log: BSdata.checkTotalAssetsLog,
                    status: BSdata.checkTotalAssetsStatus,
                    value: BSdata.qTotalAssetsValue,
                },
                qAcctPayable: {
                    log: BSdata.checkAcctPayableLog,
                    status: BSdata.checkAcctPayableStatus,
                    value: BSdata.qAcctPayableValue,
                },
                otherCurrentLiabilities: {
                    log: BSdata.checkOtherCurrentLiabilitiesLog,
                    status: BSdata.checkOtherCurrentLiabilitiesStatus,
                    value: BSdata.otherCurrentLiabilitiesValue, //extra
                },
                qShortTermNotes: {
                    log: BSdata.checkShortTermNotesLog,
                    status: BSdata.checkShortTermNotesStatus,
                    value: BSdata.qShortTermNotesValue,
                },
                otherShortTermLiab: {
                    log: BSdata.checkOtherShortTermLiabLog,
                    status: BSdata.checkOtherShortTermLiabStatus,
                    value: BSdata.otherShortTermLiabValue,
                },
                qCurrentLiab: {
                    log: BSdata.checkCurrentLiabLog,
                    status: BSdata.checkCurrentLiabStatus,
                    value: BSdata.qCurrentLiabValue,
                },
                qLongTermDebt: {
                    log: BSdata.checkLongTermDebtLog,
                    status: BSdata.checkLongTermDebtStatus,
                    value: BSdata.qLongTermDebtValue,
                },
                nonCurrentLiab: {
                    log: BSdata.checkNonCurrentLiabLog,
                    status: BSdata.checkNonCurrentLiabStatus,
                    value: BSdata.nonCurrentLiabValue,
                },
                capitalStock: {
                    log: BSdata.checkCapitalStockLog,
                    status: BSdata.checkCapitalStockStatus,
                    value: BSdata.capitalStockValue, //extra
                },
                qRetainedEarnings: {
                    log: BSdata.checkRetainedEarningsLog,
                    status: BSdata.checkRetainedEarningsStatus,
                    value: BSdata.qRetainedEarningsValue,
                },
                totalEquity: {
                    log: BSdata.checkTotalEquityLog,
                    status: BSdata.checkTotalEquityStatus,
                    value: BSdata.totalEquityValue,
                },
                totalLiabilitiesAndShareholdersEquity: {
                    log: BSdata.checkTotalLiabilitiesAndShareholdersEquityLog,
                    status: BSdata.checkTotalLiabilitiesAndShareholdersEquityStatus,
                    value: BSdata.totalLiabilitiesAndShareholdersEquityValue, //extra
                },
                qFreeCashFlow: {
                    log: '',
                    status: '',
                    value: qFreeCashFlowValue,
                },
                netWorkingCapital: {
                    log: '',
                    status: '',
                    value: netWorkingCapitalValue,
                },
                totalLiabilitiesShortLongTerm: {
                    log: '',
                    status: '',
                    value: BSdata.qCurrentLiabValue + BSdata.nonCurrentLiabValue,
                },
                debtToEquity: {
                    log: '',
                    status: '',
                    value: debtToEquityValue,
                },
                currentRatio: {
                    log: '',
                    status: '',
                    value: currentRatioValue,
                },
                interestCoverageRatio: {
                    log: '',
                    status: '',
                    value: interestCoverageRatioValue,
                },
                microUnemployment: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.unemployment,
                },
                microDebtToIncome: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.household_debt_to_income,
                },
                microDebtToGdp: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.household_debt_to_GDP,
                },
                microHousePrice: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.house_price_YoY,
                },
                microFixedIncome: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.fixed_income,
                },
                microCommodities: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.commodities,
                },
                microEmployment: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.employment_ratio,
                },
                microRetailSales: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.retail_sales_YoY
                },
                microOutputGap: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.output_gap
                },
                microAutoSales: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.auto_sales
                },
                microVol: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.vol
                },
                microCredit: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.credit
                },
                microRates2Y: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.rates_2Y
                },
                microIndustrialProduction: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.industrial_production_YoY
                },
                microBusinessOptimism: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.business_optimism
                },
                microCPI: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.CPI_YoY
                },
                microPPI: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.PPI_YoY
                },
                microEnergy: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.energy
                },
                microOil: {
                    log: '',
                    status: '',
                    value: !macroDailyData ? '--' : macroDailyData.oil
                }
            };

            let displayRatingSetTemp = {
                fitchRating: {
                    log: '',
                    status: '',
                    value: !macroDailyData || !quarterDataObj.agencyRatings['Fitch']['rating'] ? '--' : quarterDataObj.agencyRatings['Fitch']['rating'],
                },
                moodysRating: {
                    log: '',
                    status: '',
                    value: !macroDailyData || !quarterDataObj.agencyRatings['Moody']['rating']? '--' : quarterDataObj.agencyRatings['Moody']['rating'],
                },
                spRating: {
                    log: '',
                    status: '',
                    value: !macroDailyData || !quarterDataObj.agencyRatings['SandP']['rating']? '--' : quarterDataObj.agencyRatings['SandP']['rating'],
                }
            };
            let ratingTemp;
            let scoreTemp;
            let displayRoboR8Result;
            if (autoCorrectData === true) {
                displayRoboR8Result = { roboR8: roboR8Result };
                ratingTemp = roboR8Rating;
                scoreTemp = roboR8Score;
            }
            else {
                displayRoboR8Result = { roboR8: zeroResult };
                ratingTemp = 0;
                scoreTemp = 0;
            }

            let displayDataSetFinal = Object.entries(displayDataSetTemp).reduce((obj, [key, value]) => {
                    obj[key] = {
                        log: value.log ? value.log : this.handleLog(value.value),
                        status: value.status ? value.status : this.handleStatus(value.value),
                        value: isNaN(value.value) ? "--" : convertToDecimal(value.value)
                    };
                    return obj;
                }
                , {});


            displayDataSetFinal = {
                industry: companyIndustry,
                qEmployees: quarterDataObj.employees,
                qReportDate: quarterDataObj.report_date,
                qFiscalDate: quarterDataObj.fiscal_date,
                ...displayDataSetFinal,
                ...displayRatingSetTemp,
                ...displayRoboR8Result,
                roboR8rating: ratingTemp,
                roboR8score: scoreTemp,
                roboR8date: roboR8date,
                roboR8target: roboR8target,
                roboR8peers: peerResults, // *ACTUAL PEERS* used by radar and scatter plot in VS industry mode
                roboR8peersHistory: peerResultsHistory, // used by scatter plots in VS industry mode
                roboR8peersData: peerData,  // *ALL COMPANIES* used by radar map in VS industry mode
                roboR8peersBars: peerCreditBars // used by peer rating score skews in VS industry mode
            };

            this.props.updateCompanyData(displayDataSetFinal);
        }

        this.handleMigoResult(prevProps, migoResult);
    }

    handleMigoResult(prevProps, migoResult) {
        if (prevProps.migoResult.quantiles !== migoResult.quantiles && migoResult.quantiles) {
            let roboR8Result = [];           //roboR8 rating result for the company [Rating, Prob]
            let roboR8Score = 0;             //roboR8 numerical score
            let roboR8Rating = 0;            //roboR8 rating (mode)
            let roboR8target = undefined;    //roboR8 target

            let quantiles = migoResult.quantiles;

            let roboR8max = 0;

            for (let i = 1; i <= 24; i++) {
                let ithQuantile = parseFloat(quantiles[i - 1]);
                if (ithQuantile > roboR8max) {
                    roboR8max = ithQuantile;
                    roboR8Rating = i;
                }
                roboR8Score += i * ithQuantile;

                roboR8Result.push({
                    rating: mapScoreToRating(i).migo,
                    prob: ithQuantile * 100,
                })
            }

            let today = new Date();
            let ddmmyy = getDDMMYY(today);

            roboR8Score = (parseFloat(roboR8Score) * 100) / 24;  //zero if no rating
            let partialCompanyDataUpdate = {
                roboR8: roboR8Result,
                roboR8rating: roboR8Rating,
                roboR8score: roboR8Score,
                roboR8target: roboR8target,
                roboR8date: ddmmyy
            };
            this.props.updateCompanyData(partialCompanyDataUpdate);
        }
        if (prevProps.migoResult.quantiles !== migoResult.quantiles && migoResult.migoError){
            this.openMigoErrorNotification(migoResult.migoError);
        }
        if (!prevProps.batchError && this.props.batchError){
            this.openMigoErrorNotification("An error occurred during Migo's batch scoring operation, please try again later.");
        }
    }

    async handlePdf() {
        this.setState({ downloadPDF: true });
        const input = document.getElementById('root');
        window.scrollTo(0,0);
        await html2canvas(input, {
            allowTaint: true,
            foreignObjectRendering: true,
            scale: 2
        })
            .then((canvas) => {
                // var a = document.createElement('a');
                const imgData =  canvas.toDataURL("image/jpeg");

                // a.wnload = 'download.jpg';
                // a.clichref = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
                // a.dok();
                const doc = new jsPDF('l', 'pt', [ canvas.width/2, canvas.height/2]);
                doc.addImage(imgData, 'PNG', 0, 0, canvas.width/2, canvas.height/2);
                doc.save('download.pdf');
                this.setState({ downloadPDF: false })
            });
    }


    handleLog(value) {
        if (isNaN(value)) {
            return 'Data Unavailable';
        } else if (value === Infinity || value === -Infinity) {
            return 'Value cannot divide by 0';
        } else {
            return '';
        }
    }

    handleStatus(value) {
        if (isNaN(value)) {
            return 'error';
        } else if (value === Infinity || value === -Infinity) {
            return 'error';
        } else {
            return '';
        }
    }

    openNotification1() {
        notification['error']({
            message: 'Error',
            description: 'Please check the fundamental data, selected report quarter not found.',
            duration: 5,
        });
    };

    openNotification2() {
        notification['error']({
            message: 'Error',
            description: 'Please check the macro-economic data, no data for the selected report date.',
            duration: 5,
        });
    };

    openNotification3() {
        notification['error']({
            message: 'Error',
            description: 'Please check the ratings data, company not found.',
            duration: 5,
        });
    };

    openNotification4() {
        notification['error']({
            message: 'Error',
            description: 'Please check the agency ratings data, no data for the selected report date.',
            duration: 5,
        });
    };

    openNotification5() {
        notification['error']({
            message: 'Error',
            description: 'Please check the roboR8 ratings data, no data for the selected report date and company.',
            duration: 5,
        });
    };

    openNotification6() {
        notification['error']({
            message: 'Error',
            description: 'Please choose a company to examine from the top selection bar',
            duration: 5,
        });
    };

    openMigoErrorNotification(msg) {
        notification['error']({
            message: 'Error',
            description: msg,
            duration: 5,
        });
    };

    loadCompanyList(){
        const promise = new Promise((resolve, reject) => {
            axiosInstance.get("/api/comdata/get-com-list").then(response=> {
                resolve(response.data)
            })
                .catch(err=>{
                    console.log(err.response);
                })
        });
        return promise;
    }

    loadDataFromLocalStorage() {
        const promise = new Promise((resolve, reject) => {
            // Wipe the localStorage if version
            const dataStoreVersion = localStorage.getItem('dataStoreVersion');
            const requiredVersion = "0.1.1";
            if (!dataStoreVersion || isNewerVersion(dataStoreVersion, requiredVersion)){
                localStorage.removeItem('t12mDataStore');
                localStorage.removeItem('qDataStore');
                localStorage.setItem('dataStoreVersion', process.env.REACT_APP_VERSION);
            }

            // recover t12m data to new store
            const t12mDataStore = localStorage.getItem('t12mDataStore')
                ? JSON.parse(localStorage.getItem('t12mDataStore'))
                : {};
            const t12mListByCompany = [];
            for (let [region, regionData] of Object.entries(t12mDataStore))
                for (let [cluster, clusterData] of Object.entries(regionData))
                    for (let [companyId, t12mData] of Object.entries(clusterData))
                        t12mListByCompany.push(
                            {
                                region: region, cluster: cluster, t12mData: t12mData
                            }
                        );
            // recover company meta and 4 quarterly datas to orig store
            const qDataStore = localStorage.getItem('qDataStore')
                ? JSON.parse(localStorage.getItem('qDataStore'))
                : {};
            const qListByCompany = Object.values(qDataStore);
            resolve({t12mListByCompany: t12mListByCompany, qListByCompany: qListByCompany});
        });

        return promise;
    }

    loadTileBounds(){
        const promise = new Promise((resolve, reject) => {
            axiosInstance.get("/api/comdata/tile-bounds").then(response=> {
                resolve(response.data)
            })
                .catch(err=>{
                    console.log(err.response);
                })
        });
        return promise;
    }

    async readData() {
        await Promise.all([
            this.loadCompanyList(),
            this.loadDataFromLocalStorage(),
            this.loadTileBounds(),
        ]).then((data) => {
            this.props.updateCompanyList(data[0]);
            this.props.addNewEntriesToSheet(data[1].t12mListByCompany);
            this.props.addNewEntriesToCompanyResults(data[1].qListByCompany);
            this.setState({
                loading: false,
            }, () => {
                console.log(this.state)
            });
            this.props.allTileBoundsLoaded(data[2])
            this.props.currentCompanyChanged('AAPL-US');
        })
    }

    runCreditScore(){
        let data = this.props.companyData;
        let args = [];
        args.push(this.props.headerSelected.companyRegion);
        args.push(this.props.headerSelected.companyID.split('-')[0]);
        args.push(this.props.headerSelected.companyName);

        const companyMeta = this.props.companyList[this.props.headerSelected.companyRegion]
            [this.props.headerSelected.companyCluster]
            [this.props.headerSelected.companyGIC][this.props.headerSelected.companyID];

        args.push(companyMeta.GICS1);
        args.push(companyMeta.industry);
        args.push(companyMeta.GICS3);
        args.push(companyMeta.GICS4);
        args.push(companyMeta.RekikiSector);
        args.push(companyMeta.CountryOfAsset);
        args.push(companyMeta.CurrencyOfAsset);

        let dateParts = data.qReportDate.split("/");
        // month is 0-based, that's why we need dataParts[1] - 1
        var reportDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        let excelDate = Math.floor(reportDate/8.64e7) + 25570;
        args.push(excelDate);
        args.push(data.qEmployees);

        let macros = getIndustrySpecificMacroData(data);
        args.push(Object.values(macros)[0].value);
        args.push(Object.values(macros)[1].value);
        args.push(Object.values(macros)[2].value);
        args.push(Object.values(macros)[3].value);
        args.push(Object.values(macros)[4].value);
        args.push(Object.values(macros)[5].value);
        args.push(data.qSales.value);
        args.push(data.costGoodsSold.value);
        args.push(data.qGrossProfit.value);
        args.push(data.sGandA.value);
        args.push(data.qDandA.value);
        args.push(data.qEBIT.value);
        args.push(data.qGrossInterest.value);
        args.push(data.earningBeforeIncomeTaxes.value);
        args.push(data.incomeTaxExpense.value);
        args.push(data.qNetIncome.value);
        args.push(data.increaseDecreaseInOtherItems.value);
        args.push(data.qOpCashFlow.value);
        args.push(data.qCapex.value);
        args.push(data.investmentsInOtherAssets.value);
        args.push(data.qCashFromInvest.value);
        args.push(data.qDividend.value);
        args.push(data.qSaleOfEquity.value);
        args.push(data.qIssueLTDebt.value);
        args.push(data.qRepayLTDebt.value);
        args.push(data.otherFinancingActivities.value);
        args.push(data.qCashFromFinance.value);
        args.push(data.netIncreaseInCashAndEquivalents.value);
        args.push(data.qCash.value);
        args.push(data.qAcctReceivable.value);
        args.push(data.qInventory.value);
        args.push(data.prepaidExpenses.value);
        args.push(data.qCurrentAssets.value);
        args.push(data.propertyPlantAndEquipment.value);
        args.push(data.qAccumDep.value);
        args.push(data.qNetFixedAsset.value);
        args.push(data.qIntangibles.value);
        args.push(data.totalOtherAssets.value);
        args.push(data.qTotalAssets.value);
        args.push(data.qAcctPayable.value);
        args.push(data.otherCurrentLiabilities.value);
        args.push(data.qShortTermNotes.value);
        args.push(data.otherShortTermLiab.value);
        args.push(data.qCurrentLiab.value);
        args.push(data.qLongTermDebt.value);
        args.push(data.nonCurrentLiab.value);
        args.push(data.qRetainedEarnings.value);
        args.push(data.totalEquity.value);
        args.push(data.totalLiabilitiesAndShareholdersEquity.value);

        // get previous quarter's 24 quantiles
        const companyResults = this.props.companyResults[this.props.headerSelected.companyID];
        const previousQuarter = prevQuarter(this.props.headerSelected.reportingPeriod);
        if (companyResults[previousQuarter]&&companyResults[previousQuarter].roboR8Results)
            args = args.concat(companyResults[previousQuarter].roboR8Results.quantiles);

        this.props.runCreditScore(args, this.props.headerSelected.companyID, previousQuarter)
    }

    runBatchScore(){
        // get selected region and cluster.
        const region = this.props.selectedUploadTabRegion;
        const cluster = this.props.selectedUploadTabCluster;
        // get selected rows.
        const selected = this.props.selectedCompanyIds;
        const mergedDataStore = mergeDeep({}, this.props.t12mDataStore, this.props.t12mDataStoreServer);
        const rows = [];
        for (let id of selected){
            rows.push(mergedDataStore[region][cluster][id]);
        }
        // call action
        this.props.runBatchScore(rows, region);
    }

    render() {
        const runCreditButton = (<Button size={size} shape="round" type="primary" className="run-credit"
                                         disabled={this.props.yearlyReport !== 12 || !this.props.customisedData || this.props.loadingCreditScore}
                                         onClick={()=>this.runCreditScore()}>
            Run Credit Score
        </Button>);

        const runBatchButton = (<Button size={size} shape="round" type="primary" className="run-credit"
            disabled={this.props.selectedCompanyIds.length === 0 || this.props.activeTabKey !== "4" || this.props.batchScoring}
                                         onClick={()=>this.runBatchScore()}>
            Run Batch Score
        </Button>);

        let BatchButtonTooltip = (this.props.selectedUploadTabRegion === undefined && this.props.selectedUploadTabCluster === undefined) ?
            "Ensure Region and Cluster are specified in the 'Upload Data' tab before running batch score": "Select rows in the table under 'Upload Data' tab to enable.";

      const tabExtraContent = (
        <div className="tab-extra-operation">
            <Button size={size} shape="round" loading={this.state.downloadPDF} icon={<DownloadOutlined />} className="download-pdf" onClick={()=>this.handlePdf()}>
                Download PDF
            </Button>
            {this.props.yearlyReport!==12||!this.props.customisedData ?
                (<Tooltip trigger={'hover'} title="Switch to 'T12M' and check 'Customised' checkbox to enable.">
                        {runCreditButton}
                </Tooltip>) : runCreditButton
            }
            {this.props.selectedCompanyIds.length===0 ?
                (<Tooltip trigger={'hover'} title={BatchButtonTooltip}>
                    {runBatchButton}
                </Tooltip>) : runBatchButton
            }
        </div>
      );

        return (
            <>
                {
                    !this.state.loading ? (
                        <div>
                            <Header />
                            <div className={`load_quarter_spinner ${this.state.companyDataLoading? "load_quarter_spinner_show":"load_quarter_spinner_hide"}`}>
                                <Spin tip="Loading..." />
                            </div>
                            <div className="body-wrapper">
                                <div className="container-fluid">
                                    <Tabs defaultActiveKey={this.props.activeTabKey} tabBarExtraContent={tabExtraContent} size={size} onChange={(key)=>this.props.updateActiveTab(key)} className="tab">
                                        <Tabs.TabPane tab="Summary View" key="1">
                                            <SimpleView />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab="Detailed View" key="2">
                                            <DistributionMode />
                                        </Tabs.TabPane>
                                        {!this.props.headerSelected.companyID ?
                                            <Tabs.TabPane tab="vs Industry Mode" key="3" disabled = "true">
                                            </Tabs.TabPane> :
                                            <Tabs.TabPane tab="vs Industry Mode" key="3">
                                                <Spin spinning={this.props.loadingCreditScore} tip="MIGO is processing...">
                                                    <VsIndustryMode />
                                                </Spin>
                                            </Tabs.TabPane>
                                        }
                                        <Tabs.TabPane tab="Upload Data" key="4">
                                            <UploadSheet />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab={<span style={{ color: "deeppink" }}> Help < QuestionCircleOutlined/> </span>} key = "5">
                                            <HelpReadMe />
                                        </Tabs.TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="loading-wrapper">
                            <Spin tip="Loading..." />
                        </div>
                    )
                }
            </>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        loading: state.loadingReducer.loading,
        headerSelected: state.headerSelectedReducer.headerSelected,
        yearlyReport: state.yearlyReportReducer.yearlyReport,
        autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
        customisedData: state.customisedDataReducer.customisedData,
        companyData: state.companyDataReducer.companyData,
        companyResults: state.companyListReducer.companyResults,
        macroData: state.macroEconomyReducer.data,
        migoResult: state.migoReducer,
        companyList: state.companyListReducer.companyList,
        loadingCreditScore: state.migoReducer.loadingCreditScore,
        activeTabKey: state.activeTabReducer.key,
        selectedCompanyIds: state.uploadTabReducer.selectedCompanyIds,
        t12mDataStore: state.uploadTabReducer.t12mDataStore,
        t12mDataStoreServer: state.uploadTabReducer.t12mDataStoreServer,
        selectedUploadTabRegion: state.uploadTabReducer.selectedRegion,
        selectedUploadTabCluster: state.uploadTabReducer.selectedCluster,
        batchScoring: state.uploadTabReducer.batchScoring,
        batchError: state.uploadTabReducer.batchError
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateCompanyData: (data) => {
            dispatch(
                companyData(data)
            )
        },
        updateCompanyList: (data) => {
            dispatch(
                companyList(data)
            )
        },
        runCreditScore: (args, companyId, prevQuarter) => {
            dispatch(
                submitDataForCreditRating(args, companyId, prevQuarter)
            )
        },
        runBatchScore: (rows, region) => {
            dispatch(
                submitDataForBatchRating(rows, region)
            )
        },
        updateActiveTab: (key) => {
            dispatch(
                changeActiveTab(key)
            )
        },
        addNewEntriesToSheet: (t12mListByCompany) => {
            dispatch(newUploadSheetEntries(t12mListByCompany))
        },
        addNewEntriesToCompanyResults: (qListByCompany) => {
            dispatch(newCompanyResultEntries(qListByCompany))
        },
        allTileBoundsLoaded: (data) => {
            dispatch(allTileBoundsLoaded(data))
        },
      currentCompanyChanged: (company) => {
        dispatch(
          currentCompanyChanged(company)
        )
      },
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(index);


