import {combineReducers} from'redux';
import darkModeReducer from './darkModeReducer';
import loadingReducer from './loadingReducer';
import headerSelectedReducer from './headerSelectedReducer'
import companyIdIndexReducer from './companyIdIndexReducer'
import companyDataReducer from './companyDataReducer'
import yearlyReportReducer from './yearlyReportReducer'
import autoCorrectDataReducer from './autoCorrectDataReducer'
import customisedDataReducer from './customisedDataReducer'
import bubbleGraphReducer from './bubbleGraphReducer'
import radarPeersReducer from './radarPeersReducer'
import authReducer from './auth'
import companyListReducer from "./companyListReducer";
import macroEconomyReducer from "./macroEconomyReducer";
import migoReducer from "./migoReducer";
import activeTabReducer from "./activeTabReducer";
import uploadTabReducer from "./uploadTabReducer";
import globalStyleReducer from "./globalStyleReducer";
import migoModelReducer from "./migoModelReducer";
import companyContextReducer from "./companyContextReducer";

const rootReducer = combineReducers({
    darkModeReducer,
    loadingReducer,
    headerSelectedReducer,
    companyIdIndexReducer,
    companyDataReducer,
    yearlyReportReducer,
    autoCorrectDataReducer,
    customisedDataReducer,
    bubbleGraphReducer,
    radarPeersReducer,
    authReducer,
    companyListReducer,
    macroEconomyReducer,
    migoReducer,
    activeTabReducer,
    uploadTabReducer,
    globalStyleReducer,
    migoModelReducer,
    companyContextReducer,
});

export default rootReducer;