import * as types from "../constants";
import axiosInstance from "../../utils/axios";
import {getISOLastDayOfPrevMonth} from "../../utils";

export const fetchMacroForRegionDate = (region, date) => {
    return (dispatch, getState) => {
        const lastDayPrevMonth = getISOLastDayOfPrevMonth(date);
        const macroData = getState().macroEconomyReducer.data;
        if(typeof macroData[region]!=="undefined"&&macroData[region][lastDayPrevMonth])
            return;
        let url = `/api/macrodata/${region}/${lastDayPrevMonth}`;
        axiosInstance.get(url)
            .then(response=> {
                dispatch(macroDataLoaded(region, lastDayPrevMonth, response.data))
            })
            .catch(err=>{
                dispatch(macroDataLoaded(region, lastDayPrevMonth, null))
            });
    }
};

export const macroDataLoaded = (region, date, data) => {
    return {
        type: types.MACRO_DATA_LOADED,
        data: data,
        region: region,
        date: date
    };
};