import React from "react";
import {Link, Redirect, Route, withRouter} from 'react-router-dom'
import {Form, Input, Button, Checkbox, Modal} from "antd";
import {UserOutlined, LockOutlined} from '@ant-design/icons'

import "./index.css";
import "antd/dist/antd.css";
import logo from "../../assets/images/LandingPage/robor8-logo.svg";
import * as actions from '../../redux/actions/index'
import {connect} from "react-redux";
import laptopPhoneApp from "../../assets/images/LandingPage/laptop-phone-app.png"
import appstore from "../../assets/images/LandingPage/appstore.png"
import googleplay from "../../assets/images/LandingPage/googleplay.png"
import phone from "../../assets/images/LandingPage/phone.png"
import laptop from "../../assets/images/LandingPage/laptop.png"
import phone2 from "../../assets/images/LandingPage/phone2.png"
import scale from "../../assets/images/LandingPage/scale.svg"
import orbiting from "../../assets/images/LandingPage/orbiting.svg"
import magnifier from "../../assets/images/LandingPage/magnifier.svg"
import barchart from "../../assets/images/LandingPage/barchart.svg"
import cloud from "../../assets/images/LandingPage/cloud.svg"
import ruler from "../../assets/images/LandingPage/ruler.svg"
import AboutUs from "./aboutUs";
import Migo from "./migo";
import Faq from "./faq";

function openDisclaimer(){
  Modal.info({
    title: 'roboR8 is NOT a credit rating agency',
    width: 1000,
    content: (
      <div style={{ fontSize: "1.2rem", textAlign: "justify", textJustify: "inter-word" }}>
        <font color="#3C87D7">
          <p>
            roboR8&trade uses <font face="arial"> MIGO&trade; </font>, a proprietary machine-learning system, to produce scores which are
            congruent to the ratings from more widely recognised credit rating agencies. roboR8&trade's
            output is fully determined by <font face="arial"> MIGO&trade; </font>, with no subjective opinion of credit-worthiness.
            Rekiki PTE Ltd is a technology firm and a member of the Singapore FinTech Association.
            roboR8&trade is a product of Rekiki PTE Ltd. Neither roboR8&trade nor Rekiki PTE Ltd are credit
            rating agencies and neither is certified by any regulating authority to provide credit ratings.<br /> <br /> </p>

          <p>
            NO WARRANTY, EXPRESS OR IMPLIED, AS TO THE ACCURACY, TIMELINESS, COMPLETENESS, MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR
            PURPOSE OF ANY SCORE, RATING DISTRIBUTION, ASSESSMENT, OTHER OPINION OR INFORMATION MADE BY ROBOR8 IN ANY FORM OR MANNER WHATSOEVER.
            ROBOR8'S SCORE, RATING DISTRIBUTION, ASSESSMENT OR OTHER OPINION ARE NOT AND DO NOT PROVIDE RECOMMENDATIONS ON THE SUITABILITY OF AN
            INVESTMENT FOR ANY PARTICULAR INVESTOR OR EXPOSURE TO A COMPANY FOR ANY PARTICULAR USER. ROBOR8 PROVIDES ITS OUTPUT WITH THE
            EXPECTATION AND UNDERSTANDING THAT EACH USER WILL, WITH DUE CARE, MAKE ITS OWN STUDY AND EVALUATION OF EACH COMPANY THAT IS UNDER CONSIDERATION. </p>
        </font>
        <font face="font1 bold" color = "red">
          <p>
            Please press "OK" if you understand the above and wish to proceed.
          </p>
        </font>
      </div>
    ),
    onOk() {
      console.log('Notification Clicked!');
    },
  });
};

const FormItem = Form.Item;
class NormalLoginForm extends React.Component {
    formRef = React.createRef();

    onFinish = values => {
        this.props.onAuth(values.username, values.password, values.remember)
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    componentDidUpdate() {
      if (this.props.error) {
        this.formRef.current.setFields([
          {
            name: 'submit',
            errors: ['Wrong username/password combination'],
          }
        ]);
      }
    }

    render() {
        let authRedirect = null;
        if(this.props.isAuthenticated){
          openDisclaimer();
          authRedirect = <Redirect to="/app"/>
        }

        const loginPageFirst = () => (
          <>
            <div className="login-div-row2">
              <div className="login-div-row2-left">
                <div>
                  <div className="login-div-title">
                    Democratizing
                    Credit Scores
                  </div>
                  <div className="login-div-subtitle">
                    Unbiased, accessible company ratings for all,<br/>
                    powered by machine intelligence.<br/>
                    Download the app today!
                  </div>
                  <Form onFinish={this.onFinish} onFinishFailed={this.onFinishFailed} className="login-form" ref={this.formRef}>
                    <FormItem
                      name="username"
                      rules={[{ required: true, message: "Please input your username!" }]}>
                      <Input
                        size="large"
                        className="login-form-input"
                        prefix={<UserOutlined style={{ fontSize: 13 }} />}
                        placeholder="Username"
                        //defaultValue="guest"
                        //disabled
                      />
                    </FormItem>

                    <FormItem
                      name="password"
                      rules={[{ required: true, message: "Please input your Password!" }]}>
                      <Input
                        size="large"
                        className="login-form-input"
                        prefix={<LockOutlined style={{ fontSize: 13 }} />}
                        type="password"
                        placeholder="Password"
                        //defaultValue="BCVSQFNW4karb7KJ"
                        //disabled
                      />
                    </FormItem>
                    <div style={{display: 'flex', flexDirection:'row', justifyContent:"space-between", alignItems:"center"}}>
                      <Form.Item name="remember" valuePropName="checked">
                        <Checkbox className="login-form-subtext">Remember me</Checkbox>
                      </Form.Item>
                      <Form.Item name="forgot">
                        <a href="" className="login-form-subtext">
                          Forgot password?
                        </a>
                      </Form.Item>
                    </div>
                    <FormItem
                      name="submit">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        Enter
                      </Button>
                    </FormItem>
                    <div style={{ fontSize: 16, color: "white", textAlign: "center", fontFamily: "font1 medium" }}>
                      {"Request login from "}
                      <a href="mailto: support@robor8.com" style={{ fontSize: 16, color: "white", textAlign: "center", textDecoration: "underline", fontFamily: "font1 medium" }}>
                         support@robor8.com
                      </a>
                    </div>                
                  </Form>
                  <div className="login-div-download-row">
                    <a target='_blank' rel='noopener noreferrer' href={"https://apps.apple.com/app/id1592953521"}>
                      <img src={appstore} alt="appstore" className="login-div-appstore" />
                    </a>
                    <a target='_blank' rel='noopener noreferrer' href={"https://play.google.com/store/apps/details?id=com.robor8_app"}>
                      <img src={googleplay} alt="googleplay" className="login-div-googleplay" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="login-div-row2-right">
                <img
                  alt="laptopPhoneApp"
                  src={laptopPhoneApp}
                  className="login-div-laptopphoneapp" />
              </div>
            </div>
          </>
        )

        const loginPageRest = () => (
          <>
            <div className="login-div2">
              <div className="el--1">
                <img src={phone} alt="phone" className="phone"/>
              </div>
              <div className="el--2">
                <img src={laptop} alt="laptop" className="laptop"/>
              </div>
              <div className="el--3">
                <img src={phone2} alt="phone2" className="phone"/>
              </div>
              <div className="el--4">
                <div className="login-div2-text1">
                  Track your supply chain and customer-base risk
                </div>
              </div>
              <div className="el--5">
                <div className="login-div2-text2">
                  Compare company scores
                  versus their peers
                </div>
              </div>
              <div className="el--6">
                <div className="login-div2-text3">
                  Give your company a health check
                </div>
              </div>
            </div>
            <div className="login-div3">
              <div className="login-div3-row">
                <div className="login-div3-icon-row">
                  <img src={scale} alt='scale' />
                  <img src={orbiting} alt='orbiting' />
                  <img src={magnifier} alt='magnifier' />
                </div>
                <div className="login-div3-title-row">
                  <div className="login-div3-title">
                    Unbiased
                  </div>
                  <div className="login-div3-title">
                    Consistent
                  </div>
                  <div className="login-div3-title">
                    Insight
                  </div>
                </div>
                <div className="login-div3-subtitle-row">
                  <div className="login-div3-item">
                    <div className="login-div3-subtitle">
                      An objective credit
                      score without human
                      bias or subjectivity
                    </div>
                  </div>
                  <div className="login-div3-item">
                    <div className="login-div3-subtitle">
                      Consistency methodology
                      across region, industry, size
                      and public/private sectors
                    </div>
                  </div>
                  <div className="login-div3-item">
                    <div className="login-div3-subtitle">
                      Understand how the credit
                      score is dependent upon the
                      financial statement inputs
                      and macro-economic factors
                    </div>
                  </div>
                </div>
              </div>
              <div className="login-div3-row">
                <div className="login-div3-icon-row">
                  <img src={barchart} alt='scale' />
                  <img src={cloud} alt='orbiting' />
                  <img src={ruler} alt='magnifier' />
                </div>
                <div className="login-div3-title-row">
                  <div className="login-div3-title">
                    Distribution
                  </div>
                  <div className="login-div3-title">
                    Unrestricted Capacity
                  </div>
                  <div className="login-div3-title">
                    Congruent
                  </div>
                </div>
                <div className="login-div3-subtitle-row">
                  <div className="login-div3-item">
                    <div className="login-div3-subtitle">
                      A distribution rather than a
                      single score gives confidence
                      and shows the likelihood of
                      potential rating movement
                    </div>
                  </div>
                  <div className="login-div3-item">
                    <div className="login-div3-subtitle">
                      Fully cloud based. Live ratings,
                      with no restrictions on the
                      volume of issuers or frequency
                      of rating updates
                    </div>
                  </div>
                  <div className="login-div3-item">
                    <div className="login-div3-subtitle">
                      Translate the roboR8 score
                      to existing agencies to identify
                      inconsistencies or anomalies
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
        return (
          <div className="landing-page">
            {authRedirect}
            <div className={this.props.location.pathname === '/login'? "login-div":"login-div login-div-about"} >
              <div className="login-div-row1">
                <Link to="/">
                  <img className="logo" src={logo} alt="logo"/>
                </Link>
                <div className="login-menu-buttons">
                  <Link to="/">
                    <button>
                      Home
                    </button>
                  </Link>
                  <Link to="/login/about">
                    <button>
                      About Us
                    </button>
                  </Link>
                  <Link to="/login/migo">
                    <button>
                      What Is MIGO
                    </button>
                  </Link>
                  <Link to="/login/faq">
                    <button>
                      FAQ
                    </button>
                  </Link>
                </div>
              </div>
              <Route path={'/login'} exact component={loginPageFirst} />
              <Route path={'/login/about'} exact component={AboutUs} />
              <Route path={'/login/migo'} exact component={Migo} />
              <Route path={'/login/faq'} exact component={Faq} />
              <div className="login-div-row3">
                <div className="footnote">
                  powered by MIGO™: machine intelligence via graph optimization
                </div>
              </div>
            </div>
            <Route path={'/login'} exact component={loginPageRest} />
          </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.authReducer.error,
        isAuthenticated: state.authReducer.token != null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, password, remember)=> dispatch(actions.auth(username, password, remember))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NormalLoginForm));
