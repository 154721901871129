import React from "react";
import {connect} from 'react-redux';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coordinate, Legend,
} from "bizcharts";
import DataSet from "@antv/data-set";
import {convertRemToPx} from "../utils";

let companyID;

let data = [{
    group: "Rating Score Skew Probabilities",
    type: "EMPTY",
    total: 100,
    "< Credit Score": 0,
    "= Credit Score": 100,
    "> Credit Score": 0
}]

class Bardiverge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            chartSize: 0,
        };
        companyID = this.props.headerSelected.companyID;
        data = this.props.companyData.roboR8peersBars;
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);       
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentWillReceiveProps(nextProps) {
        companyID = nextProps.headerSelected.companyID;
        data = nextProps.companyData.roboR8peersBars;
        this.updateWindowDimensions();
    };

    updateWindowDimensions() {
        //let winHeight = window.innerHeight;
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            chartSize:(30*data.length)
            //chartSize: (winHeight * this.props.height)
        });        
    }
    
    render() {
        const { DataView } = DataSet;

        if (data.length === 0 || this.props.autoCorrectData !== true) {
            data = [{
                group: "Rating Score Skew Probabilities",
                type: "EMPTY",
                total: 100,
                "< Credit Score": 0,
                "= Credit Score": 100,
                "> Credit Score": 0
            }]
        }

        const dv = new DataView();
        dv.source(data)
            .transform({
                type: "map",
            })
            .transform({
                type: "fold",
                fields: [
                    "< Credit Score",
                    "= Credit Score",
                    "> Credit Score",
                ],
                key: "opinion",
                value: "value",
                retains: ["group", "type"]
            });
        const scale = {
            "value": {
                max: 100
            }
        };
        const colorMap = this.props.darkMode ? {
            /*
            "< Credit Score": "#BF606A",
            "= Credit Score": "#316E9A",
            "> Credit Score": "#6E9B65"
            */
            "< Credit Score": "#ff98a2",
            "= Credit Score": "#6e94f4",
            "> Credit Score": "#99da9b"
        } :
            {
                "< Credit Score": "#ffb1b9",
                "= Credit Score": "#6e94f4",
                "> Credit Score": "#99da9b"
            };
        
            
        const label = {            
            style: {
                fill: this.props.darkMode ? '#A3ABBD' : '#192A3E', // 文本的颜色
                fontSize: convertRemToPx(this.props.rootFontSize), // 文本大小
                fontFamily: 'font1 medium',
            }
        }
        return (
            <div className="diverging-chart-multiple">
                <Chart padding="auto" height={this.state.chartSize} data={dv} autoFit scale={scale}
                       visible={this.props.activeTabKey === this.props.underTabKey}>
                    {/* <Axis name="type" title={null} labelOffset={10} /> */}
                    {/* <Axis name="name" label={'hello world'} labelOffset={10}  visible={false}/> */}
                    <Legend visible={false} />
                    <Axis
                        name="value"
                        formatter={function (val) {
                            return val + "%";
                        }}
                        label={null}
                        grid={null}
                    />
                    <Axis
                        name="type"
                        position="bottom"
                        label={label}
                        grid={null}
                    />
                    <Coordinate transpose />
                    <Tooltip shared={true} position='bottom'/>
                    <Geom
                        tooltip={['value*opinion', (value, opinion) => {
                            return {
                                name: opinion,
                                value: Math.round(value) + '%'
                            };
                        }]}
                        size={this.state.height * 0.02}
                        type="interval"
                        adjust={'stack'}
                        position="type*value"
                        color={[
                            "opinion",
                            function (opinion) {
                                return colorMap[opinion];
                            }
                        ]}
                        // style={['type', {
                        //     lineWidth: 2,
                        //     stroke: (type) => {
                        //         if (type === companyID) {
                        //             if (this.props.darkMode === true) return '#a3b7d6';
                        //             else return '#3f7dd9';
                        //         }
                        //         return "";
                        //     }
                        // }]}
        
                        shape="smooth"
                        opacity={1}
                        label={[
                            "value",
                            (value) => {
                                return {
                                    content: Math.round(value) + "%",
                                    style: {
                                        rotate: 0,
                                        textAlign: "center",
                                        fontFamily: 'font1 medium',
                                        fill: this.props.darkMode ? "#00003f" : "#ffffff",
                                        fontSize: 11,
                                    },
                                    offset: 0,
                                    position: 'middle',
                                }
                            }
                        ]}
                    >
                    </Geom>
                </Chart>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        darkMode: state.darkModeReducer.darkMode,
        headerSelected: state.headerSelectedReducer.headerSelected,
        companyData: state.companyDataReducer.companyData,
        autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
      rootFontSize: state.globalStyleReducer.rootFontSize,
      activeTabKey: state.activeTabReducer.key
    }
}

export default connect(mapStateToProps,null)(Bardiverge);