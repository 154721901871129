import {TILE_BOUNDS} from '../constants';

let initialState={
    tileBounds: {},
};

const migoModelReducer = (state = initialState, action) => {
    switch (action.type) {
        case TILE_BOUNDS:
            console.log('tile bounds action firing');
            return {
                ...state,
                tileBounds: action.payload,
            };
        default:
            return state;
    }
};

export default migoModelReducer;