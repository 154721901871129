import React, { Component } from 'react'
import {Row, Col } from 'antd';
import { SmileOutlined, MehOutlined, FrownOutlined } from '@ant-design/icons';
import { customisedData, companyData } from '../redux/actions';

import { connect } from 'react-redux';
import {PercentageInsideCircle} from "./Misc/percentageInsideCircle";

class liquidityRatios extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editCompanyData: this.props.companyData
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("statement ratios receive new props!");
        this.setState({
            custom: false,
            data: nextProps,
            editCompanyData: nextProps.companyData
        });
    };

    componentDidMount() {
        this.setState({ data: this.props })
    };
    
    render() {

        const {
            qSales,
           
            qCash,
            qCurrentAssets,
            qAcctReceivable,
            qCurrentLiab,
           
        } = this.state.editCompanyData;

        let qTile1, qTile2, qTile3;
        let qTile1Str, qTile2Str, qTile3Str;
        let qTile1Bounds, qTile2Bounds, qTile3Bounds;
        let Rmax = 50;
               
        let currentRatio = qCurrentLiab.value == 0 ? Rmax : Math.max(0, qCurrentAssets.value / qCurrentLiab.value) * 100;
        let cashRatio = qCurrentLiab.value == 0 ? Rmax : Math.max(0, (parseFloat(qCash.value) + parseFloat(qAcctReceivable.value)) / qCurrentLiab.value) * 100;
        let turnover = qAcctReceivable.value == 0 ? Rmax : Math.max(0, qSales.value / qAcctReceivable.value);

        let headerSelection = this.props.headerSelected;
        let qTileBoundsRegion = this.props.tileBounds[headerSelection.companyRegion];

        if (qTileBoundsRegion) {
            let qTileBounds = qTileBoundsRegion[headerSelection.companyGIC];
            if (qTileBounds) {

                qTile1Str = qTileBounds["factor12"]; qTile1Bounds = qTile1Str.split(":");
                qTile2Str = qTileBounds["factor11"]; qTile2Bounds = qTile2Str.split(":");
                qTile3Str = qTileBounds["factor15"]; qTile3Bounds = qTile3Str.split(":");

                qTile1 = [parseFloat(qTile1Bounds[0]) * 100, parseFloat(qTile1Bounds[1]) * 100, parseFloat(qTile1Bounds[2]) * 100, parseFloat(qTile1Bounds[3]) * 100, parseFloat(qTile1Bounds[4]) * 100];
                qTile2 = [parseFloat(qTile2Bounds[0]) * 100, parseFloat(qTile2Bounds[1]) * 100, parseFloat(qTile2Bounds[2]) * 100, parseFloat(qTile2Bounds[3]) * 100, parseFloat(qTile2Bounds[4]) * 100];
                qTile3 = [parseFloat(qTile3Bounds[0]), parseFloat(qTile3Bounds[1]), parseFloat(qTile3Bounds[2]), parseFloat(qTile3Bounds[3]), parseFloat(qTile3Bounds[4])];
            }
        }
        if (!qTile1) {
            qTile1 = [0, 0, 0, 0, 0];
            qTile2 = [0, 0, 0, 0, 0];
            qTile3 = [0, 0, 0, 0, 0];
        }
        
        return (
            <div className="cash-flow-statement input-style-class ratios">
                <div className="input-wrapper" >
                    <div className="title">
                        <span className="bold">LIQUIDITY RATIOS</span>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '2.5px' }}>
                        <Row gutter={[, 8]}>
                            <Col span={8}>
                                <PercentageInsideCircle percentageNumber={cashRatio} q1={qTile1[0]} q2={qTile1[1]} q3={qTile1[2]} q4={qTile1[3]} q5={qTile1[4]}/>
                            </Col>
                            <Col span={8}>
                                <PercentageInsideCircle percentageNumber={currentRatio} q1={qTile2[0]} q2={qTile2[1]} q3={qTile2[2]} q4={qTile2[3]} q5={qTile2[4]}/>
                            </Col>
                            <Col span={8}>
                                <PercentageInsideCircle percentageNumber={turnover} q1={qTile3[0]} q2={qTile3[1]} q3={qTile3[2]} q4={qTile3[3]} q5={qTile3[4]} endingChar="x" decimals = "1"/>
                            </Col>
                        </Row>
                        <Row gutter={[, 8]}>
                            <Col span={8} className="light">Cash</Col>
                            <Col span={8} className="light">Current</Col>
                            <Col span={8} className="light">Turnover</Col>
                        </Row>
                    </div>
                </div>         
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        headerSelected: state.headerSelectedReducer.headerSelected,
        companyData: state.companyDataReducer.companyData,
        tileBounds: state.migoModelReducer.tileBounds
    }
}

export default connect(mapStateToProps)(liquidityRatios);
