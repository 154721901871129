import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import {Route, Switch, Redirect} from 'react-router-dom';
import * as actions from '../redux/actions'
import {  } from 'antd';

import './App.css';
import '../main.css';
import Pages from '../uiPages';
import Login from '../components/Login'
import AboutUs from "../components/Login/aboutUs";

const App = (props) => {
    const rootElement = document.documentElement;

    useEffect(() => {
        props.onTryAutoSignup();
    }, [])

    useEffect(() => {
        rootElement.style.fontSize = `${props.globalStyleReducer.rootFontSize}vh`
    }, [props.globalStyleReducer.rootFontSize])

    return (
        <div className="App">
            <Switch>
                <Route path="/" exact>
                  <Redirect to="/login" />
                </Route>
                <Route path="/login" component={Login} />
                <Route path="/about" component={AboutUs} />
                {props.authReducer.token?
                    <Route path="/app" component={Pages} /> : <Redirect to="/login" />}
            </Switch>
        </div>
    )
}

const mapStateToProps = state => {
    return state
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: ()=> dispatch(actions.authCheckState())
    }
};

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export {connectedApp as App}