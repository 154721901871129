import React from "react";
import {convertRemToPx} from "../../utils";

export function PercentageInsideCircle(props) {
    var color, charX, direction, precision, fixed;

    direction = (typeof (props.direction) === 'undefined') ? "1" : "2";

    if (direction === "1") {                 //Big number = good, small = bad
        if (props.percentageNumber > props.q4) color = "#a8e6cf";
        else if (props.percentageNumber > props.q3) color = "#dcedc1";
        else if (props.percentageNumber > props.q2) color = "#ffd3b6";
        else if (props.percentageNumber > props.q1) color = "#ffaaa5";
        else color = "#ff8b94";
    } else {                                 //Big number = bad, small = good
        if (props.percentageNumber > props.q4) color = "#ff8b94";
        else if (props.percentageNumber > props.q3) color = "#ffaaa5";
        else if (props.percentageNumber > props.q2) color = "#ffd3b6";
        else if (props.percentageNumber > props.q1) color = "#dcedc1";
        else color = "#a8e6cf";
    }

    charX = (typeof (props.endingChar) === 'undefined') ? "%" : props.endingChar;
    precision = (typeof (props.decimals) === 'undefined') ? 0 : props.decimals;
    fixed = props.percentageNumber.toFixed(precision);
    
    
    return (<svg xmlns="http://www.w3.org/2000/svg" style={{shapeRendering:"geometricPrecision", textRendering:"geometricPrecision", imageRendering:"optimizeQuality", fillRule: "evenodd", clipRule: "evenodd"}}
                 height={'6rem'} width={'6rem'}>
        <g className = "lightRatio">
            <circle style={{fill:"none",stroke: color, strokeWidth:'0.4rem', strokeMiterlimit:10}} cx="3rem" cy="3rem" r="2.8rem">
            </circle>
            <text x="50%"
                  y="50%"
                  dominantBaseline="middle"
                  textAnchor="middle">
                {fixed}{charX}</text>
        </g>
    </svg>)
}