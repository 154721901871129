import React from 'react';
import "./index.css";
import { Typography, Layout } from 'antd';
const { Content } = Layout;

const AboutUs = (props) => {
  return (
      <div className="about-us">
          About Us
          <Typography className="data-title" style={{ textAlign: "justify", textJustify: "inter-word" }}>

              <Content className="about-us-content">
                  roboR8&trade; was developed by the research team at Rekiki PTE Ltd (www.rekiki.com), whose founders, whist creating a lending platform, became frustrated by the 
                  high cost, low coverage and inconsistencies of credit ratings. They also believed that the traditional "issuer-pays" or "investor-pays" business model of the credit 
                  agencies could allow bias into ratings. Thus, they looked to create an objective, scalable scoring system using machine intelligence.  <br /><br />
                  roboR8&trade; was born with the aim to bring credit scoring to all, not only those who have access to agencies or a team of analysts. roboR8&trade; is fully cloud-based
                  and interactive, allowing anyone, from small business owners to large lending portfolio managers, to have an objective real-time credit assessment.<br /><br />
                  For small business owners, roboR8&trade; can be used to monitor their own company's health, along with the health of their customers and suppliers. It ends the asymmetric 
                  information between banks and borrowers, giving them independent visibility on their credit worthiness and an understanding on loan application decisions.
                  For financial professionals, roboR8&trade; provides a consistent score across a vast universe of global companies. The efficient cloud-based engine allows stress 
                  testing, giving better risk insight. Therefore, more informed investment or lending decisions can be made.<br /><br />
                  Details on roboR8 and Rekiki's founders can be found at www.rekiki.com/who-we-are
              </Content>
          </Typography>
      </div>
  );
}

export default AboutUs;