import React from "react";
import Flags from "country-flag-icons/react/3x2";
import {connect} from "react-redux";
import {Typography} from "antd";
import { blue } from '@ant-design/colors';

const {Link} = Typography;

function TitleCard(props){
    const {
        companyGIC,
        companyName,
        companyRegion,
        companyCluster,
        companyID
    } = props.headerSelected;

    const companyCountry = companyID? props.companyList[companyRegion][companyCluster][companyGIC][companyID].CountryOfAsset : "";
    const companyWebsite = companyID? props.companyList[companyRegion][companyCluster][companyGIC][companyID].website??"" : "";

    const CountryFlagTag = Flags[companyCountry]? React.createElement(
        Flags[companyCountry],
        {
            style:{
                width: '5%',
                minWidth: 20,
                marginRight: 5
            }
        }
    ) : null;

    return (
        <div className="data-title">
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                {companyID? CountryFlagTag: null}
                <span className="main-title"><b>{companyName}</b></span>
                <Link href={companyWebsite} target="_blank" style={{ color: blue[7], marginLeft: '1rem'}} >
                    {companyWebsite}
                </Link>
            </div>
            <span className="sub-title">{companyGIC} (amounts quoted in million)</span>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        headerSelected: state.headerSelectedReducer.headerSelected,
        companyList: state.companyListReducer.companyList
    }
};

export default connect(mapStateToProps)(TitleCard);