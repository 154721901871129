import React from "react";
import { connect } from 'react-redux';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coordinate,
    Legend,
} from "bizcharts";

class AssetsPie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            width: 0,
            height: 0,
            chartSize: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);        
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("asset pie receive new props!");
        this.setState({
            custom: false,
            data: nextProps
        });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let winHeight = window.innerHeight;

        this.setState({ width: window.innerWidth, height: window.innerHeight, chartSize: (winHeight * this.props.height) });
    }
       
    
    render() {
        let data, total, totCurr, totFixed, totIntang, totOther;

        if (this.props.autoCorrectData === true) {
            total = Math.max(0, parseFloat(this.props.companyData.qTotalAssets.value));
            
            if (parseFloat(total) <= 0.0) {
                totOther = 1.0; totCurr = 0.0; totFixed = 0.0; totIntang = 0.0;
            } else {
                totCurr = Math.max(0, parseFloat(this.props.companyData.qCurrentAssets.value) / parseFloat(total));
                totFixed = Math.max(0, parseFloat(this.props.companyData.qNetFixedAsset.value) / parseFloat(total));
                totIntang = Math.max(0, parseFloat(this.props.companyData.qIntangibles.value) / parseFloat(total));
                totOther = 1.0 - parseFloat(totFixed) - parseFloat(totCurr) - parseFloat(totIntang);
            }

            data = [
                { score: totCurr, item: "Current" },
                { score: totFixed, item: "Fixed" },
                { score: totIntang, item: "Intangibles" },
                { score: totOther, item: "Other" },
            ];
        } else {
            data = [
                { item: "ratio", user: "unknown", score: 1 }
            ];
        }

        const cols = {
            score: {
                formatter: val => {
                    val = (val * 100).toFixed(2) + '%';
                    return val;
                },
                nice: true
            },
        };        

        return (
            <div className="assetsPie">
                <Chart
                    height={window.innerHeight * this.props.height}
                    visible={this.props.activeTabKey===this.props.underTabKey && this.props.activeSubTabKey===this.props.underSubTab}
                    data={data} autoFit scale={cols} interactions={['element-active']}>
                    <Coordinate type="theta" radius={0.75} />
                    <Tooltip showTitle={false} />
                    <Legend visible={false} />
                    <Axis visible={false}/>
                    <Geom
                        type="interval"
                        position="score"
                        adjust="stack"
                        color={["item", ['#A8BEF8', '#8BA9F6', '#6E94F4', '#5876C3']]}
                        style={{
                            stroke: '#E5E5E5',
                            lineWidth: 1,
                        }}

                        label={[
                            "item",
                            (value) => {
                                return {
                                    content: value,
                                    style: {
                                        rotate: 0,
                                        fontFamily: 'font1 medium',
                                        fill: this.props.darkMode ? '#A3ABBD' : '#192A3E',
                                        fontSize: 11,
                                    },
                                }
                            }
                        ]}
                    >
                    </Geom>
                </Chart>
            </div>
        );
    }
}

const mapStateToProps = (state) =>{
  return{
      darkMode: state.darkModeReducer.darkMode,
      headerSelected: state.headerSelectedReducer.headerSelected,
      companyData: state.companyDataReducer.companyData,
      autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
      customisedData: state.customisedDataReducer.customisedData,
      radarPeers: state.radarPeersReducer.radarPeers,
    activeTabKey: state.activeTabReducer.key,
    activeSubTabKey: state.activeTabReducer.subTabKey
  }
}

export default connect(mapStateToProps, null)(AssetsPie);