import {
    COMPANY_RESULT_ENTRIES,
    NEW_UPLOAD_ENTRIES, RUN_BATCH_SCORE_ERROR, RUN_BATCH_SCORE_FINISH, RUN_BATCH_SCORE_START,
    SELECTED_COMPANY_IDS,
    SERVER_T12M_ENTRIES,
    TOGGLE_LOADING_T12M, UPLOAD_TAB_DELETE_ROWS, UPLOAD_TAB_SET_REGION_CLUSTER
} from "../constants";
import {tryDeleteDataStoreItem, tryDeleteqDataStoreItem, tryUpdateDataStoreScore} from "../../utils/reduxUtils";
import {createRoboR8RatingObjectFromRoboR8Results} from "../../utils";

let initialState={
    t12mDataStore: {},
    t12mDataStoreServer: {},
    qDataStore: {},
    selectedCompanyIds: [],
    selectedRegion: undefined,
    selectedCluster: undefined,
    loading: false,
    batchScoring: false,
    batchError: false
};

const uploadTabReducer = (state = initialState, action)=>{
    switch(action.type){
        case NEW_UPLOAD_ENTRIES:
            const dataStore = {...state.t12mDataStore};
            for (const item of action.data){
                const region = item.region;
                const cluster = item.cluster;
                const dataCopy = {
                    ...item.t12mData,
                    region: region,
                    cluster: cluster
                };
                dataStore[region] = dataStore[region] || {};
                dataStore[region][cluster] = dataStore[region][cluster] || {};
                dataStore[region][cluster][dataCopy.companyId] = dataCopy;
            }
            return {
                ...state,
                t12mDataStore: dataStore
            };
        case SERVER_T12M_ENTRIES:
            const dataStoreServer = {...state.t12mDataStoreServer};
            for (const comData of Object.values(action.data)){
                const region = action.region;
                const cluster = action.cluster;
                const dataCopy = {
                    ...comData,
                    region: region,
                    cluster: cluster
                };
                dataStoreServer[region] = dataStoreServer[region] || {};
                dataStoreServer[region][cluster] = dataStoreServer[region][cluster] || {};
                dataStoreServer[region][cluster][dataCopy.companyId] = dataCopy;
            }
            return {
                ...state,
                t12mDataStoreServer: dataStoreServer
            };
        case COMPANY_RESULT_ENTRIES:
            // update companyList
            const qDataStore = state.qDataStore;
            for (const item of action.data){
                const q1data = item.qData[0];
                qDataStore[q1data.companyId] = {region: item.region, cluster: item.cluster, qData: item.qData};
            }

            return {
                ...state,
                qDataStore: {...qDataStore}
            };
        case UPLOAD_TAB_DELETE_ROWS:
            tryDeleteDataStoreItem(state.t12mDataStore, action.region, action.cluster, action.idList);
            tryDeleteDataStoreItem(state.t12mDataStoreServer, action.region, action.cluster, action.idList);
            tryDeleteqDataStoreItem(state.qDataStore, action.idList);
            return {
                ...state,
                t12mDataStore: {...state.t12mDataStore},
                t12mDataStoreServer: {...state.t12mDataStoreServer},
                qDataStore: {...state.qDataStore}
            };
        case TOGGLE_LOADING_T12M:
            return {
                ...state,
                loading: action.data
            };
        case SELECTED_COMPANY_IDS:
            return {
                ...state,
                selectedCompanyIds: action.data
            };
        case RUN_BATCH_SCORE_START:
            return {
                ...state,
                batchError: false,
                batchScoring: true
            };
        case RUN_BATCH_SCORE_FINISH:
            const {data, region, cluster} = action;
            const t12mDataStore = {...state.t12mDataStore};
            const t12mDataStoreServer = {...state.t12mDataStoreServer};
            const newQDataStore = state.qDataStore;
            for (let item of data){
                const partialRoboR8Obj = createRoboR8RatingObjectFromRoboR8Results(item.roboR8Results);
                tryUpdateDataStoreScore(t12mDataStore, region, cluster, item.companyId, partialRoboR8Obj);
                tryUpdateDataStoreScore(t12mDataStoreServer, region, cluster, item.companyId, partialRoboR8Obj);
                if(newQDataStore[item.companyId]!==undefined){
                    const lastQuarterData = newQDataStore[item.companyId]['qData'][3];
                    newQDataStore[item.companyId]['qData'][3] = {...lastQuarterData, roboR8Results: item.roboR8Results}
                }
            }

            return {
                ...state,
                t12mDataStore: t12mDataStore,
                t12mDataStoreServer: t12mDataStoreServer,
                qDataStore: {...newQDataStore},
                batchScoring: false
            };
        case RUN_BATCH_SCORE_ERROR:
            return {
                ...state,
                batchError: true,
                batchScoring: false
            };
        case UPLOAD_TAB_SET_REGION_CLUSTER:
            return {
                ...state,
                selectedRegion: action.data.region,
                selectedCluster: action.data.cluster
            };
        default:
            return state;
    }
};

export default uploadTabReducer;