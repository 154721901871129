export function mapScoreToRating(score) {
    var migo = '';
    var moody = '';
    var sp = '';
    var fitch = '';

    switch (score) {
        case 1:
            migo = 'C/D';
            moody = 'C/D';
            sp = 'C/D';
            fitch = 'C/D';
            break;
        case 2:
            migo = 'C/D';
            moody = 'C/D';
            sp = 'C/D';
            fitch = 'C/D';
            break;
        case 3:
            migo = 'C/D';
            moody = 'C/D';
            sp = 'C/D';
            fitch = 'C/D';
            break;
        case 4:
            migo = 'C/D';
            moody = 'C/D';
            sp = 'C/D';
            fitch = 'C/D';
            break;
        case 5:
            migo = 'C/D';
            moody = 'C/D';
            sp = 'C/D';
            fitch = 'C/D';
            break;
        case 6:
            migo = 'CCC-';
            moody = 'Caa3';
            sp = 'CCC-';
            fitch = 'CCC-';
            break;
        case 7:
            migo = 'CCC';
            moody = 'Caa2';
            sp = 'CCC';
            fitch = 'CCC';
            break;
        case 8:
            migo = 'CCC+';
            moody = 'Caa1';
            sp = 'CCC+';
            fitch = 'CCC+';
            break;
        case 9:
            migo = 'B-';
            moody = 'B3';
            sp = 'B-';
            fitch = 'B-';
            break;
        case 10:
            migo = 'B';
            moody = 'B2';
            sp = 'B';
            fitch = 'B';
            break;
        case 11:
            migo = 'B+';
            moody = 'B1';
            sp = 'B+';
            fitch = 'B+';
            break;
        case 12:
            migo = 'BB-';
            moody = 'Ba3';
            sp = 'BB-';
            fitch = 'BB-';
            break;
        case 13:
            migo = 'BB';
            moody = 'Ba2';
            sp = 'BB';
            fitch = 'BB';
            break;
        case 14:
            migo = 'BB+';
            moody = 'Ba1';
            sp = 'BB+';
            fitch = 'BB+';
            break;
        case 15:
            migo = 'BBB-';
            moody = 'Baa3';
            sp = 'BBB-';
            fitch = 'BBB-';
            break;
        case 16:
            migo = 'BBB';
            moody = 'Baa2';
            sp = 'BBB';
            fitch = 'BBB';
            break;
        case 17:
            migo = 'BBB+';
            moody = 'Baa1';
            sp = 'BBB+';
            fitch = 'BBB+';
            break;
        case 18:
            migo = 'A-';
            moody = 'A3';
            sp = 'A-';
            fitch = 'A-';
            break;
        case 19:
            migo = 'A';
            moody = 'A2';
            sp = 'A';
            fitch = 'A';
            break;
        case 20:
            migo = 'A+';
            moody = 'A1';
            sp = 'A+';
            fitch = 'A+';
            break;
        case 21:
            migo = 'AA-';
            moody = 'Aa3';
            sp = 'AA-';
            fitch = 'AA-';
            break;
        case 22:
            migo = 'AA';
            moody = 'Aa2';
            sp = 'AA';
            fitch = 'AA';
            break;
        case 23:
            migo = 'AA+';
            moody = 'Aa1';
            sp = 'AA+';
            fitch = 'AA+';
            break;
        case 24:
            migo = 'AAA';
            moody = 'Aaa';
            sp = 'AAA';
            fitch = 'AAA';
            break;
        default:
            migo = 'NA';
            moody = 'NA';
            sp = 'NA';
            fitch = 'NA';
    }
    return { migo: migo, moody: moody, sp: sp, fitch: fitch };
}

export function mapRatingToScore(rating) {
    var score;
    
    switch (rating) {
        case 'D':
            score = 5;
            break;
        case 'DD':
        case 'SD':
            score = 5;
            break;
        case 'DDD':
        case 'RD':
            score = 5;
            break;
        case 'C':
            score = 5;
            break;
        case 'Ca':
        case 'CC':
            score = 5;
            break;
        case 'Caa3':
        case 'CCC-':
            score = 6;
            break;
        case 'CCC':
        case 'Caa2':
            score = 7;
            break;
        case 'CCC+':
        case 'Caa1':
            score = 8;
            break;
        case 'B3':
        case 'B-':
            score = 9;
            break;
        case 'B':
        case 'B2':
            score = 10;
            break;
        case 'B+':
        case 'B1':
            score = 11;
            break;
        case 'Ba3':
        case 'BB-':
            score = 12;
            break;
        case 'BB':
        case 'Ba2':
            score = 13;
            break;
        case 'BB+':
        case 'Ba1':
            score = 14;
            break;
        case 'BBB-':
        case 'Baa3':
            score = 15;
            break;
        case 'BBB':
        case 'Baa2':
            score = 16;
            break;
        case 'BBB+':
        case 'Baa1':
            score = 17;
            break;
        case 'A-':
        case 'A3':
            score = 18;
            break;
        case 'A':
        case 'A2':
            score = 19;
            break;
        case 'A+':
        case 'A1':
            score = 20;
            break;
        case 'AA-':
        case 'Aa3':
            score = 21;
            break;
        case 'AA':
        case 'Aa2':
            score = 22;
            break;
        case 'AA+':
        case 'Aa1':
            score = 23;
            break;
        case 'AAA':
        case 'Aaa':
            score = 24;
            break;
        default:
            score = -1;
    }
    return score;
}

export function mapRatingToScoreSimple(rating) {
    var score;

    switch (rating) {
        case 'D':
        case 'DD':
        case 'SD':
        case 'DDD':
        case 'RD':
        case 'C':
        case 'Ca':
        case 'CC':
        case 'Caa3':
        case 'CCC-':            
        case 'CCC':
        case 'Caa2':            
        case 'CCC+':
        case 'Caa1':
            score = 1;
            break;
        case 'B3':
        case 'B-':            
        case 'B':
        case 'B2':            
        case 'B+':
        case 'B1':
            score = 2;
            break;
        case 'Ba3':
        case 'BB-':            
        case 'BB':
        case 'Ba2':            
        case 'BB+':
        case 'Ba1':
            score = 3;
            break;
        case 'BBB-':
        case 'Baa3':            
        case 'BBB':
        case 'Baa2':            
        case 'BBB+':
        case 'Baa1':
            score = 4;
            break;
        case 'A-':
        case 'A3':            
        case 'A':
        case 'A2':            
        case 'A+':
        case 'A1':
            score = 5;
            break;
        case 'AAA':
        case 'Aaa':
        case 'AA+':
        case 'Aa1':
        case 'AA':
        case 'Aa2':
        case 'AA-':
        case 'Aa3':
            score = 6;
            break;
        default:
            score = -1;
    }
    return score;
}

export function createRoboR8RatingObjectFromRoboR8Results(roboR8Results){
    let roboR8Check = false;
    let roboR8Result = [];           //roboR8 rating result for the company [Rating, Prob]
    let roboR8Score = 0;             //roboR8 numerical score
    let roboR8Rating = 0;            //roboR8 rating (mode)
    let roboR8target = undefined;
    let roboR8date = '';             //roboR8 rating date

    let zeroResult = [];             //Null rating probs

    for (let i = 1; i <= 24; i++) {
        zeroResult.push({
            rating: mapScoreToRating(i).migo,
            prob: 0,
        })
    }

    let quantiles = [];
    if (roboR8Results){
        roboR8Check = true;
        quantiles = roboR8Results.quantiles;
        roboR8target = roboR8Results.target;
        roboR8date = roboR8Results.date;
        roboR8date = roboR8date.substr(0, 6) + roboR8date.substr(8);
    }

    let roboR8max = 0;
    if (roboR8Check === true) {
        for (let i = 1; i <= 24; i++) {
            let ithQuantile = parseFloat(quantiles[i-1]);
            if (ithQuantile > roboR8max) {
                roboR8max = ithQuantile;
                roboR8Rating = i;
            }
            roboR8Score += i * ithQuantile;

            roboR8Result.push({
                rating: mapScoreToRating(i).migo,
                prob: ithQuantile * 100,
            })
        }
    } else {
        roboR8Result = zeroResult;
    }

    roboR8Score = (parseFloat(roboR8Score) * 100) / 24;  //zero if no rating

    return {
        roboR8Result:roboR8Result,
        roboR8Score: roboR8Score,
        roboR8Rating: roboR8Rating,
        roboR8target: roboR8target,
        roboR8date: roboR8date
    }
}