import React from 'react';
import DataSet from '@antv/data-set';
import {Chart, Polygon, Tooltip} from 'bizcharts';
import {mapScoreToRating} from "../utils";
import {companySelected} from "../redux/actions";
import {connect} from "react-redux";

const scale = {
  x: {
    nice: true,
  },
  y: {
    nice: true,
  },
}

function Treemap(props) {
  const { DataView } = DataSet;
  const data = {
    name: 'root',
    children: [
      { name: 'EMPTY', value: 0 }
    ],
  };
  var i;
  let ratingCount = {}
  if (props.autoCorrectData === true) {
    data.children = [];
    for (i = 1; i <= props.companyData.roboR8peers.length; i++) {
      const rating = mapScoreToRating(props.companyData.roboR8peers[i - 1].rating).migo;
      if(ratingCount[rating]===undefined){
        ratingCount[rating] = 0;
      }
      ratingCount[rating] ++;
    }
    for (const [rating, count] of Object.entries(ratingCount)) {
      data.children.push({name: rating, value: count});
    }
  }
  console.log(data.children)
  const dv = new DataView();
  dv.source(data, {
    type: 'hierarchy',
  }).transform({
    field: 'value',
    type: 'hierarchy.treemap',
    tile: 'treemapBinary',
    as: ['x', 'y'],
  });
// 将 DataSet 处理后的结果转换为 G2 接受的数据
  const nodes = [];
    for (const node of dv.getAllNodes()) {
        if (node.data.name === 'root') {
            continue;
        }
        const eachNode = {
            name: node.data.name,
            x: node.x,
            y: node.y,
            value: node.data.value,
        };

        nodes.push(eachNode);
    };

    const colorMap = {

        "AAA": "#5AC68A",
        "AA+": "#63DD9E",
        "AA": "#74E0A4",
        "AA-": "#83E3AE",        
        "A+": "#93E7B8",
        "A": "#A2EAC2",
        "A-": "#B2EECC",
        "BBB+": "#DEF8D8",
        "BBB": "#F0F8D8",
        "BBB-": "#FAF8D8",
        "BB+": "#FAF0D8",
        "BB": "#FFE6D4",
        "BB-": "#FFDCD4",
        "B+": "#FFD2D4",
        "B": "#FFB6B3",
        "B-": "#F8858B",
        "CCC+": "#FD706B",
        "CCC": "#FF6663",
        "CCC-": "#E54C38",
        "C/D": "#C23A22"
    }; 
  return <Chart
    scale={scale}
    pure
    height={window.innerHeight * props.height}
    autoFit
    data={nodes}
    visible={props.activeTabKey === props.underTabKey}
  >
    <Tooltip showTitle={false} />
    <Polygon
          color={[
              "name",
              function (name) {
                  return colorMap[name];
              }
          ]}
      position="x*y"
      style={{
        lineWidth: 1,
          stroke: '#D1D1D1'
      }}
      tooltip={['name*value', (x, y) => {
        return {
          "name": x,
          "value":y + " companies",
        };
      }]}
      label={['name', {
          offset: 0,          
          style: {
              textBaseline: 'middle',
              fontFamily: 'font1 medium',
              fill: '#0077d0',
          },
        content: (obj) => {
          if (obj.name !== 'root') {
            return obj.name;
          }
        }
      }]}
    />
  </Chart>
}

const mapStateToProps = (state) =>{
  return{
    darkMode: state.darkModeReducer.darkMode,
    companyData: state.companyDataReducer.companyData,
    autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
    rootFontSize: state.globalStyleReducer.rootFontSize,
    bubbleGraph: state.bubbleGraphReducer.bubbleGraph,
    activeTabKey: state.activeTabReducer.key
  }
}

const mapDispatchToProps = dispatch => {
  return {
    companySelected: (company) => {
      dispatch(
        companySelected(company)
      )
    }
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(Treemap);