import { COMPANY_ID_INDEX } from '../constants';

let initialState={
    index:'2',
}

const companyIdIndexReducer = (state = initialState, action)=>{

    switch(action.type){
        case COMPANY_ID_INDEX:
          return{
            ...state,
            index:action.index,
          }
      default:
          return state;
      }
}

export default companyIdIndexReducer;