import { ROOT_FONT_SIZE } from '../constants';

let initialState={
    rootFontSize: 1.05,
}

const globalStyleReducer = (state = initialState, action)=>{

    switch(action.type){
        case ROOT_FONT_SIZE:
            return{
                ...state,
                rootFontSize: action.payload,
            }
        default:
            return state;
    }
}

export default globalStyleReducer;