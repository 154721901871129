import { convertToDecimal } from './';

export function resetCompanyISdata(editCompanyData) {

    const newISdata = {
        qGrossProfitValue: editCompanyData.qGrossProfit.value,
        qSalesValue: editCompanyData.qSales.value,
        costGoodsSoldValue: editCompanyData.costGoodsSold.value,
        qEBITValue: editCompanyData.qEBIT.value,
        sGandAValue: editCompanyData.sGandA.value,
        qDandAValue: editCompanyData.qDandA.value,
        qGrossInterestValue: editCompanyData.qGrossInterest.value,
        earningBeforeIncomeTaxesValue: editCompanyData.earningBeforeIncomeTaxes.value,
        incomeTaxExpenseValue: editCompanyData.incomeTaxExpense.value,
        qNetIncomeValue: editCompanyData.qNetIncome.value,

        checkGrossProfitStatus: editCompanyData.qGrossProfit.status,
        checkSalesValueStatus: editCompanyData.qSales.status,
        checkCostGoodsSoldStatus: editCompanyData.costGoodsSold.status,
        checkEBITStatus: editCompanyData.qEBIT.status,
        checkSGandAStatus: editCompanyData.sGandA.status,
        checkDandAStatus: editCompanyData.qDandA.status,
        checkGrossInterestStatus: editCompanyData.qGrossInterest.status,
        checkEarningBeforeIncomeTaxesStatus: editCompanyData.earningBeforeIncomeTaxes.status,
        checkIncomeTaxExpenseStatus: editCompanyData.incomeTaxExpense.status,
        checkNetIncomeStatus: editCompanyData.qNetIncome.status,

        checkGrossProfitLog: editCompanyData.qGrossProfit.log,
        checkSalesValueLog: editCompanyData.qSales.log,
        checkCostGoodsSoldLog: editCompanyData.costGoodsSold.log,
        checkEBITLog: editCompanyData.qEBIT.log,
        checkSGandALog: editCompanyData.sGandA.log,
        checkDandALog: editCompanyData.qDandA.log,
        checkGrossInterestLog: editCompanyData.qGrossInterest.log,
        checkEarningBeforeIncomeTaxesLog: editCompanyData.earningBeforeIncomeTaxes.log,
        checkIncomeTaxExpenseLog: editCompanyData.incomeTaxExpense.log,
        checkNetIncomeLog: editCompanyData.qNetIncome.log
    };

    return newISdata;
}

export function shiftStatement(shift, companyEntry) {
    var oldShift;
    var newEntry;

    if (companyEntry.status === 'custom') oldShift = parseFloat(companyEntry.log); else oldShift = 0;

    const Log = convertToDecimal(oldShift + parseFloat(shift));
    const Value = convertToDecimal(parseFloat(companyEntry.value) + parseFloat(shift));
    if (Log <= 0.1 && Log >= -0.1) newEntry = { log: Log, status: '', value: Value };    
    else newEntry = { log: Log, status: 'custom', value: Value };

    return newEntry;
}

export function shiftSalesRevenue(shift, companyData) {
    const editSales = shiftStatement(shift, companyData.qSales);
    const editGrossProfit = shiftStatement(shift, companyData.qGrossProfit);
    const editEBIT = shiftStatement(shift, companyData.qEBIT);
    const editEBT = shiftStatement(shift, companyData.earningBeforeIncomeTaxes);
    const editNetIncome = shiftStatement(shift, companyData.qNetIncome);

    const shiftAll = { editSales: editSales, editGrossProfit: editGrossProfit, editEBIT: editEBIT, editEBT: editEBT, editNetIncome: editNetIncome };
    return shiftAll;
}

export function shiftCostGoodsSold(shift, companyData) {
    const editCostGoodsSold = shiftStatement(shift, companyData.costGoodsSold);
    const editGrossProfit = shiftStatement(-shift, companyData.qGrossProfit);
    const editEBIT = shiftStatement(-shift, companyData.qEBIT);
    const editEBT = shiftStatement(-shift, companyData.earningBeforeIncomeTaxes);
    const editNetIncome = shiftStatement(-shift, companyData.qNetIncome);

    const shiftAll = { editCostGoodsSold: editCostGoodsSold, editGrossProfit: editGrossProfit, editEBIT: editEBIT, editEBT: editEBT, editNetIncome: editNetIncome };
    return shiftAll;
}

export function shiftGrossProfit(shift, companyData) {
    const editCostGoodsSold = shiftStatement(-shift, companyData.costGoodsSold);
    const editGrossProfit = shiftStatement(shift, companyData.qGrossProfit);
    const editEBIT = shiftStatement(shift, companyData.qEBIT);
    const editEBT = shiftStatement(shift, companyData.earningBeforeIncomeTaxes);
    const editNetIncome = shiftStatement(shift, companyData.qNetIncome);

    const shiftAll = { editCostGoodsSold: editCostGoodsSold, editGrossProfit: editGrossProfit, editEBIT: editEBIT, editEBT: editEBT, editNetIncome: editNetIncome };
    return shiftAll;
}

export function shiftSGandA(shift, companyData) {
    const editSGandA = shiftStatement(shift, companyData.sGandA);
    const editEBIT = shiftStatement(-shift, companyData.qEBIT);
    const editEBT = shiftStatement(-shift, companyData.earningBeforeIncomeTaxes);
    const editNetIncome = shiftStatement(-shift, companyData.qNetIncome);

    const shiftAll = { editSGandA: editSGandA, editEBIT: editEBIT, editEBT: editEBT,  editNetIncome: editNetIncome };
    return shiftAll;
}

export function shiftDandA(shift, companyData) {
    const editDandA = shiftStatement(shift, companyData.qDandA);
    const editEBIT = shiftStatement(-shift, companyData.qEBIT);
    const editEBT = shiftStatement(-shift, companyData.earningBeforeIncomeTaxes);
    const editNetIncome = shiftStatement(-shift, companyData.qNetIncome);

    const shiftAll = { editDandA: editDandA, editEBIT: editEBIT, editEBT: editEBT, editNetIncome: editNetIncome };
    return shiftAll;
}

export function shiftEBIT(shift, companyData) {
    const editDandA = shiftStatement(-shift, companyData.qDandA);
    const editEBIT = shiftStatement(shift, companyData.qEBIT);
    const editEBT = shiftStatement(shift, companyData.earningBeforeIncomeTaxes);
    const editNetIncome = shiftStatement(shift, companyData.qNetIncome);

    const shiftAll = { editDandA: editDandA, editEBIT: editEBIT, editEBT: editEBT, editNetIncome: editNetIncome };
    return shiftAll;
}

export function shiftGrossInterest(shift, companyData) {
    const editGrossInterest = shiftStatement(shift, companyData.qGrossInterest);
    const editEBT = shiftStatement(-shift, companyData.earningBeforeIncomeTaxes);
    const editNetIncome = shiftStatement(-shift, companyData.qNetIncome);

    const shiftAll = { editGrossInterest: editGrossInterest, editEBT: editEBT, editNetIncome: editNetIncome };
    return shiftAll;
}

export function shiftEBT(shift, companyData) {
    const editGrossInterest = shiftStatement(-shift, companyData.qGrossInterest);
    const editEBT = shiftStatement(shift, companyData.earningBeforeIncomeTaxes);
    const editNetIncome = shiftStatement(shift, companyData.qNetIncome);

    const shiftAll = { editGrossInterest: editGrossInterest, editEBT: editEBT, editNetIncome: editNetIncome };
    return shiftAll;
}

export function shiftIncomeTax(shift, companyData) {
    const editIncomeTax = shiftStatement(shift, companyData.incomeTaxExpense);
    const editNetIncome = shiftStatement(-shift, companyData.qNetIncome);

    const shiftAll = { editIncomeTax: editIncomeTax, editNetIncome: editNetIncome };
    return shiftAll;
}

export function shiftNetIncome(shift, companyData) {
    const editIncomeTax = shiftStatement(-shift, companyData.incomeTaxExpense);
    const editNetIncome = shiftStatement(shift, companyData.qNetIncome);

    const shiftAll = { editIncomeTax: editIncomeTax, editNetIncome: editNetIncome };
    return shiftAll;
}

export function cfShift(shift, companyData) {
    const editCashFromOp = shiftStatement(shift, companyData.qOpCashFlow);
    const editCashIncrease = shiftStatement(shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editCashFromOp: editCashFromOp, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function shiftIncreaseOthers(shift, companyData) {
    const editIncreaseOthers = shiftStatement(shift, companyData.increaseDecreaseInOtherItems);
    const editCashFromOp = shiftStatement(shift, companyData.qOpCashFlow);
    const editCashIncrease = shiftStatement(shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editIncreaseOthers: editIncreaseOthers, editCashFromOp: editCashFromOp, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function shiftCashFromOp(shift, companyData) {
    const editIncreaseOthers = shiftStatement(shift, companyData.increaseDecreaseInOtherItems);
    const editCashFromOp = shiftStatement(shift, companyData.qOpCashFlow);
    const editCashIncrease = shiftStatement(shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editIncreaseOthers: editIncreaseOthers, editCashFromOp: editCashFromOp, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function shiftCapex(shift, companyData) {
    const editCapex = shiftStatement(shift, companyData.qCapex);
    const editCashFromInv = shiftStatement(-shift, companyData.qCashFromInvest);
    const editCashIncrease = shiftStatement(-shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editCapex: editCapex, editCashFromInv: editCashFromInv, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function shiftInvestOthers(shift, companyData) {
    const editInvestOthers = shiftStatement(shift, companyData.investmentsInOtherAssets);
    const editCashFromInv = shiftStatement(shift, companyData.qCashFromInvest);
    const editCashIncrease = shiftStatement(shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editInvestOthers: editInvestOthers, editCashFromInv: editCashFromInv, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function shiftCashFromInv(shift, companyData) {
    const editInvestOthers = shiftStatement(shift, companyData.investmentsInOtherAssets);
    const editCashFromInv = shiftStatement(shift, companyData.qCashFromInvest);
    const editCashIncrease = shiftStatement(shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editInvestOthers: editInvestOthers, editCashFromInv: editCashFromInv, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function shiftDividend(shift, companyData) {
    const editDividend = shiftStatement(shift, companyData.qDividend);
    const editCashFromFin = shiftStatement(-shift, companyData.qCashFromFinance);
    const editCashIncrease = shiftStatement(-shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editDividend: editDividend, editCashFromFin: editCashFromFin, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function shiftSaleOfEquity(shift, companyData) {
    const editSaleOfEquity = shiftStatement(shift, companyData.qSaleOfEquity);
    const editCashFromFin = shiftStatement(shift, companyData.qCashFromFinance);
    const editCashIncrease = shiftStatement(shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editSaleOfEquity: editSaleOfEquity, editCashFromFin: editCashFromFin, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function shiftIssueDebt(shift, companyData) {
    const editIssueDebt = shiftStatement(shift, companyData.qIssueLTDebt);
    const editCashFromFin = shiftStatement(shift, companyData.qCashFromFinance);
    const editCashIncrease = shiftStatement(shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editIssueDebt: editIssueDebt, editCashFromFin: editCashFromFin, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function shiftRepayDebt(shift, companyData) {
    const editRepayDebt = shiftStatement(shift, companyData.qRepayLTDebt);
    const editCashFromFin = shiftStatement(-shift, companyData.qCashFromFinance);
    const editCashIncrease = shiftStatement(-shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editRepayDebt: editRepayDebt, editCashFromFin: editCashFromFin, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function shiftOtherFinance(shift, companyData) {
    const editOtherFinance = shiftStatement(shift, companyData.otherFinancingActivities);
    const editCashFromFin = shiftStatement(shift, companyData.qCashFromFinance);
    const editCashIncrease = shiftStatement(shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editOtherFinance: editOtherFinance, editCashFromFin: editCashFromFin, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function shiftCashFromFin(shift, companyData) {
    const editOtherFinance = shiftStatement(shift, companyData.otherFinancingActivities);
    const editCashFromFin = shiftStatement(shift, companyData.qCashFromFinance);
    const editCashIncrease = shiftStatement(shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editOtherFinance: editOtherFinance, editCashFromFin: editCashFromFin, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function shiftCashIncrease(shift, companyData) {
    const editOtherFinance = shiftStatement(shift, companyData.otherFinancingActivities);
    const editCashFromFin = shiftStatement(shift, companyData.qCashFromFinance);
    const editCashIncrease = shiftStatement(shift, companyData.netIncreaseInCashAndEquivalents);

    const shiftAll = { editOtherFinance: editOtherFinance, editCashFromFin: editCashFromFin, editCashIncrease: editCashIncrease };
    return shiftAll;
}

export function bsShift(shift, companyData) {
    const editCash = shiftStatement(shift, companyData.qCash);
    const editCurrentAssets = shiftStatement(shift, companyData.qCurrentAssets);
    const editTotalAssets = shiftStatement(shift, companyData.qTotalAssets);
    const editNonCurrentLiab = shiftStatement(shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(shift, companyData.totalLiabilitiesAndShareholdersEquity);

    const shiftAll = { editCash: editCash, editCurrentAssets: editCurrentAssets, editTotalAssets: editTotalAssets, editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity };

    return shiftAll;
}

export function shiftCash(shift, companyData) {
    return bsShift(shift, companyData);
}

export function shiftAcctRec(shift, companyData) {
    const editAcctRec = shiftStatement(shift, companyData.qAcctReceivable);
    const editCurrentAssets = shiftStatement(shift, companyData.qCurrentAssets);
    const editTotalAssets = shiftStatement(shift, companyData.qTotalAssets);
    const editNonCurrentLiab = shiftStatement(shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(shift, companyData.totalLiabilitiesAndShareholdersEquity);

    const shiftAll = { editAcctRec: editAcctRec, editCurrentAssets: editCurrentAssets, editTotalAssets: editTotalAssets, editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity };
    
    return shiftAll;
}

export function shiftInventory(shift, companyData) {
    const editInventory = shiftStatement(shift, companyData.qInventory);
    const editCurrentAssets = shiftStatement(shift, companyData.qCurrentAssets);
    const editTotalAssets = shiftStatement(shift, companyData.qTotalAssets);
    const editNonCurrentLiab = shiftStatement(shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(shift, companyData.totalLiabilitiesAndShareholdersEquity);

    const shiftAll = { editInventory: editInventory, editCurrentAssets: editCurrentAssets, editTotalAssets: editTotalAssets, editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity };

    return shiftAll;
}

export function shiftPrePaid(shift, companyData) {
    const editPrePaid = shiftStatement(shift, companyData.prepaidExpenses);
    const editCurrentAssets = shiftStatement(shift, companyData.qCurrentAssets);
    const editTotalAssets = shiftStatement(shift, companyData.qTotalAssets);
    const editNonCurrentLiab = shiftStatement(shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(shift, companyData.totalLiabilitiesAndShareholdersEquity);

    const shiftAll = { editPrePaid: editPrePaid, editCurrentAssets: editCurrentAssets, editTotalAssets: editTotalAssets, editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity };

    return shiftAll;
}

export function shiftCurrentAssets(shift, companyData) {
    const editCash = shiftStatement(shift, companyData.qCash);
    const editCurrentAssets = shiftStatement(shift, companyData.qCurrentAssets);
    const editTotalAssets = shiftStatement(shift, companyData.qTotalAssets);
    const editNonCurrentLiab = shiftStatement(shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(shift, companyData.totalLiabilitiesAndShareholdersEquity);

    const shiftAll = { editCash: editCash, editCurrentAssets: editCurrentAssets, editTotalAssets: editTotalAssets, editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity };

    return shiftAll;
}

export function shiftPPE(shift, companyData) {
    const editPPE = shiftStatement(shift, companyData.propertyPlantAndEquipment);
    const editNetFixedAsset = shiftStatement(shift, companyData.qNetFixedAsset);
    const editTotalAssets = shiftStatement(shift, companyData.qTotalAssets);
    const editNonCurrentLiab = shiftStatement(shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(shift, companyData.totalLiabilitiesAndShareholdersEquity);

    const shiftAll = { editPPE: editPPE, editNetFixedAsset: editNetFixedAsset, editTotalAssets: editTotalAssets, editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity };

    return shiftAll;
}

export function shiftAccumDep(shift, companyData) {
    const editAccumDep = shiftStatement(shift, companyData.qAccumDep);
    const editNetFixedAsset = shiftStatement(-shift, companyData.qNetFixedAsset);
    const editTotalAssets = shiftStatement(-shift, companyData.qTotalAssets);
    const editNonCurrentLiab = shiftStatement(-shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(-shift, companyData.totalLiabilitiesAndShareholdersEquity);

    const shiftAll = { editAccumDep: editAccumDep, editNetFixedAsset: editNetFixedAsset, editTotalAssets: editTotalAssets, editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity };

    return shiftAll;
}

export function shiftNetFixed(shift, companyData) {
    const editPPE = shiftStatement(shift, companyData.propertyPlantAndEquipment);
    const editNetFixedAsset = shiftStatement(shift, companyData.qNetFixedAsset);
    const editTotalAssets = shiftStatement(shift, companyData.qTotalAssets);
    const editNonCurrentLiab = shiftStatement(shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(shift, companyData.totalLiabilitiesAndShareholdersEquity);

    const shiftAll = { editPPE: editPPE, editNetFixedAsset: editNetFixedAsset, editTotalAssets: editTotalAssets, editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity };

    return shiftAll;
}

export function shiftIntangible(shift, companyData) {
    const editIntangibles = shiftStatement(shift, companyData.qIntangibles);
    const editOtherAssets = shiftStatement(shift, companyData.totalOtherAssets);
    const editTotalAssets = shiftStatement(shift, companyData.qTotalAssets);
    const editNonCurrentLiab = shiftStatement(shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(shift, companyData.totalLiabilitiesAndShareholdersEquity);

    const shiftAll = { editIntangibles: editIntangibles, editOtherAssets: editOtherAssets, editTotalAssets: editTotalAssets, editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity };

    return shiftAll;
}

export function shiftOtherAssets(shift, companyData) {
    const editOtherAssets = shiftStatement(shift, companyData.totalOtherAssets);
    const editTotalAssets = shiftStatement(shift, companyData.qTotalAssets);
    const editNonCurrentLiab = shiftStatement(shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(shift, companyData.totalLiabilitiesAndShareholdersEquity);

    const shiftAll = { editOtherAssets: editOtherAssets, editTotalAssets: editTotalAssets, editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity };

    return shiftAll;
}

export function shiftTotalAssets(shift, companyData) {
    const editOtherAssets = shiftStatement(shift, companyData.totalOtherAssets);
    const editTotalAssets = shiftStatement(shift, companyData.qTotalAssets);
    const editNonCurrentLiab = shiftStatement(shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(shift, companyData.totalLiabilitiesAndShareholdersEquity);

    const shiftAll = { editOtherAssets: editOtherAssets, editTotalAssets: editTotalAssets, editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity };

    return shiftAll;
}

export function shiftAcctPay(shift, companyData) {
    const editAcctPay = shiftStatement(shift, companyData.qAcctPayable);
    const editCurrentLiab = shiftStatement(shift, companyData.qCurrentLiab);
    const editNonCurrentLiab = shiftStatement(-shift, companyData.nonCurrentLiab);

    const shiftAll = { editAcctPay: editAcctPay, editCurrentLiab: editCurrentLiab, editNonCurrentLiab: editNonCurrentLiab };

    return shiftAll;
}

export function shiftOtherCurrentLiab(shift, companyData) {
    const editOtherCurrentLiab = shiftStatement(shift, companyData.otherCurrentLiabilities);
    const editCurrentLiab = shiftStatement(shift, companyData.qCurrentLiab);
    const editNonCurrentLiab = shiftStatement(-shift, companyData.nonCurrentLiab);

    const shiftAll = { editOtherCurrentLiab: editOtherCurrentLiab, editCurrentLiab: editCurrentLiab, editNonCurrentLiab: editNonCurrentLiab };

    return shiftAll;
}

export function shiftShortTermNotes(shift, companyData) {
    const editSTD = shiftStatement(shift, companyData.qShortTermNotes);
    const editCurrentLiab = shiftStatement(shift, companyData.qCurrentLiab);
    const editNonCurrentLiab = shiftStatement(-shift, companyData.nonCurrentLiab);

    const shiftAll = { editSTD: editSTD, editCurrentLiab: editCurrentLiab, editNonCurrentLiab: editNonCurrentLiab };

    return shiftAll;
}

export function shiftCurrentLTD(shift, companyData) {
    const editOtherSTD = shiftStatement(shift, companyData.otherShortTermLiab);
    const editCurrentLiab = shiftStatement(shift, companyData.qCurrentLiab);
    const editNonCurrentLiab = shiftStatement(-shift, companyData.nonCurrentLiab);

    const shiftAll = { editOtherSTD: editOtherSTD, editCurrentLiab: editCurrentLiab, editNonCurrentLiab: editNonCurrentLiab };

    return shiftAll;
}

export function shiftTotalCurrentLiab(shift, companyData) {
    const editOtherCurrentLiab = shiftStatement(shift, companyData.otherCurrentLiabilities);
    const editCurrentLiab = shiftStatement(shift, companyData.qCurrentLiab);
    const editNonCurrentLiab = shiftStatement(-shift, companyData.nonCurrentLiab);

    const shiftAll = { editOtherCurrentLiab: editOtherCurrentLiab, editCurrentLiab: editCurrentLiab, editNonCurrentLiab: editNonCurrentLiab };

    return shiftAll;
}

export function shiftLongTermDebt(shift, companyData) {
    const editLTD = shiftStatement(shift, companyData.qLongTermDebt);

    const shiftAll = { editLTD: editLTD };

    return shiftAll;
}

export function shiftNonCurrentLiab(shift, companyData) {
    const editNonCurrentLiab = shiftStatement(shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(shift, companyData.totalLiabilitiesAndShareholdersEquity);
    const editOtherAssets = shiftStatement(shift, companyData.totalOtherAssets);
    const editTotalAssets = shiftStatement(shift, companyData.qTotalAssets);

    const shiftAll = { editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity, editOtherAssets: editOtherAssets, editTotalAssets: editTotalAssets };

    return shiftAll;
}

export function shiftCapitalStock(shift, companyData) {
    const editStock = shiftStatement(shift, companyData.capitalStock);
    const editRetainedEarnings = shiftStatement(-shift, companyData.qRetainedEarnings);

    const shiftAll = { editStock: editStock, editRetainedEarnings: editRetainedEarnings };

    return shiftAll;
}

export function shiftRetainedEarnings(shift, companyData) {
    const editRetainedEarnings = shiftStatement(shift, companyData.qRetainedEarnings);
    const editEquity = shiftStatement(shift, companyData.totalEquity);
    const editNonCurrentLiab = shiftStatement(-shift, companyData.nonCurrentLiab);

    const shiftAll = { editRetainedEarnings: editRetainedEarnings, editEquity: editEquity, editNonCurrentLiab: editNonCurrentLiab };

    return shiftAll;
}

export function shiftEquity(shift, companyData) {
    const editRetainedEarnings = shiftStatement(shift, companyData.qRetainedEarnings);
    const editEquity = shiftStatement(shift, companyData.totalEquity);
    const editNonCurrentLiab = shiftStatement(-shift, companyData.nonCurrentLiab);

    const shiftAll = { editRetainedEarnings: editRetainedEarnings, editEquity: editEquity, editNonCurrentLiab: editNonCurrentLiab };

    return shiftAll;
}

export function shiftTotalLiabEquity(shift, companyData) {
    const editNonCurrentLiab = shiftStatement(shift, companyData.nonCurrentLiab);
    const editTotalLiabEquity = shiftStatement(shift, companyData.totalLiabilitiesAndShareholdersEquity);
    const editOtherAssets = shiftStatement(shift, companyData.totalOtherAssets);
    const editTotalAssets = shiftStatement(shift, companyData.qTotalAssets);

    const shiftAll = { editNonCurrentLiab: editNonCurrentLiab, editTotalLiabEquity: editTotalLiabEquity, editOtherAssets: editOtherAssets, editTotalAssets: editTotalAssets };

    return shiftAll;
}
