import React from "react";
import { connect } from 'react-redux';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coordinate, Legend,
} from "bizcharts";
import {convertRemToPx} from "../utils";

class ProfitPie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            width: 0,
            height: 0,
            chartSize: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);        
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("profit pie receive new props!");
        this.setState({
            custom: false,
            data: nextProps
        });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let winHeight = window.innerHeight;

        this.setState({ width: window.innerWidth, height: window.innerHeight, chartSize: (winHeight * this.props.height) });
    }
       
    
    render() {
        let data, total, grossInc, EBIT, EBT, netInc;

        if (this.props.autoCorrectData === true) {
            total = Math.max(0, parseFloat(this.props.companyData.qSales.value));
            
            if (parseFloat(total) === 0.0) {
                grossInc = 0.0; EBIT = 0.0; EBT = 0.0; netInc = 0.0;
            } else {
                grossInc = Math.max(0, parseFloat(this.props.companyData.qGrossProfit.value) / parseFloat(total));
                EBIT = Math.max(0, parseFloat(this.props.companyData.qEBIT.value) / parseFloat(total));
                EBT = Math.max(0, parseFloat(this.props.companyData.earningBeforeIncomeTaxes.value) / parseFloat(total));
                netInc = Math.max(0, parseFloat(this.props.companyData.qNetIncome.value) / parseFloat(total));
            }

            data = [
                { score: grossInc, item: "Gross Profit" },
                { score: EBIT, item: "EBIT" },
                { score: EBT, item: "Earning Before Tax" },
                { score: netInc, item: "Net Profit" },
            ];
        } else {
            data = [
                { item: "ratio", score: 1 }
            ];
        }

        return (
            <div className="profitPie">
                <Chart
                    height={window.innerHeight * this.props.height}
                    data={data} autoFit
                    visible={this.props.activeTabKey===this.props.underTabKey && this.props.activeSubTabKey===this.props.underSubTab}
                >
                    <Coordinate type="polar"
                                startAngle={Math.PI}
                                endAngle={Math.PI * (3 / 2)}
                    />
                    <Legend visible={false} />
                    <Axis
                        name="score"
                        grid={{
                            line: {
                                style: {
                                    stroke: this.props.darkMode ? '#f2d9d9' : '#f2d9d9',
                                    lineWidth: 0.5,
                                    lineDash: null,
                                }
                            },
                            closed: false,
                        }}
                        label={null}
                    />
                    <Axis
                        name="item"
                        label={{
                            style: {
                                fill: this.props.darkMode ? '#A3ABBD' : '#192A3E', // 文本的颜色
                                fontSize: convertRemToPx(this.props.rootFontSize), // 文本大小
                            }
                        }}
                    />
                    <Tooltip showTitle={false} />
                    <Geom
                        type="interval"
                        position="item*score"
                        element-highlight
                        color={["item", ['#5FC563', '#7CD07F','#99da9b', '#B6E4B7']]}
                        style={{
                            stroke: '#E5E5E5',
                            lineWidth: 1,
                        }}
                        tooltip={['item*score', (item,score) => {
                            return {
                                // Custom tooltip on the title display and so on.
                                name: item,
                                value: (score * 100).toFixed(2) + '%'
                            };
                        }]}
                    />
                </Chart>
            </div>
        );
    }
}

const mapStateToProps = (state) =>{
  return{
      darkMode: state.darkModeReducer.darkMode,
      headerSelected: state.headerSelectedReducer.headerSelected,
      companyData: state.companyDataReducer.companyData,
      autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
      customisedData: state.customisedDataReducer.customisedData,
      radarPeers: state.radarPeersReducer.radarPeers,
      rootFontSize: state.globalStyleReducer.rootFontSize,
    activeTabKey: state.activeTabReducer.key,
    activeSubTabKey: state.activeTabReducer.subTabKey
  }
}

export default connect(mapStateToProps, null)(ProfitPie);