import React from "react";
import {connect} from 'react-redux';
import {
    Chart,
    Geom,
    Tooltip,
    Coordinate,
    Label,
    Legend, Axis,
} from "bizcharts";
import DataSet from "@antv/data-set";

class Bardiverge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            chartSize: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let winHeight = window.innerHeight;

        this.setState({ width: window.innerWidth, height: window.innerHeight, chartSize: (winHeight * 0.20) });
    }


    render() {
        const { DataView } = DataSet;
        const roboR8prob = this.props.companyData.roboR8;

        let data = [];
        let roboR8max = 0;
        let roboR8below = 0;
        let roboR8above = 0;
        let A, B, C;

        if (this.props.autoCorrectData === true) {
            if (this.props.companyData.roboR8rating === 0) roboR8max = 0;
            else roboR8max = roboR8prob[this.props.companyData.roboR8rating - 1].prob;
            for (let i = 1; i <= 24; i++) {
                if (i < this.props.companyData.roboR8rating) roboR8below = roboR8below + roboR8prob[i - 1].prob;
                if (i > this.props.companyData.roboR8rating) roboR8above = roboR8above + roboR8prob[i - 1].prob;
            }

            A = 100 * Math.round(roboR8below) / (Math.round(roboR8below) + Math.round(roboR8max) + Math.round(roboR8above));
            B = 100 * Math.round(roboR8max) / (Math.round(roboR8below) + Math.round(roboR8max) + Math.round(roboR8above));
            C = 100 * Math.round(roboR8above) / (Math.round(roboR8below) + Math.round(roboR8max) + Math.round(roboR8above));

            data = [
                {
                    group: "Rating Score Skew Probabilities",
                    type: "Rating Score Skew Probabilities",
                    total: 100,
                    "< Rating": A,
                    "= Rating": B,
                    "> Rating": C
                },
            ];
        } else {
            data = [
                {
                    group: "Rating Score Skew Probabilities",
                    type: "Rating Score Skew Probabilities",
                    total: 100,
                    "< Rating": 0,
                    "= Rating": 100,
                    "> Rating": 0
                },
            ];
        }
        const dv = new DataView();
        dv.source(data)
            .transform({
                type: "map",
            })
            .transform({
                type: "fold",
                fields: [
                    "< Rating",
                    "= Rating",
                    "> Rating",
                ],
                key: "opinion",
                value: "value",
                retains: ["group", "type"]
            });
        const colorMap = this.props.darkMode ? {
            /*
            "< Rating": "#BF606A",
            "= Rating": "#316E9A",
            "> Rating": "#6E9B65"
            */
            "< Rating": "#ff98a2",
            "= Rating": "#6e94f4",
            "> Rating": "#99da9b"
        } :
            {
                "< Rating": "#ffb1b9",
                "= Rating": "#6e94f4",
                "> Rating": "#99da9b"
            };

        return (
            <div className="diverging-chart">
                <Chart padding={{ top: (this.state.height * 0.02), right: 0, bottom: (this.state.height * 0.05), left: 0 }} height={this.state.chartSize} data={dv} autoFit
                       visible={this.props.activeTabKey === this.props.underTabKey}>
                     <Axis name="type" visible={false} />
                    <Axis name="value" visible={false} />
                    {/* <Axis name="name" label={'hello world'} labelOffset={10}  visible={false}/> */}
                    {/* <Axis
            name="value"
            title={null}
            tickLine={null}
            position="right"
            formatter={function(val) {
              return val + "%";
            }}
          /> */}
                    <Coordinate transpose />
                    <Legend
                        marker= {{
                            symbol: 'circle'
                        }}
                        itemName={{
                            style: {
                                fill: this.props.darkMode ? "#A3ABBD" : "#192A3E",
                                fontSize: 12,
                                fontFamily: 'font1 light'
                            }
                        }}
                    />
                    <Tooltip
                        shared={true}
                        showTitle={false}
                        inPlot={false}
                    />

                    <Geom
                        tooltip={['value*opinion', (value, opinion) => {
                            return {
                                //自定义 tooltip 上显示的 title 显示内容等。
                                name: opinion,
                                value: Math.round(value) + '%'
                            };
                        }]}
                        type="interval"
                        adjust={'stack'}
                        size={this.state.height * 0.10}
                        position="type*value"
                        color={[
                            "opinion",
                            function (opinion) {
                                return colorMap[opinion];
                            }
                        ]}
                        shape="smooth"
                        opacity={1}
                        label={[
                            "value",
                            (value) => {
                            return {
                                content: Math.round(value) + "%",
                                style: {
                                    rotate: 0,
                                    textAlign: "center",
                                    fontFamily: 'font1 medium',
                                    fill: this.props.darkMode ? "#00003f" : "#ffffff",
                                },
                                offset: 0,
                                position: 'middle',
                            }
                            }
                        ]}
                    />
                </Chart>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        darkMode: state.darkModeReducer.darkMode,
        companyData: state.companyDataReducer.companyData,
        autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
      activeTabKey: state.activeTabReducer.key
    }
}

export default connect(mapStateToProps,null)(Bardiverge);