import { COMPANY_DATA } from '../constants';

const data = [];

for (let i = 1; i <= 24; i++) {    
    data.push({
        rating:`R${i}`,
        prob: 0,
    })
}
        
let initialState={
    companyData: {
        qEmployees: '--',
        qReportDate: '--',
        qFiscalDate: '--',

        qSales: {
            log: '',
            status: '',
            value: '--',
        },
        costGoodsSold: {
            log: '',
            status: '',
            value: '--',
        }, //extra
        qGrossProfit: {
            log: '',
            status: '',
            value: '--',
        },
        sGandA: {
            log: '',
            status: '',
            value: '--',
        }, //extra
        qDandA: {
            log: '',
            status: '',
            value: '--',
        },
        qEBIT: {
            log: '',
            status: '',
            value: '--',
        },
        qGrossInterest: {
            log: '',
            status: '',
            value: '--',
        },
        earningBeforeIncomeTaxes: {
            log: '',
            status: '',
            value: '--',
        }, //extra
        incomeTaxExpense: {
            log: '',
            status: '',
            value: '--',
        }, //extra
        qNetIncome: {
            log: '',
            status: '',
            value: '--',
        },

        increaseDecreaseInOtherItems: {
            log: '',
            status: '',
            value: '--',
        }, //extra
        qOpCashFlow: {
            log: '',
            status: '',
            value: '--',
        },
        qCapex: {
            log: '',
            status: '',
            value: '--',
        },
        investmentsInOtherAssets: {
            log: '',
            status: '',
            value: '--',
        }, //extra      
        qCashFromInvest: {
            log: '',
            status: '',
            value: '--',
        },
        qDividend: {
            log: '',
            status: '',
            value: '--',
        },
        qSaleOfEquity: {
            log: '',
            status: '',
            value: '--',
        },
        qIssueLTDebt: {
            log: '',
            status: '',
            value: '--',
        },
        qRepayLTDebt: {
            log: '',
            status: '',
            value: '--',
        },
        otherFinancingActivities: {
            log: '',
            status: '',
            value: '--',
        }, //extra
        qCashFromFinance: {
            log: '',
            status: '',
            value: '--',
        },
        netIncreaseInCashAndEquivalents: {
            log: '',
            status: '',
            value: '--',
        }, //extra

        qCash: {
            log: '',
            status: '',
            value: '--',
        },
        qAcctReceivable: {
            log: '',
            status: '',
            value: '--',
        },
        qInventory: {
            log: '',
            status: '',
            value: '--',
        },
        prepaidExpenses: {
            log: '',
            status: '',
            value: '--',
        }, //extra   
        qCurrentAssets: {
            log: '',
            status: '',
            value: '--',
        },
        propertyPlantAndEquipment: {
            log: '',
            status: '',
            value: '--',
        }, //extra  
        qAccumDep: {
            log: '',
            status: '',
            value: '--',
        },
        qNetFixedAsset: {
            log: '',
            status: '',
            value: '--',
        },
        qIntangibles: {
            log: '',
            status: '',
            value: '--',
        },
        totalOtherAssets: {
            log: '',
            status: '',
            value: '--',
        }, //extra  
        qTotalAssets: {
            log: '',
            status: '',
            value: '--',
        },

        qAcctPayable: {
            log: '',
            status: '',
            value: '--',
        },
        otherCurrentLiabilities: {
            log: '',
            status: '',
            value: '--',
        }, //extra    
        qShortTermNotes: {
            log: '',
            status: '',
            value: '--',
        },
        otherShortTermLiab: {
            log: '',
            status: '',
            value: '--',
        }, //extra
        qCurrentLiab: {
            log: '',
            status: '',
            value: '--',
        },
        qLongTermDebt: {
            log: '',
            status: '',
            value: '--',
        },
        nonCurrentLiab: {
            log: '',
            status: '',
            value: '--',
        }, //extra
        capitalStock: {
            log: '',
            status: '',
            value: '--',
        }, //extra      
        qRetainedEarnings: {
            log: '',
            status: '',
            value: '--',
        },
        totalEquity: {
            log: '',
            status: '',
            value: '--',
        }, //extra
        totalLiabilitiesAndShareholdersEquity: {
            log: '',
            status: '',
            value: '--',
        }, //extra   

        qFreeCashFlow: {
            log: '',
            status: '',
            value: '--',
        },
        netWorkingCapital: {
            log: '',
            status: '',
            value: '--',
        },
        totalLiabilitiesShortLongTerm: {
            log: '',
            status: '',
            value: '--',
        },
        totalShareholdersEquity: {
            log: '',
            status: '',
            value: '--',
        },        
        debtToEquity: {
            log: '',
            status: '',
            value: '--',
        },
        currentRatio: {
            log: '',
            status: '',
            value: '--',
        },
        interestCoverageRatio: {
            log: '',
            status: '',
            value: '--',
        },

        microUnemployment: {
            log: '',
            status: '',
            value: '--',
        },
        microDebtToIncome: {
            log: '',
            status: '',
            value: '--',
        },
        microDebtToGdp: {
            log: '',
            status: '',
            value: '--',
        },
        microHousePrice: {
            log: '',
            status: '',
            value: '--',
        },
        microFixedIncome: {
            log: '',
            status: '',
            value: '--',
        },
        microCommodities: {
            log: '',
            status: '',
            value: '--',
        },
        microEmployment: {
            log: '',
            status: '',
            value: '--',
        },
        microRetailSales: {
            log: '',
            status: '',
            value: '--',
        },
        microOutputGap: {
            log: '',
            status: '',
            value: '--',
        },
        microAutoSales: {
            log: '',
            status: '',
            value: '--',
        },
        microVol: {
            log: '',
            status: '',
            value: '--',
        },
        microCredit: {
            log: '',
            status: '',
            value: '--',
        },
        microRates2Y: {
            log: '',
            status: '',
            value: '--',
        },
        microIndustrialProduction: {
            log: '',
            status: '',
            value: '--',
        },
        microBusinessOptimism: {
            log: '',
            status: '',
            value: '--',
        },
        microCPI: {
            log: '',
            status: '',
            value: '--',
        },
        microPPI: {
            log: '',
            status: '',
            value: '--',
        },
        microEnergy: {
            log: '',
            status: '',
            value: '--',
        },
        microOil: {
            log: '',
            status: '',
            value: '--',
        },
        fitchRating: {
            log: '',
            status: '',
            value: '--',
        },
        moodysRating: {
            log: '',
            status: '',
            value: '--',
        },
        spRating: {
            log: '',
            status: '',
            value: '--',
        },
        roboR8: data,
        roboR8date: '',
        roboR8rating: 1,
        roboR8score: 0,
        roboR8peers: '',
        roboR8peersHistory: '',
        roboR8peersData: '',
        roboR8peersBars: '',
        industry: 'Automobiles and Components'
    }
};

const companyDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_DATA:
            // if action.companyData is null, it means we want to reset to initial state
            if (action.companyData == null){
                console.log('Company Data Reducer Reset firing');
                return {
                    ...state,
                    companyData: {...initialState.companyData}
                };
            }

            // otherwise update companyData
            console.log('Company Data Reducer firing');
            return {
                ...state,
                companyData: {
                    ...state.companyData,
                    ...action.companyData
                },
            };
        default:
            return state;
    }
};

export default companyDataReducer;