import React from 'react';
import "./index.css";
import { ClockCircleOutlined } from '@ant-design/icons';
import { Typography, Layout, Image, Row, Col, Timeline } from 'antd';
const { Content } = Layout;


const Migo = (props) => {
  return (
    <div className="whats-migo">
      MIGO&trade; : Machine Intelligence via Graph Optimization
    <Typography className="data-title" style={{ textAlign: "justify", textJustify: "inter-word" }}>
              
              <Content className="whats-migo-content">
                  roboR8&trade; is a company credit scoring system which uses a proprietary algorithm called MIGO&trade; which has the unique property of being mathematically optimal
                  within its class.<br /><br /> MIGO&trade; is not really a single algorithm but more of a system with six distinct steps.
                  MIGO&trade; was developed by the research team at Rekiki PTE Ltd (www.rekiki.com) and belongs to a class of Artificial Intelligence called Machine Learning (ML). 
                  It uses a semi-supervised learning algorithm where the system is given "samples" used for training. A sample is an input:target pair and the
                  objective is to minimize the miss-classification of the targets given a set of unseen inputs. The mathematics behind the MIGO&trade; system is based on
                  combinatorial optimization, graph theory, deep learning, classification trees, and dynamic programming.<br /><br />
                  MIGO&trade; uses a type of ML algorithm called <font style={{ fontFamily: "font1 medium" }}> CLASSIFICATION TREES </font>, but here is where any similarity to 
                  any other tree-based algorithm ends. The six, simplified steps to the MIGO&trade; system are <br /><br />

                  <Timeline mode = "alternate">
                      <Timeline.Item label="Signal Construction" style={{ color: "#c6c6c6" }} > <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}> COMBINATORIAL OPTIMIZATION </font>
                          <font style={{ color: "#80a9e6" }}> is used to derive a set of input groups from the company's financial statement and macro-economic releases. </font>
                          <br /><br />
                      </Timeline.Item>
                      <Timeline.Item label="Input Selection" style={{ color: "#c6c6c6" }}> <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}> GRAPH THEORY </font> 
                          <font style={{ color: "#80a9e6" }}> ensures that the chosen set of input groups cover all aspects of the financials with no overlooked, and potentially significant, entries. </font>
                          <br /><br />
                      </Timeline.Item>
                      <Timeline.Item label="Regime Identification" style={{ color: "#c6c6c6" }}> <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}> SIMILARITY </font>
                          <font style={{ color: "#80a9e6" }}> identifies which credit regime is applicable to the current state of the company </font>
                          <br /><br />
                      </Timeline.Item>
                      <Timeline.Item label="Model Creation" style={{ color: "#c6c6c6" }}> <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}> GRAPH THEORETIC TECHNIQUES, DEEP LEARNING, QUADRATIC and DYNAMIC PROGRAMMING </font>
                          <font style={{ color: "#80a9e6" }}> are used to create a optimal classification tree for all companies in the same industry group </font>
                          <br /><br />
                      </Timeline.Item>
                      <Timeline.Item label="Model-Set Aggregation" style={{ color: "#c6c6c6" }}> <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}> EVOLUTIONARY ALGORITHMS </font>
                          <font style={{ color: "#80a9e6" }}> combine the super-set of models for a particular industry to produce a credit forecast </font>
                          <br /><br />
                      </Timeline.Item>
                      <Timeline.Item label="Solution Processing" style={{ color: "#c6c6c6" }}> <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}> NON-LINEAR OPTIMIZATION </font>
                          <font style={{ color: "#80a9e6" }}> creates the final credit distribution from the credit forecast given the risk, trajectory and history </font>
                          <br /><br />
                      </Timeline.Item>    
                  </Timeline>
                  <br />
                  <Row gutter={24}>                      
                      <Col className="gutter-row" span={18}>      
                          The inputs are numerous, possibly correlated and, in many cases, contradictory, which required a new form of <font style={{ fontFamily: "font1 medium" }}>
                          DEEP LEARNING </font> applied to classification trees to be developed. The classification tree's <font style={{ fontFamily: "font1 medium" }}>
                          DYNAMIC PROGRAMMING </font> algorithm is combined with graph optimization techniques to produce in a tree that is mathematically proven to be 
                          optimal for any given error definition, and has a superior decision process that includes non-orthogonal separations of the space. 
                          The resulting classification trees have superior performance to any other existing system. An example of such 
                          non-orthogonal separations for two dimensions is shown here.
                      </Col>              
                      <Col className="gutter-row" span={6}><Image src={'/images/twoDexample.png'} className="whats-migo-image" /></Col>
                  </Row>
                  <br />
                  This model is used to map the company's features to its credit score and is unique for each input group; the collection being called the MIGO&trade; forest.
                  The outputs from each model of the forest are then combined, to produce the final answer of the system. <br /><br />
                  Compared to other traditional ML algorithms (such as Neural Networks or Support Vector Machines), 
                  classification trees have the unique property that, however complex the derivation algorithm may be, 
                  the solutions themselves are easily understandable and simple to explain, giving insight, sensitivity and therefore trust into the answer. <br /><br />             
                  For white-papers or questions regarding the MIGO&trade; system, please email us at info@rekiki.com                  
              </Content>
          </Typography>
    </div>
  );
}

export default Migo;