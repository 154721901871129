import React from "react";
import { connect } from 'react-redux';
import { mapRatingToScoreSimple} from '../utils';
import {
  Chart,
  Geom,
  Axis,
  Tooltip,
  Label,
} from "bizcharts";

let dataTemp = [];
for (let i = 1; i <= 24; i++) {
    dataTemp.push({
        rating: `R${i}`,
        prob: 0,
    })
}

class ColumnSimple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            ganttSize: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let winHeight = window.innerHeight;

        this.setState({ width: window.innerWidth, height: window.innerHeight, ganttSize: (winHeight * this.props.height) });
    }


    render() {
        dataTemp = this.props.companyData.roboR8;

        let dataMap = [
            { rating: "Critical Risk", prob: 0 },
            { rating: "Low Quality", prob: 0 },
            { rating: "Lower Medium", prob: 0 },
            { rating: "Upper Medium", prob: 0 },
            { rating: "High Quality", prob: 0}
        ];
        
        for (let i = 1; i <= 24; i++) {
            if (i < 9) dataMap[0].prob += dataTemp[i - 1].prob;
            else if (i < 15) dataMap[1].prob += dataTemp[i - 1].prob;
            else if (i < 18) dataMap[2].prob += dataTemp[i - 1].prob;
            else if (i < 21) dataMap[3].prob += dataTemp[i - 1].prob;
            else dataMap[4].prob += dataTemp[i - 1].prob;
        }

        const data = dataMap;

        const cols = {
            prob: {
                tickInterval: 10,
                min: 0, // 定义数值范围的最小值
                max: 110, // 定义数值范围的最大值
            }
        };

        const grid = {
            line: {
                style: {
                    stroke: this.props.darkMode ? '#4F4F4F' : '#f2d9d9', //'#4F4F4F' : '#E5E5E5',
                    lineWidth: 1,
                    lineDash: [0, 0]
                }
            }
        }

        return (
            <div className="gantt-chart">
                <Chart height={this.state.ganttSize} padding={[0, 0, 30, 0]} data={data} scale={cols} autoFit renderer='svg'
                       visible={this.props.activeTabKey === "1"}>
                    <Axis name="rating"
                        label={{
                            style: {
                                fill: this.props.darkMode ? '#A3ABBD' : '#192A3E',
                                textAlign: "center",
                                fontFamily: 'font1 medium',
                                fontSize: 11,
                            },
                            autoHide: false, // 是否自动隐藏，默认 true
                            }}
                          line={null}
                        grid={grid}
                    />
                    <Axis
                        label={null}
                        line={grid.line}
                        name="prob"    
                        grid={grid}
                    />
                    <Tooltip
                        showCrosshairs={true}
                        crosshairs={{
                            type: "x",
                            follow: true,
                        }}
                    />
                    <Geom type="interval" position="rating*prob"
                        color='#6E94F4'
                        opacity={1}
                        size={75}
                        label={[
                            "prob",
                            (val) => {
                            return {
                                content: val <= 0.5? "" : Math.round(val) + "%",
                                offset: -10,
                                style: {
                                    fill: this.props.darkMode ? "#00003f" : "#ffffff",
                                    rotate: 0,
                                    textAlign: "center",
                                    fontSize: 11,
                                    fontFamily: 'font1 medium'
                                }
                                }
                            }
                        ]}
                    >
                    </Geom>
                </Chart>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        darkMode: state.darkModeReducer.darkMode,
        companyData: state.companyDataReducer.companyData,
      activeTabKey: state.activeTabReducer.key,
    }
}

export default connect(mapStateToProps, null)(ColumnSimple);