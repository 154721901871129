import {checkEmptyString} from "./miscUtils";
import {autoCorrectBalanceSheet, autoCorrectCashFlowStatement, autoCorrectIncomeStatement} from "./autoCorrectUtils";
import {createRoboR8RatingObjectFromRoboR8Results, mapScoreToRating} from "./ratingUtils";

export function processReport(reportData){
    let ISvalue = {
        qGrossProfitValue: '',
        qSalesValue: '',
        costGoodsSoldValue: '',
        qEBITValue: '',
        sGandAValue: '',
        qDandAValue: '',
        qGrossInterestValue: '',
        earningBeforeIncomeTaxesValue: '',
        incomeTaxExpenseValue: '',
        qNetIncomeValue: ''
    };

    let ISstatus = {
        checkGrossProfitStatus: '',
        checkSalesValueStatus: '',
        checkCostGoodsSoldStatus: '',
        checkEBITStatus: '',
        checkSGandAStatus: '',
        checkDandAStatus: '',
        checkGrossInterestStatus: '',
        checkEarningBeforeIncomeTaxesStatus: '',
        checkIncomeTaxExpenseStatus: '',
        checkNetIncomeStatus: ''
    };

    let ISlog = {
        checkGrossProfitLog: '',
        checkSalesValueLog: '',
        checkCostGoodsSoldLog: '',
        checkEBITLog: '',
        checkSGandALog: '',
        checkDandALog: '',
        checkGrossInterestLog: '',
        checkEarningBeforeIncomeTaxesLog: '',
        checkIncomeTaxExpenseLog: '',
        checkNetIncomeLog: ''
    };

    let ISdata = { ...ISvalue, ...ISstatus, ...ISlog };

    let CFvalue = {
        qNetIncomeValue: '',
        qDandAValue: '',
        increaseDecreaseInOtherItemsValue: '',
        qOpCashFlowValue: '',
        qCapexValue: '',
        investmentsInOtherAssetsValue: '',
        qCashFromInvestValue: '',
        qDividendValue: '',
        qSaleOfEquityValue: '',
        qIssueLTDebtValue: '',
        qRepayLTDebtValue: '',
        otherFinancingActivitiesValue: '',
        qCashFromFinanceValue: '',
        netIncreaseInCashAndEquivalentsValue: ''
    };

    let CFstatus = {
        checkNetIncomeStatus: '',
        checkDandAStatus: '',
        checkIncreaseDecreaseInOtherItemsStatus: '',
        checkOpCashFlowStatus: '',
        checkCapexStatus: '',
        checkInvestmentsInOtherAssetsStatus: '',
        checkCashFromInvestStatus: '',
        checkDividendStatus: '',
        checkSaleOfEquityStatus: '',
        checkIssueLTDebtStatus: '',
        checkRepayLTDebtStatus: '',
        checkOtherFinancingActivitiesStatus: '',
        checkCashFromFinanceStatus: '',
        checkNetIncreaseInCashAndEquivalentsStatus: ''
    };

    let CFlog = {
        checkNetIncomeLog: '',
        checkDandALog: '',
        checkIncreaseDecreaseInOtherItemsLog: '',
        checkOpCashFlowLog: '',
        checkCapexLog: '',
        checkInvestmentsInOtherAssetsLog: '',
        checkCashFromInvestLog: '',
        checkDividendLog: '',
        checkSaleOfEquityLog: '',
        checkIssueLTDebtLog: '',
        checkRepayLTDebtLog: '',
        checkOtherFinancingActivitiesLog: '',
        checkCashFromFinanceLog: '',
        checkNetIncreaseInCashAndEquivalentsLog: ''
    };

    let CFdata = { ...CFvalue, ...CFstatus, ...CFlog };

    let BSAvalue = {
        qCashValue: '',
        qAcctReceivableValue: '',
        qInventoryValue: '',
        prepaidExpensesValue: '',
        qCurrentAssetsValue: '',
        propertyPlantAndEquipmentValue: '',
        qAccumDepValue: '',
        qNetFixedAssetValue: '',
        qIntangiblesValue: '',
        totalOtherAssetsValue: '',
        qTotalAssetsValue: ''
    };

    let BSAstatus = {
        checkCashStatus: '',
        checkAcctReceivableStatus: '',
        checkInventoryStatus: '',
        checkPrepaidExpensesStatus: '',
        checkCurrentAssetsStatus: '',
        checkPropertyPlantAndEquipmentStatus: '',
        checkAccumDepStatus: '',
        checkNetFixedAssetStatus: '',
        checkIntangiblesStatus: '',
        checkTotalOtherAssetsStatus: '',
        checkTotalAssetsStatus: ''
    };

    let BSAlog = {
        checkCashLog: '',
        checkAcctReceivableLog: '',
        checkInventoryLog: '',
        checkPrepaidExpensesLog: '',
        checkCurrentAssetsLog: '',
        checkPropertyPlantAndEquipmentLog: '',
        checkAccumDepLog: '',
        checkNetFixedAssetLog: '',
        checkIntangiblesLog: '',
        checkTotalOtherAssetsLog: '',
        checkTotalAssetsLog: ''
    };

    let BSLvalue = {
        qAcctPayableValue: '',
        otherCurrentLiabilitiesValue: '',
        qShortTermNotesValue: '',
        otherShortTermLiabValue: '',
        qCurrentLiabValue: '',
        qLongTermDebtValue: '',
        qTotalLiabValue: '',
        nonCurrentLiabValue: '',
        capitalStockValue: '',
        qRetainedEarningsValue: '',
        totalEquityValue: '',
        totalLiabilitiesAndShareholdersEquityValue: ''
    };

    let BSLstatus = {
        checkAcctPayableStatus: '',
        checkOtherCurrentLiabilitiesStatus: '',
        checkShortTermNotesStatus: '',
        checkOtherShortTermLiabStatus: '',
        checkCurrentLiabStatus: '',
        checkLongTermDebtStatus: '',
        checkTotalLiabStatus: '',
        checkNonCurrentLiabStatus: '',
        checkCapitalStockStatus: '',
        checkRetainedEarningsStatus: '',
        checkTotalEquityStatus: '',
        checkTotalLiabilitiesAndShareholdersEquityStatus: ''
    };

    let BSLlog = {
        checkAcctPayableLog: '',
        checkOtherCurrentLiabilitiesLog: '',
        checkShortTermNotesLog: '',
        checkOtherShortTermLiabLog: '',
        checkCurrentLiabLog: '',
        checkLongTermDebtLog: '',
        checkTotalLiablog: '',
        checkNonCurrentLiabLog: '',
        checkCapitalStockLog: '',
        checkRetainedEarningsLog: '',
        checkTotalEquityLog: '',
        checkTotalLiabilitiesAndShareholdersEquityLog: ''
    };

    let BSdata = { ...BSAvalue, ...BSAstatus, ...BSAlog, ...BSLvalue, ...BSLstatus, ...BSLlog };

    ISdata.qSalesValue = checkEmptyString(reportData.sales);

    ISdata.qGrossProfitValue = checkEmptyString(reportData.gross_profit);

    ISdata.costGoodsSoldValue = ISdata.qSalesValue - ISdata.qGrossProfitValue;

    ISdata.qDandAValue = checkEmptyString(reportData.d_and_a);

    ISdata.qEBITValue = checkEmptyString(reportData.EBIT);

    ISdata.sGandAValue = ISdata.qGrossProfitValue - ISdata.qDandAValue - ISdata.qEBITValue;

    ISdata.qGrossInterestValue = checkEmptyString(reportData.gross_interest);

    ISdata.earningBeforeIncomeTaxesValue = ISdata.qEBITValue - ISdata.qGrossInterestValue;

    ISdata.qNetIncomeValue = checkEmptyString(reportData.net_income);

    ISdata.incomeTaxExpenseValue = ISdata.qEBITValue - ISdata.qGrossInterestValue - ISdata.qNetIncomeValue;

    CFdata.qNetIncomeValue = ISdata.qNetIncomeValue;
    CFdata.qDandAValue = ISdata.qDandAValue;

    CFdata.qOpCashFlowValue = checkEmptyString(reportData.op_cash_flow);

    CFdata.increaseDecreaseInOtherItemsValue = CFdata.qOpCashFlowValue - ISdata.qNetIncomeValue - ISdata.qDandAValue;

    CFdata.qCapexValue = checkEmptyString(reportData.capex);

    CFdata.qCashFromInvestValue = checkEmptyString(reportData.cash_from_investment);

    CFdata.investmentsInOtherAssetsValue = CFdata.qCashFromInvestValue - CFdata.qCapexValue;

    CFdata.qDividendValue = checkEmptyString(reportData.dividend);

    CFdata.qSaleOfEquityValue = checkEmptyString(reportData.sale_of_equity);

    CFdata.qIssueLTDebtValue = checkEmptyString(reportData.issue_L_T_debt);

    CFdata.qRepayLTDebtValue = checkEmptyString(reportData.repay_long_term_debt);

    CFdata.qCashFromFinanceValue = checkEmptyString(reportData.cash_from_finance);

    CFdata.otherFinancingActivitiesValue = CFdata.qCashFromFinanceValue - CFdata.qDividendValue - CFdata.qSaleOfEquityValue - CFdata.qIssueLTDebtValue - CFdata.qRepayLTDebtValue;

    CFdata.netIncreaseInCashAndEquivalentsValue = CFdata.qOpCashFlowValue + CFdata.qCashFromInvestValue + CFdata.qCashFromFinanceValue;

    BSdata.qCashValue = checkEmptyString(reportData.cash);

    BSdata.qAcctReceivableValue = checkEmptyString(reportData.acct_receivable);

    BSdata.qInventoryValue = checkEmptyString(reportData.inventory);

    BSdata.qCurrentAssetsValue = checkEmptyString(reportData.current_assets);

    BSdata.prepaidExpensesValue = BSdata.qCurrentAssetsValue - BSdata.qCashValue - BSdata.qAcctReceivableValue - BSdata.qInventoryValue;

    BSdata.qAccumDepValue = checkEmptyString(reportData.accum_dep);

    BSdata.qNetFixedAssetValue = checkEmptyString(reportData.net_fixed_asset);

    BSdata.propertyPlantAndEquipmentValue = BSdata.qNetFixedAssetValue + BSdata.qAccumDepValue;

    BSdata.qIntangiblesValue = checkEmptyString(reportData.intangibles);

    BSdata.qTotalAssetsValue = checkEmptyString(reportData.total_assets);

    BSdata.totalOtherAssetsValue = BSdata.qTotalAssetsValue - BSdata.qNetFixedAssetValue - BSdata.qCurrentAssetsValue;

    BSdata.qAcctPayableValue = checkEmptyString(reportData.acct_payable);

    BSdata.qShortTermNotesValue = checkEmptyString(reportData.short_term_notes);

    BSdata.otherShortTermLiabValue = 0;

    BSdata.qCurrentLiabValue = checkEmptyString(reportData.current_liab);

    BSdata.otherCurrentLiabilitiesValue = BSdata.qCurrentLiabValue - BSdata.qAcctPayableValue - BSdata.qShortTermNotesValue - BSdata.otherShortTermLiabValue;

    BSdata.qLongTermDebtValue = checkEmptyString(reportData.L_T_debt);

    BSdata.qTotalLiabValue = checkEmptyString(reportData.total_liab);

    BSdata.nonCurrentLiabValue = BSdata.qTotalLiabValue - BSdata.qCurrentLiabValue;

    BSdata.qRetainedEarningsValue = checkEmptyString(reportData.retained_earnings);

    BSdata.totalEquityValue = BSdata.qTotalAssetsValue - BSdata.qTotalLiabValue;

    BSdata.capitalStockValue = BSdata.totalEquityValue - BSdata.qRetainedEarningsValue;

    BSdata.totalLiabilitiesAndShareholdersEquityValue = BSdata.qTotalLiabValue + BSdata.totalEquityValue;

    //Check debt values add up, otherwise adjust short term debt
    let totalDebt = checkEmptyString(reportData.total_debt);
    if (parseFloat(BSdata.qShortTermNotesValue) + parseFloat(BSdata.qLongTermDebtValue) !== parseFloat(totalDebt))
        BSdata.qShortTermNotesValue = totalDebt - BSdata.qLongTermDebtValue;

    let qFreeCashFlowValue = checkEmptyString(reportData.free_cash_flow);

    let netWorkingCapitalValue = BSdata.qCurrentAssetsValue - BSdata.qCurrentLiabValue;

    let debtToEquityValue = BSdata.qTotalLiabValue / BSdata.totalEquityValue;

    let currentRatioValue = BSdata.qCurrentAssetsValue / BSdata.qCurrentLiabValue;

    let interestCoverageRatioValue = ISdata.qEBITValue / ISdata.qGrossInterestValue;

    ISdata = autoCorrectIncomeStatement(ISdata);
    CFdata = autoCorrectCashFlowStatement(CFdata);
    BSdata = autoCorrectBalanceSheet(BSdata);

    //Update ratios, net working cap and total liabilities
    netWorkingCapitalValue = BSdata.qCurrentAssetsValue - BSdata.qCurrentLiabValue;

    if (BSdata.totalEquityValue === 0) debtToEquityValue = 0;
    else debtToEquityValue = BSdata.qTotalLiabValue / BSdata.totalEquityValue;
    if (BSdata.qCurrentLiabValue === 0) currentRatioValue = 0;
    else currentRatioValue = BSdata.qCurrentAssetsValue / BSdata.qCurrentLiabValue;
    if (ISdata.qGrossInterestValue === 0) interestCoverageRatioValue = 0;
    else interestCoverageRatioValue = ISdata.qEBITValue / ISdata.qGrossInterestValue;

    const result = {};
    result.sales = ISdata.qSalesValue;
    result.grossProfit = ISdata.qGrossProfitValue;
    result.depreciationAndAmortisation = ISdata.qDandAValue;
    result.ebit = ISdata.qEBITValue;
    result.interestExpense = ISdata.qGrossInterestValue;
    result.netIncome = ISdata.qNetIncomeValue;
    result.netCashFromOperating = CFdata.qOpCashFlowValue;
    result.capex = CFdata.qCapexValue;
    result.netCashFromInvesting = CFdata.qCashFromInvestValue;
    result.netCashFromFinancing = CFdata.qCashFromFinanceValue;
    result.cashAndEquivalents = BSdata.qCashValue;
    result.accountReceivable = BSdata.qAcctReceivableValue;
    result.inventory = BSdata.qInventoryValue;
    result.totalCurrentAssets = BSdata.qCurrentAssetsValue;
    result.accumulatedDepreciation = BSdata.qAccumDepValue;
    result.netFixedAsset = BSdata.qNetFixedAssetValue;
    result.intangibles = BSdata.qIntangiblesValue;
    result.totalAssets = BSdata.qTotalAssetsValue;
    result.accountPayable = BSdata.qAcctPayableValue;
    result.shortTermDebt = BSdata.qShortTermNotesValue;
    result.totalCurrentLiabilities = BSdata.qCurrentLiabValue;
    result.longTermDebt = BSdata.qLongTermDebtValue;
    result.totalLiabilities = BSdata.qTotalLiabValue;
    result.retainedEarnings = BSdata.qRetainedEarningsValue;
    result.totalEquity = BSdata.totalEquityValue;
    result.freeCashFlow = qFreeCashFlowValue;
    result.companyId = reportData.company;
    result.companyName = reportData.company_name;


    if(reportData.robor8_rating){
        reportData.robor8_rating.quantiles = [];
        for (let i = 1; i <= 24; i++) {
            let ithQuantile = parseFloat(reportData.robor8_rating[`quantile_${i}`]);
            reportData.robor8_rating.quantiles.push(ithQuantile)
        }
        result.roboR8 = createRoboR8RatingObjectFromRoboR8Results(reportData.robor8_rating);
    }

    return result;
}