import styled, {keyframes} from 'styled-components';

import React, {useEffect, useRef, useState} from 'react';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default function Gauge(props) {
    const {value} = props;
    const prevValue = usePrevious(value);

    return (
        <GaugeWrapper size={props.size} value={props.value} prevValue={prevValue}>
            <GaugeOverlay/>
        </GaugeWrapper>
    )
}

// Create the keyframes
let rotate = (value, prevValue)=> {
    return keyframes`
  from {
    transform: rotate(${prevValue*1.8+180}deg);
  }

  to {
    transform: rotate(${value*1.8+180}deg);
  }
`;}

const GaugeWrapper = styled.div`
    margin: 0 auto;
    position: relative;
    width: ${props => props.size ? (props.size*2)+'rem' : '300px'};
    height: ${props => props.size ? props.size+'rem' : '150px' };
    border-radius: ${props => props.size ? (props.size * 2) + 'rem' : '300px'} ${props => props.size ? (props.size * 2) + 'rem' : '300px'} 0 0;
    overflow: hidden;
    &::after{
        background: linear-gradient(90.94deg, #DD4953 3.39%, #F9C36C 47.74%, #7DFF81 100.14%);
        transform-origin: center bottom;
        animation: ${props=>{return rotate(props.value, props.prevValue)}} 0.3s linear forwards;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: inherit;
        box-sizing: border-box;
    }
    &::before{
        border: 10px solid #dfe6e9; 
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: inherit;
        box-sizing: border-box;
    }
`;

const GaugeOverlay = styled.div`
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    box-sizing: border-box;

    
    top: 10px;                      /*  match border width  */
    left: 10px;                     /*  match border width  */
    width: calc(100% - 20px);       /*  match border width times 2  */
    height: calc(200% - 20px);      /*  match border width times 2  */
    border-radius: 100%;
    background: white;
    z-index: 1;  
`;


// #595b5b