import React, { Component } from 'react'
import {Tabs, Input, Button, Radio, Checkbox,Tooltip,Divider, Spin} from 'antd';
//import calenderDot from '../assets/images/Vector-2.png'
import tooltip from '../assets/images/informational icon.png'
import MacroData from '../components/macroData'
import IncomeStatement from '../components/incomeStatement'
import CashflowStatement from '../components/cashflowStatement'
import BalanceSheet from '../components/balanceSheet'
import StatementRatios from '../components/statementRatios'
import Gauge from '../components/gauge'
import Diverging from '../components/diverging'
import Gantt from '../components/gantt'
import RadarSimple from '../components/radarSimple'
import CapStructurePie from '../components/capStructurePie'
import AssetsPie from '../components/assetsPie'
import ProfitPie from '../components/profitPie'
import CashBar from '../components/cashBar'
import {yearlyReport, autoCorrectData, customisedData, changeActiveTab, changeActiveSubTab} from '../redux/actions';
import { mapScoreToRating, mapRatingToScore } from '../utils';
import { SmileOutlined, MehOutlined, FrownOutlined, PieChartOutlined, BarsOutlined, BarChartOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';
import Reading from "../components/Misc/reading";
import TitleCard from "../components/companyData/TitleCard";

const size = "small";

class DistributionMode extends Component {

    componentDidMount() {
        this.setState({ data: this.props })
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            custom: false,
            data: nextProps,
        });
    };

    yearlyReportOnChange = (e) => {
        this.props.updateYearlyReport(e.target.value);
        if (e.target.value !== 12 && this.props.customisedData)
            this.props.updateCustomisedData(false);
    };

    autoCorrectDataOnChange = (e) => {
        this.props.updateAutoCorrectData(e.target.value);
        if (!e.target.value && this.props.customisedData)
            this.props.updateCustomisedData(false);
    };

    customisedDataOnChange = (e) => {
        this.props.updateCustomisedData(e.target.checked);
    };

    render() {

        const {
            reportingPeriod,
            companyName,
            companyGIC,
        } = this.props.headerSelected;

        const {

            fitchRating,
            moodysRating,
            spRating,

            roboR8target,
            roboR8rating,
            roboR8score,
            roboR8date

        } = this.props.companyData;

        let confidence, plusMinusConfidence, plusConfidence, minusConfidence, ratingOutlook;

        if (roboR8rating !== 0) {

            ratingOutlook = Math.round(roboR8rating) - Math.round(roboR8target);

            confidence = this.props.companyData.roboR8[roboR8rating-1].prob;

            if (roboR8rating === 24) plusConfidence = 0;
            else plusConfidence = this.props.companyData.roboR8[roboR8rating].prob;

            if (roboR8rating === 1) minusConfidence = 0;
            else minusConfidence = this.props.companyData.roboR8[roboR8rating - 2].prob;

            plusMinusConfidence = confidence + plusConfidence + minusConfidence;
        } else {
            confidence = 0;
            plusMinusConfidence = 0;
        }

        var r8rating = roboR8rating;
        if (roboR8rating < 5) r8rating = 5;

        var ratingDiff = { fitch: '', moodys: '', sp: '' };
        if (mapRatingToScore(fitchRating.value) <= 0 || roboR8rating <= 0) ratingDiff.fitch = 'NA';
        else ratingDiff.fitch = parseFloat(r8rating) - parseFloat(mapRatingToScore(fitchRating.value));

        if (mapRatingToScore(moodysRating.value) <= 0 || roboR8rating <= 0) ratingDiff.moodys = 'NA';
        else ratingDiff.moodys = parseFloat(r8rating) - parseFloat(mapRatingToScore(moodysRating.value));

        if (mapRatingToScore(spRating.value) <= 0 || roboR8rating <=0) ratingDiff.sp = 'NA';
        else ratingDiff.sp = parseFloat(r8rating) - parseFloat(mapRatingToScore(spRating.value));

        const agencyData = [
            {
                roboR8Rating: mapScoreToRating(roboR8rating).fitch,
                agencyRating: fitchRating.value,
                ratingDifference: ratingDiff.fitch
            },
            {
                roboR8Rating: mapScoreToRating(roboR8rating).moody,
                agencyRating: moodysRating.value,
                ratingDifference: ratingDiff.moodys
            },
            {
                roboR8Rating: mapScoreToRating(roboR8rating).sp,
                agencyRating: spRating.value,
                ratingDifference: ratingDiff.sp
            },
            {
                roboR8Rating: '--',
                agencyRating: '--',
                ratingDifference: 'NA'
            },
            {
                roboR8Rating: '--',
                agencyRating: '--',
                ratingDifference: 'NA'
            }
        ];

        return (
            <div className="row no-gutters parent-row">
                <div className="col-lg-6" style={{ paddingRight: '2.5px' }}>
                    <MacroData />
                    <Tabs defaultActiveKey={this.props.subTabKey} size={size} className="tab" onChange={(key)=>this.props.updateActiveSubTab(key)}>
                        <Tabs.TabPane className="light" tab = {<span> Financial Report < BarsOutlined /> </span>} key="1">
                            <div className="setting-wrapper">
                                <div className="row no-gutters">
                                    <div className="column col-3" style={{ paddingRight: '2.5px' }}>
                                        <div className="duration">
                                            <Radio.Group className="d-flex" onChange={this.yearlyReportOnChange} value={this.props.yearlyReport} disabled={this.props.headerSelected.reportingPeriod === '' ? true : false}>
                                                <div className="icon ml-1 d-flex justify-content-center align-items-center">
                                                </div>
                                                <Radio value={12}>T12M</Radio>
                                                <Radio value={3}>3M</Radio>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    <div className="column col-9" style={{ paddingLeft: '2.5px' }} >
                                        <div className="data justify-content-center align-items-center">
                                            <Radio.Group
                                                disabled={this.props.headerSelected.reportingPeriod === '' ? true : false}
                                                onChange={this.autoCorrectDataOnChange}
                                                value={this.props.autoCorrectData}>
                                                <Radio value={false} >Original Data</Radio>
                                                <Radio value={true}>Auto Corrected Data</Radio>
                                            </Radio.Group>
                                            <Checkbox
                                                // disabled={true}
                                                disabled={this.props.autoCorrectData === false ? true :
                                                   this.props.yearlyReport === 12 ? false : true}
                                                onChange={this.customisedDataOnChange}
                                                checked={this.props.customisedData}
                                            >Customised</Checkbox>
                                            <Tooltip title="Customised option is only valid if Auto Corrected Data and T12M options are already selected">
                                                <img src={tooltip} alt="tooltip" className="tooltip-icon" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="data-entry-wrapper">
                                <div className="row no-gutters vertical-card-stack">
                                    <div className="col-6 column" style={{ paddingRight: '2.5px', marginTop: '5px' }}>
                                        <TitleCard />
                                        <IncomeStatement />
                                    </div>
                                    <div className="col-6 column" style={{ paddingLeft: '2.5px', marginTop: '5px' }}>
                                        <CashflowStatement />
                                    </div>
                                </div>
                                <div className="row no-gutters vertical-card-stack">
                                    <div className="col-12" style={{ marginTop: '5px' }}>
                                        <BalanceSheet />
                                    </div>
                                </div>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane className="medium" tab={<span> Summary < BarChartOutlined /> </span>} key="2">
                            <div className="setting-wrapper">
                                <div className="row no-gutters">
                                    <div className="column col-3" style={{ paddingRight: '2.5px' }}>
                                        <div className="duration">
                                            <Radio.Group className="d-flex" onChange={this.yearlyReportOnChange} value={this.props.yearlyReport} disabled={this.props.headerSelected.reportingPeriod === '' ? true : false}>
                                                <div className="icon ml-1 d-flex justify-content-center align-items-center">
                                                </div>
                                                <Radio value={12}>T12M</Radio>
                                                <Radio value={3}>3M</Radio>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    <div className="column col-9" style={{ paddingLeft: '2.5px' }} >
                                        <div className="data justify-content-center align-items-center">
                                            <Radio.Group
                                                disabled={this.props.headerSelected.reportingPeriod === '' ? true : false}
                                                onChange={this.autoCorrectDataOnChange}
                                                value={this.props.autoCorrectData}>
                                                <Radio value={false} >Original Data</Radio>
                                                <Radio value={true}>Auto Corrected Data</Radio>
                                            </Radio.Group>
                                            <Checkbox
                                                disabled={this.props.autoCorrectData === false ? true :
                                                    this.props.yearlyReport === 12 ? false : true}
                                                onChange={this.customisedDataOnChange}
                                                checked={this.props.customisedData}
                                            >Customised</Checkbox>
                                            <Tooltip title="Customised option is only valid if Auto Corrected Data and T12M options are already selected">
                                                <img src={tooltip} alt="tooltip" className="tooltip-icon" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="data-entry-wrapper">
                                <div className="data-title">
                                    <span className="main-title"><b>{companyName}</b>&nbsp;<span className="sub-title">{companyGIC}</span> </span>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-6 column" style={{ paddingRight: '2.5px', marginTop: '5px' }}>
                                        <div className="rating-score-skew-probabilities input-style-class">
                                            <div className="input-wrapper">
                                                <div className="title">
                                                    <span className="bold">&nbsp;&nbsp;PROFITABILITY</span>
                                                </div>
                                                <ProfitPie height={0.3} underTabKey="2" underSubTab="2"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 column" style={{ paddingLeft: '2.5px', marginTop: '5px' }}>
                                        <div className="rating-score-skew-probabilities input-style-class">
                                            <div className="input-wrapper">
                                                <div className="title">
                                                    <span className="bold">&nbsp;&nbsp;CASH GENERATION</span>
                                                </div>
                                                <CashBar height={0.3} underTabKey="2" underSubTab="2"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-6 column" style={{ paddingRight: '2.5px', marginTop: '5px' }}>
                                        <div className="rating-score-skew-probabilities input-style-class">
                                            <div className="input-wrapper">
                                                <div className="title">
                                                    <span className="bold">&nbsp;&nbsp;ASSSETS BREAKDOWN</span>
                                                </div>
                                                <AssetsPie height={0.3} underTabKey="2" underSubTab="2"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 column" style={{ paddingLeft: '2.5px', marginTop: '5px' }}>
                                        <div className="rating-score-skew-probabilities input-style-class">
                                            <div className="input-wrapper">
                                                <div className="title">
                                                    <span className="bold">&nbsp;&nbsp;CAPITAL STRUCTURE</span>
                                                </div>
                                                <CapStructurePie height={0.3} underTabKey="2" underSubTab="2"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </div>

                <div className="col-lg-6" style={{ paddingLeft: '2.5px' }}>
                    <div className="data-entry-wrapper vertical-card-stack">
                        <div className="row no-gutters">
                            <div className="col-6 same-height" style={{ paddingRight: '2.5px' }}>
                                <StatementRatios/>
                                <div className="rating-score-skew-probabilities input-style-class" style={{ marginTop: '5px' }}>
                                    <div className="input-wrapper">
                                        <div className="title">
                                            <Tooltip title="roboR8 output, presented as a credit bar. The credit bar is a compressed version
                                                of the full rating score distribution. The central blue segment indicates
                                                the probability of the assigned rating, defined as the mode of the rating score
                                                distribution. The green segment gives the probability of a rating which is of higher
                                                quality than the assigned rating, and the pink segment gives the probabiltiy of a lower
                                                quality rating than the assigned rating.">
                                                <img src={tooltip} alt="tooltip" className="tooltip-icon" />
                                            </Tooltip>
                                            <span className="bold">&nbsp;&nbsp;RATING SCORE SKEW PROBABILITIES</span>
                                        </div>
                                        <Diverging underTabKey="2"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6" style={{ paddingLeft: '2.5px' }}>
                                <Spin spinning={this.props.loadingCreditScore} tip="MIGO is processing...">
                                    <div className="credit-score-classification input-style-class same-height">
                                        <div className="input-wrapper">
                                            <div className="title">
                                                <Tooltip title="roboR8 output: Credit Score from 1-100, 1 being the lowest credit quality, 100 being the highest.
                                                    The Outlook can be either Positive, Neutral or Negative. Positive and Negative outlooks have 3
                                                     levels, indicated by 1-3 green faces or 1-3 red faces respectively. The roboR8 Rating maps the roboR8 output
                                                     to a more familiar credit-rating-agency alphanumeric. +/- 1 Notch Confidence presents the probability
                                                     of the company having a rating that is between the assigned rating minus 1 notch to the assigned rating plus 1 notch.">
                                                <img src={tooltip} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                                <span className="bold">&nbsp;&nbsp;CREDIT SCORE {roboR8date? `(${roboR8date})` : ''}</span>
                                            </div>
                                            <div className="score-wrapper">
                                                <Gauge size={10} value={Math.round(roboR8score)} />
                                                <div className="label-wrapper">
                                                    <Reading className="score d-block" value={Math.round(roboR8score)} />
                                                    <span className="light">/ 100</span>
                                                </div>
                                            </div>

                                            <Divider dashed />

                                            <div className="field">
                                                <span className="bold">Outlook
                                                {ratingOutlook > 2 ? <span className="bold"> Positive</span>
                                                        : ratingOutlook < -2 ? <span className="bold"> Negative</span> : <span className="bold"> Neutral</span>
                                                    }
                                                </span>
                                                <span className="points">
                                                    <div style={{ fontSize: "2rem" }}>
                                                        &nbsp;&nbsp;
                                                        {ratingOutlook > 2 ?
                                                            ratingOutlook > 4 ?
                                                                ratingOutlook > 6 ? <span> <SmileOutlined style={{ color: "#3BCD94" }} /> <SmileOutlined style={{ color: "#3BCD94" }} /> <SmileOutlined style={{ color: "#3BCD94" }} /> </span>
                                                                    : <span> <SmileOutlined style={{ color: "#3BCD94" }} /> <SmileOutlined style={{ color: "#3BCD94" }} /> </span>
                                                                : <span> <SmileOutlined style={{ color: "#3BCD94" }} /> </span>
                                                            : ratingOutlook < -2 ?
                                                                 ratingOutlook < -4 ?
                                                                    ratingOutlook < -6 ? <span> <FrownOutlined style={{ color: "#DD4953" }} /> <FrownOutlined style={{ color: "#DD4953" }} /> <FrownOutlined style={{ color: "#DD4953" }} /> </span>
                                                                        : <span> <FrownOutlined style={{ color: "#DD4953" }} /> <FrownOutlined style={{ color: "#DD4953" }} /> </span>
                                                                    : <span> <FrownOutlined style={{ color: "#DD4953" }} /> </span>
                                                                : <span> <MehOutlined style={{ color: "#F9C36C" }} /> </span>
                                                        }
                                                    </div>
                                                </span>
                                            </div>
                                            <Divider dashed />
                                            <div className="field">
                                                <span className="medium">roboR8 Rating</span>
                                                <span className="points">
                                                {mapScoreToRating(roboR8rating).migo}
                                            </span>
                                            </div>
                                            <Divider dashed />
                                            <div className="field">
                                                <span >Rating Probability</span>
                                                <span className="points">{Math.round(confidence)}%</span>
                                            </div>
                                            <Divider dashed />
                                            <div className="field">
                                                <span >+/- 1 Notch Confidence</span>
                                                <span className="points">{Math.round(plusMinusConfidence)}%</span >
                                            </div>

                                            <Divider />

                                            <div className="rating-table">
                                                <div className="table-row mb-3">
                                                    <div className="long-col">

                                                    </div>
                                                    <div className="short-col">
                                                        <span className="medium">roboR8 <br /> Rating</span>
                                                    </div>
                                                    <div className="short-col">
                                                        <span className="medium">Agency <br /> Rating</span>
                                                    </div>
                                                    <div className="short-col">
                                                        <span className="medium">Rating <br /> Difference</span>
                                                    </div>
                                                </div>
                                                {
                                                    agencyData.map((agency, index) => (
                                                        <div className="table-wrapper mb-3" key={index}>

                                                            <div className="table-row" key={index}>
                                                                <div className="long-col">
                                                                    <span className="label">Agency {index + 1}</span>
                                                                </div>
                                                                <div className="short-col">
                                                                    <Input style={{width: 60}} value={agency.roboR8Rating} />
                                                                </div>
                                                                <div className="short-col">
                                                                    <Input style={{width: 60}} value={agency.agencyRating}/>
                                                                </div>
                                                                <div className="short-col">
                                                                    <Input style={{width: 60}} value={agency.ratingDifference} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Spin>
                            </div>
                        </div>
                    </div>

                    <div className="data-entry-wrapper vertical-card-stack">
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="rating-score-distribution input-style-class" style={{
                                    display: 'flex',
                                }}>
                                    <div className="input-wrapper">
                                        <div className="title">
                                            <Tooltip title="roboR8 output, mapped to more familiar credit-rating-agency ratings.
                                                            24 ratings are used and shown along the x-axis, with each rating given
                                                            a probability of occurance">
                                                <img src={tooltip} alt="tooltip" className="tooltip-icon" />
                                            </Tooltip>
                                            <span className="bold">&nbsp;&nbsp;RATING SCORE DISTRIBUTION</span>
                                        </div>
                                        <Gantt height={0.3} underTabKey="2"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) =>{
    return{
        headerSelected:state.headerSelectedReducer.headerSelected,
        companyData: state.companyDataReducer.companyData,
        yearlyReport:state.yearlyReportReducer.yearlyReport,
        autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
        customisedData: state.customisedDataReducer.customisedData,
        loadingCreditScore: state.migoReducer.loadingCreditScore,
        subTabKey: state.activeTabReducer.subTabKey
    }
  };



const mapDispatchToProps = dispatch => {
    return {
        updateYearlyReport: (month) => {
            dispatch(
                yearlyReport(month)
            )
        },
        updateAutoCorrectData: (check) => {
            dispatch(
                autoCorrectData(check)
            )
        },
        updateCustomisedData: (check) => {
            dispatch(
                customisedData(check)
            )
        },
        updateActiveSubTab: (key) => {
          dispatch(
            changeActiveSubTab(key)
          )
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DistributionMode);




