import React, { Component } from 'react'
import {Input, Tooltip,Divider} from 'antd';
import errorRed from '../assets/images/icons8-error-red.png'
import errorOrange from '../assets/images/icons8-error-orange.png'
import checkedIcon from '../assets/images/icons8-checked.png'
import editIcon from '../assets/images/icons-edit-flat.png'
import {customisedData, companyData } from '../redux/actions';

import {
    shiftIncreaseOthers, shiftCashFromOp, shiftCapex, shiftInvestOthers, shiftCashFromInv,
    shiftDividend, shiftSaleOfEquity, shiftIssueDebt, shiftRepayDebt, shiftOtherFinance,
    shiftCashFromFin, bsShift
} from '../utils';

import { setStatementRatios } from '../utils';

import { connect } from 'react-redux';


const size = "small";

class cashflowStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editCompanyData: this.props.companyData
        }
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("cashflow statement receive new props!");
        this.setState({
            custom: false,
            data: nextProps,
            editCompanyData: nextProps.companyData
        });
    };

    componentDidMount() {
        this.setState({ data: this.props })
    };

    editCompanyDataOnChange = (data) => {
        this.props.updateCompanyData(data);
    }

    checkIssueIcon(status) {
        switch (status) {
            case 'warning':
                return errorOrange;
            case 'error':
                return errorRed;
            case 'fixed':
                return checkedIcon;
            case 'custom':
                return editIcon;
            default:
                return '';
        }
    };

    handleIncreaseOthersChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.increaseDecreaseInOtherItems.value);
        const shiftAll = shiftIncreaseOthers(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            increaseDecreaseInOtherItems: shiftAll.editIncreaseOthers,
            qOpCashFlow: shiftAll.editCashFromOp,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleOpCashFlowChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qOpCashFlow.value);
        const shiftAll = shiftCashFromOp(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            increaseDecreaseInOtherItems: shiftAll.editIncreaseOthers,
            qOpCashFlow: shiftAll.editCashFromOp,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleCapexChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qCapex.value);
        const shiftAll = shiftCapex(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            qCapex: shiftAll.editCapex,
            qCashFromInvest: shiftAll.editCashFromInv,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleInvestOthersChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.investmentsInOtherAssets.value);
        const shiftAll = shiftInvestOthers(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            investmentsInOtherAssets: shiftAll.editInvestOthers,
            qCashFromInvest: shiftAll.editCashFromInv,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleCashFromInvChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qCashFromInvest.value);
        const shiftAll = shiftCashFromInv(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            investmentsInOtherAssets: shiftAll.editInvestOthers,
            qCashFromInvest: shiftAll.editCashFromInv,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,        
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleDividendChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qDividend.value);
        const shiftAll = shiftDividend(shift, this.state.editCompanyData);
        
        let newData = {
            custom: true,
            qDividend: shiftAll.editDividend,
            qCashFromFinance: shiftAll.editCashFromFin,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleSaleOfEquityChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qSaleOfEquity.value);
        const shiftAll = shiftSaleOfEquity(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            qSaleOfEquity: shiftAll.editSaleOfEquity,
            qCashFromFinance: shiftAll.editCashFromFin,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleIssueDebtChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qIssueLTDebt.value);
        const shiftAll = shiftIssueDebt(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            qIssueLTDebt: shiftAll.editIssueDebt,
            qCashFromFinance: shiftAll.editCashFromFin,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleRepayDebtChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qRepayLTDebt.value);
        const shiftAll = shiftRepayDebt(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            qRepayLTDebt: shiftAll.editRepayDebt,
            qCashFromFinance: shiftAll.editCashFromFin,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleOtherFinanceChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.otherFinancingActivities.value);
        const shiftAll = shiftOtherFinance(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            otherFinancingActivities: shiftAll.editOtherFinance,
            qCashFromFinance: shiftAll.editCashFromFin,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleCashFromFinChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qCashFromFinance.value);
        const shiftAll = shiftCashFromFin(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            otherFinancingActivities: shiftAll.editOtherFinance,
            qCashFromFinance: shiftAll.editCashFromFin,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleCashIncreaseChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.netIncreaseInCashAndEquivalents.value);
        const shiftAll = shiftCashFromFin(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            otherFinancingActivities: shiftAll.editOtherFinance,
            qCashFromFinance: shiftAll.editCashFromFin,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
    
    render() {

       const {
            
            qDandA,
            qNetIncome,
            
            increaseDecreaseInOtherItems, //extra
            qOpCashFlow,
            qCapex,
            investmentsInOtherAssets, //extra      
            qCashFromInvest,
            qDividend,
            qSaleOfEquity,
            qIssueLTDebt,
            qRepayLTDebt,
            otherFinancingActivities, //extra
            qCashFromFinance,
            netIncreaseInCashAndEquivalents, //extra            

        } = this.state.editCompanyData;
            //= this.props.companyData;
        
        return (            
            <div className="cash-flow-statement input-style-class">
                <div className="input-wrapper">
                    <div className="title">
                        <span>CASH FLOW STATEMENT</span>
                    </div>
                    <div className={`${qNetIncome.status} field`}>
                        <span className="medium">Net Income (Loss)</span>
                        <div className="input-box-wrapper">
                            {
                                qNetIncome.status === "" ? null : (
                                    <Tooltip title={qNetIncome.log}>
                                        <img src={this.checkIssueIcon(qNetIncome.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qNetIncome.value} />
                        </div>
                    </div>
                    <div className={`${qDandA.status} field`}>
                        <span className="medium">Depreciation &amp; Amortisation</span>
                        <div className="input-box-wrapper">
                            {
                                qDandA.status === "" ? null : (
                                    <Tooltip title={qDandA.log}>
                                        <img src={this.checkIssueIcon(qDandA.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qDandA.value} />
                        </div>
                    </div>
                    <div className={`${increaseDecreaseInOtherItems.status} field`}>
                        <span className="light">Increase (Decrease) in Other Items</span>
                        <div className="input-box-wrapper">
                            {
                                increaseDecreaseInOtherItems.status === "" ? null : (
                                    <Tooltip title={increaseDecreaseInOtherItems.log}>
                                        <img src={this.checkIssueIcon(increaseDecreaseInOtherItems.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={increaseDecreaseInOtherItems.value}
                                onChange={this.props.customisedData ? this.handleIncreaseOthersChange : false} />
                        </div>
                    </div>

                    <Divider dashed />

                    <div className={`${qOpCashFlow.status} field`}>
                        <span className="medium">Net Cash From Operating Activities</span>
                        <div className="input-box-wrapper">
                            {
                                qOpCashFlow.status === "" ? null : (
                                    <Tooltip title={qOpCashFlow.log}>
                                        <img src={this.checkIssueIcon(qOpCashFlow.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qOpCashFlow.value}
                                onChange={this.props.customisedData ? this.handleOpCashFlowChange : false} />
                        </div>
                    </div>
                    <div className={`${qCapex.status} field`}>
                        <span className="light">Capital Expenditure</span>
                        <div className="input-box-wrapper">
                            {
                                qCapex.status === "" ? null : (
                                    <Tooltip title={qCapex.log}>
                                        <img src={this.checkIssueIcon(qCapex.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qCapex.value}
                                onChange={this.props.customisedData ? this.handleCapexChange : false} />
                        </div>
                    </div>
                    <div className={`${investmentsInOtherAssets.status} field`}>
                        <span className="light">Investments in Other Assets</span>
                        <div className="input-box-wrapper">
                            {
                                investmentsInOtherAssets.status === "" ? null : (
                                    <Tooltip title={investmentsInOtherAssets.log}>
                                        <img src={this.checkIssueIcon(investmentsInOtherAssets.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={investmentsInOtherAssets.value}
                                onChange={this.props.customisedData ? this.handleInvestOthersChange : false} />
                        </div>
                    </div>

                    <Divider dashed />

                    <div className={`${qCashFromInvest.status} field`}>
                        <span className="medium">Net Cash From Investing Activities</span>
                        <div className="input-box-wrapper">
                            {
                                qCashFromInvest.status === "" ? null : (
                                    <Tooltip title={qCashFromInvest.log}>
                                        <img src={this.checkIssueIcon(qCashFromInvest.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qCashFromInvest.value}
                                onChange={this.props.customisedData ? this.handleCashFromInvChange : false} />
                        </div>
                    </div>
                    <div className={`${qDividend.status} field`}>
                        <span className="light">Dividends or Distributions Paid</span>
                        <div className="input-box-wrapper">
                            {
                                qDividend.status === "" ? null : (
                                    <Tooltip title={qDividend.log}>
                                        <img src={this.checkIssueIcon(qDividend.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qDividend.value}
                                onChange={this.props.customisedData ? this.handleDividendChange : false} />
                        </div>
                    </div>
                    <div className={`${qSaleOfEquity.status} field`}>
                        <span className="light">Sale (Repurchase) of Equity</span>
                        <div className="input-box-wrapper">
                            {
                                qSaleOfEquity.status === "" ? null : (
                                    <Tooltip title={qSaleOfEquity.log}>
                                        <img src={this.checkIssueIcon(qSaleOfEquity.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qSaleOfEquity.value}
                                onChange={this.props.customisedData ? this.handleSaleOfEquityChange : false} />
                        </div>
                    </div>
                    <div className={`${qIssueLTDebt.status} field`}>
                        <span className="light">Proceeds from Issuance of Debt</span>
                        <div className="input-box-wrapper">
                            {
                                qIssueLTDebt.status === "" ? null : (
                                    <Tooltip title={qIssueLTDebt.log}>
                                        <img src={this.checkIssueIcon(qIssueLTDebt.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qIssueLTDebt.value}
                                onChange={this.props.customisedData ? this.handleIssueDebtChange : false} />
                        </div>
                    </div>
                    <div className={`${qRepayLTDebt.status} field`}>
                        <span className="light">Repayments of Debts</span>
                        <div className="input-box-wrapper">
                            {
                                qRepayLTDebt.status === "" ? null : (
                                    <Tooltip title={qRepayLTDebt.log}>
                                        <img src={this.checkIssueIcon(qRepayLTDebt.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qRepayLTDebt.value}
                                onChange={this.props.customisedData ? this.handleRepayDebtChange : false} />
                        </div>
                    </div>
                    <div className={`${otherFinancingActivities.status} field`}>
                        <span className="light">Other Financing Activities</span>
                        <div className="input-box-wrapper">
                            {
                                otherFinancingActivities.status === "" ? null : (
                                    <Tooltip title={otherFinancingActivities.log}>
                                        <img src={this.checkIssueIcon(otherFinancingActivities.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={otherFinancingActivities.value}
                                onChange={this.props.customisedData ? this.handleOtherFinanceChange : false} />
                        </div>
                    </div>

                    <Divider dashed />

                    <div className={`${qCashFromFinance.status} field`}>
                        <span className="medium">Net Cash From Financing Activities</span>
                        <div className="input-box-wrapper">
                            {
                                qCashFromFinance.status === "" ? null : (
                                    <Tooltip title={qCashFromFinance.log}>
                                        <img src={this.checkIssueIcon(qCashFromFinance.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qCashFromFinance.value}
                                onChange={this.props.customisedData ? this.handleCashFromFinChange : false} />
                        </div>
                    </div>
                </div>
                <div className={`${netIncreaseInCashAndEquivalents.status} result`}>
                    <span className="medium">Net Increase in Cash &amp; Equivalents</span>
                    <div className="input-box-wrapper">
                        {
                            netIncreaseInCashAndEquivalents.status === "" ? null : (
                                <Tooltip title={netIncreaseInCashAndEquivalents.log}>
                                    <img src={this.checkIssueIcon(netIncreaseInCashAndEquivalents.status)} alt="tooltip" className="tooltip-icon" />
                                </Tooltip>
                            )
                        }
                        <Input size={size} value={netIncreaseInCashAndEquivalents.value}
                            onChange={this.props.customisedData ? this.handleCashIncreaseChange : false} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) =>{
    return {
        companyData: state.companyDataReducer.companyData,
        customisedData: state.customisedDataReducer.customisedData,
    }
  }



const mapDispatchToProps = dispatch => {
    return {
        updateCustomisedData: (check) => {
            dispatch(
                customisedData(check)
            )
        },
        updateCompanyData: (data) => {
            dispatch(
                companyData(data)
            )
        }     
    }
}
  
export default connect(mapStateToProps,mapDispatchToProps)(cashflowStatement);




