import { BUBBLE_GRAPH } from '../constants';

let initialState = {
    bubbleGraph: false,
}

const bubbleGraphReducer = (state = initialState, action) => {

    switch (action.type) {
        case BUBBLE_GRAPH:
            console.log('Bubble Graph Reducer firing');
            return {
                ...state,
                bubbleGraph: action.bubbleGraph,
            }
        default:
            return state;
    }
}

export default bubbleGraphReducer;