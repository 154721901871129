import React, { Component } from 'react'
import { Tabs, Tooltip, Divider, Checkbox } from 'antd';

import ProfitMarginRatios from '../components/profitMarginRatios'
import LiquidityRatios from '../components/liquidityRatios'
import Leverage1Ratios from '../components/leverage1Ratios'
import Leverage2Ratios from '../components/leverage2Ratios'
import { SmileOutlined, MehOutlined, FrownOutlined } from '@ant-design/icons';
import tooltip from '../assets/images/informational icon.png'
import Diverging from '../components/diverging'
import DivergingMultiple from '../components/divergingMultiple'
import Gauge from '../components/gauge'
import Radar from '../components/radar'
import Gantt from '../components/gantt'
import ScatterPlot from '../components/scatterPlotChart'
import ScatterPlotSingle from '../components/scatterPlotChartSingle'
import Treemap from '../components/treeMap'
import { bubbleGraph, radarPeers, changeActiveSubTab } from '../redux/actions';
import { convertToDecimal, mapScoreToRating } from '../utils';
import { connect } from 'react-redux';
import Reading from "../components/Misc/reading";
import TitleCard from "../components/companyData/TitleCard";

class VsIndustryMode extends Component {
    
    bubbleGraphOnChange = (e) => {
        this.props.updateBubbleGraph(e.target.checked);
    };

    radarPeersOnChange = (e) => {
        this.props.updateRadarPeers(e.target.checked);
    };

    render() {
        if (this.props.companyData.roboR8peers === ''){
            return null; // do not render anything if companyData is empty
        }

        const {
            companyName,
            companyGIC,
        } = this.props.headerSelected;

        const {            
            roboR8target,
            roboR8rating,
            roboR8score,
            roboR8date,
            roboR8peersHistory
        } = this.props.companyData;

        let confidence, plusMinusConfidence, plusConfidence, minusConfidence, ratingOutlook;

        if (roboR8rating !== 0) {
            ratingOutlook = Math.round(roboR8rating) - Math.round(roboR8target);
            confidence = this.props.companyData.roboR8[roboR8rating - 1].prob;

            if (roboR8rating === 24) plusConfidence = 0;
            else plusConfidence = this.props.companyData.roboR8[roboR8rating].prob;

            if (roboR8rating === 1) minusConfidence = 0;
            else minusConfidence = this.props.companyData.roboR8[roboR8rating - 2].prob;

            plusMinusConfidence = confidence + plusConfidence + minusConfidence;
        } else {
            confidence = 0;
            plusMinusConfidence = 0;
        }
       
        let match = [];
        let matchPercent = [];
        let within = [];
        let withinPercent = [];
        let rated = [];
        let unrated = [];
        let avgScore = [];
        let agencyRating = [];

        //Get the scatter plot statistics
        for (let k = 1; k <= 4; k++) {
            rated[k - 1] = 0;
            unrated[k - 1] = 0;
            match[k - 1] = 0;
            matchPercent[k - 1] = 0;
            within[k - 1] = 0;
            withinPercent[k - 1] = 0;
            avgScore[k - 1] = 0;
            agencyRating[k - 1] = "";
            if (this.props.autoCorrectData === true) {
                if (roboR8peersHistory[k - 1].peers === "") {
                    rated[k - 1] = "NA";
                    unrated[k - 1] = "NA";
                    match[k - 1] = "NA";
                    matchPercent[k - 1] = "NA";
                    within[k - 1] = "NA";
                    withinPercent[k - 1] = "NA";
                    avgScore[k - 1] = "NA";
                    agencyRating[k - 1] = "NA";
                } else {
                    for (let i = 1; i <= roboR8peersHistory[k - 1].peers.length; i++) {
                        if (roboR8peersHistory[k - 1].peers[i - 1].grade === "unrated by agency") unrated[k - 1]++;
                        else if (roboR8peersHistory[k - 1].peers[i - 1].grade === "match agency rating") match[k - 1]++;
                        else if (roboR8peersHistory[k - 1].peers[i - 1].grade === "within +/-1 notch") within[k - 1]++;
                        avgScore[k - 1] += roboR8peersHistory[k - 1].peers[i - 1].score;
                    }
                    match[k - 1]++;                                    //Add 1 to include selected company
                    rated[k - 1] = roboR8peersHistory[k - 1].peers.length - unrated[k - 1];
                    within[k - 1] = within[k - 1] + match[k - 1];

                    matchPercent[k - 1] = convertToDecimal(match[k - 1] / rated[k - 1] * 100, 0);
                    withinPercent[k - 1] = convertToDecimal(within[k - 1] / rated[k - 1] * 100, 0);

                    avgScore[k - 1] = avgScore[k - 1] / roboR8peersHistory[k - 1].peers.length;
                    agencyRating[k - 1] = mapScoreToRating(Math.round(avgScore[k - 1] * 24 / 100)).migo;
                }
            }
        }

        return (
            <div className="vs-industry-mode">
                <div className="row no-gutters">
                    <div className="col-6" style={{ paddingRight: '2.5px' }}>                        
                        <TitleCard />
                        <div className="row no-gutters">                                
                            <div className="col-6 column" style={{ paddingRight: '2.5px', marginTop: '5px'  }}>
                                <ProfitMarginRatios/>
                            </div>
                                                       
                            <div className="col-6 column" style={{ paddingLeft: '2.5px', marginTop: '5px'  }}>
                                <LiquidityRatios/>
                            </div>
                        </div>

                        <div className="row no-gutters">
                            <div className="col-6 column" style={{ paddingRight: '2.5px', marginTop: '5px'  }}>
                                <Leverage1Ratios/>
                            </div>
                            <div className="col-6 column" style={{ paddingLeft: '2.5px', marginTop: '5px'  }}>
                                <Leverage2Ratios/>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-6" style={{ paddingRight: '2.5px', marginTop: '5px' }}>
                                <div className="rating-score-skew-probabilities input-style-class" style={{ paddingTop: '5px' }}>
                                    <div className="input-wrapper">

                                        <div className="title">
                                            <span className="bold">RATING SCORE SKEW PROBABILITIES</span>
                                        </div>
                                        <Diverging underTabKey="3"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6" style={{ paddingLeft: '2.5px', marginTop: '5px' }}>
                                <div className="credit-score-classification input-style-class">
                                    <div className="input-wrapper">
                                        <div className="title">
                                            <span className="bold">CREDIT SCORE {roboR8date? `(${roboR8date})` : ''}</span>
                                        </div>
                                        <div className="score-wrapper">
                                            <Gauge size={10} value={Math.round(roboR8score)} />
                                            <div className="label-wrapper">
                                                <Reading className="score d-block" value={Math.round(roboR8score)} />
                                                <span className="light">/ 100</span>
                                            </div>
                                        </div>

                                        <Divider dashed />

                                        <div className="field">
                                            <span className="bold">Outlook
                                                {ratingOutlook > 2 ? <span className="bold"> Positive</span>
                                                    : ratingOutlook < -2 ? <span className="bold"> Negative</span> : <span className="bold"> Neutral</span>
                                                }
                                            </span>
                                            <span className="points">
                                                <div style={{ fontSize: "2rem" }}>
                                                    &nbsp;&nbsp;
                                                        {ratingOutlook > 2 ? <SmileOutlined style={{ color: "#3BCD94" }} />
                                                        : ratingOutlook < -2 ? <FrownOutlined style={{ color: "#DD4953" }} /> : <MehOutlined style={{ color: "#F9C36C" }} />
                                                    }
                                                </div>
                                            </span>
                                        </div>

                                        <Divider dashed />
                                        <div className="field">
                                            <span className="medium">roboR8 Rating</span>
                                            <span className="points">{mapScoreToRating(roboR8rating).migo}</span>
                                        </div>
                                        <Divider dashed />
                                        <div className="field">
                                            <span >Rating Probability</span>
                                            <span className="points">{Math.round(confidence)}%</span>
                                        </div>

                                        <Divider dashed />
                                        <div className="field">
                                            <span >+/- 1 Notch Confidence</span>
                                            <span className="points">{Math.round(plusMinusConfidence)}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters vertical-card-stack">
                            <div className="col-12" style={{ paddingTop: '5px' }}>
                                <div className="rating-score-distribution input-style-class">
                                    <div className="input-wrapper">
                                        <div className="title">
                                            <span className="bold">RATING SCORE DISTRIBUTION</span>
                                        </div>
                                        <Gantt height={0.28} underTabKey="3"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
                    
                    <div className="col-6" style={{ paddingLeft: '2.5px'}}>
                        <div className="row no-gutters vertical-card-stack">
                            <div className="col-6" >
                                <div className="rating-score-skew-probabilities input-style-class">
                                    <div className="input-wrapper">
                                        <div className="title">
                                            <Tooltip title="Company's financial ratios versus average ratios of all corporates withing same industry 
                                                 (same rating if checkbox checked), each ratio normalised to 100%. The Blue area corresponds to 
                                                  the ratios spanned by the selected company, the Green are corresponds to the ratios spanned
                                                  by the industry.">
                                                <img src={tooltip} alt="tooltip" className="tooltip-icon" />
                                            </Tooltip>
                                            <span className="bold">&nbsp;&nbsp;RATIOS vs INDUSTRY</span>
                                            <span className="label" style={{ position: "absolute", right: "40px", color: "#3f7dd9", fontFamily: "font1 medium" }}>
                                                Same Rating
                                            </span>
                                            <Checkbox Checkbox
                                                className="checkbox-wrapper" style={{ position: "absolute", right: "15px" }}
                                                disabled={false}
                                                onChange={this.radarPeersOnChange}
                                                checked={this.props.radarPeers}
                                            >
                                            </Checkbox>
                                        </div>
                                        <Radar height={0.265} underTabKey="3"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6" style={{ paddingLeft: '5px' }}>
                                <div className="rating-score-skew-probabilities input-style-class">
                                    <div className="input-wrapper">
                                        <div className="title">
                                            <Tooltip title="Rating skew-bars for all corporates within the same industry and rating as selected company, ordered by credit score">
                                                <img src={tooltip} alt="tooltip" className="tooltip-icon" />
                                            </Tooltip>
                                            <span className="bold">&nbsp;&nbsp;PEER RATING SCORE SKEWS</span>
                                        </div>
                                        <DivergingMultiple height={0.265}  underTabKey="3"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row no-gutters vertical-card-stack">
                            <div className="col-12" >
                                <div className="rating-score-skew-probabilities input-style-class">
                                    <div className="input-wrapper">                                        
                                        <div className="title">
                                            <Tooltip title="Scatter-graph of ratings for all corporates within the same industry and region as selected
                                                company. The dots have one of 5 colors: Green, Orange and Red dots show companies that have been rated by a major 
                                                credit-rating-agency. If the rating matches the rating assigned by roboR8, it is a Green color, if it is 
                                                within 1 notch of the roboR8 rating, it is an Orange color and if it is more than 1 notch away from the 
                                                roboR8 rating, it is given a Red color. The Blue dot shows the current selected company and Grey dots show 
                                                companies that are not rated by a major credit-rating-agency but which are rated by roboR8. Upon ticking the
                                                Confidence checkbox, each dot with change size to be proportional to its roboR8 rating probability - thus
                                                larger dots have more certainty of the assigned rating versus those dots which are of smaller sizes">
                                                <img src={tooltip} alt="tooltip" className="tooltip-icon" />
                                            </Tooltip>
                                            <span className="bold">&nbsp;&nbsp;AGENCY +/- 1 NOTCH: {withinPercent[0]}% ({within[0]}/{rated[0]}), 
                                            MEAN: {Math.round(avgScore[0])} ({agencyRating[0]}), UNRATED:{unrated[0]}                                               
                                            </span>    
                                            <span className="label" style={{ position: "absolute", right: "40px" , color:"#3f7dd9", fontFamily:"font1 medium"}}>                                                
                                             &nbsp;&nbsp;Confidence
                                            </span>
                                            <Checkbox Checkbox
                                                className="checkbox-wrapper" style={{position: "absolute", right: "15px" }}
                                                disabled={false}
                                                onChange={this.bubbleGraphOnChange}
                                                checked={this.props.bubbleGraph}
                                            >
                                            </Checkbox>
                                        </div> 
                                        <ScatterPlotSingle height={0.265} underTabKey={"3"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row no-gutters vertical-card-stack">
                            <div className="col-12">
                                <div className="rating-score-skew-probabilities input-style-class">
                                    <div className="input-wrapper">
                                        <Tabs defaultActiveKey="1" className="graph-tab"
                                              size="small" type="card"
                                        >
                                            <Tabs.TabPane tab={<span style={{fontSize:"1rem"}}> Rating Frequency </span>} key="1">
                                                <Treemap height={0.265} underTabKey={"3"} />
                                            </Tabs.TabPane>
                                            <Tabs.TabPane className="medium"
                                                          tab={<span  style={{fontSize:"1rem"}}> Historical Scatter</span>}
                                                          key="2">
                                                <div className="row no-gutters scatter-plot-label">
                                                    <div className="col-3">
                                                        <span className="light" style={{ color: this.props.darkMode ? '#A3ABBD' : '#192A3E' }}>
                                                            Mean: {Math.round(avgScore[3])} ({agencyRating[3]}) </span>
                                                    </div>
                                                    <div className="col-3">
                                                        <span className="light" style={{ color: this.props.darkMode ? '#A3ABBD' : '#192A3E' }}>
                                                            Mean: {Math.round(avgScore[2])} ({agencyRating[2]})</span>
                                                    </div>
                                                    <div className="col-3">
                                                        <span className="light" style={{ color: this.props.darkMode ? '#A3ABBD' : '#192A3E' }}>
                                                            Mean: {Math.round(avgScore[1])} ({agencyRating[1]})</span>
                                                    </div>
                                                    <div className="col-3">
                                                        <span className="light" style={{ color: this.props.darkMode ? '#A3ABBD' : '#192A3E' }}>
                                                            Mean: {Math.round(avgScore[0])} ({agencyRating[0]})</span>
                                                    </div>
                                                </div>
                                                <ScatterPlot height={0.265} underTabKey={"3"} />
                                            </Tabs.TabPane>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        headerSelected: state.headerSelectedReducer.headerSelected,
        darkMode: state.darkModeReducer.darkMode,
        companyData: state.companyDataReducer.companyData,
        autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
        bubbleGraph: state.bubbleGraphReducer.bubbleGraph,
        radarPeers: state.radarPeersReducer.radarPeers
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateBubbleGraph: (check) => {
            dispatch(
                bubbleGraph(check)
            )
        },
        updateRadarPeers: (check) => {
            dispatch(
                radarPeers(check)
            )
        }
    }
}  

export default connect(mapStateToProps, mapDispatchToProps)(VsIndustryMode);
