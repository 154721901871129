import React, { Component } from 'react'
import {Input, Tooltip,Divider} from 'antd';
import errorRed from '../assets/images/icons8-error-red.png'
import errorOrange from '../assets/images/icons8-error-orange.png'
import checkedIcon from '../assets/images/icons8-checked.png'
import editIcon from '../assets/images/icons-edit-flat.png'
//import treeIcon from '../assets/images/icons-network.png'
import {customisedData, companyData } from '../redux/actions';

import {
    shiftCash, shiftAcctRec, shiftCurrentAssets,
    shiftNetFixed, shiftIntangible, shiftOtherAssets, shiftTotalAssets
} from '../utils';

import {
    shiftAcctPay, shiftTotalCurrentLiab,
    shiftNonCurrentLiab, shiftCapitalStock, shiftRetainedEarnings, shiftEquity, 
    shiftTotalLiabEquity
} from '../utils';

import {setStatementRatios} from '../utils';

import { connect } from 'react-redux';

const size = "small";

class balanceSheetSimple extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editCompanyData: this.props.companyData
        }
    }

    componentDidMount() {
        this.setState({ data: this.props })
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("balance sheet receive new props!");
        this.setState({
            custom: false,
            data: nextProps,
            editCompanyData: nextProps.companyData
        });
    };
  
    editCompanyDataOnChange = (data) => {
        this.props.updateCompanyData(data);
    }

    checkIssueIcon(status) {
        switch (status) {
            case 'warning':
                return errorOrange;
            case 'error':
                return errorRed;
            case 'fixed':
                return checkedIcon;
            case 'custom':
                return editIcon;
            default:
                return '';
        }
    };

    handleCashChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qCash.value);
        const shiftAll = shiftCash(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: shiftAll.editCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            qCash: shiftAll.editCash,
            qCurrentAssets: shiftAll.editCurrentAssets,
            qTotalAssets: shiftAll.editTotalAssets,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,
            totalLiabilitiesAndShareholdersEquity: shiftAll.editTotalLiabEquity,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleAcctRecChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qAcctReceivable.value);
        const shiftAll = shiftAcctRec(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: shiftAll.editCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            qAcctReceivable: shiftAll.editAcctRec,
            qCurrentAssets: shiftAll.editCurrentAssets,
            qTotalAssets: shiftAll.editTotalAssets,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,
            totalLiabilitiesAndShareholdersEquity: shiftAll.editTotalLiabEquity,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
        
    handleCurrentAssetsChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qCurrentAssets.value);
        const shiftAll = shiftCurrentAssets(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: shiftAll.editCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            qCash: shiftAll.editCash,
            qCurrentAssets: shiftAll.editCurrentAssets,
            qTotalAssets: shiftAll.editTotalAssets,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,
            totalLiabilitiesAndShareholdersEquity: shiftAll.editTotalLiabEquity,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleNetFixedAssetChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qNetFixedAsset.value);
        const shiftAll = shiftNetFixed(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            propertyPlantAndEquipment: shiftAll.editPPE,
            qNetFixedAsset: shiftAll.editNetFixedAsset,
            qTotalAssets: shiftAll.editTotalAssets,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,
            totalLiabilitiesAndShareholdersEquity: shiftAll.editTotalLiabEquity,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }  

    handleIntangibleChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qIntangibles.value);
        const shiftAll = shiftIntangible(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            qIntangibles: shiftAll.editIntangibles,
            totalOtherAssets: shiftAll.editOtherAssets,
            qTotalAssets: shiftAll.editTotalAssets,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,
            totalLiabilitiesAndShareholdersEquity: shiftAll.editTotalLiabEquity,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleOtherAssetsChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.totalOtherAssets.value);
        const shiftAll = shiftOtherAssets(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            totalOtherAssets: shiftAll.editOtherAssets,
            qTotalAssets: shiftAll.editTotalAssets,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,
            totalLiabilitiesAndShareholdersEquity: shiftAll.editTotalLiabEquity,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleTotalAssetsChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qTotalAssets.value);
        const shiftAll = shiftTotalAssets(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            totalOtherAssets: shiftAll.editOtherAssets,
            qTotalAssets: shiftAll.editTotalAssets,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,
            totalLiabilitiesAndShareholdersEquity: shiftAll.editTotalLiabEquity,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleAcctPayChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qAcctPayable.value);
        const shiftAll = shiftAcctPay(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: shiftAll.editCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            qAcctPayable: shiftAll.editAcctPay,
            qCurrentLiab: shiftAll.editCurrentLiab,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }

        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
    
    handleTotalCurrentLiabChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qCurrentLiab.value);
        const shiftAll = shiftTotalCurrentLiab(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: shiftAll.editCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            otherCurrentLiabilities: shiftAll.editOtherCurrentLiab,
            qCurrentLiab: shiftAll.editCurrentLiab,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
    
    handleNonCurrentLiabChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.nonCurrentLiab.value);
        const shiftAll = shiftNonCurrentLiab(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            totalOtherAssets: shiftAll.editOtherAssets,
            qTotalAssets: shiftAll.editTotalAssets,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,
            totalLiabilitiesAndShareholdersEquity: shiftAll.editTotalLiabEquity,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleCapitalStockChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.capitalStock.value);
        const shiftAll = shiftCapitalStock(shift, this.state.editCompanyData);
        
        let newData = {
            custom: true,
            capitalStock: shiftAll.editStock,
            qRetainedEarnings: shiftAll.editRetainedEarnings
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleRetainedEarningsChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qRetainedEarnings.value);
        const shiftAll = shiftRetainedEarnings(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: shiftAll.editEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            qRetainedEarnings: shiftAll.editRetainedEarnings,
            totalEquity: shiftAll.editEquity,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleTotalEquityChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.totalEquity.value);
        const shiftAll = shiftEquity(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: shiftAll.editEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            qRetainedEarnings: shiftAll.editRetainedEarnings,
            totalEquity: shiftAll.editEquity,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleTotalLiabEquityChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.totalLiabilitiesAndShareholdersEquity.value);
        const shiftAll = shiftTotalLiabEquity(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: shiftAll.editNonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            totalOtherAssets: shiftAll.editOtherAssets,
            qTotalAssets: shiftAll.editTotalAssets,
            nonCurrentLiab: shiftAll.editNonCurrentLiab,
            totalLiabilitiesAndShareholdersEquity: shiftAll.editTotalLiabEquity,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    render() {

        const {
            qCash,
            qAcctReceivable,
            qCurrentAssets,           
            qNetFixedAsset,
            qIntangibles,
            totalOtherAssets, //extra  
            qTotalAssets,

            qAcctPayable,            
            qCurrentLiab,
            nonCurrentLiab,
            capitalStock, //extra      
            qRetainedEarnings,
            totalEquity,
            totalLiabilitiesAndShareholdersEquity, //extra   

        } = this.state.editCompanyData;
        //= this.props.companyData;

        return (
            <div className="balance-sheet input-style-class" >
                <div className="input-wrapper">
                    <div className="title">
                        <span>BALANCE SHEET</span>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-6 column">

                            <div className="column-child">

                                <div className={`${qCash.status} field`}>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;Cash plus Short-Term Investments</span>
                                    <div className="input-box-wrapper">
                                        {
                                            qCash.status === "" ? null : (
                                                <Tooltip title={qCash.log}>
                                                    <img src={this.checkIssueIcon(qCash.status)} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                            )
                                        }
                                        <Input size={size} value={qCash.value}
                                            onChange={this.props.customisedData ? this.handleCashChange : false} />
                                    </div>
                                </div>
                                <div className={`${qAcctReceivable.status} field`}>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;Accounts Receivable</span>
                                    <div className="input-box-wrapper">
                                        {
                                            qAcctReceivable.status === "" ? null : (
                                                <Tooltip title={qAcctReceivable.log}>
                                                    <img src={this.checkIssueIcon(qAcctReceivable.status)} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                            )
                                        }
                                        <Input size={size} value={qAcctReceivable.value}
                                            onChange={this.props.customisedData ? this.handleAcctRecChange : false} />
                                    </div>
                                </div>
                                <div className={`${qCurrentAssets.status} field`}>
                                    <span className="medium">Total Current Assets</span>
                                    <div className="input-box-wrapper">
                                        {
                                            qCurrentAssets.status === "" ? null : (
                                                <Tooltip title={qCurrentAssets.log}>
                                                    <img src={this.checkIssueIcon(qCurrentAssets.status)} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                            )
                                        }
                                        <Input size={size} value={qCurrentAssets.value}
                                            onChange={this.props.customisedData ? this.handleCurrentAssetsChange : false} />
                                    </div>
                                </div>                               
                                <div className={`${qNetFixedAsset.status} field`}>
                                    <span className="medium">Net Fixed Assets</span>
                                    <div className="input-box-wrapper">
                                        {
                                            qNetFixedAsset.status === "" ? null : (
                                                <Tooltip title={qNetFixedAsset.log}>
                                                    <img src={this.checkIssueIcon(qNetFixedAsset.status)} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                            )
                                        }
                                        <Input size={size} value={qNetFixedAsset.value}
                                            onChange={this.props.customisedData ? this.handleNetFixedAssetChange : false} />
                                    </div>
                                </div>
                                <div className={`${qIntangibles.status} field`}>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;Intangible Assets</span>
                                    <div className="input-box-wrapper">
                                        {
                                            qIntangibles.status === "" ? null : (
                                                <Tooltip title={qIntangibles.log}>
                                                    <img src={this.checkIssueIcon(qIntangibles.status)} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                            )
                                        }
                                        <Input size={size} value={qIntangibles.value}
                                            onChange={this.props.customisedData ? this.handleIntangibleChange : false} />
                                    </div>
                                </div>

                                <div className={`${totalOtherAssets.status} field`}>
                                    <span className="medium">Total Other Assets</span>
                                    <div className="input-box-wrapper">
                                        {
                                            totalOtherAssets.status === "" ? null : (
                                                <Tooltip title={totalOtherAssets.log}>
                                                    <img src={this.checkIssueIcon(totalOtherAssets.status)} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                            )
                                        }
                                        <Input size={size} value={totalOtherAssets.value}
                                            onChange={this.props.customisedData ? this.handleOtherAssetsChange : false} />
                                    </div>
                                </div>

                            </div>


                            <div className={`${qTotalAssets.status} result`}>
                                <span>Total Assets</span>
                                <div className="input-box-wrapper">
                                    {
                                        qTotalAssets.status === "" ? null : (
                                            <Tooltip title={qTotalAssets.log}>
                                                <img src={this.checkIssueIcon(qTotalAssets.status)} alt="tooltip" className="tooltip-icon" />
                                            </Tooltip>
                                        )
                                    }
                                    <Input size={size} value={qTotalAssets.value}
                                        onChange={this.props.customisedData ? this.handleTotalAssetsChange : false} />
                                </div>
                            </div>
                        </div>

                        <div className="col-6 column">

                            <div className="column-child">

                                <div className={`${qAcctPayable.status} field`}>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;Accounts Payable</span>
                                    <div className="input-box-wrapper">
                                        {
                                            qAcctPayable.status === "" ? null : (
                                                <Tooltip title={qAcctPayable.log}>
                                                    <img src={this.checkIssueIcon(qAcctPayable.status)} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                            )
                                        }
                                        <Input size={size} value={qAcctPayable.value}
                                            onChange={this.props.customisedData ? this.handleAcctPayChange : false} />
                                    </div>
                                </div>
                                <div className={`${qCurrentLiab.status} field`}>
                                    <span className="medium">Total Current Liabilities</span>
                                    <div className="input-box-wrapper">
                                        {
                                            qCurrentLiab.status === "" ? null : (
                                                <Tooltip title={qCurrentLiab.log}>
                                                    <img src={this.checkIssueIcon(qCurrentLiab.status)} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                            )
                                        }
                                        <Input size={size} value={qCurrentLiab.value}
                                            onChange={this.props.customisedData ? this.handleTotalCurrentLiabChange : false} />
                                    </div>
                                </div>                                
                                <div className={`${nonCurrentLiab.status} field`}>
                                    <span className="medium">Total Non-Current Liabilities</span>
                                    <div className="input-box-wrapper">
                                        {
                                            nonCurrentLiab.status === "" ? null : (
                                                <Tooltip title={nonCurrentLiab.log}>
                                                    <img src={this.checkIssueIcon(nonCurrentLiab.status)} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                            )
                                        }
                                        <Input size={size} value={nonCurrentLiab.value}
                                            onChange={this.props.customisedData ? this.handleNonCurrentLiabChange : false} />
                                    </div>
                                </div>
                                <div className={`${capitalStock.status} field`}>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;Capital Stock</span>
                                    <div className="input-box-wrapper">
                                        {
                                            capitalStock.status === "" ? null : (
                                                <Tooltip title={capitalStock.log}>
                                                    <img src={this.checkIssueIcon(capitalStock.status)} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                            )
                                        }
                                        <Input size={size} value={capitalStock.value}
                                            onChange={this.props.customisedData ? this.handleCapitalStockChange : false} />
                                    </div>
                                </div>
                                <div className={`${qRetainedEarnings.status} field`}>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;Retained Earnings (Deficit)</span>
                                    <div className="input-box-wrapper">
                                        {
                                            qRetainedEarnings.status === "" ? null : (
                                                <Tooltip title={qRetainedEarnings.log}>
                                                    <img src={this.checkIssueIcon(qRetainedEarnings.status)} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                            )
                                        }
                                        <Input size={size} value={qRetainedEarnings.value}
                                            onChange={this.props.customisedData ? this.handleRetainedEarningsChange : false} />
                                    </div>
                                </div>

                                <div className={`${totalEquity.status} field`}>
                                    <span className="medium">Total Shareholders' Equity</span>
                                    <div className="input-box-wrapper">
                                        {
                                            totalEquity.status === "" ? null : (
                                                <Tooltip title={totalEquity.log}>
                                                    <img src={this.checkIssueIcon(totalEquity.status)} alt="tooltip" className="tooltip-icon" />
                                                </Tooltip>
                                            )
                                        }
                                        <Input size={size} value={totalEquity.value}
                                            onChange={this.props.customisedData ? this.handleTotalEquityChange : false} />
                                    </div>
                                </div>
                            </div>

                            <div className={`${totalLiabilitiesAndShareholdersEquity.status} result`}>
                                <span>Total Liabilities &amp; Shareholders EQ.</span>
                                <div className="input-box-wrapper">
                                    {
                                        totalLiabilitiesAndShareholdersEquity.status === "" ? null : (
                                            <Tooltip title={totalLiabilitiesAndShareholdersEquity.log}>
                                                <img src={this.checkIssueIcon(totalLiabilitiesAndShareholdersEquity.status)} alt="tooltip" className="tooltip-icon" />
                                            </Tooltip>
                                        )
                                    }
                                    <Input size={size} value={totalLiabilitiesAndShareholdersEquity.value}
                                        onChange={this.props.customisedData ? this.handleTotalLiabEquityChange : false} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        companyData: state.companyDataReducer.companyData,
        customisedData: state.customisedDataReducer.customisedData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateCustomisedData: (check) => {
            dispatch(
                customisedData(check)
            )
        },
        updateCompanyData: (data) => {
            dispatch(
                companyData(data)
            )
        }
    }
}
  
export default connect(mapStateToProps,mapDispatchToProps)(balanceSheetSimple);




