import * as types from "../constants";
import axiosInstance from "../../utils/axios";

export const companyList = (companyList) => {
    return {
        type: types.COMPANY_LIST,
        companyList: companyList
    }
};

export const currentCompanyChanged = (companyId) => {
    return {
        type: types.CURRENT_COMPANY,
        payload: companyId
    }
};

export const companySelected = (company) => {
    return (dispatch, getState) => {
        dispatch(currentCompanyChanged(company))
        let targetCompanyResults = getState().companyListReducer.companyResults[company];
        if (targetCompanyResults) {
            dispatch(companyResults(targetCompanyResults, company))
        }
        else{
            let url = '/api/comdata/company-results/'+company;
            axiosInstance.get(url)
                .then(response=> {
                    dispatch(companyResults(response.data, company))
                })
                .catch(err=>{
                    console.log(err.response);
                })
        }
    }
};

export const companyResults = (results, company) => {
    return {
        type: types.COMPANY_RESULTS,
        results: results,
        company: company
    };
};

export const companyRatingsLoaded = (data, company, quarter) => {
    return {
        type: types.COMPANY_RATINGS_LOADED,
        data: data,
        company: company,
        quarter: quarter
    };
};

export const companyPeerDataLoaded = (data, company, quarter) => {
    return {
        type: types.COMPANY_PEERDATA_LOADED,
        data: data,
        company: company,
        quarter: quarter
    };
};

export const fetchRatingsForCompanyQuarter = (company, quarter) => {
    return (dispatch, getState) => {
        const quarterData = getState().companyListReducer.companyResults[company][quarter];
        if(typeof quarterData.agencyRatings!=="undefined"&&typeof quarterData.roboR8Results!=="undefined")
            return;
        let url = `/api/comdata/ratings/${company}/${quarter}`;
        axiosInstance.get(url)
            .then(response=> {
                dispatch(companyRatingsLoaded(response.data, company, quarter))
            })
            .catch(err=>{
                console.log(err.response);
            });
    }
};

export const fetchPeerDataForCompanyQuarter = (company, quarter) => {
    return (dispatch, getState) => {
        const quarterData = getState().companyListReducer.companyResults[company][quarter];
        if(typeof quarterData.peerData!=="undefined")
            return;
        let url = `/api/peerdata/${company}/${quarter}`;
        axiosInstance.get(url)
            .then(response=> {
                dispatch(companyPeerDataLoaded(response.data, company, quarter))
            })
            .catch(err=>{
                console.log(err.response);
            });
    }
};

export const newCompanyResultEntries = (qListByCompany) => {
    return {
        type: types.COMPANY_RESULT_ENTRIES,
        data: qListByCompany
    };
};
