import {
    RUN_CREDIT_SCORE_START,
    RUN_CREDIT_SCORE_FINISH,
    RUN_CREDIT_SCORE_ERROR, HEADER_SELECTED
} from '../constants';


let initialState={
    loadingCreditScore: false,
    migoError: '',
    quantiles: null
};

const migoReducer = (state = initialState, action) => {

    switch (action.type) {
        case RUN_CREDIT_SCORE_START:
            console.log('Migo sent action firing');
            return {
                ...state,
                loadingCreditScore: true,
            };
        case RUN_CREDIT_SCORE_FINISH:
            console.log('Migo received action firing');
            return {
                ...state,
                loadingCreditScore: false,
                quantiles: action.data
            };
        case RUN_CREDIT_SCORE_ERROR:
            console.log('Migo error action firing');
            return {
                ...state,
                loadingCreditScore: false,
                migoError: action.message
            };
        case HEADER_SELECTED:
            console.log('Migo reset action firing');
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default migoReducer;