import React, { Component } from 'react'
import { Input, Tooltip, Divider } from 'antd';
import errorRed from '../assets/images/icons8-error-red.png'
import errorOrange from '../assets/images/icons8-error-orange.png'
import checkedIcon from '../assets/images/icons8-checked.png'
import editIcon from '../assets/images/icons-edit-flat.png'

import { customisedData, companyData } from '../redux/actions';
import {
    shiftSalesRevenue, shiftCostGoodsSold, shiftGrossProfit, shiftSGandA, shiftDandA,
    shiftEBIT, shiftGrossInterest, shiftEBT, shiftIncomeTax, shiftNetIncome,
    cfShift, bsShift
} from '../utils';

import {  setStatementRatios } from '../utils';

import { connect } from 'react-redux';

const size = "small";

class incomeStatement extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            editCompanyData: this.props.companyData
        }
    }
    
    /*
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.companyData !== prevState.editCompanyData) {
            return { editCompanyData: nextProps.companyData };
        }
        else return null;
    }
    */

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("income statement receive new props!");
        this.setState({
            custom: false,
            data: nextProps,
            editCompanyData: nextProps.companyData
        });
    };

    componentDidMount() {
        this.setState({ data: this.props })
    };
    
    editCompanyDataOnChange = (data) => {
        this.props.updateCompanyData(data);
    }
    
    checkIssueIcon(status) {
        switch (status) {
            case 'warning':
                return errorOrange;
            case 'error':
                return errorRed;
            case 'fixed':
                return checkedIcon;
            case 'custom':
                return editIcon;
            default:
                return '';
        }
    };

    handleSalesRevenueChange = (e) => { 
    
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qSales.value);
        const shiftAll = shiftSalesRevenue(shift, this.state.editCompanyData);

        const shiftCF = shiftAll.editNetIncome.value - this.state.editCompanyData.qNetIncome.value;
        const shiftAllCF = cfShift(shiftCF, this.state.editCompanyData);

 //       const shiftBS = shiftAllCF.editCashIncrease.value - this.state.editCompanyData.netIncreaseInCashAndEquivalents.value;
 //       const shiftAllBS = bsShift(shiftBS, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: shiftAll.editEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: this.state.editCompanyData.nonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            qSales: shiftAll.editSales,
            qGrossProfit: shiftAll.editGrossProfit,
            qEBIT: shiftAll.editEBIT,
            earningBeforeIncomeTaxes: shiftAll.editEBT,
            qNetIncome: shiftAll.editNetIncome,

            qOpCashFlow: shiftAllCF.editCashFromOp,
            netIncreaseInCashAndEquivalents: shiftAllCF.editCashIncrease,
/*
            qCash: shiftAllBS.editCash,
            qCurrentAssets: shiftAllBS.editCurrentAssets,
            qTotalAssets: shiftAllBS.editTotalAssets,
            qRetainedEarnings: shiftAllBS.editRetainedEarnings,
            totalEquity: shiftAllBS.editTotalEquity,
            totalLiabilitiesAndShareholdersEquity: shiftAllBS.editTotalLiabEquity,
*/
            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }     

    handleCostGoodsSoldChange = (e) => {
    
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.costGoodsSold.value);
        const shiftAll = shiftCostGoodsSold(shift, this.state.editCompanyData);

        const shiftCF = shiftAll.editNetIncome.value - this.state.editCompanyData.qNetIncome.value;
        const shiftAllCF = cfShift(shiftCF, this.state.editCompanyData);

//        const shiftBS = shiftAllCF.editCashIncrease.value - this.state.editCompanyData.netIncreaseInCashAndEquivalents.value;
//        const shiftAllBS = bsShift(shiftBS, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: shiftAll.editEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: this.state.editCompanyData.nonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            costGoodsSold: shiftAll.editCostGoodsSold,
            qGrossProfit: shiftAll.editGrossProfit,
            qEBIT: shiftAll.editEBIT,
            earningBeforeIncomeTaxes: shiftAll.editEBT,
            qNetIncome: shiftAll.editNetIncome,

            qOpCashFlow: shiftAllCF.editCashFromOp,
            netIncreaseInCashAndEquivalents: shiftAllCF.editCashIncrease,
/*
            qCash: shiftAllBS.editCash,
            qCurrentAssets: shiftAllBS.editCurrentAssets,
            qTotalAssets: shiftAllBS.editTotalAssets,
            qRetainedEarnings: shiftAllBS.editRetainedEarnings,
            totalEquity: shiftAllBS.editTotalEquity,
            totalLiabilitiesAndShareholdersEquity: shiftAllBS.editTotalLiabEquity,
*/
            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }            

    handleGrossProfitChange = (e) => {
    
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qGrossProfit.value);
        const shiftAll = shiftGrossProfit(shift, this.state.editCompanyData);

        const shiftCF = shiftAll.editNetIncome.value - this.state.editCompanyData.qNetIncome.value;
        const shiftAllCF = cfShift(shiftCF, this.state.editCompanyData);

//        const shiftBS = shiftAllCF.editCashIncrease.value - this.state.editCompanyData.netIncreaseInCashAndEquivalents.value;
//        const shiftAllBS = bsShift(shiftBS, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: shiftAll.editEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: this.state.editCompanyData.nonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            costGoodsSold: shiftAll.editCostGoodsSold,
            qGrossProfit: shiftAll.editGrossProfit,
            qEBIT: shiftAll.editEBIT,
            earningBeforeIncomeTaxes: shiftAll.editEBT,
            qNetIncome: shiftAll.editNetIncome,

            qOpCashFlow: shiftAllCF.editCashFromOp,
            netIncreaseInCashAndEquivalents: shiftAllCF.editCashIncrease,
/*
            qCash: shiftAllBS.editCash,
            qCurrentAssets: shiftAllBS.editCurrentAssets,
            qTotalAssets: shiftAllBS.editTotalAssets,
            qRetainedEarnings: shiftAllBS.editRetainedEarnings,
            totalEquity: shiftAllBS.editTotalEquity,
            totalLiabilitiesAndShareholdersEquity: shiftAllBS.editTotalLiabEquity,
*/
            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
    
    handleSGandAChange = (e) => {
    
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.sGandA.value);
        const shiftAll = shiftSGandA(shift, this.state.editCompanyData);

        const shiftCF = shiftAll.editNetIncome.value - this.state.editCompanyData.qNetIncome.value;
        const shiftAllCF = cfShift(shiftCF, this.state.editCompanyData);

//        const shiftBS = shiftAllCF.editCashIncrease.value - this.state.editCompanyData.netIncreaseInCashAndEquivalents.value;
//        const shiftAllBS = bsShift(shiftBS, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: shiftAll.editEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: this.state.editCompanyData.nonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            sGandA: shiftAll.editSGandA,
            qEBIT: shiftAll.editEBIT,
            earningBeforeIncomeTaxes: shiftAll.editEBT,
            qNetIncome: shiftAll.editNetIncome,

            qOpCashFlow: shiftAllCF.editCashFromOp,
            netIncreaseInCashAndEquivalents: shiftAllCF.editCashIncrease,
/*
            qCash: shiftAllBS.editCash,
            qCurrentAssets: shiftAllBS.editCurrentAssets,
            qTotalAssets: shiftAllBS.editTotalAssets,
            qRetainedEarnings: shiftAllBS.editRetainedEarnings,
            totalEquity: shiftAllBS.editTotalEquity,
            totalLiabilitiesAndShareholdersEquity: shiftAllBS.editTotalLiabEquity,
*/
            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
    
    handleDandAChange = (e) => {
    
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qDandA.value);
        const shiftAll = shiftDandA(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: shiftAll.editEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: this.state.editCompanyData.nonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            qDandA: shiftAll.editDandA,
            qEBIT: shiftAll.editEBIT,
            earningBeforeIncomeTaxes: shiftAll.editEBT,
            qNetIncome: shiftAll.editNetIncome,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
    
    handleEBITChange = (e) => {
    
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qEBIT.value);
        const shiftAll = shiftEBIT(shift, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: shiftAll.editEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: this.state.editCompanyData.nonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);
        
        let newData = {
            custom: true,
            qDandA: shiftAll.editDandA,
            qEBIT: shiftAll.editEBIT,
            earningBeforeIncomeTaxes: shiftAll.editEBT,
            qNetIncome: shiftAll.editNetIncome,

            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
    
    handleGrossInterestChange = (e) => {
    
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qGrossInterest.value);
        const shiftAll = shiftGrossInterest(shift, this.state.editCompanyData);

        const shiftCF = shiftAll.editNetIncome.value - this.state.editCompanyData.qNetIncome.value;
        const shiftAllCF = cfShift(shiftCF, this.state.editCompanyData);

//        const shiftBS = shiftAllCF.editCashIncrease.value - this.state.editCompanyData.netIncreaseInCashAndEquivalents.value;
//        const shiftAllBS = bsShift(shiftBS, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: shiftAll.editGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: this.state.editCompanyData.nonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);
        
        let newData = {
            custom: true,
            qGrossInterest: shiftAll.editGrossInterest,
            earningBeforeIncomeTaxes: shiftAll.editEBT,
            qNetIncome: shiftAll.editNetIncome,

            qOpCashFlow: shiftAllCF.editCashFromOp,
            netIncreaseInCashAndEquivalents: shiftAllCF.editCashIncrease,
/*
            qCash: shiftAllBS.editCash,
            qCurrentAssets: shiftAllBS.editCurrentAssets,
            qTotalAssets: shiftAllBS.editTotalAssets,
            qRetainedEarnings: shiftAllBS.editRetainedEarnings,
            totalEquity: shiftAllBS.editTotalEquity,
            totalLiabilitiesAndShareholdersEquity: shiftAllBS.editTotalLiabEquity,
*/
            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
    
    handleEarningBeforeIncomeTaxesChange = (e) => {
    
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.earningBeforeIncomeTaxes.value);
        const shiftAll = shiftEBT(shift, this.state.editCompanyData);

        const shiftCF = shiftAll.editNetIncome.value - this.state.editCompanyData.qNetIncome.value;
        const shiftAllCF = cfShift(shiftCF, this.state.editCompanyData);

//        const shiftBS = shiftAllCF.editCashIncrease.value - this.state.editCompanyData.netIncreaseInCashAndEquivalents.value;
//        const shiftAllBS = bsShift(shiftBS, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: shiftAll.editGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: this.state.editCompanyData.nonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            qGrossInterest: shiftAll.editGrossInterest,
            earningBeforeIncomeTaxes: shiftAll.editEBT,
            qNetIncome: shiftAll.editNetIncome,

            qOpCashFlow: shiftAllCF.editCashFromOp,
            netIncreaseInCashAndEquivalents: shiftAllCF.editCashIncrease,
/*
            qCash: shiftAllBS.editCash,
            qCurrentAssets: shiftAllBS.editCurrentAssets,
            qTotalAssets: shiftAllBS.editTotalAssets,
            qRetainedEarnings: shiftAllBS.editRetainedEarnings,
            totalEquity: shiftAllBS.editTotalEquity,
            totalLiabilitiesAndShareholdersEquity: shiftAllBS.editTotalLiabEquity,
*/
            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
    
    handleIncomeTaxExpenseChange = (e) => {
    
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.incomeTaxExpense.value);
        const shiftAll = shiftIncomeTax(shift, this.state.editCompanyData);

        const shiftCF = shiftAll.editNetIncome.value - this.state.editCompanyData.qNetIncome.value;
        const shiftAllCF = cfShift(shiftCF, this.state.editCompanyData);

//        const shiftBS = shiftAllCF.editCashIncrease.value - this.state.editCompanyData.netIncreaseInCashAndEquivalents.value;
//        const shiftAllBS = bsShift(shiftBS, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: this.state.editCompanyData.nonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            incomeTaxExpense: shiftAll.editIncomeTax,
            qNetIncome: shiftAll.editNetIncome,

            qOpCashFlow: shiftAllCF.editCashFromOp,
            netIncreaseInCashAndEquivalents: shiftAllCF.editCashIncrease,
/*
            qCash: shiftAllBS.editCash,
            qCurrentAssets: shiftAllBS.editCurrentAssets,
            qTotalAssets: shiftAllBS.editTotalAssets,
            qRetainedEarnings: shiftAllBS.editRetainedEarnings,
            totalEquity: shiftAllBS.editTotalEquity,
            totalLiabilitiesAndShareholdersEquity: shiftAllBS.editTotalLiabEquity,
*/
            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
    
    handleNetIncomeChange = (e) => {
    
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qNetIncome.value);
        const shiftAll = shiftNetIncome(shift, this.state.editCompanyData);

        const shiftCF = shiftAll.editNetIncome.value - this.state.editCompanyData.qNetIncome.value;
        const shiftAllCF = cfShift(shiftCF, this.state.editCompanyData);

//        const shiftBS = shiftAllCF.editCashIncrease.value - this.state.editCompanyData.netIncreaseInCashAndEquivalents.value;
//        const shiftAllBS = bsShift(shiftBS, this.state.editCompanyData);

        const ratioData = {
            ratioEBIT: this.state.editCompanyData.qEBIT,
            ratioGrossInterest: this.state.editCompanyData.qGrossInterest,
            ratioCurrentAssets: this.state.editCompanyData.qCurrentAssets,
            ratioCurrentLiab: this.state.editCompanyData.qCurrentLiab,
            ratioNonCurrentLiab: this.state.editCompanyData.nonCurrentLiab,
            ratioTotalEquity: this.state.editCompanyData.totalEquity
        }
        const ratioAll = setStatementRatios(ratioData, this.state.editCompanyData);

        let newData = {
            custom: true,
            incomeTaxExpense: shiftAll.editIncomeTax,
            qNetIncome: shiftAll.editNetIncome,

            qOpCashFlow: shiftAllCF.editCashFromOp,
            netIncreaseInCashAndEquivalents: shiftAllCF.editCashIncrease,
/*
            qCash: shiftAllBS.editCash,
            qCurrentAssets: shiftAllBS.editCurrentAssets,
            qTotalAssets: shiftAllBS.editTotalAssets,
            qRetainedEarnings: shiftAllBS.editRetainedEarnings,
            totalEquity: shiftAllBS.editTotalEquity,
            totalLiabilitiesAndShareholdersEquity: shiftAllBS.editTotalLiabEquity,
*/
            netWorkingCapital: ratioAll.netWorkingCap,
            totalLiabilitiesShortLongTerm: ratioAll.totLiab,
            debtToEquity: ratioAll.debtToEq,
            currentRatio: ratioAll.currentRat,
            interestCoverageRatio: ratioAll.interestCov
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
    
    render() {

        const {
            qSales,
            costGoodsSold,
            qGrossProfit,
            sGandA,
            qDandA,
            qEBIT,
            qGrossInterest,
            earningBeforeIncomeTaxes, //extra
            incomeTaxExpense, //extra
            qNetIncome,

        } = this.state.editCompanyData;
            //= this.props.companyData;
 
        return (
           <div className="income-statement input-style-class">
                <div className="input-wrapper">
                    <div className="title">
                        <span>INCOME STATEMENT</span>
                    </div>                    
                    <div className={`${qSales.status} field`}>
                        <span className="light"> Sales Revenue</span>
                        <div className="input-box-wrapper">
                            {
                                qSales.status === "" ? null : (
                                    <Tooltip title={qSales.log}>
                                        <img src={this.checkIssueIcon(qSales.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }   
                            <Input size={size} value={qSales.value}
                                onChange={this.props.customisedData ? this.handleSalesRevenueChange : false} />
                        </div>
                    </div>
                    <div className={`${costGoodsSold.status} field`}>
                        <span className="light">Cost of Goods Sold</span>
                        <div className="input-box-wrapper">
                            {
                                costGoodsSold.status === "" ? null : (
                                    <Tooltip title={costGoodsSold.log}>
                                        <img src={this.checkIssueIcon(costGoodsSold.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={costGoodsSold.value}
                                onChange={this.props.customisedData ? this.handleCostGoodsSoldChange : false} />
                        </div>
                    </div>
                    <Divider dashed />
                    
                    <div className={`${qGrossProfit.status} field`}>
                        <span className="medium">Gross Profit</span>
                        <div className="input-box-wrapper">
                            {
                                qGrossProfit.status === "" ? null : (
                                    <Tooltip title={qGrossProfit.log}>
                                        <img src={this.checkIssueIcon(qGrossProfit.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qGrossProfit.value}
                                onChange={this.props.customisedData ? this.handleGrossProfitChange : false} />
                        </div>
                    </div>
                    <div className={`${sGandA.status} field`}>

                        <span className="light">S,G &amp; A plus other Expenses</span>

                        <div className="input-box-wrapper">

                            {
                                sGandA.status === "" ? null : (
                                    <Tooltip title={sGandA.log}>
                                        <img src={this.checkIssueIcon(sGandA.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={sGandA.value}
                                onChange={this.props.customisedData ? this.handleSGandAChange : false} />
                        </div>
                    </div>
                    <div className={`${qDandA.status} field`}>
                        <span className="light">Depreciation &amp; Amortisation</span>
                        <div className="input-box-wrapper">
                            {
                                qDandA.status === "" ? null : (
                                    <Tooltip title={qDandA.log}>
                                        <img src={this.checkIssueIcon(qDandA.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qDandA.value}
                                onChange={this.props.customisedData ? this.handleDandAChange : false} />
                        </div>
                    </div>
                    <Divider dashed />
                    
                    <div className={`${qEBIT.status} field`}>
                        <span className="medium">Operating Profit</span>
                        <div className="input-box-wrapper">
                            {
                                qEBIT.status === "" ? null : (
                                    <Tooltip title={qEBIT.log}>
                                        <img src={this.checkIssueIcon(qEBIT.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qEBIT.value}
                                onChange={this.props.customisedData ? this.handleEBITChange : false} />
                        </div>
                    </div>
                    <div className={`${qGrossInterest.status} field`}>
                        <span className="light">Interest Expenses</span>
                        <div className="input-box-wrapper">
                            {
                                qGrossInterest.status === "" ? null : (
                                    <Tooltip title={qGrossInterest.log}>
                                        <img src={this.checkIssueIcon(qGrossInterest.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qGrossInterest.value}
                                onChange={this.props.customisedData ? this.handleGrossInterestChange : false} />
                        </div>
                    </div>
                    <Divider dashed />

                    <div className={`${earningBeforeIncomeTaxes.status} field`}>

                        <span className="medium">Earnings Before Tax</span>
                        <div className="input-box-wrapper">
                            {
                                earningBeforeIncomeTaxes.status === "" ? null : (
                                    <Tooltip title={earningBeforeIncomeTaxes.log}>
                                        <img src={this.checkIssueIcon(earningBeforeIncomeTaxes.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={earningBeforeIncomeTaxes.value}
                                onChange={this.props.customisedData ? this.handleEarningBeforeIncomeTaxesChange : false} />
                        </div>
                    </div>
                    <div className={`${incomeTaxExpense.status} field`}>
                        <span className="light">Income Tax Expenses</span>
                        <div className="input-box-wrapper">
                            {
                                incomeTaxExpense.status === "" ? null : (
                                    <Tooltip title={incomeTaxExpense.log}>
                                        <img src={this.checkIssueIcon(incomeTaxExpense.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={incomeTaxExpense.value}
                                onChange={this.props.customisedData ? this.handleIncomeTaxExpenseChange : false} />
                        </div>
                    </div>
                </div>
                <div className={`${qNetIncome.status} result`}>
                    <span className="medium">Net Income (Loss)</span>
                    <div className="input-box-wrapper">
                        {
                            qNetIncome.status === "" ? null : (
                                <Tooltip title={qNetIncome.log}>
                                    <img src={this.checkIssueIcon(qNetIncome.status)} alt="tooltip" className="tooltip-icon" />
                                </Tooltip>
                            )
                        }
                        <Input size={size} value={qNetIncome.value}
                            onChange={this.props.customisedData ? this.handleNetIncomeChange : false} />
                    </div>

                </div>
            </div>
        )        
    }
}

const mapStateToProps = (state) => {
    return {
        companyData: state.companyDataReducer.companyData,
        customisedData: state.customisedDataReducer.customisedData,
    }
}

const mapDispatchToProps = dispatch => {
    return {   
        updateCustomisedData: (check) => {
            dispatch(
                customisedData(check)
            )
        },     
        updateCompanyData: (data) => {
            dispatch(
                companyData(data)
            )
        }         
    }
}
  
export default connect(mapStateToProps,mapDispatchToProps)(incomeStatement);




