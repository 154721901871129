export function mapIDToRegion(RatingID) {
    var region = '';

    switch (RatingID) {
        case 'ANZ':
            region = 'AU/NZ';
            break;
        case 'AS1':
            region = 'CN/TW/HK';
            break;
        case 'AS2':
            region = 'JP/KR';
            break;
        case 'AS3':
            region = 'E.Asia ex';
            break;
        case 'CAF':
            region = 'C.Africa';
            break;
        case 'CAM':
            region = 'C.America';
            break;
        case 'CAS':
            region = 'C.Asia';
            break;
        case 'CBN':
            region = 'Caribbean';
            break;
        case 'EAF':
            region = 'E.Africa';
            break;
        case 'EU1':
            region = 'EU/UK/CH';
            break;
        case 'EU2':
            region = 'E.Europe ex EU';
            break;
        case 'EU3':
            region = 'Europe ex';
            break;
        case 'EUA':
            region = 'E.Europe/Asia';
            break;
        case 'GRL':
            region = 'Greenland';
            break;
        case 'ME1':
            region = 'Middle East';
            break;
        case 'ME2':
            region = 'IL';
            break;
        case 'NAF':
            region = 'N.Africa';
            break;
        case 'NAM':
            region = 'N.America';
            break;
        case 'OCA':
            region = 'Oceania';
            break;
        case 'SAF':
            region = 'S.Africa ex ZAR';
            break;
        case 'SAM':
            region = 'S.America';
            break;
        case 'SAS':
            region = 'S.Asia';
            break;
        case 'SE1':
            region = 'SE.Asia 1';
            break;
        case 'SE2':
            region = 'SE.Asia 2';
            break;
        case 'WAF':
            region = 'W.Africa';
            break;
        case 'ZAR':
            region = 'ZA';
            break;
        default:
            region = 'Unknown';
    }
    return region;
}

export function mapRegionToID(region) {
    var regionID;

    switch (region) {
        case 'AU/NZ':
            region = 'ANZ';
            break;
        case 'CN/TW/HK':
            region = 'AS1';
        case 'JP/KR':
            region = 'AS2';
        case 'E.Asia ex':
            region = 'AS3';
        case 'C.Africa':
            region = 'CAF';
        case 'C.America':
            region = 'CAM';
        case 'C.Asia':
            region = 'CAS';
        case 'Caribbean':
            region = 'CBN';
        case 'E.Africa':
            region = 'EAF';
        case 'EU/UK/CH':
            region = 'EU1';
        case 'E.Europe ex EU':
            region = 'EU2';
        case 'Europe ex':
            region = 'EU3';
        case 'E.Europe/Asia':
            region = 'EUA';
        case 'Greenland':
            region = 'GRL';
        case 'Middle East':
            region = 'ME1';
        case 'IL':
            region = 'ME2';
        case 'N.Africa':
            region = 'NAF';
        case 'N.America':
            region = 'NAM';
        case 'Oceania':
            region = 'OCA';
        case 'S.Africa ex ZAR':
            region = 'SAF';
        case 'S.America':
            region = 'S.America';
        case 'S.Asia':
            region = 'SAS';
        case 'SE.Asia 1':
            region = 'SE1';
        case 'SE.Asia 2':
            region = 'SE2';
        case 'W.Africa':
            region = 'WAF';
        case 'ZA':
            region = 'ZAR';
        default:
            region = 'Unknown';
    }

    return regionID;
}
