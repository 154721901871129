import React, { Component } from 'react'
import { Radio, Checkbox, Tooltip} from 'antd';
//import calenderDot from '../assets/images/Vector-2.png'
import tooltip from '../assets/images/informational icon.png'
import MacroData from '../components/macroData'
import IncomeStatementSimple from '../components/incomeStatementSimple'
import CashflowStatementSimple from '../components/cashflowStatementSimple'
import BalanceSheetSimple from '../components/balanceSheetSimple'
import RadarSimple from '../components/radarSimple'
import ColumnSimple from '../components/ganttSimple'
import { yearlyReport, autoCorrectData, customisedData } from '../redux/actions';
import { mapScoreToRating, mapRatingToScore } from '../utils';

import { connect } from 'react-redux';
import TitleCard from "../components/companyData/TitleCard";


class SimpleView extends Component {

    componentDidMount() {
        this.setState({ data: this.props })
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            custom: false,
            data: nextProps,
        });
    };

    yearlyReportOnChange = (e) => {
        this.props.updateYearlyReport(e.target.value);
        if (e.target.value !== 12 && this.props.customisedData)
            this.props.updateCustomisedData(false);
    };

    autoCorrectDataOnChange = (e) => {
        this.props.updateAutoCorrectData(e.target.value);
        if (!e.target.value && this.props.customisedData)
            this.props.updateCustomisedData(false);
    };

    customisedDataOnChange = (e) => {
        this.props.updateCustomisedData(e.target.checked);
    };

    render() {

        const {
            reportingPeriod,
            companyName,
            companyGIC
        } = this.props.headerSelected;

        const {

            fitchRating,
            moodysRating,
            spRating,

            roboR8target,
            roboR8rating,
            roboR8score,
            roboR8date

        } = this.props.companyData;

        let confidence, plusMinusConfidence, plusConfidence, minusConfidence, ratingOutlook;

        if (roboR8rating !== 0) {

            ratingOutlook = Math.round(roboR8rating) - Math.round(roboR8target);

            confidence = this.props.companyData.roboR8[roboR8rating - 1].prob;

            if (roboR8rating === 24) plusConfidence = 0;
            else plusConfidence = this.props.companyData.roboR8[roboR8rating].prob;

            if (roboR8rating === 1) minusConfidence = 0;
            else minusConfidence = this.props.companyData.roboR8[roboR8rating - 2].prob;

            plusMinusConfidence = confidence + plusConfidence + minusConfidence;
        } else {
            confidence = 0;
            plusMinusConfidence = 0;
        }

        var ratingDiff = { fitch: '', moodys: '', sp: '' };
        if (mapRatingToScore(fitchRating.value) === 0 || roboR8rating === 0) ratingDiff.fitch = 'NA';
        else ratingDiff.fitch = parseFloat(roboR8rating) - parseFloat(mapRatingToScore(fitchRating.value));

        if (mapRatingToScore(moodysRating.value) === 0 || roboR8rating === 0) ratingDiff.moodys = 'NA';
        else ratingDiff.moodys = parseFloat(roboR8rating) - parseFloat(mapRatingToScore(moodysRating.value));

        if (mapRatingToScore(spRating.value) === 0 || roboR8rating === 0) ratingDiff.sp = 'NA';
        else ratingDiff.sp = parseFloat(roboR8rating) - parseFloat(mapRatingToScore(spRating.value));

        const agencyData = [
            {
                roboR8Rating: mapScoreToRating(roboR8rating).fitch,
                agencyRating: fitchRating.value,
                ratingDifference: ratingDiff.fitch
            },
            {
                roboR8Rating: mapScoreToRating(roboR8rating).moody,
                agencyRating: moodysRating.value,
                ratingDifference: ratingDiff.moodys
            },
            {
                roboR8Rating: mapScoreToRating(roboR8rating).sp,
                agencyRating: spRating.value,
                ratingDifference: ratingDiff.sp
            },
            {
                roboR8Rating: '--',
                agencyRating: '--',
                ratingDifference: 'NA'
            },
            {
                roboR8Rating: '--',
                agencyRating: '--',
                ratingDifference: 'NA'
            }
        ];

        return (
            <div className="row no-gutters parent-row">
                <div className="col-lg-6" style={{ paddingRight: '2.5px' }}>
                    <MacroData />

                    <div className="title-wrapper">
                        <span className="medium">Financial Report (reduced)  {reportingPeriod.slice(0, 2)} {reportingPeriod.slice(2, 6)}</span>
                    </div>

                    <div className="setting-wrapper">
                        <div className="row no-gutters">
                            <div className="column col-3" style={{ paddingRight: '2.5px' }}>
                                <div className="duration">
                                    <Radio.Group className="d-flex" onChange={this.yearlyReportOnChange} value={this.props.yearlyReport} disabled={this.props.headerSelected.reportingPeriod === '' ? true : false}>
                                        <div className="icon ml-1 d-flex justify-content-center align-items-center">

                                        </div>
                                        <Radio value={12}>T12M</Radio>
                                        <Radio value={3}>3M</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className="column col-9" style={{ paddingLeft: '2.5px' }} >
                                <div className="data justify-content-center align-items-center">
                                    <Radio.Group
                                        disabled={this.props.headerSelected.reportingPeriod === '' ? true : false}
                                        onChange={this.autoCorrectDataOnChange}
                                        value={this.props.autoCorrectData}>
                                        <Radio value={false} >Original Data</Radio>
                                        <Radio value={true}>Auto Corrected Data</Radio>
                                    </Radio.Group>
                                    <Checkbox
                                        // disabled={true}
                                        disabled={this.props.autoCorrectData === false ? true :
                                           this.props.yearlyReport === 12 ? false : true}
                                        onChange={this.customisedDataOnChange}
                                        checked={this.props.customisedData}
                                    >Customised
                                    </Checkbox>
                                    <Tooltip title="Customised option is only valid if Auto Corrected Data and T12M options are already selected">
                                        <img src={tooltip} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="data-entry-wrapper">
                        <TitleCard />
                        <div className="row no-gutters">
                            <div className="col-6 column" style={{ paddingRight: '2.5px', marginTop: '5px' }}>
                                <IncomeStatementSimple />
                            </div>
                            <div className="col-6 column" style={{ paddingLeft: '2.5px', marginTop: '5px' }}>
                                <CashflowStatementSimple/>
                            </div>
                        </div>

                        <div className="row no-gutters">
                            <div className="col-12" style={{ marginTop: '5px' }}>
                                <BalanceSheetSimple />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6" style={{ paddingLeft: '2.5px' }}>

                    <div className="data-entry-wrapper">
                        <div className="row no-gutters vertical-card-stack" >
                            <div className="rating-score-skew-probabilities input-style-class">
                                <div className="input-wrapper">
                                    <div className="title">
                                        <Tooltip title="Radar for 8 ratios derived from the financial statement. Each of the company's
                                                            ratios are compared to the average across its industry and assigned a numeric from
                                                            1 to 5, 3 being equal (or close) to the industry average, with <3 being below and
                                                            >3 being above">
                                            <img src={tooltip} alt="tooltip" className="tooltip-icon" />
                                        </Tooltip>
                                        <span className="bold">&nbsp;&nbsp;RATIOS vs INDUSTRY</span>
                                    </div>
                                    <RadarSimple height={0.3} />
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters vertical-card-stack">
                            <div className="rating-score-distribution input-style-class" style={{
                                marginTop: '5px',
                                // height: '562px',
                                // background: 'white',
                                display: 'flex',
                                // alignItems: 'flex-end',
                            }}>
                                <div className="input-wrapper">
                                    <div className="title">
                                        <Tooltip title="Simplified credit distribution, where each of the 5 credit buckets are assigned a probability.
                                                            Critical Risk implies the company is vulnerable to nonpayment of its obligations whilst High
                                                            Quality implies the company has a strong likelihood that its financial commitments will be met.">
                                            <img src={tooltip} alt="tooltip" className="tooltip-icon" />
                                        </Tooltip>
                                        <span className="bold">&nbsp;&nbsp;CREDIT QUALITY DISTRIBUTION</span>
                                    </div>
                                    <ColumnSimple height={0.3} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        headerSelected: state.headerSelectedReducer.headerSelected,
        companyData: state.companyDataReducer.companyData,
        yearlyReport: state.yearlyReportReducer.yearlyReport,
        autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
        customisedData: state.customisedDataReducer.customisedData,
        loadingCreditScore: state.migoReducer.loadingCreditScore,
        companyList: state.companyListReducer.companyList
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateYearlyReport: (month) => {
            dispatch(
                yearlyReport(month)
            )
        },
        updateAutoCorrectData: (check) => {
            dispatch(
                autoCorrectData(check)
            )
        },
        updateCustomisedData: (check) => {
            dispatch(
                customisedData(check)
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleView);
