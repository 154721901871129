import React, { Component } from 'react'
import { Row, Col } from 'antd';
import { SmileOutlined, MehOutlined, FrownOutlined } from '@ant-design/icons';
import { customisedData, companyData } from '../redux/actions';

import { connect } from 'react-redux';
import {PercentageInsideCircle} from "./Misc/percentageInsideCircle";

class leverage2Ratios extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editCompanyData: this.props.companyData
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("statement ratios receive new props!");
        this.setState({
            custom: false,
            data: nextProps,
            editCompanyData: nextProps.companyData
        });
    };

    componentDidMount() {
        this.setState({ data: this.props })
    };
    
    render() {

        const {
            qEBIT,
            qDandA,
            qGrossInterest,

            qCash,
            qAcctReceivable,
            qTotalAssets,

            qShortTermNotes,
            qLongTermDebt,
            totalEquity,

        } = this.state.editCompanyData;

        let qTile1, qTile2, qTile3;
        let qTile1Str, qTile2Str, qTile3Str;
        let qTile1Bounds, qTile2Bounds, qTile3Bounds;
        let Rmax = 50;

        let EBITDA = parseFloat(qEBIT.value) + parseFloat(qDandA.value);
        let CASHEQUIV = parseFloat(qCash.value) + parseFloat(qAcctReceivable.value);
        let TOTALDEBT = parseFloat(qShortTermNotes.value) + parseFloat(qLongTermDebt.value);
        let TOTALLIAB = parseFloat(qTotalAssets.value) - parseFloat(totalEquity.value);
        let NETDEBT = parseFloat(TOTALDEBT) - parseFloat(CASHEQUIV);

        let debtToEquity = totalEquity.value == 0 ? Rmax : Math.max(0, TOTALLIAB / totalEquity.value)*100;
        let turnOfLeverage = EBITDA <= 0 ? Rmax : Math.max(0, NETDEBT / EBITDA);
        let interestCov = qGrossInterest.value <= 0 ? Rmax : Math.max(0, qEBIT.value / qGrossInterest.value);

        let headerSelection = this.props.headerSelected;
        let qTileBoundsRegion = this.props.tileBounds[headerSelection.companyRegion];

        if (qTileBoundsRegion) {
            let qTileBounds = qTileBoundsRegion[headerSelection.companyGIC];
            if (qTileBounds) {

                qTile1Str = qTileBounds["factor6"]; qTile1Bounds = qTile1Str.split(":");
                qTile2Str = qTileBounds["factor7"]; qTile2Bounds = qTile2Str.split(":");
                qTile3Str = qTileBounds["factor10"]; qTile3Bounds = qTile3Str.split(":");

                qTile1 = [parseFloat(qTile1Bounds[0]), parseFloat(qTile1Bounds[1]), parseFloat(qTile1Bounds[2]), parseFloat(qTile1Bounds[3]), parseFloat(qTile1Bounds[4])];
                qTile2 = [(1/parseFloat(qTile2Bounds[4])), (1/parseFloat(qTile2Bounds[3])), (1/parseFloat(qTile2Bounds[2])), (1/parseFloat(qTile2Bounds[1])), (1/parseFloat(qTile2Bounds[0]))];
                qTile3 = [parseFloat(qTile3Bounds[0]), parseFloat(qTile3Bounds[1]), parseFloat(qTile3Bounds[2]), parseFloat(qTile3Bounds[3]), parseFloat(qTile3Bounds[4])];
            }
        }
        if (!qTile1) {
            qTile1 = [0, 0, 0, 0, 0];
            qTile2 = [0, 0, 0, 0, 0];
            qTile3 = [0, 0, 0, 0, 0];
        }

        return (
            <div className="cash-flow-statement input-style-class ratios">
                <div className="input-wrapper">
                    <div className="title">
                        <span className="bold">LEVERAGE RATIOS</span>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '2.5px' }}>
                        <Row gutter={[, 8]}>
                            <Col span={8}>
                                <PercentageInsideCircle percentageNumber={debtToEquity} q1={qTile1[0]} q2={qTile1[1]} q3={qTile1[2]} q4={qTile1[3]} q5={qTile1[4]} direction = "2"/>
                            </Col>
                            <Col span={8}>
                                <PercentageInsideCircle percentageNumber={turnOfLeverage} q1={qTile2[0]} q2={qTile2[1]} q3={qTile2[2]} q4={qTile2[3]} q5={qTile2[4]} endingChar="x" decimals="1" direction = "2"/>
                            </Col>
                            <Col span={8}>
                                <PercentageInsideCircle percentageNumber={interestCov} q1={qTile3[0]} q2={qTile3[1]} q3={qTile3[2]} q4={qTile3[3]} q5={qTile3[4]} endingChar="x" decimals="1"/>
                            </Col>
                        </Row>
                        <Row gutter={[, 8]}>
                            <Col span={8} className="light">Debt/Equity</Col>
                            <Col span={8} className="light">Turn of Leverage</Col>
                            <Col span={8} className="light">Interest Cover</Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        headerSelected: state.headerSelectedReducer.headerSelected,
        companyData: state.companyDataReducer.companyData,
        tileBounds: state.migoModelReducer.tileBounds
    }
}

export default connect(mapStateToProps)(leverage2Ratios);
