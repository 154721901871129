import React from 'react';
import "./index.css";
import { Typography, Layout, Divider } from 'antd';

const {Content } = Layout;

const Faq = (props) => {
  return (
    <div className="whats-migo">
          Frequently Asked Questions
    <Typography className="data-title" style={{ textAlign: "justify", textJustify: "inter-word" }}>
              <Content className="faq-content">
                  <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}>What is roboR8&trade;?</font>
                  <Divider className="ant-divider" />
                  <font style={{ fontFamily: "font1 light", fontSize: "small", color: "#c6c6c6" }}>
                      roboR8&trade; is a corporate credit scoring system that uses proprietary algorithm called MIGO&trade; to produce credit scores for non-financial companies.
                      These credit scores are congruent to more traditional credit rating agencies. roboR8&trade;'s output is fully determined by MIGO&trade;, with no subjective
                      opinion of credit-worthiness. roboR8&trade; does not take views on any company. Rekiki PTE Ltd is a technology company and a member of the Singapore
                      Fintech Association. roboR8&trade; is a product of Rekiki PTE Ltd. Neither Rekiki PTE Ltd nor roboR8&trade; are rating agencies and neither is certified or
                      regulated by any regulating authority to provide credit ratings. We are a technology company and present an objective credit score directly from the output 
                      of the MIGO&trade; engine.
                  </font>
                  <br /><br />
                  <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}>What is roboR8&trade;'s mission?</font>
                  <Divider className="ant-divider" />
                  <font style={{ fontFamily: "font1 light", fontSize: "small", color: "#c6c6c6" }}>
                      roboR8&trade; seeks to give credit scoring access to everyone, including small business owners, start-up investors and lenders. With roboR8&trade;, the
                      potential of credit scoring data can be exploited, supply chains can be dynamically managed, SME credit risk can be self-regulated and asymmetric credit 
                      information can be reduced. Credit ratings have traditionally been available to the privileged few from large financial institutions; we aim to disrupt
                      the credit scoring landscape to allow anyone to generate scores using machine intelligence.
                  </font>
                  <br /><br />
                  <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}>What is MIGO&trade;?</font>
                  <Divider className="ant-divider" />
                  <font style={{ fontFamily: "font1 light", fontSize: "small", color: "#c6c6c6" }}>
                      MIGO&trade; belongs to a class of Artificial Intelligence called Machine Learning (ML). It uses a semi-supervised learning algorithm such that it
                      can map a sample (a company's financial statement plus some macro-economic data) to a target (the credit score of a company). At the heart of MIGO&trade; 
                      is a type of ML called classification trees, although it is only similar to existing classification trees by name. MIGO&trade;'s trees combine deep learning, 
                      dynamic programming, graph theory and evolutionary algorithms to produce a mathematically optimal tree. For more detail on MIGO&trade; see "What is MIGO?"
                  </font>
                  <br /><br />
                  <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}>What do you mean MIGO&trade; is optimal?</font>
                  <Divider className="ant-divider" />
                  <font style={{ fontFamily: "font1 light", fontSize: "small", color: "#c6c6c6" }}>
                      MIGO&trade;'s trees are optimal in the sense that, for a given number of final regions, they have the minimum miss-classification error out of all possible
                      partitions. This is guaranteed with a mathematical proof and ensures MIGO&trade; will outperform any other tree algorithm.
                  </font>
                  <br /><br />
                  <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}>What are the inputs to roboR8&trade;?</font>
                  <Divider className="ant-divider" />
                  <font style={{ fontFamily: "font1 light", fontSize: "small", color: "#c6c6c6" }}>
                      roboR8&trade; only takes inputs from a company's financial statement. Internally, the system also uses macro-economic releases such as unemployment,
                      inflation and house prices. From the macro-economic data and the company's financial statement, a set of over 100 ratios are produced which give a good
                      overview of a corporate's financial health. roboR8&trade; is not reliant upon a particular form of financial statement filing because it reconstructs the
                      necessary inputs from core entries - therefore roboR8 is fully applicable across regions with different filing standards.
                  </font>
                  <br /><br />
                  <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}>How much detail of the financial statement is required to score a company?</font>
                  <Divider className="ant-divider" />
                  <font style={{ fontFamily: "font1 light", fontSize: "small", color: "#c6c6c6" }}>
                      As expected, the more data from the financial statement that is given, the higher confidence of the roboR8&trade; output. Ideally, roboR8 would use at least
                      20 statement values. However, using only around 7 entries, roboR8&trade; is able to give a score with high confidence.
                  </font>
                  <br /><br />
                  <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}>Can roboR8 be used on SMEs as well as public companies?</font>
                  <Divider className="ant-divider" />
                  <font style={{ fontFamily: "font1 light", fontSize: "small", color: "#c6c6c6" }}>
                      The short answer is yes! <br />
                      roboR8&trade; is a multi-regime model and trains on actual defaults as well as credit scores. These examples of historical defaults
                      include those coming from the SME universe. Therefore, it is extremely powerful in assessing the default likelihood of a company, whether that be a large or small
                      corporate. It requires no tradeable instruments and only uses financial statements by design - the raw inputs into MIGO&trade; are ratios and are not biased by the
                      company's turnover or size. roboR8&trade; was always intended to be applicable to SMEs as well as larger companies.
                  </font>
                  <br /><br />
                  <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}>What are the outputs of roboR8&trade;?</font>
                  <Divider className="ant-divider" />
                  <font style={{ fontFamily: "font1 light", fontSize: "small", color: "#c6c6c6" }}>
                      roboR8&trade; presents a credit score which can be directly compared to existing credit rating agencies, with the same nomenclature and alphanumeric scoring system. 
                      This allows a very direct and simple way to assess the accuracy versus the larger agencies. However, roboR8&trade; actually produces a full probability distribution 
                      of the ratings spectrum rather than a single rating classification. The assigned score is then determined by the rating with the highest probability from this distribution.  
                      Because the full distribution is output, variance, as well as skewness from that distribution can be determined, indicating the risk of a company's credit score and 
                      the likelihood of transition. Further, the model, by design, has a sophisticated confidence measure for each rating label. Note that a rating distribution allows the 
                      comparison of same-rated corporates: companies with the same single-label rating do not possess the same risk and the distributional rating output can be used to make 
                      an objective credit comparison between companies.<br /> 
                      Extremely detailed information and sensitivity analysis is stored by the system, but not currently output due to clarity concerns. For example, the most likely 
                      rating transition, the exact sensitivity to all financial statement ratios, how "far" from a rating transition is each of the input ratios (i.e.by how much does a 
                      ratio need to move in order for a rating to change) and what is the explicit importance of each input, are just a few examples.
                  </font>
                  <br /><br />
                  <font style={{ fontFamily: "font1 medium", color: "#80a9e6" }}>In summary, compared to other rating methodologies, what are roboR8&trade;'s advantages? </font>
                  <Divider className="ant-divider" />
                  <font style={{ fontFamily: "font1 light", fontSize: "small", color: "#c6c6c6" }}>
                      <li><font style={{ fontFamily: "font1 medium", color: "#ffb8c9" }}>Rating Distribution:</font> Rather than a rating being given as a single number, the full rating probability distribution is produced.</li> 
                      <li><font style={{ fontFamily: "font1 medium", color: "#ffb8c9" }}>Unbiased:</font> No dependence upon analysts and therefore an objective score.</li>
                      <li><font style={{ fontFamily: "font1 medium", color: "#ffb8c9" }}>Optimal:</font> The individual classification trees produced from a factor-set are theoretically proven to be optimal.</li>
                      <li><font style={{ fontFamily: "font1 medium", color: "#ffb8c9" }}>Data Robustness:</font> MIGO requires smaller amounts of data than competing systems to produce accurate results.</li>
                      <li><font style={{ fontFamily: "font1 medium", color: "#ffb8c9" }}>Consistent:</font> A single collection of models is constructed such that methodological and model consistency is assured across:
                         <ul>
                              <li>Region</li>
                              <li>Industry</li>
                              <li>Company size</li>                        
                              <li>Public and private sectors </li>
                          </ul>
                      </li>
                      <li><font style={{ fontFamily: "font1 medium", color: "#ffb8c9" }}>Unrestricted Capacity:</font> roboR8 places no restriction upon the volume of issuers which can be rated or upon the frequency of rating updates.</li>
                      <li><font style={{ fontFamily: "font1 medium", color: "#ffb8c9" }}>Insight:</font> roboR8 is not a machine-learning "black-box". It allows the user to gain insight into the dependence of the score on each component of the
                          financial statement and macro-economic factors. This naturally encourages scenario analysis.</li>
                      <li><font style={{ fontFamily: "font1 medium", color: "#ffb8c9" }}>Time Indifferent:</font> The model makes no assumption about when the targets are observed and hence, the same model can be used to create a rating 1 day ahead, 
                          1 week ahead, 1 month ahead or any other arbitrary horizon window.</li>
                  </font>
        </Content>
    </Typography>
    </div>
  );
}

export default Faq;