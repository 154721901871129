import React from "react";
import {connect} from 'react-redux';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coordinate, Legend,
} from "bizcharts";
import {convertRemToPx, getFinancialRatios} from '../utils';

class Radar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            width: 0,
            height: 0,
            chartSize: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);        
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("radar receive new props!");
        this.setState({
            custom: false,
            data: nextProps
        });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let winHeight = window.innerHeight;

        this.setState({ width: window.innerWidth, height: window.innerHeight, chartSize: (winHeight * this.props.height) });
    }
    
    render() {

        const Rmax = 50;

        let data, companyScore;
        let peerName, peerPos; 
        let companyID = this.props.headerSelected.companyID;
        let peerData = this.props.companyData.roboR8peersData;

        let companyRawRatios = {};
        let peerRawRatios = {
            "currentRatio": 0, "cashRatio": 0, "grossMargin": 0, "netMargin": 0, "LTDRatio": 0, "debtToEqRatio": 0, "debtRatio": 0, "interestCov": 0, "leverage": 0
        };
        let companyRatios = {};
        let peerRatios = {};

        let maxCurrentRatio = 10; let maxCashRatio = 10; let maxGrossMargin = 1; let maxNetMargin = 1;
        let maxLTDRatio = 1; let maxDebtToEqRatio = 5; let maxDebtRatio = 2; let maxInterestCov = 50; let maxLeverage = 50;

        let peerCount = 0;
        let peerScore = 0;

        for (let i = 1; i <= this.props.companyData.roboR8peers.length; i++) {

            if (this.props.radarPeers === true) {
                if (this.props.companyData.roboR8peers[i - 1].rating !== this.props.companyData.roboR8rating) continue;
            }

            peerName = this.props.companyData.roboR8peers[i - 1].name;
            peerPos = peerData.header.indexOf(peerName);

            //Get financial ratios if data exists
            let ratios = getFinancialRatios(peerData, peerPos);

            //Skip peer if data corrupt
            if (isNaN(this.props.companyData.roboR8peers[i - 1].score) ||
                isNaN(ratios.currentRatio) || isNaN(ratios.cashRatio) || isNaN(ratios.grossMargin) ||
                isNaN(ratios.netMargin) || isNaN(ratios.LTDRatio) || isNaN(ratios.debtToEqRatio) ||
                isNaN(ratios.debtRatio) || isNaN(ratios.interestCov) || isNaN(ratios.leverage))
                continue

            if (peerName === this.props.headerSelected.companyID) {
                companyScore = this.props.companyData.roboR8peers[i - 1].score;

                //If customised is selected, company statement is trailing 12m, so used companyData to get ratios
                if (this.props.customisedData === true) {

                    let EBITDA = parseFloat(this.props.companyData.qEBIT.value) + parseFloat(this.props.companyData.qDandA.value);
                    let cashEquiv = parseFloat(this.props.companyData.qCash.value) + parseFloat(this.props.companyData.qAcctReceivable.value);
                    let totalDebt = parseFloat(this.props.companyData.totalLiabilitiesAndShareholdersEquity.value) - parseFloat(this.props.companyData.totalEquity.value);
                    let netDebt = parseFloat(totalDebt) - parseFloat(cashEquiv);

                    let currentRatio = this.props.companyData.qCurrentLiab.value === 0 ? Rmax : Math.max(0, this.props.companyData.qCurrentAssets.value / this.props.companyData.qCurrentLiab.value);
                    let cashRatio = this.props.companyData.qCurrentLiab.value === 0 ? Rmax : Math.max(0, cashEquiv / this.props.companyData.qCurrentLiab.value)
                    let grossMargin = this.props.companyData.qSales.value === 0 ? Rmax : Math.max(0, this.props.companyData.qGrossProfit.value / this.props.companyData.qSales.value);
                    let netMargin = this.props.companyData.qSales.value === 0 ? Rmax : Math.max(0, this.props.companyData.qNetIncome.value / this.props.companyData.qSales.value);
                    let debtToEquity = this.props.companyData.totalEquity.value === 0 ? Rmax : Math.max(0, totalDebt / this.props.companyData.totalEquity.value);
                    let debtRatio = this.props.companyData.qTotalAssets.value === 0 ? Rmax : Math.max(0, totalDebt / this.props.companyData.qTotalAssets.value);
                    let interestCov = this.props.companyData.qGrossInterest.value <= 0 ? Rmax : Math.max(0, this.props.companyData.qEBIT.value / this.props.companyData.qGrossInterest.value);
                    let leverage = EBITDA <= 0 ? Rmax : Math.max(0, (netDebt + parseFloat(this.props.companyData.qCurrentLiab.value)) / EBITDA);

                    companyRawRatios.currentRatio = Math.min(currentRatio, maxCurrentRatio);
                    companyRawRatios.cashRatio = Math.min(cashRatio, maxCashRatio);
                    companyRawRatios.grossMargin = Math.min(grossMargin, maxGrossMargin);
                    companyRawRatios.netMargin = Math.min(netMargin, maxNetMargin);
                    companyRawRatios.LTDRatio = Math.min(Math.max(0, this.props.companyData.qLongTermDebt.value / totalDebt), maxLTDRatio);
                    companyRawRatios.debtToEqRatio = Math.min(debtToEquity, maxDebtToEqRatio);
                    companyRawRatios.debtRatio = Math.min(debtRatio, maxDebtRatio);
                    companyRawRatios.interestCov = Math.min(interestCov, maxInterestCov);
                    companyRawRatios.leverage = Math.min(leverage, maxLeverage);                   

                } else companyRawRatios = ratios;
            }
            peerCount++;

            peerScore += this.props.companyData.roboR8peers[i - 1].score;
            for (var key of Object.keys(peerRawRatios)) {
                peerRawRatios[key] += ratios[key];
            }
        }
        //Get average ratios
        peerScore /= peerCount;
        for (var key2 of Object.keys(peerRawRatios)) {
            peerRawRatios[key2] /= peerCount;
        }

        maxCurrentRatio = Math.max(companyRawRatios.currentRatio, peerRawRatios.currentRatio);
        maxCashRatio = Math.max(companyRawRatios.cashRatio, peerRawRatios.cashRatio);
        maxGrossMargin = Math.max(companyRawRatios.grossMargin, peerRawRatios.grossMargin);
        maxNetMargin = Math.max(companyRawRatios.netMargin, peerRawRatios.netMargin);
        maxLTDRatio = Math.max(companyRawRatios.LTDRatio, peerRawRatios.LTDRatio);
        maxDebtToEqRatio = Math.max(companyRawRatios.debtToEqRatio, peerRawRatios.debtToEqRatio);
        maxDebtRatio = Math.max(companyRawRatios.debtRatio, peerRawRatios.debtRatio);
        maxInterestCov = Math.max(companyRawRatios.interestCov, peerRawRatios.interestCov);
        maxLeverage = Math.max(companyRawRatios.leverage, peerRawRatios.leverage);

        companyRatios.currentRatio = maxCurrentRatio == 0 ? 0 : companyRawRatios.currentRatio/(maxCurrentRatio);
        companyRatios.cashRatio = maxCashRatio == 0 ? 0 : companyRawRatios.cashRatio/(maxCashRatio);
        companyRatios.grossMargin = maxGrossMargin == 0 ? 0 : companyRawRatios.grossMargin/(maxGrossMargin);
        companyRatios.netMargin = maxNetMargin == 0 ? 0 : companyRawRatios.netMargin/(maxNetMargin);
        companyRatios.LTDRatio = maxLTDRatio == 0 ? 0 : companyRawRatios.LTDRatio/(maxLTDRatio);
        companyRatios.debtToEqRatio = maxDebtToEqRatio == 0 ? 0 : companyRawRatios.debtToEqRatio/(maxDebtToEqRatio);
        companyRatios.debtRatio = maxDebtRatio == 0 ? 0 : companyRawRatios.debtRatio/(maxDebtRatio);
        companyRatios.interestCov = maxInterestCov == 0 ? 0 : companyRawRatios.interestCov/(maxInterestCov);
        companyRatios.leverage = maxLeverage == 0 ? 0 : companyRawRatios.leverage/(maxLeverage);

        peerRatios.currentRatio = maxCurrentRatio == 0 ? 0 : peerRawRatios.currentRatio/(maxCurrentRatio);
        peerRatios.cashRatio = maxCashRatio == 0 ? 0 : peerRawRatios.cashRatio/(maxCashRatio);
        peerRatios.grossMargin = maxGrossMargin == 0 ? 0 : peerRawRatios.grossMargin/(maxGrossMargin);
        peerRatios.netMargin = maxNetMargin == 0 ? 0 : peerRawRatios.netMargin/(maxNetMargin);
        peerRatios.LTDRatio = maxLTDRatio == 0 ? 0 : peerRawRatios.LTDRatio/(maxLTDRatio);
        peerRatios.debtToEqRatio = maxDebtToEqRatio == 0 ? 0 : peerRawRatios.debtToEqRatio/(maxDebtToEqRatio);
        peerRatios.debtRatio = maxDebtRatio == 0 ? 0 : peerRawRatios.debtRatio/(maxDebtRatio);
        peerRatios.interestCov = maxInterestCov == 0 ? 0 : peerRawRatios.interestCov/(maxInterestCov);
        peerRatios.leverage = maxLeverage == 0 ? 0 : peerRawRatios.leverage/(maxLeverage);
        
        if (this.props.autoCorrectData === true) {
            data = [
                { item: 'rating', user: companyID, score: companyScore, raw: companyScore },
                { item: 'rating', user: 'Industry Mean', score: Math.round(peerScore), raw: Math.round(peerScore) },
                { item: 'current ratio', user: companyID, score: Math.round(100 * companyRatios.currentRatio), raw: Math.round(companyRawRatios.currentRatio*100)},
                { item: 'current ratio', user: 'Industry Mean', score: Math.round(100 * peerRatios.currentRatio), raw: Math.round(peerRawRatios.currentRatio * 100)},
                { item: 'cash ratio', user: companyID, score: Math.round(100 * companyRatios.cashRatio), raw: Math.round(companyRawRatios.cashRatio * 100)},
                { item: 'cash ratio', user: 'Industry Mean', score: Math.round(100 * peerRatios.cashRatio), raw: Math.round(peerRawRatios.cashRatio * 100)},
                { item: 'gross margin', user: companyID, score: Math.round(100 * companyRatios.grossMargin), raw: Math.round(companyRawRatios.grossMargin * 100)},
                { item: 'gross margin', user: 'Industry Mean', score: Math.round(100 * peerRatios.grossMargin), raw: Math.round(peerRawRatios.grossMargin * 100)},
                { item: 'net margin', user: companyID, score: Math.round(100 * companyRatios.netMargin), raw: Math.round(companyRawRatios.netMargin * 100)},
                { item: 'net margin', user: 'Industry Mean', score: Math.round(100 * peerRatios.netMargin), raw: Math.round(peerRawRatios.netMargin * 100)},
                { item: 'LTD to liabilities', user: companyID, score: Math.round(100 * companyRatios.LTDRatio), raw: Math.round(companyRawRatios.LTDRatio * 100)},
                { item: 'LTD to liabilities', user: 'Industry Mean', score: Math.round(100 * peerRatios.LTDRatio), raw: Math.round(peerRawRatios.LTDRatio * 100)},
                { item: 'debt to equity', user: companyID, score: Math.round(100 * companyRatios.debtToEqRatio), raw: Math.round(companyRawRatios.debtToEqRatio * 100)},
                { item: 'debt to equity', user: 'Industry Mean', score: Math.round(100 * peerRatios.debtToEqRatio), raw: Math.round(peerRawRatios.debtToEqRatio * 100)},
                { item: 'debt ratio', user: companyID, score: Math.round(100 * companyRatios.debtRatio), raw: Math.round(companyRawRatios.debtRatio * 100)},
                { item: 'debt ratio', user: 'Industry Mean', score: Math.round(100 * peerRatios.debtRatio), raw: Math.round(peerRawRatios.debtRatio * 100)},
                { item: 'leverage', user: companyID, score: Math.round(100 * companyRatios.leverage), raw: Math.round(companyRawRatios.leverage * 100)},
                { item: 'leverage', user: 'Industry Mean', score: Math.round(100 * peerRatios.leverage), raw: Math.round(peerRawRatios.leverage * 100)},
                { item: 'interest coverage', user: companyID, score: Math.round(100 * companyRatios.interestCov), raw: Math.round(companyRawRatios.interestCov * 100)},
                { item: 'interest coverage', user: 'Industry Mean', score: Math.round(100 * peerRatios.interestCov), raw: Math.round(peerRawRatios.interestCov * 100)},                
            ];
        } else {
            data = [
                { item: "ratio", user: "unknown", score: 0, raw: 0  }
            ];
        }

        const cols = {
            score: {
                tickInterval: 20,
                min: 1,
                max: 100,                
            },
        };        

        return (
            <div className="radar">
                <Chart
                    height={250}
                    padding="auto" data={data} autoFit renderer='svg'
                    scale={cols}
                    visible={this.props.activeTabKey === this.props.underTabKey}
                >
                    <Tooltip shared={true} showMarkers={true}/>
                    <Legend visible={false} />
                    <Coordinate type="polar" radius={1}/>
                    <Axis
                        name="item"
                        line={null}
                        tickline={null}
                        grid={{
                            line: {
                                style: {
                                    stroke: this.props.darkMode ? '#f2d9d9' : '#f2d9d9',
                                    lineWidth: 0.5,
                                    lineDash: null,
                                }
                            },
                        }}
                        label={{
                            style: {
                                fill: this.props.darkMode ? '#A3ABBD' : '#192A3E', // 文本的颜色
                              fontSize: convertRemToPx(this.props.rootFontSize), // 文本大小
                            }
                        }}
                    />
                    <Axis
                        name="score"
                        line={null}
                        tickLine={null}
                        grid={{
                            type: "circle",
                            line: {
                                style: {
                                    stroke: this.props.darkMode ? '#E5E5E5' : '#E5E5E5',
                                    lineWidth: 1,
                                    lineDash: [0, 0]
                                }
                            }
                        }}
                        label={null}
                        title={false}
                    />

                    <Geom
                        type="area"
                        position="item*score"
                        color="user"
                        toolTip={false}
                        style={{
                            fillOpacity: 0.75,
                            strokeOpacity: 0.75,
                        }}
                    />

                    <Geom
                        type="point"
                        position="item*score"
                        color="user"
                        shape="circle"
                        size={2}
                        style={{
                            stroke: '#fff',
                            lineWidth: 1,
                            fillOpacity: 1,
                        }}
                        tooltip={['item*user*raw', (item, user, raw) => {
                            return {
                                // Custom tooltip on the title display and so on.
                                name: user + ' (raw)',
                                title: item,
                                value: raw + '%'
                            };
                        }]}
                    />
                </Chart>
            </div>
        );
    }
}

const mapStateToProps = (state) =>{
  return{
      darkMode: state.darkModeReducer.darkMode,
      headerSelected: state.headerSelectedReducer.headerSelected,
      companyData: state.companyDataReducer.companyData,
      autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
      customisedData: state.customisedDataReducer.customisedData,
      radarPeers: state.radarPeersReducer.radarPeers,
      rootFontSize: state.globalStyleReducer.rootFontSize,
    activeTabKey: state.activeTabReducer.key
  }
}

export default connect(mapStateToProps,null)(Radar);