import React, {Component} from 'react'
import logo from '../../assets/images/roboR8-logo.png'
import tooltip from '../../assets/images/informational icon.png'
import {Avatar, Checkbox, Menu, notification, Radio, Select, Tooltip, Dropdown} from 'antd';
import {LogoutOutlined, FontSizeOutlined} from "@ant-design/icons"
import {connect} from 'react-redux';
import {
    companySelected, currentCompanyChanged,
    darkMode,
    fetchPeerDataForCompanyQuarter,
    fetchRatingsForCompanyQuarter,
    headerSelected,
    logout, rootFontSize
} from '../../redux/actions';
import HeaderDropdown from "../HeaderDropdown";
import './index.css';
import {
    getClusterOptions,
    getCompanyData,
    getCompanyOptions,
    getIndustryOptions,
    getQuartersFromYear,
    getYearsFromResults,
    getAllCompanyOptions,
    findDefaultCompanyId
} from "./utils";
import { fetchMacroForRegionDate } from "../../redux/actions/macroEconomy";
import { mapIDToRegion } from '../../utils/regionUtils';

const size="small";

class index extends Component {
    constructor(props){
        super(props);

        let regions = [];
        for (let [k, v] of Object.entries(props.companyList)){
            regions.push({
                value: k,
                label: mapIDToRegion(k)
            })
        }

        let initRegion = regions[0].value;
        this.state = {
            companyList: props.companyList,
            allCompanyOptions: getAllCompanyOptions(props.companyList),
            filterResultList:true,

            regionOptions: [
                ...regions,
                {
                    value: 'CUSTOM',
                    label: 'CUSTOM'
                }
            ],
            regionValue: initRegion,
            clusterValue:'',
            industryValue:'',
            companyIdValue:undefined,
            selectedCompanyResults:props.companyResults[''],
            selectedQuarterResult: {},
            companyNameValue:undefined,
            reportingPeriodQuaterValue:'',
            reportingPeriodYearValue:'',

            darkMode:false,
            darkModeLoading:false,

            searchingId: false,
            searchIdOptions: [],
            searchNameOptions: []
        };

        this.handleThemeChange = this.handleThemeChange.bind(this)
        this.handleFontChange = this.handleFontChange.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.currentCompany && prevProps.currentCompany !== this.props.currentCompany) {
            this.selectedCompanyContextUpdate(this.props.currentCompany);
        }
        if (prevProps.companyList !== this.props.companyList){
            let regions = [];
            for (let [k, v] of Object.entries(this.props.companyList)){
                regions.push({
                    value: k,
                    label: k
                })
            }

            this.setState({
                companyList: this.props.companyList,
                allCompanyOptions: getAllCompanyOptions(this.props.companyList),
                filterResultList:false,

                regionOptions: [
                    ...regions,
                    {
                        value: 'CUSTOM',
                        label: 'CUSTOM'
                    }
                ]
            })
        }

        let selectedCompanyResults = this.props.companyResults[this.state.companyIdValue];
        if(selectedCompanyResults && Object.keys(selectedCompanyResults).length!==0 && selectedCompanyResults !== prevState.selectedCompanyResults){
            console.log(`different selectedCompanyData spotted!PrevCompanyId:${prevState.companyIdValue}`);
            let quarterToSelect;
            // try get the quarter with latest robor8 ratings first
            for (let [k, v] of Object.entries(selectedCompanyResults))
                if (v['has_latest_robor8_rating'])
                    quarterToSelect = k;
            // otherwise just get the latest quarter with financial report
            if (!quarterToSelect)
            {
                // get the years
                let sortedYears = getYearsFromResults(selectedCompanyResults);
                // get the latest year
                let latestYear = sortedYears[sortedYears.length-1];
                // get all quaters of latest year and set to state
                let quarters = getQuartersFromYear(latestYear, selectedCompanyResults);
                // get latest quarter
                let latestQuarter = quarters[quarters.length-1];
                quarterToSelect = `${latestQuarter}${latestYear}`;
            }
            this.setState({
                reportingPeriodQuaterValue: quarterToSelect.slice(0,2),
                reportingPeriodYearValue: quarterToSelect.slice(2),
                selectedCompanyResults: selectedCompanyResults,
                selectedQuarterResult:selectedCompanyResults[quarterToSelect]
            });
        }
        else if (this.props.companyResults !== prevProps.companyResults &&
            selectedCompanyResults && Object.keys(selectedCompanyResults).length===0){
            this.setState({
                reportingPeriodQuaterValue: '',
                reportingPeriodYearValue: '',
                selectedQuarterResult: {}
            });
            this.noFinancialDataNotification();
        }

        if (prevState.selectedQuarterResult !== this.state.selectedQuarterResult) {
            this.handleUpdateSelected()
        }
    }

    noFinancialDataNotification() {
        notification['error']({
            message: 'Error',
            description: 'No financial data found for the selected company.',
            duration: 5,
        });
    };

    handleUpdateSelected(){
        const {companyIdValue,reportingPeriodQuaterValue,reportingPeriodYearValue} = this.state;
        if(companyIdValue && reportingPeriodQuaterValue !== '' && reportingPeriodYearValue !==''){
            let headerSelected ={
                companyRegion: this.state.regionValue,
                companyID: this.state.companyIdValue,
                companyName: this.state.companyNameValue,
                companyGIC: this.state.industryValue,
                companyCluster: this.state.clusterValue,
                reportingPeriod: this.state.reportingPeriodQuaterValue + this.state.reportingPeriodYearValue
            }
            this.props.updateHeaderSelected(headerSelected);
            this.props.fetchRatingsForCompanyQuarter(headerSelected.companyID,
                this.state.reportingPeriodQuaterValue + this.state.reportingPeriodYearValue);
            this.props.fetchPeerDataForCompanyQuarter(headerSelected.companyID,
                this.state.reportingPeriodQuaterValue + this.state.reportingPeriodYearValue);
            this.props.fetchMacroForRegionDate(this.state.regionValue, this.state.selectedQuarterResult['report_date'])
        }
        else{
            let headerSelected ={
                companyRegion: '',
                companyID: undefined,
                companyName: undefined,
                companyGIC: '',
                companyCluster: '',
                reportingPeriod:''
            }
            this.props.updateHeaderSelected(headerSelected);
        }
    }

    handleFontChange({ item, key, keyPath, domEvent }) {
        this.props.rootFontSize(key);
    }

    handleThemeChange(value){
        setTimeout( () => {
            this.props.enableDarkMode(value)
            this.setState({ darkMode: value }, () => {
                switch (value.target.value) {
                    case "spring":
                        document.body.classList.remove('summer-mode');
                        document.body.classList.remove('autumn-mode');
                        document.body.classList.remove('winter-mode');
                        this.setState({ darkModeLoading: false });
                        this.props.enableDarkMode(false);
                        break;
                    case "summer":
                        document.body.classList.remove('autumn-mode');
                        document.body.classList.remove('winter-mode');
                        document.body.classList.add('summer-mode');
                        this.setState({ darkModeLoading: false })
                        this.props.enableDarkMode(false);
                        break;
                    case "autumn":
                        document.body.classList.remove('summer-mode');
                        document.body.classList.remove('winter-mode');
                        document.body.classList.add('autumn-mode');
                        this.setState({ darkModeLoading: true })
                        this.props.enableDarkMode(true);
                        break;
                    case "winter":
                        document.body.classList.remove('summer-mode');
                        document.body.classList.remove('autumn-mode');
                        document.body.classList.add('winter-mode');
                        this.setState({ darkModeLoading: true })
                        this.props.enableDarkMode(true);
                        break;
                    default:
                        document.body.classList.remove('summer-mode');
                        document.body.classList.remove('autumn-mode');
                        document.body.classList.remove('winter-mode');
                        this.setState({ darkModeLoading: false })
                        this.props.enableDarkMode(false);
                        break;
                }
            })
         },200);
    }

    handleRegionChange(value,event){
        // disable CUSTOM option for now
        if (value === "CUSTOM"){
            this.setState({
                regionValue: this.state.regionValue
            });
            return;
        }

        if (this.state.companyIdValue){
            let newCompanyId = findDefaultCompanyId(
                this.state.companyList, value, null, null, this.state.filterResultList);
            this.handleCompanyIdChange(newCompanyId, null);
            return;
        }
        this.setState({
            regionValue: value,
            clusterValue: '',
            industryValue: ''
        })
    }

    handleClusterChange(value,event){
        if (value==="ALL"){
            this.setState({
                clusterValue: value,
                industryValue: '',
                companyIdValue: undefined,
                companyNameValue: undefined,
                reportingPeriodQuaterValue: '',
                reportingPeriodYearValue: '',
                selectedCompanyResults: null,
                selectedQuarterResult:{},
                searchIdOptions: [],
                searchNameOptions: []
            }
            , ()=>this.props.companySelected('')
            );
            return;
        }
        if (this.state.selectedCompanyResults){
            let newCompanyId = findDefaultCompanyId(
                this.state.companyList, this.state.regionValue, value, null, this.state.filterResultList);
            this.handleCompanyIdChange(newCompanyId, null);
            return;
        }
        this.setState({
            clusterValue: value,
            industryValue: ''
        })
    }

    handleIndustryChange(value,event){
        if (value==="ALL"){
            this.setState({
                    industryValue: value,
                    companyIdValue: undefined,
                    companyNameValue: undefined,
                    reportingPeriodQuaterValue: '',
                    reportingPeriodYearValue: '',
                    selectedCompanyResults: null,
                    selectedQuarterResult:{},
                    searchIdOptions: [],
                    searchNameOptions: []
                }
                , ()=>this.props.companySelected('')
            );
            return;
        }
        if (this.state.selectedCompanyResults){
            let newCompanyId = findDefaultCompanyId(
                this.state.companyList, this.state.regionValue, this.state.clusterValue, value, this.state.filterResultList);
            this.handleCompanyIdChange(newCompanyId, null);
            return;
        }
        this.setState({
            industryValue:value
        });
    }

    handleCompanyIdChange(value, event) {
        this.props.currentCompanyChanged(value);
    }

    selectedCompanyContextUpdate(company){
        let companyData = getCompanyData(this.state.companyList, company);
        this.setState({
                companyIdValue: company,
                companyNameValue: companyData['name'],
                industryValue: companyData['industry'],
                clusterValue: companyData['cluster'],
                regionValue: companyData['region'],
            }, ()=>this.props.companySelected(company)
        );
    }

    handleCompanyNameChange(value, event){
        let companyId = event.key;
        this.handleCompanyIdChange(companyId, event);
    }

    handleReportingPeriodQuaterChange(quater) {
        let year = this.state.reportingPeriodYearValue;
        this.setState({
            reportingPeriodQuaterValue: quater,
            selectedQuarterResult:this.state.selectedCompanyResults[`${quater}${year}`]
        })
    }

    handleReportingPeriodYearChange(year) {
        let quarters = getQuartersFromYear(year, this.state.selectedCompanyResults);
        let currentQuarterSelected = this.state.reportingPeriodQuaterValue;
        let quarterToSelect = quarters.includes(currentQuarterSelected)? currentQuarterSelected: quarters[0];

        this.setState({
            reportingPeriodYearValue: year,
            reportingPeriodQuaterValue: quarterToSelect,
            selectedQuarterResult: this.state.selectedCompanyResults[`${quarterToSelect}${year}`]
        })
    }

    handleResultDataOnly=(e)=>{
        this.setState({filterResultList:e.target.checked});
    };

    handleCompanyIdSearch(value, event) {
        if (this.state.regionValue&&this.state.clusterValue&&this.state.industryValue)
            return;
        let result = [];
        if (value.length===0){
            // do nothing
        }
        else if(value.length === 1){
            const allOptions = this.state.allCompanyOptions;
            result = allOptions.filter(data=>{
                return data[0].toLowerCase().indexOf(value.toLowerCase()) === 0;
            });
        }
        else {
            const allOptions = this.state.allCompanyOptions;
            result = allOptions.filter(data=>{
                return data[0].toLowerCase().indexOf(value.toLowerCase()) >= 0;
            });
        }
        result = this.furtherFilter(result);
        this.setState({searchIdOptions: result})
    }

    handleCompanyNameSearch(value, event) {
        if (this.state.regionValue&&this.state.clusterValue&&this.state.industryValue)
            return;
        let result = [];
        if (value.length===0){
            // do nothing
        }
        else if(value.length === 1){
            const allOptions = this.state.allCompanyOptions;
            result = allOptions.filter(data=>{
                return data[1].name.toLowerCase().indexOf(value.toLowerCase()) === 0;
            });
        }
        else{
            const allOptions = this.state.allCompanyOptions;
            result = allOptions.filter(data=>{
                return data[1].name.toLowerCase().indexOf(value.toLowerCase()) >= 0;
            });
        }
        result = this.furtherFilter(result);
        this.setState({searchNameOptions: result})
    }


    furtherFilter(result) {
        if (this.state.clusterValue === 'ALL')
            result = result.filter(data => {
                    return data[1]['region'] === this.state.regionValue;
                }
            );
        if (this.state.industryValue === 'ALL')
            result = result.filter(data => {
                    return data[1]['region'] === this.state.regionValue && data[1]['cluster'] === this.state.clusterValue;
                }
            );
        if (this.state.filterResultList)
            result = result.filter(data => {
                    return data[1]['hasRekikiResult'];
                }
            );
        result = result.slice(0, 100);
        return result;
    }

    render() {
        const menuHeaderDropdown = (
            <Menu selectedKeys={[]}>
                <Menu.Item key="logout" onClick={()=>this.props.onLogout()}>
                    <LogoutOutlined />
                    Logout
                </Menu.Item>
            </Menu>
        );

        const menu = (
            <Menu onClick={this.handleFontChange}>
                <Menu.Item key={1.4}>
                    <span style={{fontSize: '1.4vh'}}>
                        Large
                    </span>
                </Menu.Item>
                <Menu.Item key={1.2}>
                    <span style={{fontSize: '1.2vh'}}>
                        Normal
                    </span>
                </Menu.Item>
                <Menu.Item key={1.05}>
                    <span style={{fontSize: '1.05vh'}}>
                        Small
                    </span>
                </Menu.Item>
            </Menu>
        );

        return (
        <div className="header-wrapper">
            <div className="top-bar-wrapper">
                <div className="container-fluid">
                    <div className="top-bar">
                        <div className="left">
                            <div className="logo">
                                <img src={logo} alt="logo"/>
                            </div>
                            <div className="migo">
                                    <em>powered by &nbsp; </em> <font size="2"> MIGO&trade; </font>
                                    </div>
                        </div>
                        <div className="right">

                            <HeaderDropdown overlay={menuHeaderDropdown}>
                                <div>
                                    <span className="username mr-3">Hi, {this.props.username}</span>
                                    <Avatar size="medium">{this.props.username.charAt(0)}</Avatar>
                                </div>
                            </HeaderDropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div className="top-search-bar-wrapper">
                <div className="container-fluid">
                    <div className="top-search-bar">
                            <div className="row  no-gutters">
                                    <div className="region field">
                                        <span className="label">
                                            Region
                                        </span>
                                        <Select disabled={this.props.activeTabKey==='4'} size={size} value={this.state.regionValue}
                                                className="select" onChange={(val,event)=>{this.handleRegionChange(val, event)}}>
                                        {
                                            this.state.regionOptions.map(data => {
                                                return <Select.Option key={data.value} value={data.value}>{data.label}</Select.Option>
                                            })
                                        }
                                        </Select>
                                    </div>
                                    <div className="clusters field">
                                        <span className="label">
                                            Clusters
                                        </span>
                                        <Select disabled={this.props.activeTabKey==='4'} size={size} value={this.state.clusterValue} className="select" onChange={(val,event)=>{this.handleClusterChange(val,event)}}>
                                        {
                                            this.state.regionValue?
                                            getClusterOptions(this.state.companyList, this.state.regionValue).map(data => {
                                                return <Select.Option key={data} value={data}>{data}</Select.Option>
                                            }):[]
                                        }
                                        </Select>
                                    </div>
                                    <div className="gics-industry field">
                                        <span className="label">
                                            Industry
                                        </span>
                                        <Select
                                            disabled={this.props.activeTabKey==='4'}
                                        dropdownMatchSelectWidth={false}
                                        size={size} value={this.state.industryValue} className="select" onChange={(val,event)=>{this.handleIndustryChange(val,event)}}>
                                        {
                                            this.state.clusterValue?
                                            getIndustryOptions(this.state.companyList,
                                                this.state.regionValue, this.state.clusterValue).map((data,index) => {
                                            return <Select.Option key={index} value={data}>{data}</Select.Option>
                                            }):[]
                                        }
                                        </Select>
                                    </div>
                                    <div className="company-id field">
                                        <div className="d-flex align-items-center">
                                            <span className="label">
                                                Company ID
                                            </span>
                                            <div className="checkbox-wrapper">
                                                <Checkbox onChange={this.handleResultDataOnly} checked={this.state.filterResultList}/>
                                                <Tooltip title="Check the checkbox to show only those companies which have a roboR8 rating">
                                                    <img src={tooltip} alt="tooltip" className="tooltip-icon"/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <Select
                                            disabled={this.props.activeTabKey==='4'}
                                        dropdownMatchSelectWidth={false}
                                        showSearch
                                        // placeholder={!this.state.industryValue? "Type to search":"Select an ID"}
                                        placeholder={!this.state.industryValue? "Type to search":"Select an ID"}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                          }
                                        size={size}
                                        value={this.state.companyIdValue}
                                        className="select"
                                        onSearch={(val,event)=>{this.handleCompanyIdSearch(val,event)}}
                                        onChange={(val,event)=>{this.handleCompanyIdChange(val,event)}}>
                                        {
                                            this.state.regionValue&&this.state.clusterValue&&this.state.industryValue?
                                                getCompanyOptions(
                                                    this.state.companyList,
                                                    this.state.regionValue, this.state.clusterValue, this.state.industryValue,
                                                    this.state.filterResultList
                                                ).map((data,index) => {
                                                    return <Select.Option key={data[0]} value={data[0]}>{data[0]}</Select.Option>
                                                }):
                                                this.state.searchIdOptions.map((data,index) => {
                                                    return <Select.Option key={data[0]} value={data[0]}>{data[0]}</Select.Option>
                                                })
                                        }
                                        </Select>
                                    </div>
                                    <div className="company-name field">
                                        <span className="label">
                                            Company Name
                                        </span>
                                        <Select
                                            disabled={this.props.activeTabKey==='4'}
                                            dropdownMatchSelectWidth={false}
                                             showSearch
                                            placeholder={!this.state.industryValue? "Type to search":"Select a name"}
                                            optionFilterProp="children"
                                             filterOption={(input, option) =>
                                                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                               }
                                            size={size} value={this.state.companyNameValue}
                                            className="select"
                                            onSearch={(val,event)=>{this.handleCompanyNameSearch(val,event)}}
                                            onChange={(val,event)=>{this.handleCompanyNameChange(val,event)}}>
                                            {
                                                this.state.regionValue&&this.state.clusterValue&&this.state.industryValue?
                                                    getCompanyOptions(
                                                        this.state.companyList,
                                                        this.state.regionValue, this.state.clusterValue, this.state.industryValue,
                                                        this.state.filterResultList
                                                    ).map((data,index) => {
                                                        return <Select.Option key={data[0]} value={data[1]['name']}>{data[1]['name']}</Select.Option>
                                                    }):
                                                    this.state.searchNameOptions.map((data,index) => {
                                                        return <Select.Option key={data[0]} value={data[1]['name']}>{data[1]['name']}</Select.Option>
                                            })
                                            }
                                        </Select>
                                    </div>
                                    <div className="employees field" style={{textAlign:'center'}}>
                                        <span className="label" style={{textAlign:'center'}}>
                                            Employees
                                        </span>
                                        <span className="field-value" >
                                            {this.state.selectedQuarterResult['employees']==null?
                                                "--":this.state.selectedQuarterResult['employees']}
                                        </span>
                                    </div>
                                    <div className="reporting-period field">
                                        <span className="label">
                                            Reporting Period
                                        </span>
                                        <div className="row no-gutters">
                                            <div className="column col-6">
                                                <Select
                                                placeholder="Quater"
                                                disabled={
                                                    !this.state.companyIdValue || this.props.activeTabKey==='4'
                                                }
                                                size={size} value={this.state.reportingPeriodQuaterValue} className="select" onChange={val=>{this.handleReportingPeriodQuaterChange(val)}}>
                                                    {
                                                        (this.state.reportingPeriodYearValue&&this.state.selectedCompanyResults)?
                                                        getQuartersFromYear(
                                                            this.state.reportingPeriodYearValue,
                                                            this.state.selectedCompanyResults
                                                        ).map((data,index) => {
                                                            return <Select.Option key={index} value={data}>{data}</Select.Option>
                                                        }):[]
                                                    }
                                                </Select>
                                            </div>
                                            <div className="column col-6" style={{paddingRight:'0px'}}>
                                                <Select
                                                placeholder="Year"
                                                disabled={
                                                    !this.state.companyIdValue || this.props.activeTabKey==='4'
                                                }
                                                size={size} value={this.state.reportingPeriodYearValue} className="select" onChange={val=>{this.handleReportingPeriodYearChange(val)}}>
                                                    {
                                                        (this.state.reportingPeriodYearValue&&this.state.selectedCompanyResults)?
                                                            getYearsFromResults(this.state.selectedCompanyResults).map((data,index) => {
                                                                return <Select.Option key={index} value={data}>{data}</Select.Option>
                                                        }):[]
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="report-date field" style={{textAlign:'center'}}>
                                        <span className="label" style={{textAlign:'center'}}>
                                            Report Date
                                        </span>
                                        <span className="field-value">
                                           {this.state.selectedQuarterResult['report_date']==null?
                                               '--':this.state.selectedQuarterResult['report_date']}
                                        </span>
                                    </div>
                                    <div className="fiscal-date field" style={{textAlign:'center'}}>
                                        <span className="label" style={{textAlign:'center'}}>
                                            Fiscal Date
                                        </span>
                                        <span className="field-value">
                                            {this.state.selectedQuarterResult['fiscal_date']==null?
                                                '--': this.state.selectedQuarterResult['fiscal_date']}
                                        </span>
                                    </div>
                                    <div className="theme-choice" style={{ display: 'flex', alignItems: 'center'}}>
                                    <span className="theme-radio">
                                        <Radio.Group defaultValue="spring" buttonStyle="outline" size={size} onChange={this.handleThemeChange}>
                                            <Radio className="spring-radio" value="spring"></Radio>
                                            <Radio className="summer-radio" value="summer"></Radio>
                                            <Radio className="autumn-radio" value="autumn"></Radio>
                                            <Radio className="winter-radio" value="winter"></Radio>
                                        </Radio.Group>
                                    </span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                    <Dropdown overlay={menu} className="font-size-choice" trigger={'click'}>
                                        <FontSizeOutlined onClick={e => e.preventDefault()}/>
                                    </Dropdown>
                                </div>
                            </div>
                    </div>
                </div>
            </div>

        </div>
        )
    }
}

const mapStateToProps = (state) =>{
    return{
        companyData:state.companyDataReducer.companyData,
        darkMode:state.darkModeReducer.darkMode,
        username: state.authReducer.firstName,
        companyList: state.companyListReducer.companyList,
        companyResults:state.companyListReducer.companyResults,
        activeTabKey: state.activeTabReducer.key,
        rootFontSize: state.globalStyleReducer.rootFontSize,
        currentCompany: state.companyContextReducer.currentCompany,
    }
  };

const mapDispatchToProps = dispatch => {
    return {
        updateHeaderSelected: (selected) => {
            dispatch(
              headerSelected(selected)
            )
        },
        enableDarkMode: (darkmode) => {
            dispatch(
                darkMode(darkmode)
            )
        },
        companySelected: (company) => {
            dispatch(
                companySelected(company)
            )
        },
        currentCompanyChanged: (company) => {
            dispatch(
                currentCompanyChanged(company)
            )
        },
        fetchRatingsForCompanyQuarter: (company, quarter) => {
          dispatch(
              fetchRatingsForCompanyQuarter(company, quarter)
          )
        },
        fetchMacroForRegionDate: (region, date) => {
          dispatch(
              fetchMacroForRegionDate(region, date)
          )
        },
        fetchPeerDataForCompanyQuarter: (company, quarter) => {
            dispatch(
                fetchPeerDataForCompanyQuarter(company, quarter)
            )
        },
        rootFontSize: (value) => {
          dispatch(rootFontSize(value))
        },
        onLogout: () => dispatch(logout())
    }
  };

export default connect(mapStateToProps,mapDispatchToProps)(index);
