import {mapRatingToScore, shiftStatement} from './';

export const convertRowToColumn = row => (
    Object.keys(row[0]).map(function (c) {
        return row.map(function (r) {
          return r[c];
        });
      })
)

export const convertToDecimal = (value, decPlace=1) => parseFloat(Math.round(value * 100) / 100).toFixed(decPlace);

export const checkEmptyString = string => string === '' ? 'Error' : Number(parseFloat(string));

export const emptyStringToZero = string => string === '' ? 0 : Number(parseFloat(string));

export const industryMacroMap = {
    "Automobiles and Components": {
        "Unemployment": "microUnemployment",
        "Retail Sales YoY": "microRetailSales",
        "Household Debt to Income": "microDebtToIncome",
        "Auto Sales": "microAutoSales",
        "Vol": "microVol",
        "Credit": "microCredit"
    },
    "Consumer Durables and Apparel": {
        "Unemployment": "microUnemployment",
        "Retail Sales YoY": "microRetailSales",
        "Household Debt to Income": "microDebtToIncome",
        "Auto Sales": "microAutoSales",
        "Vol": "microVol",
        "Credit": "microCredit"
    },
    "Consumer Services": {
        "Unemployment": "microUnemployment",
        "Retail Sales YoY": "microRetailSales",
        "Household Debt to Income": "microDebtToIncome",
        "Auto Sales": "microAutoSales",
        "Vol": "microVol",
        "Credit": "microCredit"
    },
    "Media": {
        "Unemployment": "microUnemployment",
        "Retail Sales YoY": "microRetailSales",
        "Household Debt to Income": "microDebtToIncome",
        "Auto Sales": "microAutoSales",
        "Vol": "microVol",
        "Credit": "microCredit"
    },
    "Retailing": {
        "Unemployment": "microUnemployment",
        "Retail Sales YoY": "microRetailSales",
        "Household Debt to Income": "microDebtToIncome",
        "Auto Sales": "microAutoSales",
        "Vol": "microVol",
        "Credit": "microCredit"
    },
    "Food and Staples Retailing": {
        "Unemployment": "microUnemployment",
        "Retail Sales YoY": "microRetailSales",
        "Household Debt to Income": "microDebtToIncome",
        "Auto Sales": "microAutoSales",
        "Vol": "microVol",
        "Credit": "microCredit"
    },
    "Food Beverage and Tobacco": {
        "Unemployment": "microUnemployment",
        "Retail Sales YoY": "microRetailSales",
        "Household Debt to Income": "microDebtToIncome",
        "Auto Sales": "microAutoSales",
        "Vol": "microVol",
        "Credit": "microCredit"
    },
    "Household and Personal Products": {
        "Unemployment": "microUnemployment",
        "Retail Sales YoY": "microRetailSales",
        "Household Debt to Income": "microDebtToIncome",
        "Auto Sales": "microAutoSales",
        "Vol": "microVol",
        "Credit": "microCredit"
    },

    "Health Care Equipment and Services": {
        "Unemployment": "microUnemployment",
        "Household Debt to Income": "microDebtToIncome",
        "Household Debt to GDP": "microDebtToGdp",
        "House Prices YoY": "microHousePrice",
        "Credit": "microCredit",
        "Rates 2Y": "microRates2Y"
    },
    "Pharmaceuticals Biotechnology": {
        "Unemployment": "microUnemployment",
        "Household Debt to Income": "microDebtToIncome",
        "Household Debt to GDP": "microDebtToGdp",
        "House Prices YoY": "microHousePrice",
        "Credit": "microCredit",
        "Rates 2Y": "microRates2Y"
    },
    "Pharmaceuticals Biotechnology and Life Sciences": {
        "Unemployment": "microUnemployment",
        "Household Debt to Income": "microDebtToIncome",
        "Household Debt to GDP": "microDebtToGdp",
        "House Prices YoY": "microHousePrice",
        "Credit": "microCredit",
        "Rates 2Y": "microRates2Y"
    },

    "Capital Goods": {
        "Employment": "microEmployment",
        "Output Gap": "microOutputGap",
        "Industrial Production": "microIndustrialProduction",
        "Business Optimism": "microBusinessOptimism",
        "Fixed Income": "microFixedIncome",
        "Commodities": "microCommodities"
    },
    "Commercial and Professional Services": {
        "Employment": "microEmployment",
        "Output Gap": "microOutputGap",
        "Industrial Production": "microIndustrialProduction",
        "Business Optimism": "microBusinessOptimism",
        "Fixed Income": "microFixedIncome",
        "Commodities": "microCommodities"
    },
    "Transportation": {
        "Employment": "microEmployment",
        "Output Gap": "microOutputGap",
        "Industrial Production": "microIndustrialProduction",
        "Business Optimism": "microBusinessOptimism",
        "Fixed Income": "microFixedIncome",
        "Commodities": "microCommodities"
    },
    "Materials": {
        "Employment": "microEmployment",
        "Output Gap": "microOutputGap",
        "Industrial Production": "microIndustrialProduction",
        "Business Optimism": "microBusinessOptimism",
        "Fixed Income": "microFixedIncome",
        "Commodities": "microCommodities"
    },

    "Media and Entertainment": {
        "Unemployment": "microUnemployment",
        "CPI YoY": "microCPI",
        "Household Debt to GDP": "microDebtToGdp",
        "House Prices YoY": "microHousePrice",
        "Credit": "microCredit",
        "Fixed Income": "microFixedIncome"
    },
    "Software and Services": {
        "Unemployment": "microUnemployment",
        "CPI YoY": "microCPI",
        "Household Debt to GDP": "microDebtToGdp",
        "House Prices YoY": "microHousePrice",
        "Credit": "microCredit",
        "Fixed Income": "microFixedIncome"
    },
    "Telecommunication Services": {
        "Unemployment": "microUnemployment",
        "CPI YoY": "microCPI",
        "Household Debt to GDP": "microDebtToGdp",
        "House Prices YoY": "microHousePrice",
        "Credit": "microCredit",
        "Fixed Income": "microFixedIncome"
    },
    "Technology Hardware and Equipment": {
        "Unemployment": "microUnemployment",
        "CPI YoY": "microCPI",
        "Household Debt to GDP": "microDebtToGdp",
        "House Prices YoY": "microHousePrice",
        "Credit": "microCredit",
        "Fixed Income": "microFixedIncome"
    },
    "Semiconductors and Semiconductor Equipment": {
        "Unemployment": "microUnemployment",
        "CPI YoY": "microCPI",
        "Household Debt to GDP": "microDebtToGdp",
        "House Prices YoY": "microHousePrice",
        "Credit": "microCredit",
        "Fixed Income": "microFixedIncome"
    },
    "Semiconductors and Semiconductor": {
        "Unemployment": "microUnemployment",
        "CPI YoY": "microCPI",
        "Household Debt to GDP": "microDebtToGdp",
        "House Prices YoY": "microHousePrice",
        "Credit": "microCredit",
        "Fixed Income": "microFixedIncome"
    },

    "Energy": {
        "Unemployment": "microUnemployment",
        "PPI YoY": "microPPI",
        "Household Debt to GDP": "microDebtToGdp",
        "Industrial Production": "microIndustrialProduction",
        "Energy": "microEnergy",
        "Oil": "microOil"
    },
    "Utilities": {
        "Unemployment": "microUnemployment",
        "PPI YoY": "microPPI",
        "Household Debt to GDP": "microDebtToGdp",
        "Industrial Production": "microIndustrialProduction",
        "Energy": "microEnergy",
        "Oil": "microOil"
    }
};

export const summableQuarterDataFields = ['EBIT', 'capex', 'cash_from_finance', 'cash_from_investment', 'd_and_a', 'dividend',
    'free_cash_flow', 'gross_interest', 'gross_profit', 'issue_L_T_debt', 'net_income', 'op_cash_flow',
    'repay_long_term_debt', 'sale_of_equity', 'sales'];

//date1 and date 2 are in DD/MM/YYYY format: find number of days between
export var dateDiffInDays = function (date1, date2) {   
    var dt1Split = date1.split('/');
    var dt2Split = date2.split('/');

    var dt1 = new Date(dt1Split[2], dt1Split[1], dt1Split[0]);
    var dt2 = new Date(dt2Split[2], dt2Split[1], dt2Split[0]);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
};


export function setStatementRatios(ratioData, companyData) {
    var shift;

    var netWorkingCap, totLiab, debtToEq, currentRat, interestCov;

    const netWorkingCapVal = convertToDecimal(parseFloat(ratioData.ratioCurrentAssets.value) - parseFloat(ratioData.ratioCurrentLiab.value));
    shift = netWorkingCapVal - companyData.netWorkingCapital.value;
    if (shift === 0.0) netWorkingCap = companyData.netWorkingCapital;
    else netWorkingCap = shiftStatement(shift, companyData.netWorkingCapital);

    const totLiabVal = convertToDecimal(parseFloat(ratioData.ratioCurrentLiab.value) + parseFloat(ratioData.ratioNonCurrentLiab.value));
    shift = totLiabVal - companyData.totalLiabilitiesShortLongTerm.value;
    if (shift === 0.0) totLiab = companyData.totalLiabilitiesShortLongTerm;
    else totLiab = shiftStatement(shift, companyData.totalLiabilitiesShortLongTerm);

    const debtToEqVal = convertToDecimal(parseFloat(totLiabVal) / parseFloat(ratioData.ratioTotalEquity.value));
    shift = debtToEqVal - companyData.debtToEquity.value;
    if (shift === 0.0) debtToEq = companyData.debtToEquity;
    else debtToEq = shiftStatement(shift, companyData.debtToEquity);

    const currentRatVal = convertToDecimal(parseFloat(ratioData.ratioCurrentAssets.value) / parseFloat(ratioData.ratioCurrentLiab.value));
    shift = currentRatVal - companyData.currentRatio.value;
    if (shift === 0.0) currentRat = companyData.currentRatio;
    else currentRat = shiftStatement(shift, companyData.currentRatio);

    const interestCovVal = convertToDecimal(parseFloat(ratioData.ratioEBIT.value) / parseFloat(ratioData.ratioGrossInterest.value));
    shift = interestCovVal - companyData.interestCoverageRatio.value;
    if (shift === 0.0) interestCov = companyData.interestCoverageRatio;
    else interestCov = shiftStatement(shift, companyData.interestCoverageRatio);

    const ratioAll = { netWorkingCap: netWorkingCap, totLiab: totLiab, debtToEq: debtToEq, currentRat: currentRat, interestCov: interestCov }

    return ratioAll;
}


export function getCompanyPeersNew(companyCode, peerRatings) {
    //Returns agency ratings and roboR8 ratings of the set of companies with the same result date and industry

    let grade;
    let peerResult = [];

    for (let [peerCode, companyRatings] of Object.entries(peerRatings)) {
        let rating, peerTemp;

        //Get roboR8 rating and rating probs
        let score = 0;
        let ratingProb = 0;
        let probLess = 0;
        let probMore = 0;
        let j;
        for (j = 1; j <= 24; j++) {
            let quantile = parseFloat(companyRatings.robor8_rating.quantiles[j-1]);
            if (quantile > ratingProb) {
                ratingProb = quantile;
                rating = j;
            }
            score += j * quantile;
        }

        //Get rating skews
        for (j = 1; j <= 24; j++) {
            let quantile = parseFloat(companyRatings.robor8_rating.quantiles[j-1]);
            if (j < rating)
                probLess = probLess + quantile;
            if (j > rating)
                probMore = probMore + quantile;
        }

        // agency ratings
        let agencyVal1, agencyVal2, agencyVal3;
        agencyVal1 = companyRatings.agency_ratings.Fitch !== "" ? parseFloat(mapRatingToScore(companyRatings.agency_ratings.Fitch)) : parseFloat(-1);
        agencyVal2 = companyRatings.agency_ratings.Moody !== "" ? parseFloat(mapRatingToScore(companyRatings.agency_ratings.Moody)) : parseFloat(-1);
        agencyVal3 = companyRatings.agency_ratings.SandP !== "" ? parseFloat(mapRatingToScore(companyRatings.agency_ratings.SandP)) : parseFloat(-1);

        let max = Math.max(agencyVal1, agencyVal2, agencyVal3);
        //Set the entries with a -1 to max in order to exclude
        let agencyVal1temp = agencyVal1 === -1 ? max : agencyVal1;
        let agencyVal2temp = agencyVal2 === -1 ? max : agencyVal2;
        let agencyVal3temp = agencyVal3 === -1 ? max : agencyVal3;

        let min = Math.min(agencyVal1temp, agencyVal2temp, agencyVal3temp);

        if (peerCode === companyCode) grade = "selected company";
        else {
            if (agencyVal1 <= 0 && agencyVal2 <= 0 && agencyVal3 <= 0) grade = "unrated by agency";
            else if (rating <= parseFloat(max) && rating >= parseFloat(min)) grade = "match agency rating";
            else if (rating >= parseFloat(min) - 1 && rating <= parseFloat(max) + 1) grade = "within +/-1 notch";
            else if (rating < parseFloat(min) - 1 || rating > parseFloat(max) + 1) grade = "differs from agency";
        }

        peerTemp = {
            name: peerCode,
            score: Math.round((score * 100) / 24),
            rating: rating,
            agency1: agencyVal1,
            agency2: agencyVal2,
            agency3: agencyVal3,
            probLess: probLess,
            ratingProb: ratingProb,
            probMore: probMore,
            grade: grade
        };

        peerResult.push(peerTemp);
    }
    return peerResult;
};


export function getFinancialRatios(ratioData, companyPos) {
    const Rmax = 50;


    let maxCurrentRatio = 10; let maxCashRatio = 10; let maxGprofitMargin = 1; let maxNprofitMargin = 1;
    let maxLTDRatio = 1; let maxDebtToEqRatio = 5; let maxDebtRatio = 2; let maxInterestCov = 50; let maxLeverage = 50;

    let totalDebt = parseFloat(ratioData.qTotalLiab[companyPos]);
    let totalEquity = parseFloat(ratioData.qTotalAssets[companyPos]) - parseFloat(ratioData.qTotalLiab[companyPos])
    let cashEquiv = parseFloat(ratioData.qCash[companyPos]) + parseFloat(ratioData.qAcctRec[companyPos]);
    let netDebt = parseFloat(totalDebt) - parseFloat(cashEquiv);
    let currentRatio, cashRatio, grossMargin, netMargin, debtRatio, debtToEquity, interestCov, leverage;
    let aSales = 0; let aGrossProfit = 0; let aNetIncome = 0; let aEBIT = 0; let aDandA = 0; let aGrossInterest = 0;
    let aEBITDA = 0;

    //Annualise Income Statement metrics
    for (let i = 1; i <= 4; i++) {
        aSales += parseFloat(ratioData.qSales[i - 1][companyPos]);
        aGrossProfit += parseFloat(ratioData.qGrossProfit[i - 1][companyPos]);
        aNetIncome += parseFloat(ratioData.qNetIncome[i - 1][companyPos]);
        aEBIT += parseFloat(ratioData.qEBIT[i - 1][companyPos]);
        aDandA += parseFloat(ratioData.qDandA[i - 1][companyPos]);
        aGrossInterest += parseFloat(ratioData.qGrossInterest[i - 1][companyPos]);
    }

    aEBITDA = aEBIT + aDandA;

    grossMargin = aSales === 0 ? Rmax : Math.max(0, aGrossProfit / aSales);
    netMargin = aSales === 0 ? Rmax : Math.max(0, aNetIncome / aSales);

    interestCov = aGrossInterest <= 0 ? Rmax : Math.max(0, aEBIT / aGrossInterest);
    leverage = aEBITDA <= 0 ? Rmax : Math.max(0, (netDebt + parseFloat(ratioData.qCurrentLiab[companyPos])) / aEBITDA);

    currentRatio = ratioData.qCurrentLiab[companyPos] === 0 ? Rmax : currentRatio = Math.max(0, ratioData.qCurrentAssets[companyPos] / ratioData.qCurrentLiab[companyPos]);
    cashRatio = ratioData.qCurrentLiab[companyPos] === 0 ? Rmax : cashRatio = Math.max(0, cashEquiv / ratioData.qCurrentLiab[companyPos]);
    debtRatio = ratioData.qTotalAssets[companyPos] === 0 ? Rmax : debtRatio = Math.max(0, totalDebt / ratioData.qTotalAssets[companyPos]);
    debtToEquity = totalEquity === 0 ? Rmax : Math.max(0, totalDebt / totalEquity);

    let ratios = {
        "currentRatio": Math.min(currentRatio, maxCurrentRatio),
        "cashRatio": Math.min(cashRatio, maxCashRatio),
        "grossMargin": Math.min(grossMargin, maxGprofitMargin),
        "netMargin": Math.min(netMargin, maxNprofitMargin),
        "LTDRatio": Math.min(Math.max(0, ratioData.qLongTermDebt[companyPos] / totalDebt), maxLTDRatio),
        "debtToEqRatio": Math.min(debtToEquity, maxDebtToEqRatio),
        "debtRatio": Math.min(debtRatio, maxDebtRatio),
        "interestCov": Math.min(interestCov, maxInterestCov),
        "leverage": Math.min(leverage, maxLeverage)
    }

    return ratios;
}

export function getPeersCreditBars(roboR8peers, roboR8rating) {

    //Get peers credit bars

    let data = [];
    let dataEntry, A, B, C, ABC;


    for (let i = 1; i <= roboR8peers.length; i++) {
        if (roboR8peers[i - 1].rating !== roboR8rating) continue;

        //Ensure we are exactly at 100 (maybe problem with rounding)
        A = Math.round(roboR8peers[i - 1].probLess * 100);
        B = Math.round(roboR8peers[i - 1].ratingProb * 100);
        C = Math.round(roboR8peers[i - 1].probMore * 100);
        ABC = parseFloat(A) + parseFloat(B) + parseFloat(C);

        A = 100 * parseFloat(A) / parseFloat(ABC);
        B = 100 * parseFloat(B) / parseFloat(ABC);
        C = 100 * parseFloat(C) / parseFloat(ABC);

        dataEntry = {
            group: "Rating Score Skew Probabilities",
            type: roboR8peers[i - 1].name,
            total: 100,
            "< Credit Score": A,
            "= Credit Score": B,
            "> Credit Score": C
        }
        data.push(dataEntry);
    }

    return data;
}

export function getISOLastDayOfPrevMonth(dateString){
    const ISOdate = `${dateString.slice(6,10)}-${dateString.slice(3,5)}-${dateString.slice(0,2)}`;
    const dateObj = new Date(ISOdate);
    dateObj.setDate(1); // going to 1st of the month
    dateObj.setHours(-1); // going to the last hour of previous month
    return dateObj.toISOString().slice(0,10);
}

export function getIndustrySpecificMacroData(companyData) {
    const result = industryMacroMap[companyData.industry]? {...industryMacroMap[companyData.industry]} :
        {...industryMacroMap["Automobiles and Components"]};

    for (let [k, v] of Object.entries(result))
        result[k] = companyData[v];
    return result;
}

export function getDDMMYY(today) {
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }
    let yy = today.getFullYear().toString().substr(2);
    return dd + '/' + mm + '/' + yy;
}

export function toCamelCaseVarName(sentenceCase) {
    var out = "";
    sentenceCase = sentenceCase.replace('&', 'And');  // replace & as it is not allowed in variable names
    sentenceCase.split(" ").forEach(function (el, idx) {
        var add = el.toLowerCase();
        out += (idx === 0 ? add : add[0].toUpperCase() + add.slice(1));
    });
    return out;
}

 /**
 * get previous quarter
 */
export function prevQuarter(quarter) {
    const q = parseInt(quarter.slice(1, 2));
    const y = parseInt(quarter.slice(2));
    const prevQ = q === 1? 4: q-1;
    const prevY = q === 1? y-1: y;
    return `Q${prevQ}${prevY}`
}

export function isNewerVersion (oldVer, newVer) {
    const oldParts = oldVer.split('.');
    const newParts = newVer.split('.');
    for (var i = 0; i < newParts.length; i++) {
        const a = ~~newParts[i]; // parse int
        const b = ~~oldParts[i]; // parse int
        if (a > b) return true;
        if (a < b) return false
    }
    return false
}

export function convertRemToPx(rem, multiplier=1){
  return multiplier*rem*parseFloat(getComputedStyle(document.documentElement).fontSize)
}