import * as types from "../constants";
import axiosInstance from "../../utils/axios";
import {process4QdataFromServer} from "../../components/UploadSheet/service";


export const newUploadSheetEntries = (t12mListByCompany) => {
    return {
        type: types.NEW_UPLOAD_ENTRIES,
        data: t12mListByCompany
    };
};

export const toggleLoading = (loading) => {
    return {
        type: types.TOGGLE_LOADING_T12M,
        data: loading
    };
};

export const newUploadSheetServerEntries = (data, region, cluster) => {
    return {
        type: types.SERVER_T12M_ENTRIES,
        data: data,
        region: region,
        cluster: cluster,
    };
};

export const ensureServerT12mData = (region, cluster) => {
    return (dispatch, getState) => {
        const t12mDataStoreServer = getState().uploadTabReducer.t12mDataStoreServer;
        if(t12mDataStoreServer[region]&&t12mDataStoreServer[region][cluster]){
            return;
        }
        else{
            dispatch(toggleLoading(true));
            let url = '/api/comdata/company-results';
            const params = {
                region: region,
                cluster: cluster
            };
            axiosInstance.get(url, {params})
                .then(response=> {
                    const processed = process4QdataFromServer(response.data);
                    dispatch(newUploadSheetServerEntries(processed, region, cluster));
                    dispatch(toggleLoading(false));
                })
                .catch(err=>{
                    console.log(err.response);
                    dispatch(toggleLoading(false));
                })
        }
    }
};

export const updateSelectedCompanyIds = (selectedCompanyIds) => {
    return {
        type: types.SELECTED_COMPANY_IDS,
        data: selectedCompanyIds
    }
};

export const setRegionCluster = (region, cluster) => {
    return {
        type: types.UPLOAD_TAB_SET_REGION_CLUSTER,
        data: {'region': region, 'cluster': cluster}
    };
};

export const deleteUploadTabRows = (idList, region, cluster) => {
    return {
        type: types.UPLOAD_TAB_DELETE_ROWS,
        idList: idList,
        region: region,
        cluster: cluster
    };
};