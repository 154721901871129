import React, {Component} from 'react'
import {Input} from 'antd';
import errorRed from '../assets/images/icons8-error-red.png'
import errorOrange from '../assets/images/icons8-error-orange.png'
import checkedIcon from '../assets/images/icons8-checked.png'
import editIcon from '../assets/images/icons-edit-flat.png'

import {connect} from 'react-redux';
import {getIndustrySpecificMacroData} from "../utils";

const size = "small";

class macroData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editCompanyData: this.props.companyData
        }
    }

    componentDidMount() {
        this.setState({ data: this.props })
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        //    console.log("receive new props!");
        this.setState({
            custom: false,
            data: nextProps,
            editCompanyData: nextProps.companyData
        });
    };
    
    checkIssueIcon(status) {
        switch (status) {
            case 'warning':
                return errorOrange;
            case 'error':
                return errorRed;
            case 'fixed':
                return checkedIcon;
            case 'custom':
                return editIcon;
            default:
                return '';
        }
    };

    render() {
        const macroMap = getIndustrySpecificMacroData(this.state.editCompanyData);
        const macroNames = Object.keys(macroMap);
        const macroValues = Object.values(macroMap);

        return (
             <div className="row no-gutters parent-row">
                <div className="col-12">
                    <div className="shadow-box macro-economic-data">
                        <div className="header">
                            <span>MACRO ECONOMIC DATA</span>
                        </div>
                        <div className="content">
                            <div className="row no-gutters">
                                <div className="column col-6">
                                    <div className="field">
                                        <span>{macroNames[0]}</span>
                                        <Input size={size} value={macroValues[0].value} />
                                    </div>
                                </div>
                                <div className="column  col-6">
                                    <div className="field">
                                        <span>{macroNames[1]}</span>
                                        <Input size={size} value={macroValues[1].value} />
                                    </div>
                                </div>
                            </div>

                            <div className="row no-gutters">
                                <div className="column  col-6">
                                    <div className="field">
                                        <span>{macroNames[2]}</span>
                                        <Input size={size} value={macroValues[2].value} />
                                    </div>
                                </div>
                                <div className="column  col-6">
                                    <div className="field">
                                        <span>{macroNames[3]}</span>
                                        <Input size={size} value={macroValues[3].value} />
                                    </div>
                                </div>
                            </div>

                            <div className="row no-gutters">
                                <div className="column  col-6">
                                    <div className="field">
                                        <span>{macroNames[4]}</span>
                                        <Input size={size} value={macroValues[4].value} />
                                    </div>
                                </div>
                                <div className="column  col-6">
                                    <div className="field">
                                        <span>{macroNames[5]}</span>
                                        <Input size={size} value={macroValues[5].value} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>               
             </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        companyData: state.companyDataReducer.companyData
    }
}


const mapDispatchToProps = dispatch => {
    return {
    }
}
  
export default connect(mapStateToProps,mapDispatchToProps)(macroData);




