import React from "react";
import { connect } from 'react-redux';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Legend,
} from "bizcharts";
import {convertRemToPx} from "../utils";

class CashBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            width: 0,
            height: 0,
            chartSize: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);        
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("asset pie receive new props!");
        this.setState({
            custom: false,
            data: nextProps
        });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let winHeight = window.innerHeight;

        this.setState({ width: window.innerWidth, height: window.innerHeight, chartSize: (winHeight * this.props.height) });
    }
       
    
    render() {
        let data, total, OpCF, InvCF, FinCF;

        if (this.props.autoCorrectData === true) {
            total = parseFloat(this.props.companyData.netIncreaseInCashAndEquivalents.value);
            OpCF = parseFloat(this.props.companyData.qOpCashFlow.value);
            InvCF = parseFloat(this.props.companyData.qCashFromInvest.value);
            FinCF = parseFloat(this.props.companyData.qCashFromFinance.value);

            data = [
                { item: "Total", score: total },
                { item: "Operations", score: OpCF },
                { item: "Investing", score: InvCF },
                { item: "Finance", score: FinCF },
            ];
        } else {
            data = [
                { item: "ratio", score: 1 }
            ];
        }

        const cols = {
            score: {
                nice: true
            }
        };

        return (
            <div className="cashBar">
                <Chart
                    height={window.innerHeight * this.props.height}
                    padding="auto" data={data} scale={cols} autoFit renderer='svg'
                    interactions={['active-region']}
                    visible={this.props.activeTabKey===this.props.underTabKey && this.props.activeSubTabKey===this.props.underSubTab}
                >
                    <Legend visible={false} />
                    <Tooltip showTitle={false} />
                    <Axis
                        name="item"
                        line={null}
                        tickline={null}
                        grid={{
                            line: {
                                style: {
                                    stroke: this.props.darkMode ? '#f2d9d9' : '#f2d9d9',
                                    lineWidth: 0.5,
                                    lineDash: null,
                                }
                            },
                            hideFirstLine: false
                        }}
                        label={{
                            rotate: 0,
                            style: {
                                textAlign: "center",
                                fill: this.props.darkMode ? '#A3ABBD' : '#192A3E', // 文本的颜色
                                fontSize: convertRemToPx(this.props.rootFontSize), // 文本大小
                            }
                        }}
                    />
                    <Axis name="score"
                        label={null}
                    />
                    <Geom
                        type="interval"
                        position="item*score"   
                        color={["item", [total > 0 ? '#5FC563' : '#F5827E', OpCF > 0 ? '#7CD07F' : '#F69490', InvCF > 0 ? '#99da9b' : '#F8A5A2', FinCF > 0 ? '#B6E4B7' : '#FABCBA']]}
                        style={{
                            stroke: '#E5E5E5',
                            lineWidth: 1,
                        }}
                        tooltip={['item*score', (item, score) => {
                            return {
                                // Custom tooltip on the title display and so on.
                                name: item,
                                value: score + " million"
                            };
                        }]}
                    >                        
                    </Geom>
                </Chart>
            </div>
        );
    }
}

const mapStateToProps = (state) =>{
  return{
      darkMode: state.darkModeReducer.darkMode,
      headerSelected: state.headerSelectedReducer.headerSelected,
      companyData: state.companyDataReducer.companyData,
      autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
      customisedData: state.customisedDataReducer.customisedData,
      radarPeers: state.radarPeersReducer.radarPeers,
    rootFontSize: state.globalStyleReducer.rootFontSize,
    activeTabKey: state.activeTabReducer.key,
    activeSubTabKey: state.activeTabReducer.subTabKey
  }
}

export default connect(mapStateToProps, null)(CashBar);