import * as types from "../constants";
import axiosInstance from "../../utils/axios";
import {getDDMMYY, mapScoreToRating} from "../../utils";


export const submitDataForCreditRating = (args, companyId, prevQuarter) => {
    return (dispatch, getState) => {
        let url = `/api/migo/run-credit-score`;
        let data = {
            arguments:  args,
            company: companyId,
            prev_quarter: prevQuarter
        };
        dispatch(runCreditScoreStarted());
        axiosInstance.post(url, data)
            .then(response=> {
                dispatch(runCreditScoreTaskIdReturned(response.data.taskId))
            })
            .catch(err=>{
                console.log(err.response);
            });
    }
};

export const runCreditScoreStarted = () => {
    return {
        type: types.RUN_CREDIT_SCORE_START
    };
};

export const runCreditScoreFinished = (data) => {
    return {
        type: types.RUN_CREDIT_SCORE_FINISH,
        data: data
    };
};

export const runCreditScoreErrored = (msg) => {
    return {
        type: types.RUN_CREDIT_SCORE_ERROR,
        message: msg
    };
};


export const runCreditScoreTaskIdReturned = (taskId) => {
    let queryMigoResultHandler = (dispatch, count) => {
        // if retried 100 times (30 seconds), then abort and show timeout error
        if(count>100){
            dispatch(runCreditScoreErrored('Request to MIGO engine timed out, please try again later.'));
            return;
        }

        let url = `/api/migo/credit-score-result/${taskId}`;
        axiosInstance.get(url)
            .then(response=> {
                if(response.data.ready)
                    if (Array.isArray(response.data.result))
                        dispatch(runCreditScoreFinished(response.data.result));
                    else
                        dispatch(runCreditScoreErrored(response.data.result));
                else
                    setTimeout(queryMigoResultHandler, 300, dispatch, count+1)
            })
            .catch(err=>{
                console.log(err.response);
                dispatch(runCreditScoreErrored(err.response));
            });
    };
    return (dispatch, getState) => {
        queryMigoResultHandler(dispatch, 1);
    }
};

export const submitDataForBatchRating = (rows, region) => {
    return (dispatch, getState) => {
        console.log(rows);
        let url = `/api/migo/run-batch-score`;
        let data = {
            data:  rows,
            region: region
        };
        dispatch(runBatchScoreStarted());
        axiosInstance.post(url, data)
            .then(response=> {
                dispatch(runBatchScoreTaskIdReturned(response.data.taskId, data))
            })
            .catch(err=>{
                console.log(err.response);
            });
    }
};

export const runBatchScoreStarted = () => {
    return {
        type: types.RUN_BATCH_SCORE_START
    };
};

export const runBatchScoreFinished = (data, region, cluster) => {
    for (let item of data){
        const roboR8Results = {date: item.date, quantiles: item.quantiles, sample: item.sample, target: item.target};
        item.roboR8Results = roboR8Results
    }
    return {
        type: types.RUN_BATCH_SCORE_FINISH,
        data: data,
        region: region,
        cluster: cluster
    };
};

export const runBatchScoreErrored = (msg) => {
    return {
        type: types.RUN_BATCH_SCORE_ERROR,
        message: msg
    };
};

export const runBatchScoreTaskIdReturned = (taskId, data) => {
    let queryMigoResultHandler = (dispatch, count) => {
        // if retried 100 times (30 seconds), then abort and show timeout error
        if(count>100){
            dispatch(runCreditScoreErrored('Request to MIGO engine timed out, please try again later.'));
            return;
        }

        let url = `/api/migo/batch-score-result/${taskId}`;
        axiosInstance.get(url)
            .then(response=> {
                if(response.data.ready)
                    if (Array.isArray(response.data.result)){
                        // join result with data by companyId
                        let merged = [];

                        for(let i=0; i<response.data.result.length; i++) {
                            merged.push({
                                ...response.data.result[i],
                                ...(data.data.find((itmInner) => itmInner.companyId === response.data.result[i].companyId))}
                            );
                        }
                        dispatch(runBatchScoreFinished(merged, data.data[0].region, data.data[0].cluster));
                    }
                    else
                        dispatch(runBatchScoreErrored(response.data.result));
                else
                    setTimeout(queryMigoResultHandler, 300, dispatch, count+1)
            })
            .catch(err=>{
                console.log(err.response);
                dispatch(runBatchScoreErrored(err.response));
            });
    };
    return (dispatch, getState) => {
        queryMigoResultHandler(dispatch, 1);
    }
};
