import { CURRENT_COMPANY, AUTH_LOGOUT } from '../constants';

let initialState={
    currentCompany: undefined, // this value should be a company code, e.g. "AMD-US"
}

const companyContextReducer = (state = initialState, action)=>{
    switch(action.type){
        case CURRENT_COMPANY:
            return{
                ...state,
                currentCompany:action.payload,
            }
        case AUTH_LOGOUT:
            return initialState;
        default:
            return state;
    }
}

export default companyContextReducer;