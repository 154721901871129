import { LOADING } from '../constants';

let initialState={
    loading:false,
}

const loadingReducer = (state = initialState, action)=>{

    switch(action.type){
        case LOADING:
          return{
            ...state,
            loading:!initialState.loading,
          }
      default:
          return state;
      }
}

export default loadingReducer;