import * as types from "../constants";
import axios from 'axios'
import axiosInstance from '../../utils/axios'
import jwt from 'jwt-decode'

export const authStart = ()=> {
    return {
        type: types.AUTH_START
    };
};

export const authSuccess = (token, firstName) => {
    axiosInstance.defaults.headers.common['Authorization'] = token;
    return {
        type: types.AUTH_SUCCESS,
        token: token,
        firstName: firstName
    };
};

export const authFail = (error) => {
    return {
        type: types.AUTH_FAIL,
        error: error
    };
};

export const logout = ()=> {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('firstName');
    return {
        type: types.AUTH_LOGOUT
    }
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime);
    };
};

export const auth = (username, password, remember) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            username: username,
            password: password,
            remember: remember
        };
        let url = '/api/auth/get-token';
        axios.post(url, authData)
            .then(response=> {
                const expirationDate = new Date().getTime() + response.data.expires_in * 1000
                const decoded = jwt(response.data.token);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('firstName', decoded.first_name);
                dispatch(authSuccess(response.data.token, decoded.first_name))
            })
            .catch(err=>{
                console.log(err.response);
                dispatch(authFail(err.response.data.message));
            })
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = localStorage.getItem('expirationDate');
            if(expirationDate > new Date().getTime()){
                const firstName = localStorage.getItem('firstName');
                dispatch(authSuccess(token, firstName));
                dispatch(checkAuthTimeout(expirationDate - new Date().getTime()));
            }
            else{
                dispatch(logout())
            }
        }
    };
};