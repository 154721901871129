import React, { Component } from 'react'
import {Input, Tooltip,Divider} from 'antd';
import errorRed from '../assets/images/icons8-error-red.png'
import errorOrange from '../assets/images/icons8-error-orange.png'
import checkedIcon from '../assets/images/icons8-checked.png'
import editIcon from '../assets/images/icons-edit-flat.png'
//import treeIcon from '../assets/images/icons-network.png'

import { connect } from 'react-redux';

const size = "small";

class statementRatios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editCompanyData: this.props.companyData
        }
    }

    componentDidMount() {
        this.setState({ data: this.props })
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("statement ratios receive new props!");
        this.setState({
            custom: false,
            data: nextProps,
            editCompanyData: nextProps.companyData
        });
    };
   
    checkIssueIcon(status) {
        switch (status) {
            case 'warning':
                return errorOrange;
            case 'error':
                return errorRed;
            case 'fixed':
                return checkedIcon;
            case 'custom':
                return editIcon;
            default:
                return '';
        }
    };

    render() {

        const {
            qCurrentAssets,
            qCurrentLiab,
            qLongTermDebt,
            totalEquity,

            qFreeCashFlow,
            netWorkingCapital,
            totalLiabilitiesShortLongTerm,
            debtToEquity,
            currentRatio,
            interestCoverageRatio,            

        } = this.state.editCompanyData;

        return (

            <div className="key-ratio-analysis input-style-class">
                <div className="input-wrapper">
                    <div className="title">
                        <span>KEY RATIO ANALYSIS</span>
                    </div>
                    <div className={`${qFreeCashFlow.status} field`}>
                        <span>Free Cash Flow</span>
                        <div className="input-box-wrapper">
                            {
                                qFreeCashFlow.status === "" ? null : (
                                    <Tooltip title={qFreeCashFlow.log}>
                                        <img src={this.checkIssueIcon(qFreeCashFlow.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qFreeCashFlow.value} />
                        </div>
                    </div>
                    <div className={`${qCurrentAssets.status} field`}>
                        <span>Total Current Assets</span>
                        <div className="input-box-wrapper">
                            {
                                qCurrentAssets.status === "" ? null : (
                                    <Tooltip title={qCurrentAssets.log}>
                                        <img src={this.checkIssueIcon(qCurrentAssets.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qCurrentAssets.value} />
                        </div>
                    </div>
                    <div className={`${qCurrentLiab.status} field`}>
                        <span>Total Current Liabilities</span>
                        <div className="input-box-wrapper">
                            {
                                qCurrentLiab.status === "" ? null : (
                                    <Tooltip title={qCurrentLiab.log}>
                                        <img src={this.checkIssueIcon(qCurrentLiab.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qCurrentLiab.value} />
                        </div>
                    </div>

                    <Divider dashed />

                    <div className={`${netWorkingCapital.status} field`}>
                        <span className="medium">Net Working Capital</span>
                        <div className="input-box-wrapper">
                            {
                                netWorkingCapital.status === "" ? null : (
                                    <Tooltip title={netWorkingCapital.log}>
                                        <img src={this.checkIssueIcon(netWorkingCapital.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={netWorkingCapital.value} />
                        </div>
                    </div>
                    <div className={`${qLongTermDebt.status} field`}>
                        <span>Long Term Debt</span>
                        <div className="input-box-wrapper">
                            {
                                qLongTermDebt.status === "" ? null : (
                                    <Tooltip title={qLongTermDebt.log}>
                                        <img src={this.checkIssueIcon(qLongTermDebt.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qLongTermDebt.value} />
                        </div>
                    </div>

                    <Divider dashed />

                    <div className={`${totalLiabilitiesShortLongTerm.status} field`}>
                        <span className="medium">Total Liabilities (Short &amp; Long Term)</span>
                        <div className="input-box-wrapper">
                            {
                                totalLiabilitiesShortLongTerm.status === "" ? null : (
                                    <Tooltip title={totalLiabilitiesShortLongTerm.log}>
                                        <img src={this.checkIssueIcon(totalLiabilitiesShortLongTerm.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={totalLiabilitiesShortLongTerm.value} />
                        </div>
                    </div>
                    <div className={`${totalEquity.status} field`}>
                        <span>Total Shareholders' Equity</span>
                        <div className="input-box-wrapper">
                            {
                                totalEquity.status === "" ? null : (
                                    <Tooltip title={totalEquity.log}>
                                        <img src={this.checkIssueIcon(totalEquity.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={totalEquity.value} />
                        </div>
                    </div>

                    <Divider dashed />

                    <div className={`${debtToEquity.status} field`}>
                        <span className="medium">Debt to Equity</span>
                        <div className="input-box-wrapper">
                            {
                                debtToEquity.status === "" ? null : (
                                    <Tooltip title={debtToEquity.log}>
                                        <img src={this.checkIssueIcon(debtToEquity.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={debtToEquity.value} />
                        </div>
                    </div>
                    <div className={`${currentRatio.status} field`}>
                        <span className="medium">Current Ratio</span>
                        <div className="input-box-wrapper">
                            {
                                currentRatio.status === "" ? null : (
                                    <Tooltip title={currentRatio.log}>
                                        <img src={this.checkIssueIcon(currentRatio.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={currentRatio.value} />
                        </div>
                    </div>
                    <div className={`${interestCoverageRatio.status} field`}>
                        <span className="medium">Interest Coverage Ratio</span>
                        <div className="input-box-wrapper">
                            {
                                interestCoverageRatio.status === "" ? null : (
                                    <Tooltip title={interestCoverageRatio.log}>
                                        <img src={this.checkIssueIcon(interestCoverageRatio.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={interestCoverageRatio.value} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) =>{
    return{
        companyData: state.companyDataReducer.companyData  
    }
  }

export default connect(mapStateToProps)(statementRatios);




