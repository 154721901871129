import {industryMacroMap, prevQuarter, summableQuarterDataFields, toCamelCaseVarName} from "../../utils";
import {processReport} from "../../utils/dataProcessing";

export const colNames = ['Company ID','Industry','Company Name','Sales','Gross Profit','Depreciation & Amortisation','EBIT',
    'Interest Expense','Net Income', 'Net Cash From Operating','Capex','Net Cash From Investing',
    'Net Cash From Financing','Cash and Equivalents', 'Account Receivable','Inventory','Total Current Assets',
    'Accumulated Depreciation','Net Fixed Asset','Intangibles','Total Assets','Account Payable','Short Term Debt',
    'Total Current Liabilities','Long Term Debt','Total Liabilities','Retained Earnings','Total Equity','Free Cash Flow'];
/**
 * Check the row's data type against a set of rules, collect those columns that fail the check
 * @param row
 * @returns {[]} list of column names whose data violates the contraint
 */
function checkRowIntegrity(row) {
    let errorList = [];
    // industry name must be standard
    if(!Object.keys(industryMacroMap).includes(row[1]))
        errorList.push(colNames[1]);

    // ensure numeric fields
    for (let i=3;i<colNames.length; i++){
        if (isNaN(row[i])){
            errorList.push(colNames[i])
        }
    }
    return errorList
}

function createData(row){
    const data = {};
    for (let i=0; i<colNames.length; i++){
        const varName = toCamelCaseVarName(colNames[i]);
        if(i<3){
            data[varName] = row[i].toString()
        }
        else{
            data[varName] = row[i]
        }
    }
    return data;
}

/**
 * Load company data from list of rows into an object.
 * @param data The list of data rows.
 * @param today Today's date.
 * @returns {object} an object containing both accepted rows as data entries and declined rows
 */
export function loadCompanyData(data, today= new Date()){
    const result = {
        declined: [],
        accepted: []
    };
    if (data.length < 3)
        return result;
    // get the last 4 quarters
    const d = today;
    const y = d.getFullYear();
    const m = d.getMonth();
    const q = Math.floor(m/3);
    const qList = [];
    const fiscalDateList = [];
    for (let i=q-3; i<=q;i++){
        let quarter = i;
        let year = y;
        if (i<=0){
            quarter = i+4;
            year = y -1;
        }
            qList.push(`Q${quarter}${year}`);
        const month = quarter * 3;
        const dayOfMonth= (new Date(year, month, 0)).getDate();
        const fiscalDate = ('0' + dayOfMonth).slice(-2)+'/'+('0' + month).slice(-2)+'/'+year;
        fiscalDateList.push(fiscalDate);
    }

    for (let row of data.slice(2)){
        // check data integrity
        const errorList = checkRowIntegrity(row);
        if(errorList.length>0){
            result.declined.push(errorList);
            continue
        }
        // append prefix to company ID
        row[0] = row[0].toString() + '-USER';
        // assign the rest of the cols
        let t12mData = createData(row);
        // break into 4 quarters
        // first quarter first
        let rowCopy = row.slice();
        for(let i=3; i<29; i++){
            // divide each field by 4, first quarter gets the extra remainder
            rowCopy[i] = parseInt(row[i]/4) + row[i]%4
        }
        let quarter1 = createData(rowCopy);
        // rest 3 quarters
        for(let i=3; i<29; i++){
            // divide each field by 4, get the integer part only, the remainder is absorbed by the first quarter.
            rowCopy[i] = parseInt(row[i]/4)
        }
        let quarter2 = createData(rowCopy);
        let quarter3 = createData(rowCopy);
        let quarter4 = createData(rowCopy);
        // return two sets of dict
        result.accepted.push({
            t12mData: {...t12mData, lastQuarter: qList[3]},
            quarter1: {...quarter1, quarter: qList[0], fiscalDate: fiscalDateList[0]},
            quarter2: {...quarter2, quarter: qList[1], fiscalDate: fiscalDateList[1]},
            quarter3: {...quarter3, quarter: qList[2], fiscalDate: fiscalDateList[2]},
            quarter4: {...quarter4, quarter: qList[3], fiscalDate: fiscalDateList[3]}
        })
    }
    return result;
}

export function getRegionClusterMapFromCompanyList(companyList) {
    const result = {};
    for (const [k, v] of Object.entries(companyList)){
        result[k] = Object.keys(v)
    }
    return result
}

/**
 * Given an array of quarter strings (e.g. ['Q12019','Q42018','Q32018']), check whether they are consecutive in
 * descending order
 * @param quarters
 * @returns {boolean} true if the quarters are consecutive, false otherwise
 */
export function checkDescendingConsecutivity(quarters) {
    if (quarters.length <= 1)
        return true;
    for (let i=1; i<quarters.length; i++){
       if (prevQuarter(quarters[i-1])!==quarters[i])
           return false;
    }
    return true;
}

/**
 * combine 4 quarterly reports into a 12-month report by summing up applicable data fields from the 4 quarterly results,
 * while taking the latest quarter's data for non-summable fields (e.g. total assets)
 * in case the 4 quarters given in the list are not consecutive quarters, we set the summable fields to 0.
 * @param data The data object containing latest 4 quarterly reports of a company
 * @returns {{}} the combined 12-monthly report
 */
export function combine4Qto12Mdata(comData) {
    const t12mData = {...comData.latest_four[0], robor8_rating: comData.robor8_rating, company_name: comData.name};
    const isConsecutive = checkDescendingConsecutivity(comData.latest_four.map(data => data.quarter));
    for (let field of summableQuarterDataFields)
        if (isConsecutive) {
            const intermediate = comData.latest_four.reduce((total, value) => {
                return total + value[field];
            }, 0);
            t12mData[field] = parseFloat(intermediate.toFixed(2))
        } else
            t12mData[field] = 0;
    return t12mData;
}


export function process4QdataFromServer(data) {
    const result = {};
    for (const comData of Object.values(data)){
        const t12mData = combine4Qto12Mdata(comData);
        const t12mDataCorrected = processReport(t12mData);
        result[comData._id] = {...t12mDataCorrected, lastQuarter: comData.latest_four[0].quarter, industry: comData.industry}
    }
    return result
}

/**
 * flatten the multi level hierachy data store into a flat list of data entries used by Ant Design's Table
 * @param dataStore
 */
export function flattenDataStore(dataStore) {
    let flattened = [];
    for (let [regionKey, regionData] of Object.entries(dataStore))
        for (let [clusterKey, clusterData] of Object.entries(regionData)){
            flattened = [...flattened, ...Object.values(clusterData)];
        }
    flattened = flattened.map(data => {
        return {
            key: data.companyId,
            score: data.score,
            ...data
        }
    });
    return flattened
}


/**
 * Return a new map that is the union of two maps, i.e. contains all region from both maps, and all clusters from
 * same region in both maps.
 * @param map1
 * @param map2
 */
export function mergeRegionClusterMaps(map1, map2){
    return {...map2} // TODO
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}
/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function mapDataToCompanyResultFormat(qdata) {
    return {
        EBIT: qdata.ebit,
        L_T_debt: qdata.longTermDebt,
        acct_payable: qdata.accountPayable,
        acct_receivable: qdata.accountReceivable,
        accum_dep: qdata.accumulatedDepreciation,
        capex: qdata.capex,
        cash: qdata.cashAndEquivalents,
        cash_from_finance: qdata.netCashFromFinancing,
        cash_from_investment: qdata.netCashFromInvesting,
        company: qdata.companyId,
        current_assets: qdata.totalCurrentAssets,
        current_liab: qdata.totalCurrentLiabilities,
        d_and_a: qdata.depreciationAndAmortisation,
        dividend: 0,
        employees: 0,
        fiscal_date: qdata.fiscalDate,
        free_cash_flow: qdata.freeCashFlow,
        gross_interest: qdata.interestExpense,
        gross_profit: qdata.grossProfit,
        intangibles: qdata.intangibles,
        inventory: qdata.inventory,
        issue_L_T_debt: 0,
        net_fixed_asset: qdata.netFixedAsset,
        net_income: qdata.netIncome,
        op_cash_flow: qdata.netCashFromOperating,
        quarter: qdata.quarter,
        repay_long_term_debt: 0,
        report_date: qdata.fiscalDate,
        retained_earnings: qdata.retainedEarnings,
        sale_of_equity: 0,
        sales: qdata.sales,
        short_term_notes: qdata.shortTermDebt,
        total_assets: qdata.totalAssets,
        total_debt: qdata.shortTermDebt+qdata.longTermDebt,
        total_liab: qdata.totalLiabilities,
        roboR8Results: qdata.roboR8Results
    };
}

export function mapCompanyResultToUploadSheetFormat(qdata) {
    return {
        ebit: qdata.EBIT,
        longTermDebt: qdata.L_T_debt,
        accountPayable: qdata.acct_payable,
        accountReceivable: qdata.acct_receivable,
        accumulatedDepreciation: qdata.accum_dep,
        capex: qdata.capex,
        cashAndEquivalents: qdata.cash,
        netCashFromFinancing: qdata.cash_from_finance,
        netCashFromInvesting: qdata.cash_from_investment,
        companyId: qdata.company,
        totalCurrentAssets: qdata.current_assets,
        totalCurrentLiabilities: qdata.current_liab,
        depreciationAndAmortisation: qdata.d_and_a,
        fiscalDate: qdata.fiscal_date,
        freeCashFlow: qdata.free_cash_flow,
        interestExpense: qdata.gross_interest,
        grossProfit: qdata.gross_profit,
        intangibles: qdata.intangibles,
        inventory: qdata.inventory,
        netFixedAsset: qdata.net_fixed_asset,
        netIncome: qdata.net_income,
        netCashFromOperating: qdata.op_cash_flow,
        quarter: qdata.quarter,
        retainedEarnings: qdata.retained_earnings,
        sales: qdata.sales,
        shortTermDebt: qdata.short_term_notes,
        totalAssets: qdata.total_assets,
        totalLiabilities: qdata.total_liab
    };
}
