import React from "react";
import {connect} from 'react-redux';
import {
    Chart,
    Geom,
    Axis,
    Tooltip, Legend,
} from "bizcharts";
import Brush from "@antv/g2-brush";
import {convertRemToPx, mapScoreToRating} from '../utils';


let chart;

class Jitter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            chartSize: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
               
        // new Brush({
        //     canvas: chart.get("canvas"),
        //     chart,
        //     type: "XY",
        //
        //     onBrushstart() {
        //         chart.hideTooltip();
        //     },
        //
        //     onBrushmove() {
        //         chart.hideTooltip();
        //     }
        // });
        // chart.on("plotdblclick", () => {
        //     chart.get("options").filters = {};
        //     chart.repaint();
        // });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let winHeight = window.innerHeight;

        this.setState({ width: window.innerWidth, height: window.innerHeight, chartSize: (winHeight * this.props.height) });
    }


    render() {

        const cols = {
            score: {
            //    tickInterval: 10,
            //    min: 1,
            //    max: 100,
                nice: true
            }
        };

        var i, j, repType;
        let data = [];
        let dataEntry;

        if (this.props.autoCorrectData === true) {
            for (j = this.props.companyData.roboR8peersHistory.length; j >= 1; j--) {
                switch (this.props.companyData.roboR8peersHistory[j - 1].reportQ) {
                    case 0: repType = "Current Financial Report"
                        break;
                    case -1: repType = "Prior Financial Report"
                        break;
                    case -2: repType = "2 Financial Reports Back"
                        break;
                    case -3: repType = "3 Financial Reports Back"
                        break;
                    default:
                }
                if (this.props.companyData.roboR8peersHistory[j - 1].peers === "") {
                    data[0] = [{
                        "Class": repType,
                        "grade": "selected company",
                        "name": "report missing",
                        "score": 100,
                        "Rating": "AAA",
                        "Prob": 1
                    }]
                } else {
                    for (i = 1; i <= this.props.companyData.roboR8peersHistory[j - 1].peers.length; i++) {
                        dataEntry = {
                            "Class": repType,
                            "grade": this.props.companyData.roboR8peersHistory[j - 1].peers[i - 1].grade,
                            "name": this.props.companyData.roboR8peersHistory[j - 1].peers[i - 1].name,
                            "score": this.props.companyData.roboR8peersHistory[j - 1].peers[i - 1].score,
                            "Rating": mapScoreToRating(this.props.companyData.roboR8peersHistory[j - 1].peers[i - 1].rating).migo,
                            "Prob": Math.round(this.props.companyData.roboR8peersHistory[j - 1].peers[i - 1].ratingProb * 100)
                        }
                        data.push(dataEntry);
                    }
                }
            }
        } else {
            data[0] = [{
                "Class": "EMPTY",
                "grade": "selected company",
                "name": "EMPTY",
                "score": 100,
                "Rating": "AAA",
                "Prob": 100
            }]
        }
        const colorMap = this.props.darkMode ? {
            
            /*
            "selected company": "#3f7dd9",
            "match agency rating": "#77C199",
            "within +/-1 notch": "#FCD596",
            "differs from agency": "#EC837A",
            "unrated by agency": "#A4ADBA",
            */

            "selected company": "#6e94f4",
            "match agency rating": "#2ED47A",
            "within +/-1 notch": "#FFB946",
            "differs from agency": "#F7685B",
            "unrated by agency": "#D1D1D1",
        } :
            {
                "selected company": "#1b62cc",
                "match agency rating": "#2ED47A",
                "within +/-1 notch": "#FFB946",
                "differs from agency": "#F7685B",
                "unrated by agency": "#D1D1D1",
            };

        return (
            <div className="scatterplot-chart">
                <Chart
                    height={window.innerHeight * this.props.height}
                    onGetG2Instance={this.handleGetG2Instance}
                    padding="auto" data={data} autoFit
                    scale={cols}
                    visible={this.props.activeTabKey === this.props.underTabKey}
                >
                    <Legend visible={false} />
                    <Tooltip
                        showCrosshairs={true}
                        crosshairs={{
                            type: "xy",
                            style: {
                                lineDash: [1, 1]
                            }
                        }}
                    />
                    <Axis
                        name="score"
                        grid={{
                            line: {
                                style: {
                                    stroke: this.props.darkMode ? '#4F4F4F' : '#E5E5E5',
                                    lineWidth: 1,
                                    lineDash: [0, 0]
                                }
                            }
                        }}
                        label={{
                            style: {
                                fill: this.props.darkMode ? '#A3ABBD' : '#192A3E', // 文本的颜色
                                fontSize: convertRemToPx(this.props.rootFontSize), // 文本大小
                            }
                        }}
                    />
                    <Axis
                        name="Class"
                        tickLine={null}
                        subTickLine={{
                            lineWidth: 1,
                            stroke: "#BFBFBF",
                            length: 4
                        }}
                        grid={{
                            line: {
                                style: {
                                    stroke: this.props.darkMode ? '#4F4F4F': '#E5E5E5',
                                    lineWidth: 1,
                                    lineDash: [0, 0]
                                }
                            },
                            alignTick: false,
                        }}
                        label={{
                            style: {
                                fill: this.props.darkMode ? '#A3ABBD': '#192A3E',
                                fontSize: convertRemToPx(this.props.rootFontSize), // 文本大小
                            }
                        }}
                        title={false}
                    />
                    <Geom
                        type="point"
                        position="Class*score"
                        color={[
                            "grade",
                            function (grade) {
                                return colorMap[grade];
                            }
                        ]}                        
                        size={3}
                        tooltip="grade*name*score*Rating*Prob"
                        opacity={0.65}
                        shape="circle"
                        style={['grade', (val) => {
                            return {
                                cursor: 'pointer',
                                fillOpacity: 0.65,
                                opacity: 0.65,
                                stoke: colorMap[val],
                            };
                        }]}
                        adjust="jitter"
                    />
                </Chart>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        darkMode: state.darkModeReducer.darkMode,
        companyData: state.companyDataReducer.companyData,
      rootFontSize: state.globalStyleReducer.rootFontSize,
      autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
      activeTabKey: state.activeTabReducer.key
    }
}

export default connect(mapStateToProps,null)(Jitter);
