import { convertToDecimal } from './';

export function checkAplusB(A, Astatus, B, Bstatus, C, Cstatus) {
//Function for auto-correct when A+B = C. Free variable is B    
    const autoCorrectRes = { 
        Aval:A, 
        Astat:Astatus,
        Bval:B, 
        Bstat:Bstatus,
        Cval:C, 
        Cstat:Cstatus
    };

    if(!isNaN(A) && !isNaN(B) && !isNaN(C)){
        Astatus = '';
        Bstatus = 'fixed';
        Cstatus = '';
        //console.log('All have a value')
        B = C - A;
    } else if (isNaN(A) && !isNaN(B) && !isNaN(C)) {
        Astatus = 'fixed';
        Bstatus = '';
        Cstatus = '';
        //console.log('A has no value')
        A = C - B;
    } else if (!isNaN(A) && isNaN(B) && !isNaN(C)) {
        Astatus = '';
        Bstatus = 'fixed';
        Cstatus = '';
        //console.log('B has no value')
        B = C - A;
    } else if(!isNaN(A) && !isNaN(B) && isNaN(C)){
        Astatus = '';
        Bstatus = '';
        Cstatus = 'fixed';
        //console.log('C has no value')
        C = A + B;
    } else if (!isNaN(A) && isNaN(B) && isNaN(C)) {
        Astatus = '';
        Bstatus = 'fixed';
        Cstatus = 'fixed';
        //console.log('B and C have no value')
        B = convertToDecimal(0);
        C = A;
    } else if(isNaN(A) && !isNaN(B) && isNaN(C)){
        Astatus = 'fixed';
        Bstatus = '';
        Cstatus = 'fixed';
        //console.log('A and C have no value')
        A = convertToDecimal(0);
        C = B;
    } else if(isNaN(A) && isNaN(B) && !isNaN(C)){
        Astatus = 'fixed';
        Bstatus = 'fixed';
        Cstatus = '';
        //console.log('A and B have no value')
        A = C;
        B = convertToDecimal(0);
    } else if(isNaN(A) && isNaN(B) && isNaN(C)){
        Astatus = 'fixed';
        Bstatus = 'fixed';
        Cstatus = 'fixed';
        //console.log('A, B and C have no value')
        A = convertToDecimal(0);
        B = convertToDecimal(0);
        C = convertToDecimal(0);
    }
    autoCorrectRes.Aval = A; autoCorrectRes.Astat = Astatus;
    autoCorrectRes.Bval = B; autoCorrectRes.Bstat = Bstatus;
    autoCorrectRes.Cval = C; autoCorrectRes.Cstat = Cstatus;

    return autoCorrectRes;
}

export function checkAminusB(A, Astatus, B, Bstatus, C, Cstatus){
//Function for auto-correct when A-B = C. Free variable is B    
    const autoCorrectRes = { 
        Aval:A, 
        Astat:Astatus,
        Bval:B, 
        Bstat:Bstatus,
        Cval:C, 
        Cstat:Cstatus
    };

    if(!isNaN(A) && !isNaN(B) && !isNaN(C)){
        Astatus = '';
        Bstatus = 'fixed';
        Cstatus = '';
        //console.log('All have a value')
        B = A - C;
    } else if(isNaN(A) && !isNaN(B) && !isNaN(C)){
        Astatus = 'fixed';
        Bstatus = '';
        Cstatus = '';
        //console.log('A has no value')
        A = C + B;
    } else if (!isNaN(A) && isNaN(B) && !isNaN(C)) {
        Astatus = '';
        Bstatus = 'fixed';
        Cstatus = '';
        //console.log('B has no value')
        B = A - C;
    } else if (!isNaN(A) && !isNaN(B) && isNaN(C)) {
        Astatus = '';
        Bstatus = '';
        Cstatus = 'fixed';
        //console.log('C has no value')
        C = A - B;
    } else if (!isNaN(A) && isNaN(B) && isNaN(C)) {
        Astatus = '';
        Bstatus = 'fixed';
        Cstatus = 'fixed';
        //console.log('B and C have no value')
        B = convertToDecimal(0);
        C = A;
    } else if(isNaN(A) && !isNaN(B) && isNaN(C)){
        Astatus = 'fixed';
        Bstatus = '';
        Cstatus = 'fixed';
        //console.log('A and C have no value')
        A = convertToDecimal(0);
        C = A - B;
    } else if(isNaN(A) && isNaN(B) && !isNaN(C)){
        Astatus = 'fixed';
        Bstatus = 'fixed';
        Cstatus = '';
        //console.log('A and B have no value')
        A = C;
        B = convertToDecimal(0);
    } else if(isNaN(A) && isNaN(B) && isNaN(C)){
        Astatus = 'fixed';
        Bstatus = 'fixed';
        Cstatus = 'fixed';
        //console.log('A, B and C have no value')
        A = convertToDecimal(0);
        B = convertToDecimal(0);
        C = convertToDecimal(0);
    }
    autoCorrectRes.Aval = A; autoCorrectRes.Astat = Astatus;
    autoCorrectRes.Bval = B; autoCorrectRes.Bstat = Bstatus;
    autoCorrectRes.Cval = C; autoCorrectRes.Cstat = Cstatus;

    return autoCorrectRes;
}

export function autoCorrectIncomeStatement(ISdata)
{
    const autoCorrectIS = ISdata;
   
    let autoCorrectCheck = {Aval : '', Astat : '', Bval : '', Bstat : '', Cval : '', Cstat : ''};

    let tempVal = 1;
    let tempStat = "1";
    
    //Gross Profit = Sales - Cost of Goods Sold
    let grossProfitCal = autoCorrectIS.qSalesValue - autoCorrectIS.costGoodsSoldValue;
    let grossProfit = autoCorrectIS.qGrossProfitValue;

    if(grossProfitCal !== grossProfit || isNaN(grossProfitCal) || isNaN(grossProfit)){
                  
        //Gross Profit = Sales - Cost of Goods Sold (A = sales, B = Cost of Goods Sold, C = Gross Profit: A-B=C)
        autoCorrectCheck = checkAminusB(autoCorrectIS.qSalesValue, autoCorrectIS.checkSalesValueStatus, autoCorrectIS.costGoodsSoldValue, autoCorrectIS.checkCostGoodsSoldStatus, autoCorrectIS.qGrossProfitValue, autoCorrectIS.checkGrossProfitStatus);
                  
        autoCorrectIS.qSalesValue = autoCorrectCheck.Aval; autoCorrectIS.checkSalesValueStatus = autoCorrectCheck.Astat;
        autoCorrectIS.costGoodsSoldValue = autoCorrectCheck.Bval; autoCorrectIS.checkCostGoodsSoldStatus = autoCorrectCheck.Bstat;                 
        autoCorrectIS.qGrossProfitValue = autoCorrectCheck.Cval; autoCorrectIS.checkGrossProfitStatus = autoCorrectCheck.Cstat;
    } else{
        //Reset checks to OK
        autoCorrectIS.checkGrossProfitStatus = ''; autoCorrectIS.checkGrossProfitLog = '';
        autoCorrectIS.checkSalesValueStatus = ''; autoCorrectIS.checkSalesValueLog = '';
        autoCorrectIS.checkCostGoodsSoldStatus = ''; autoCorrectIS.checkCostGoodsSoldLog = '';
    }

    //Refresh EBIT = Gross Profit - S,G and A - Depreciation and Amortization
    let EBITCal = autoCorrectIS.qGrossProfitValue- autoCorrectIS.sGandAValue - autoCorrectIS.qDandAValue;
    let EBIT = autoCorrectIS.qEBITValue;

    if(EBITCal !== EBIT || isNaN(EBITCal) || isNaN(EBIT)){

        //EBIT = Gross Profit - SGA - Depreciation and Amortization (A = Depreciation and Amortisation, B = SGA, C = [Gross Profit - EBIT]: A+B=C)
        tempVal = autoCorrectIS.qGrossProfitValue - autoCorrectIS.qEBITValue;
               
        autoCorrectCheck = checkAplusB(autoCorrectIS.qDandAValue, autoCorrectIS.checkDandAStatus, autoCorrectIS.sGandAValue, autoCorrectIS.checkSGandAStatus, tempVal, tempStat);
                  
        autoCorrectIS.qDandAValue = autoCorrectCheck.Aval; autoCorrectIS.checkDandAStatus = autoCorrectCheck.Astat;
        autoCorrectIS.sGandAValue = autoCorrectCheck.Bval; autoCorrectIS.checkSGandAStatus = autoCorrectCheck.Bstat;        
        autoCorrectIS.qEBITValue = convertToDecimal(autoCorrectIS.qGrossProfitValue) - convertToDecimal(autoCorrectCheck.Cval); autoCorrectIS.checkEBITStatus = autoCorrectCheck.Cstat;   
    }else{
        //Reset checks to OK
        autoCorrectIS.checkSGandAStatus = ''; autoCorrectIS.checkSGandALog = '';
        autoCorrectIS.checkDandAStatus = ''; autoCorrectIS.checkDandALog = '';
        autoCorrectIS.checkEBITStatus = ''; autoCorrectIS.checkEBITLog = '';
    } 

    //Refresh Earnings before Tax - EBIT - Gross Interest
    let EBTCal = autoCorrectIS.qEBITValue - autoCorrectIS.qInt;
    let EBT = autoCorrectIS.earningBeforeIncomeTaxesValue;

    if (EBTCal !== EBT || isNaN(EBTCal) || isNaN(EBT)) {

        //EBT = EBIT - Gross Interest (A = Gross Interest, B = EBT, C = EBIT: A + B = C)
        autoCorrectCheck = checkAplusB(autoCorrectIS.qGrossInterestValue, autoCorrectIS.checkGrossInterestStatus, autoCorrectIS.earningBeforeIncomeTaxesValue, autoCorrectIS.checkearningBeforeIncomeTaxesStatus, autoCorrectIS.qEBITValue, autoCorrectIS.checkEBITStatus)

        autoCorrectIS.earningBeforeIncomeTaxesValue = autoCorrectCheck.Bval; autoCorrectIS.checkEarningBeforeIncomeTaxesStatus = autoCorrectCheck.Bstat;
        autoCorrectIS.qGrossInterestValue = autoCorrectCheck.Aval; autoCorrectIS.checkGrossInterestStatus = autoCorrectCheck.Astat;
        autoCorrectIS.qEBITValue = autoCorrectCheck.Cval; autoCorrectIS.checkEBITStatus = autoCorrectCheck.Cstat;    
        
    } else{

        //Reset checks to OK
        autoCorrectIS.checkGrossInterestStatus = ''; autoCorrectIS.checkGrossInterestLog = '';
        autoCorrectIS.checkEarningBeforeIncomeTaxesStatus = ''; autoCorrectIS.checkEarningBeforeIncomeTaxesLog = '';
        autoCorrectIS.checkEBITStatus = ''; autoCorrectIS.checkEBITLog = '';
    } 

    //Refresh NetIncome = EBT - Income Tax
    let netIncomeCal = autoCorrectIS.earningBeforeIncomeTaxesValue - autoCorrectIS.incomeTaxExpenseValue;
    let netIncome = autoCorrectIS.qNetIncomeValue;
          
    if(netIncomeCal !== netIncome || isNaN(netIncomeCal) || isNaN(netIncome)){

        //Net Income = EBT - Income Taxes (A = EBT, B = Income Taxes, C = Net Income: A - B = C)
        autoCorrectCheck = checkAminusB(autoCorrectIS.earningBeforeIncomeTaxesValue, autoCorrectIS.checkEarningBeforeIncomeTaxesStatus, autoCorrectIS.incomeTaxExpenseValue, autoCorrectIS.checkIncomeTaxExpenseStatus, autoCorrectIS.qNetIncomeValue, autoCorrectIS.checkNetIncomeStatus)

        autoCorrectIS.earningBeforeIncomeTaxesValue = autoCorrectCheck.Aval; autoCorrectIS.checkEarningBeforeIncomeTaxesStatus = autoCorrectCheck.Astat; 
        autoCorrectIS.incomeTaxExpenseValue = autoCorrectCheck.Bval; autoCorrectIS.checkIncomeTaxExpenseStatus = autoCorrectCheck.Bstat;
        autoCorrectIS.qNetIncomeValue = autoCorrectCheck.Cval; autoCorrectIS.checkNetIncomeStatus = autoCorrectCheck.Cstat;
    }else{

        //Reset checks to OK
        autoCorrectIS.checkEarningBeforeIncomeTaxesStatus = ''; autoCorrectIS.checkEarningBeforeIncomeTaxesLog = ''
        autoCorrectIS.checkIncomeTaxExpenseStatus = ''; autoCorrectIS.checkIncomeTaxExpenseLog = ''        
        autoCorrectIS.checkNetIncomeStatus = ''; autoCorrectIS.checkNetIncomeLog = ''
    }
    return autoCorrectIS;
}

export function autoCorrectCashFlowStatement(CFdata)
{
    const autoCorrectCF = CFdata;

    let autoCorrectCheck = {Aval : '', Astat : '', Bval : '', Bstat : '', Cval : '', Cstat : ''};

    let tempVal1 = 1;
    let tempVal2 = 1;
    let tempStat = "1";

    // Refresh opCashFlow = Net Income + DandA + Incr in Other Items
    let opCashFlowCal = autoCorrectCF.qNetIncomeValue + autoCorrectCF.qDandAValue + autoCorrectCF.increaseDecreaseInOtherItemsValue;
    let opCashFlow = autoCorrectCF.qOpCashFlowValue;

    if( (opCashFlowCal !== opCashFlow) || isNaN(opCashFlowCal) || isNaN(opCashFlow)){ 

        //opCashFlow = Net Income + DandA + IncreaseInOtherItems (A = DandA, B = IncreaseInOtherItems, C = opCashFlow-NetIncome: A + B = C)
        tempVal1 = autoCorrectCF.qOpCashFlowValue - autoCorrectCF.qNetIncomeValue;
        autoCorrectCheck = checkAplusB(autoCorrectCF.qDandAValue, autoCorrectCF.checkDandAStatus, autoCorrectCF.increaseDecreaseInOtherItemsValue, autoCorrectCF.checkIncreaseDecreaseInOtherItemsStatus, tempVal1, tempStat)
                 
        autoCorrectCF.qDandAValue = autoCorrectCheck.Aval; autoCorrectCF.checkDandAStatus = autoCorrectCheck.Astat;
        autoCorrectCF.increaseDecreaseInOtherItemsValue = autoCorrectCheck.Bval; autoCorrectCF.checkIncreaseDecreaseInOtherItemsStatus = autoCorrectCheck.Bstat;
        autoCorrectCF.qOpCashFlowValue = autoCorrectCheck.Cval + autoCorrectCF.qNetIncomeValue; autoCorrectCF.checkOpCashFlowStatus = autoCorrectCheck.Cstat;
    } else{
        //Reset checks to OK
        autoCorrectCF.checkOpCashFlowStatus = ''; autoCorrectCF.checkOpCashFlowLog = ''
        autoCorrectCF.checkIncreaseDecreaseInOtherItemsStatus = ''; autoCorrectCF.checkIncreaseDecreaseInOtherItemsLog = ''
    }

    //Cash From Investing = - Capex + Invest in Other Assets
    let cashFromInvestCal = - autoCorrectCF.qCapexValue + autoCorrectCF.investmentsInOtherAssetsValue;
    let cashFromInvest = autoCorrectCF.qCashFromInvestValue;

    if( (cashFromInvestCal !== cashFromInvest) || isNaN(cashFromInvestCal) || isNaN(cashFromInvest)){
                  
        //Cash From Investing = - Capex + Invest In Other Assets (A = - Capex, B = Invest In Other Assets, C = Cash From Investing: A + B = C)
        autoCorrectCheck = checkAplusB(-autoCorrectCF.qCapexValue, autoCorrectCF.checkCapexStatus, autoCorrectCF.investmentsInOtherAssetsValue, autoCorrectCF.checkInvestmentsInOtherAssetsStatus, autoCorrectCF.qCashFromInvestValue, autoCorrectCF.checkCashFromInvestStatus)

        autoCorrectCF.qCapexValue = -autoCorrectCheck.Aval; autoCorrectCF.checkCapexStatus = autoCorrectCheck.Astat;
        autoCorrectCF.investmentsInOtherAssetsValue = autoCorrectCheck.Bval; autoCorrectCF.checkInvestmentsInOtherAssetsStatus = autoCorrectCheck.Bstat;
        autoCorrectCF.qCashFromInvestValue = autoCorrectCheck.Cval; autoCorrectCF.checkCashFromInvestStatus = autoCorrectCheck.Cstat;
    } else{
        //Reset checks to OK
        autoCorrectCF.checkCapexStatus = ''; autoCorrectCF.checkCapexLog = ''
        autoCorrectCF.checkInvestmentsInOtherAssetsStatus = ''; autoCorrectCF.checkInvestmentsInOtherAssetsLog = ''
        autoCorrectCF.checkCashFromInvestStatus = ''; autoCorrectCF.checkCashFromInvestLog = ''
    }

    //Cash From Finance = Dividends + Sale of Equity + Issue Debt - Repay Debt + Other Finance
    let cashFromFinanceCal = autoCorrectCF.qDividendValue + autoCorrectCF.SaleOfEQVal + autoCorrectCF.IssueDebtval - autoCorrectCF.qRepayLTDebtValue + autoCorrectCF.otherFinancingActivitiesValue;
    let cashFromFinance = autoCorrectCF.qCashFromFinanceValue;

    if( (cashFromFinanceCal !== cashFromFinance) || isNaN(cashFromFinanceCal) || isNaN(cashFromFinance)){

        //Cash From Finance = Div + Equity + Issue - Repay + Other (A = Issue - Repay, B = Div + Equity + Other, C = Cash From Finance: A + B = C)
        tempVal1 = autoCorrectCF.qIssueLTDebtValue - autoCorrectCF.qRepayLTDebtValue;
        tempVal2 = autoCorrectCF.qDividendValue + autoCorrectCF.qSaleOfEquityValue + autoCorrectCF.otherFinancingActivitiesValue;
        autoCorrectCheck = checkAplusB(tempVal1, autoCorrectCF.checkIssueLTDebtStatus, tempVal2, autoCorrectCF.checkSaleOfEquityStatus, autoCorrectCF.qCashFromFinanceValue, autoCorrectCF.checkCashFromFinanceStatus);

        if (autoCorrectCheck.Astat === 'fixed'){
            if(!isNaN(autoCorrectCF.qIssueLTDebtValue)){
                autoCorrectCF.checkIssueLTDebtStatus = '';
                autoCorrectCF.qRepayLTDebtValue = autoCorrectCF.qIssueLTDebtValue - autoCorrectCheck.Aval;
                autoCorrectCF.checkRepayLTDebtStatus = 'fixed';
            } else if (!isNaN(autoCorrectCF.qRepayLTDebtValue)){
                autoCorrectCF.checkRepayLTDebtStatus = '';
                autoCorrectCF.qIssueLTDebtValue = autoCorrectCheck.Aval + autoCorrectCF.qRepayLTDebtValue;
                autoCorrectCF.checkIssueLTDebtStatus = 'fixed';
            } else if (autoCorrectCheck.Aval >= 0){
                autoCorrectCF.qIssueLTDebtValue = autoCorrectCheck.Aval;
                autoCorrectCF.qRepayLTDebtValue = 0;
                autoCorrectCF.checkIssueLTDebtStatus = 'fixed';
                autoCorrectCF.checkRepayLTDebtStatus = 'fixed';
            } else{
                autoCorrectCF.qRepayLTDebtValue = - autoCorrectCheck.Aval;
                autoCorrectCF.qIssueLTDebtValue = 0;
                autoCorrectCF.checkIssueLTDebtStatus = 'fixed';
                autoCorrectCF.checkRepayLTDebtStatus = 'fixed';
            }
        } else{
            autoCorrectCF.checkIssueLTDebtStatus = '';
            autoCorrectCF.checkRepayLTDebtStatus = '';
        }

        if (autoCorrectCheck.Bstat === 'fixed'){
            autoCorrectCF.checkOtherFinancingActivitiesStatus = 'fixed';
            if (isNaN(autoCorrectCF.qDividendValue)){
                autoCorrectCF.qDividendValue = 0;
                autoCorrectCF.checkDividendStatus = 'fixed';
            } else {
                autoCorrectCF.checkDividendStatus = '';     
            }
            if (isNaN(autoCorrectCF.qSaleOfEquityValue)){
                autoCorrectCF.qSaleOfEquityValue = 0; 
                autoCorrectCF.checkSaleOfEquityStatus = 'fixed';
            } else autoCorrectCF.checkSaleOfEquityStatus = '';
        } else{
            autoCorrectCF.checkDividendStatus = '';
            autoCorrectCF.checkSaleOfEquityStatus = '';
            autoCorrectCF.checkOtherFinancingActivitiesStatus = '';
        }

        autoCorrectCF.otherFinancingActivitiesValue = autoCorrectCheck.Bval - autoCorrectCF.qSaleOfEquityValue - autoCorrectCF.qDividendValue;
                                     
        autoCorrectCF.qCashFromFinanceValue = autoCorrectCheck.Cval; autoCorrectCF.checkCashFromFinanceStatus = autoCorrectCheck.Cstat;
    } else{
        //Reset checks to OK
        autoCorrectCF.checkCashFromFinanceStatus = ''; autoCorrectCF.checkCashFromFinanceLog = ''
        autoCorrectCF.checkDividendStatus = ''; autoCorrectCF.checkDividendLog = ''
        autoCorrectCF.checkSaleOfEquityStatus = ''; autoCorrectCF.checkSaleOfEquityLog = ''
        autoCorrectCF.checkIssueLTDebtStatus = ''; autoCorrectCF.checkIssueLTDebtLog = ''
        autoCorrectCF.checkRepayLTDebtStatus = ''; autoCorrectCF.checkRepayLTDebtLog = ''
        autoCorrectCF.checkOtherFinancingActivitiesStatus = ''; autoCorrectCF.checkOtherFinancingActivitiesLog = ''  
    }

    autoCorrectCF.netIncreaseInCashAndEquivalentsValue = autoCorrectCF.qOpCashFlowValue + autoCorrectCF.qCashFromInvestValue + autoCorrectCF.qCashFromFinanceValue;
    autoCorrectCF.checkNetIncreaseInCashAndEquivalentsStatus = ''; autoCorrectCF.checkNetIncreaseInCashAndEquivalentsLog = '';

    return autoCorrectCF;
}

export function autoCorrectBalanceSheet(BSdata) {

    const autoCorrectBS = BSdata;

    let autoCorrectCheck = { Aval: '', Astat: '', Bval: '', Bstat: '', Cval: '', Cstat: '' };

    let tempVal1 = 1;
    let tempVal2 = 1;
    let tempStat = "1";

    //Check that accounts payable and accounts receivable are of the correct sign
    if (autoCorrectBS.qAcctReceivableValue < 0) {
        autoCorrectBS.qAcctPayableValue -= autoCorrectBS.qAcctReceivableValue;
        autoCorrectBS.prepaidExpensesValue += autoCorrectBS.qAcctReceivableValue;
        autoCorrectBS.qAcctReceivableValue = 0;
        autoCorrectBS.checkAcctReceivableStatus = 'fixed';
        autoCorrectBS.checkAcctPayableStatus = 'fixed';
    }
    if (autoCorrectBS.qAcctPayableValue < 0) {
        autoCorrectBS.qAcctReceivableValue -= autoCorrectBS.qAcctPayableValue;
        autoCorrectBS.otherCurrentLiabilitiesValue += autoCorrectBS.qAcctPayableValue;
        autoCorrectBS.qAcctPayableValue = 0;
        autoCorrectBS.checkAcctReceivableStatus = 'fixed';
        autoCorrectBS.checkAcctPayableStatus = 'fixed';
    }

    //Total Current Assets = Cash + Accts Rec + Inventory + Prepaid Exp
    let currentAssetsCal = autoCorrectBS.qCashValue + autoCorrectBS.qAcctReceivableValue + autoCorrectBS.qInventoryValue + autoCorrectBS.prepaidExpensesValue;
    let currentAssets = autoCorrectBS.qCurrentAssetsValue;

    if ((currentAssetsCal !== currentAssets) || isNaN(currentAssetsCal) || isNaN(currentAssets)) {

        //Current Assets = Cash + Accts Rec + Inventory + Prepaid Exp (A = Cash + AcctRec, B = Inventory + Prepaid, C = Current Assets: A + B = C)
        tempVal1 = autoCorrectBS.qCashValue + autoCorrectBS.qAcctReceivableValue;
        tempVal2 = autoCorrectBS.qInventoryValue + autoCorrectBS.prepaidExpensesValue;

        autoCorrectCheck = checkAplusB(tempVal1, tempStat, tempVal2, tempStat, autoCorrectBS.qCurrentAssetsValue, autoCorrectBS.checkCurrentAssetsStatus);

        if (autoCorrectCheck.Astat === 'fixed'){
            if (!isNaN(autoCorrectBS.qCashValue)) {
                autoCorrectBS.checkCashStatus = '';
                autoCorrectBS.qAcctReceivableValue = autoCorrectCheck.Aval - autoCorrectBS.qCashValue;
                autoCorrectBS.checkAcctReceivableStatus = 'fixed';        
            } else if (!isNaN(autoCorrectBS.qAcctReceivableValue)) {
                autoCorrectBS.checkAcctReceivableStatus = '';
                autoCorrectBS.qCashValue = autoCorrectCheck.Aval - autoCorrectBS.qAcctReceivableValue;
                autoCorrectBS.checkCashStatus = 'fixed';
            } else if (autoCorrectCheck.Aval >= 0) {
                autoCorrectBS.qCashValue = autoCorrectCheck.Aval;
                autoCorrectBS.qAcctReceivableValue = 0;
                autoCorrectBS.checkCashStatus = 'fixed';
                autoCorrectBS.checkAcctReceivableStatus = 'fixed';        
            } else {
                autoCorrectBS.qAcctReceivableValue = autoCorrectCheck.Aval;
                autoCorrectBS.qCashValue = 0;     
                autoCorrectBS.checkCashStatus = 'fixed';
                autoCorrectBS.checkAcctReceivableStatus = 'fixed';        
            }
        } else{
            autoCorrectBS.checkCashStatus = '';
            autoCorrectBS.checkAcctReceivableStatus = '';
        }

        if (autoCorrectCheck.Bstat === 'fixed'){        
            if (!isNaN(autoCorrectBS.qInventoryValue)) {
                autoCorrectBS.checkInventoryStatus = '';
                autoCorrectBS.prepaidExpensesValue = autoCorrectCheck.Bval - autoCorrectBS.qInventoryValue;
                autoCorrectBS.checkPrepaidExpensesStatus = 'fixed';
            } else if (!isNaN(autoCorrectBS.prepaidExpensesValue)) {
                autoCorrectBS.checkPrepaidExpensesStatus = '';
                autoCorrectBS.qInventoryValue = autoCorrectCheck.Bval - autoCorrectBS.prepaidExpensesValue;
                autoCorrectBS.checkInventoryStatus = 'fixed';
            } else if (autoCorrectCheck.Aval >= 0) {
                autoCorrectBS.qInventoryValue = autoCorrectCheck.Bval;
                autoCorrectBS.prepaidExpensesValue = 0;
                autoCorrectBS.checkInventoryStatus = 'fixed';
                autoCorrectBS.checkPrepaidExpensesStatus = 'fixed';
            } else {
                autoCorrectBS.prepaidExpensesValue = autoCorrectCheck.Bval;
                autoCorrectBS.qInventoryValue = 0;
                autoCorrectBS.checkInventoryStatus = 'fixed';
                autoCorrectBS.checkPrepaidExpensesStatus = 'fixed';
            }
        } else{
            autoCorrectBS.checkInventoryStatus = '';
            autoCorrectBS.checkPrepaidExpensesStatus = '';
        }
        
        autoCorrectBS.qCurrentAssetsValue = autoCorrectCheck.Cval; autoCorrectBS.checkCurrentAssetsStatus = autoCorrectCheck.Cstat;

        //Check that accounts receivable are of the correct sign, otherwise assign to payable
        if (autoCorrectBS.qAcctReceivableValue < 0) {
            autoCorrectBS.qAcctPayableValue -= autoCorrectBS.qAcctReceivableValue; autoCorrectBS.checkAcctPayableStatus = 'fixed';
            autoCorrectBS.prepaidExpensesValue += autoCorrectBS.qAcctReceivableValue; autoCorrectBS.checkPrepaidExpensesStatus = 'fixed';
            autoCorrectBS.qAcctReceivableValue = 0; autoCorrectBS.checkAcctReceivableStatus = 'fixed';
        }
    } else {
        //Reset checks to OK 

        autoCorrectBS.checkCashStatus = ''; autoCorrectBS.checkCashLog = ''; 
        autoCorrectBS.checkAcctReceivableStatus = ''; autoCorrectBS.checkAcctReceivableLog = '';
        autoCorrectBS.checkInventoryStatus = ''; autoCorrectBS.checkInventoryLog = '';
        autoCorrectBS.checkPrepaidExpensesStatus = ''; autoCorrectBS.checkPrepaidExpensesLog = '';
        autoCorrectBS.checkCurrentAssetsStatus = ''; autoCorrectBS.checkCurrentAssetsLog = '';
    }
    //Net Fixed Asset = PPE - Accum Dep
    let netFixedAssetCal = autoCorrectBS.propertyPlantAndEquipmentValue - autoCorrectBS.qAccumDepValue;
    let netFixedAsset = autoCorrectBS.qNetFixedAssetValue;

    if ((netFixedAssetCal !== netFixedAsset) || isNaN(netFixedAssetCal) || isNaN(netFixedAsset)) {

        //Net Fixed Asset = PPE - Accum Dep (A = PPE, B = Accum Dep, C = Net Fixed: A-B=C)
        autoCorrectCheck = checkAminusB(autoCorrectBS.propertyPlantAndEquipmentValue, autoCorrectBS.checkPropertyPlantAndEquipmentStatus, autoCorrectBS.qAccumDepValue, autoCorrectBS.checkAccumDepStatus, autoCorrectBS.qNetFixedAssetValue, autoCorrectBS.checkNetFixedAssetStatus);

        autoCorrectBS.propertyPlantAndEquipmentValue = autoCorrectCheck.Aval; autoCorrectBS.checkPropertyPlantAndEquipmentStatus = autoCorrectCheck.Astat;
        autoCorrectBS.qAccumDepValue = autoCorrectCheck.Bval; autoCorrectBS.checkAccumDepStatus = autoCorrectCheck.Bstat;
        autoCorrectBS.qNetFixedAssetValue = autoCorrectCheck.Cval; autoCorrectBS.checkNetFixedAssetStatus = autoCorrectCheck.Cstat;
    } else {
        //Reset checks to OK

        autoCorrectBS.checkPropertyPlantAndEquipmentStatus = ''; autoCorrectBS.checkPropertyPlantAndEquipmentLog = '';
        autoCorrectBS.checkAccumDepStatus = ''; autoCorrectBS.checkAccumDepLog = '';
        autoCorrectBS.checkNetFixedAssetStatus = ''; autoCorrectBS.checkNetFixedAssetLog = '';
    }

    if (isNaN(autoCorrectBS.qIntangiblesValue) || autoCorrectBS.qIntangiblesValue < 0) {
        autoCorrectBS.qIntangiblesValue = 0; autoCorrectBS.checkIntangiblesStatus = 'fixed';
    }
    //Total Assets = Total Current Assets + Total Fixed Assets + Other Assets
    let totalAssetsCal = autoCorrectBS.qCurrentAssetsValue + autoCorrectBS.qNetFixedAssetValue + autoCorrectBS.totalOtherAssetsValue;
    let totalAssets = autoCorrectBS.qTotalAssetsValue;

    if ((totalAssetsCal !== totalAssets) || isNaN(totalAssetsCal) || isNaN(totalAssets)) {

        //Total Assets = Current Assets + Total Fixed + Other (A = Current, B = Total Fixed + Other, C = Total Assets: A+B=C)
        tempVal1 = autoCorrectBS.qNetFixedAssetValue + autoCorrectBS.totalOtherAssetsValue;
        autoCorrectCheck = checkAplusB(autoCorrectBS.qCurrentAssetsValue, autoCorrectBS.checkCurrentAssetsStatus, tempVal1, tempStat, autoCorrectBS.qTotalAssetsValue, autoCorrectBS.checkTotalAssetsStatus);

        if (!isNaN(autoCorrectBS.qNetFixedAssetValue)) {
            autoCorrectBS.NetFixsStat = '';
            autoCorrectBS.totalOtherAssetsValue = autoCorrectCheck.Bval - autoCorrectBS.qNetFixedAssetValue; autoCorrectBS.checkTotalOtherAssetsStatus = autoCorrectCheck.Bstat;
        } else if (!isNaN(autoCorrectBS.totalOtherAssetsValue)) {
            autoCorrectBS.checkTotalOtherAssetsStatus = '';
            autoCorrectBS.qNetFixedAssetValue = autoCorrectCheck.Bval - autoCorrectBS.totalOtherAssetsValue; autoCorrectBS.checkNetFixedAssetStatus = autoCorrectCheck.Bstat;
        } else {
            autoCorrectBS.qNetFixedAssetValue = autoCorrectCheck.Bval;
            autoCorrectBS.totalOtherAssetsValue = convertToDecimal(0);
            autoCorrectBS.checkNetFixedAssetStatus = autoCorrectCheck.Bstat;;
            autoCorrectBS.checkTotalOtherAssetsStatus = autoCorrectCheck.Bstat;;
        }

        autoCorrectBS.qCurrentAssetsValue = autoCorrectCheck.Aval; autoCorrectBS.checkCurrentAssetsStatus = autoCorrectCheck.Astat;
        autoCorrectBS.qTotalAssetsValue = autoCorrectCheck.Cval; autoCorrectBS.checkTotalAssetsStatus = autoCorrectCheck.Cstat;
    } else {
        //Reset checks to OK

        autoCorrectBS.checkCurrentAssetsStatus = ''; autoCorrectBS.checkCurrentAssetsLog = '';
        autoCorrectBS.checkNetFixedAssetStatus = ''; autoCorrectBS.checkNetFixedAssetLog = '';
        autoCorrectBS.checkTotalOtherAssetsStatus = ''; autoCorrectBS.checkTotalOtherAssetsLog = '';
        autoCorrectBS.checkTotalAssetsStatus = ''; autoCorrectBS.checkTotalAssetsLog = '';
    }
    //Total Current Liab = Accts Pay + STD + Other Current Liab + Other ST Liab
    let currentLiabCal = autoCorrectBS.qAcctPayableValue + autoCorrectBS.otherCurrentLiabilitiesValue + autoCorrectBS.qShortTermNotesValue + autoCorrectBS.otherShortTermLiabValue;
    let currentLiab = autoCorrectBS.qCurrentLiabValue;

    if ((currentLiabCal !== currentLiab) || isNaN(currentLiabCal) || isNaN(currentLiab)) {

        //Current Liab = STD + Accts Pay + Other Current Liab + Other ST Liab (A = STD + AcctPay, B = Other ST + Other Liab, C = Current Liab: A + B = C)
        tempVal1 = autoCorrectBS.qShortTermNotesValue + autoCorrectBS.qAcctPayableValue;
        tempVal2 = autoCorrectBS.otherCurrentLiabilitiesValue + autoCorrectBS.otherShortTermLiabValue;

        autoCorrectCheck = checkAplusB(tempVal1, tempStat, tempVal2, tempStat, autoCorrectBS.qCurrentLiabValue, autoCorrectBS.checkCurrentAssetsStatus);

        if (autoCorrectCheck.Astat === 'fixed'){
            if (!isNaN(autoCorrectBS.qShortTermNotesValue)) {
                autoCorrectBS.checkShortTermNotesStatus = '';
                autoCorrectBS.qAcctPayableValue = autoCorrectCheck.Aval - autoCorrectBS.qShortTermNotesValue;
                autoCorrectBS.checkAcctPayableStatus = 'fixed'; 
            } else if (!isNaN(autoCorrectBS.qAcctPayableValue)) {
                autoCorrectBS.checkAcctPayableStatus = '';
                autoCorrectBS.qShortTermNotesValue = autoCorrectCheck.Aval - autoCorrectBS.qAcctPayableValue;
                autoCorrectBS.checkShortTermNotesStatus = 'fixed';
            } else if (autoCorrectCheck.Aval >= 0) {
                autoCorrectBS.qShortTermNotesValue = autoCorrectCheck.Aval;
                autoCorrectBS.qAcctPayableValue = 0;
                autoCorrectBS.checkShortTermNotesStatus = 'fixed';
                autoCorrectBS.checkAcctPayableStatus = 'fixed';        
            } else {
                autoCorrectBS.qAcctPayableValue = autoCorrectCheck.Aval;
                autoCorrectBS.qShortTermNotesValue = 0;
                autoCorrectBS.checkShortTermNotesStatus = 'fixed';
                autoCorrectBS.checkAcctPayableStatus = 'fixed';        
            }
        } else{
            autoCorrectBS.checkShortTermNotesStatus = '';
            autoCorrectBS.checkAcctPayableStatus = '';        
        }

        if (autoCorrectCheck.Bstat === 'fixed'){
            if (!isNaN(autoCorrectBS.otherShortTermLiabValue)) {
                autoCorrectBS.checkOtherShortTermLiabStatus = '';
                autoCorrectBS.otherCurrentLiabilitiesValue = autoCorrectCheck.Bval - autoCorrectBS.otherShortTermLiabValue;
                autoCorrectBS.checkOtherCurrentLiabilitiesStatus = 'fixed';
            } else if (!isNaN(autoCorrectBS.otherCurrentLiabilitiesValue)) {
                autoCorrectBS.checkOtherCurrentLiabilitiesStatus = '';
                autoCorrectBS.otherShortTermLiabValue = autoCorrectCheck.Bval - autoCorrectBS.otherCurrentLiabilitiesValue;
                autoCorrectBS.checkOtherShortTermLiabStatus = 'fixed';
            } else {
                autoCorrectBS.otherShortTermLiabValue = autoCorrectCheck.Bval;
                autoCorrectBS.otherCurrentLiabilitiesValue = 0;
                autoCorrectBS.checkOtherCurrentLiabilitiesStatus = 'fixed';
                autoCorrectBS.checkOtherShortTermLiabStatus = 'fixed';
            }
        } else{
            autoCorrectBS.checkOtherCurrentLiabilitiesStatus = '';
            autoCorrectBS.checkOtherShortTermLiabStatus = '';
        }

        autoCorrectBS.qCurrentLiabValue = autoCorrectCheck.Cval; autoCorrectBS.checkCurrentLiabStatus = autoCorrectCheck.Cstat;

        //Check that accounts payable are of the correct sign, otherwise assign to receivable
        if (autoCorrectBS.qAcctPayableValue < 0) {
            autoCorrectBS.qAcctReceivableValue -= autoCorrectBS.qAcctPayableValue; autoCorrectBS.checkAcctReceivableStatus = 'fixed';
            autoCorrectBS.otherCurrentLiabilitiesValue += autoCorrectBS.qAcctPayableValue; autoCorrectBS.checkOtherCurrentLiabilitiesStatus = 'fixed';
            autoCorrectBS.qAcctPayableValue = 0; autoCorrectBS.checkAcctPayableStatus = 'fixed';
        }
    } else {
        //Reset checks to OK 

        autoCorrectBS.checkShortTermNotesStatus = ''; autoCorrectBS.checkShortTermNotesLog = '';
        autoCorrectBS.checkAcctReceivableStatus = ''; autoCorrectBS.checkAcctPayableLog = '';
        autoCorrectBS.checkOtherCurrentLiabilitiesStatus = ''; autoCorrectBS.checkOtherCurrentLiabilitiesLog = '';
        autoCorrectBS.checkOtherShortTermLiabStatus = ''; autoCorrectBS.checkOtherShortTermLiabLog = '';
        autoCorrectBS.checkCurrentLiabStatus = ''; autoCorrectBS.checkCurrentLiabLog = '';
    }

    if (isNaN(autoCorrectBS.qLongTermDebtValue) || autoCorrectBS.qLongTermDebtValue < 0) {
        autoCorrectBS.qLongTermDebtValue = 0; autoCorrectBS.checkLongTermDebtStatus = 'fixed';
    }

    if (isNaN(autoCorrectBS.nonCurrentLiabValue) || autoCorrectBS.nonCurrentLiabValue < 0) {
        autoCorrectBS.nonCurrentLiabValue = 0; autoCorrectBS.checkNonCurrentLiabStatus = 'fixed';
    }

    //Total Equity = Retained Earnings + Stock
    let totalEquityCal = autoCorrectBS.qRetainedEarningsValue + autoCorrectBS.capitalStockValue;
    let totalEquity = autoCorrectBS.totalEquityValue;

    if ((totalEquityCal !== totalEquity) || isNaN(totalEquityCal) || isNaN(totalEquity)){

        //Total Equity = Ret Earn + Stock (A = Ret Earn, B = Stock, C = Total Equity: A+b=C)
        autoCorrectCheck = checkAplusB(autoCorrectBS.qRetainedEarningsValue, autoCorrectBS.checkRetainedEarningsStatus, autoCorrectBS.capitalStockValue, autoCorrectBS.checkCapitalStockStatus, autoCorrectBS.totalEquityValue, autoCorrectBS.checkTotalEquityStatus);

        autoCorrectBS.qRetainedEarningsValue = autoCorrectCheck.Aval; autoCorrectBS.checkRetainedEarningsStatus = autoCorrectCheck.Astat;
        autoCorrectBS.capitalStockValue = autoCorrectCheck.Bval; autoCorrectBS.checkCapitalStockStatus = autoCorrectCheck.Bstat;
        autoCorrectBS.totalEquityValue = autoCorrectCheck.Cval; autoCorrectBS.Equitystat = autoCorrectCheck.Cstat;
    } else {
        autoCorrectBS.checkRetainedEarningsStatus = ''; autoCorrectBS.checkRetainedEarningsLog = '';
        autoCorrectBS.checkCapitalStockStatus = ''; autoCorrectBS.checkCapitalStockLog = '';
        autoCorrectBS.checkTotalEquityStatus = ''; autoCorrectBS.checkTotalEquityLog = '';
    }

    //Total Liab&Equity = Total Current Liab + Total Non-Curr Liab + Total Equity
    let totalLiabEquityCal = autoCorrectBS.qCurrentLiabValue + autoCorrectBS.nonCurrentLiabValue + autoCorrectBS.totalEquityValue;
    let totalLiabEquity = autoCorrectBS.totalLiabilitiesAndShareholdersEquityValue;

    if ((totalLiabEquityCal !== totalLiabEquity) || isNaN(totalLiabEquityCal) || isNaN(totalLiabEquity)) {

        //Total Liab&Equity = Total Current Liab + Total Non-Curr Liab + Total Equity (A=Curr Liab, B = Non Curr + Equity, C = Liab&Equity: A+B=C)
        tempVal1 = autoCorrectBS.nonCurrentLiabValue + autoCorrectBS.totalEquityValue;
        autoCorrectCheck = checkAplusB(autoCorrectBS.qCurrentLiabValue, autoCorrectBS.checkCurrentLiabStatus, tempVal1, tempStat, autoCorrectBS.totalLiabilitiesAndShareholdersEquityValue, autoCorrectBS.checkTotalLiabilitiesAndShareholdersEquityStatus);

        if (autoCorrectCheck.Bstat === 'fixed'){
            if (!isNaN(autoCorrectBS.totalEquityValue)) {
                autoCorrectBS.checkTotalEquityStatus = '';
                autoCorrectBS.nonCurrentLiabValue = autoCorrectCheck.Bval - autoCorrectBS.totalEquityValue; 
                autoCorrectBS.checkNonCurrentLiabStatus = 'fixed';
            } else if (!isNaN(autoCorrectBS.nonCurrentLiabValue)) {
                autoCorrectBS.checkNonCurrentLiabStatus = '';
                autoCorrectBS.totalEquityValue = autoCorrectCheck.Bval - autoCorrectBS.nonCurrentLiabValue; 
                autoCorrectBS.checkTotalEquityStatus = 'fixed';
            } else {
                autoCorrectBS.totalEquityValue = autoCorrectCheck.Bval;
                autoCorrectBS.nonCurrentLiabValue = convertToDecimal(0);
                autoCorrectBS.checkTotalEquityStatus = 'fixed';
                autoCorrectBS.checkNonCurrentLiabStatus = 'fixed';
            }
        } else{
            autoCorrectBS.checkTotalEquityStatus = '';
            autoCorrectBS.checkNonCurrentLiabStatus = '';
        }

        autoCorrectBS.qCurrentLiabValue = autoCorrectCheck.Aval; autoCorrectBS.checkCurrentLiabStatus = autoCorrectCheck.Astat;
        autoCorrectBS.totalLiabilitiesAndShareholdersEquityValue = autoCorrectCheck.Cval; autoCorrectBS.checkTotalLiabilitiesAndShareholdersEquityStatus = autoCorrectCheck.Cstat;
    } else {
        //Reset checks to OK

        autoCorrectBS.checkCurrentLiabStatus = ''; autoCorrectBS.checkCurrentLiabLog = '';
        autoCorrectBS.checkTotalEquityStatus = ''; autoCorrectBS.checkTotalEquityLog = '';
        autoCorrectBS.checkNonCurrentLiabStatus = ''; autoCorrectBS.checkNonCurrentLiabLog = '';
        autoCorrectBS.checkTotalLiabilitiesAndShareholdersEquityStatus = ''; autoCorrectBS.checkTotalLiabilitiesAndShareholdersEquityLog = '';
    }

    //Total Assets = Total Liab&Equity
    if (autoCorrectBS.qTotalAssetsValue !== autoCorrectBS.totalLiabilitiesAndShareholdersEquityValue) {
        let diff = autoCorrectBS.qTotalAssetsValue - autoCorrectBS.totalLiabilitiesAndShareholdersEquityValue;

        autoCorrectBS.checkTotalAssetsStatus = '';
        autoCorrectBS.totalLiabilitiesAndShareholdersEquityValue += diff; autoCorrectBS.checkTotalLiabilitiesAndShareholdersEquityStatus = 'fixed';
        autoCorrectBS.nonCurrentLiabValue += diff; autoCorrectBS.checkNonCurrentLiabStatus = 'fixed'; 
    } else {
        //Reset checks to OK

        autoCorrectBS.checkTotalAssetsStatus = ''; autoCorrectBS.checkTotalAssetsLog = '';
        autoCorrectBS.checkTotalLiabilitiesAndShareholdersEquityStatus = ''; autoCorrectBS.checkTotalLiabilitiesAndShareholdersEquityLog = '';
    }
    return autoCorrectBS; 
}
