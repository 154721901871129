import { AUTO_CORRECT_DATA } from '../constants';

let initialState={
    autoCorrectData:true,
}

const autoCorrectDataReducer = (state = initialState, action)=>{

    switch (action.type) {
        case AUTO_CORRECT_DATA:
            console.log('Auto Correct Reducer firing');
            return {
                ...state,
                autoCorrectData: action.autoCorrectData,
            }
        default:
            return state;
    }
}

export default autoCorrectDataReducer;