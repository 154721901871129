import { DARK_MODE } from '../constants';

let initialState={
    darkMode:false,
}

const darkModeReducer = (state = initialState, action)=>{

    switch(action.type){
        case DARK_MODE:
          return{
            ...state,
            darkMode:action.darkMode,
          }
      default:
          return state;
      }
}

export default darkModeReducer;