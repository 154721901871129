import {AUTH_LOGOUT, CHANGE_ACTIVE_TAB, CHANGE_ACTIVE_SUB_TAB} from '../constants';

let initialState={
    key: "1",
    subTabKey: "1"
}

const activeTabReducer = (state = initialState, action)=>{
    switch(action.type){
        case CHANGE_ACTIVE_TAB:
            return{
                ...state,
                key:action.activeTabKey,
            }
      case CHANGE_ACTIVE_SUB_TAB:
        return {
          ...state,
          subTabKey: action.payload
        }
        case AUTH_LOGOUT:
            return initialState;
        default:
            return state;
    }
}

export default activeTabReducer;