import { RADAR_PEERS } from '../constants';

let initialState = {
    radarPeers: false,
}

const radarPeersReducer = (state = initialState, action) => {

    switch (action.type) {
        case RADAR_PEERS:
            console.log('Radar Peers Reducer firing');
            return {
                ...state,
                radarPeers: action.radarPeers,
            }
        default:
            return state;
    }
}

export default radarPeersReducer;