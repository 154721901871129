export function tryUpdateDataStoreScore(dataStore, region, cluster, ticker, roboR8obj){
    if(dataStore[region] !== undefined)
        if (dataStore[region][cluster] !== undefined)
            if (dataStore[region][cluster][ticker] !== undefined) {
                dataStore[region][cluster][ticker].roboR8 = roboR8obj;
                return 1
            }
    return 0
}

export function tryDeleteDataStoreItem(dataStore, region, cluster, idList) {
    if(dataStore[region] !== undefined)
        if (dataStore[region][cluster] !== undefined)
            for (let ticker of idList){
                if (dataStore[region][cluster][ticker] !== undefined) {
                    delete dataStore[region][cluster][ticker];
                }
            }
}

export function tryDeleteqDataStoreItem(qDataStore, idList) {
    for (let ticker of idList){
        if (qDataStore[ticker] !== undefined) {
            delete qDataStore[ticker];
        }
    }
}

export function tryDeleteCompanyLists(companyList, region, cluster, idList) {
    let clusterData = companyList[region][cluster];
    for (let industryData of Object.values(clusterData)){
        for (let id of idList)
            if(industryData[id]!==undefined)
                delete industryData[id]
    }
}

export function tryDeleteCompanyResults(companyResults, idList) {
    for (let id of idList)
        if(companyResults[id]!==undefined)
            delete companyResults[id]
}