import React from "react";
import {connect} from 'react-redux';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coordinate, Legend,
} from "bizcharts";
import {convertRemToPx, getFinancialRatios} from '../utils';

const COLOR_PLATE_8 = ['#1890FF', '#2FC25B', '#FACC14', '#223273', '#8543E0', '#13C2C2', '#3436C7', '#F04864'];

class RadarSimple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            width: 0,
            height: 0,
            chartSize: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);        
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            custom: false,
            data: nextProps
        });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let winHeight = window.innerHeight;

        this.setState({ width: window.innerWidth, height: window.innerHeight, chartSize: (winHeight * this.props.height) });
    }
    
    render() {

        const Rmax = 50;

        let data, companyScore;
        let peerName, peerPos; 
        let currRatioQ, cashRatioQ, grossMarQ, netMarQ, debtEqQ, debtRatioQ, intCovQ, leverageQ;
        let companyID = this.props.headerSelected.companyID;
        let peerData = this.props.companyData.roboR8peersData;

        let companyRawRatios = {};
        let peerRawRatios = {
            "currentRatio": 0, "cashRatio": 0, "grossMargin": 0, "netMargin": 0, "LTDRatio": 0, "debtToEqRatio": 0, "debtRatio": 0, "interestCov": 0, "leverage": 0
        };
        let companyRatios = {};
        let peerRatios = {};

        let peerCount = 0;
        let peerScore = 0;

        let maxCurrentRatio = 10; let maxCashRatio = 10; let maxGrossMargin = 1; let maxNetMargin = 1;
        let maxLTDRatio = 1; let maxDebtToEqRatio = 5; let maxDebtRatio = 2; let maxInterestCov = 50; let maxLeverage = 50;

        for (let i = 1; i <= this.props.companyData.roboR8peers.length; i++) {

            if (this.props.radarPeers === true) {
                if (this.props.companyData.roboR8peers[i - 1].rating !== this.props.companyData.roboR8rating) continue;
            }

            peerName = this.props.companyData.roboR8peers[i - 1].name;
            peerPos = peerData.header.indexOf(peerName);

            //Get financial ratios if data exists
            let ratios = getFinancialRatios(peerData, peerPos);

            //Skip peer if data corrupt
            if (isNaN(this.props.companyData.roboR8peers[i - 1].score) ||
                isNaN(ratios.currentRatio) || isNaN(ratios.cashRatio) || isNaN(ratios.grossMargin) ||
                isNaN(ratios.netMargin) || isNaN(ratios.LTDRatio) || isNaN(ratios.debtToEqRatio) ||
                isNaN(ratios.debtRatio) || isNaN(ratios.interestCov) || isNaN(ratios.leverage))
                continue

            if (peerName === this.props.headerSelected.companyID) {
                companyScore = this.props.companyData.roboR8peers[i - 1].score;

                //If customised is selected, company statement is trailing 12m, so used companyData to get ratios
                if (this.props.customisedData === true) {

                    let EBITDA = parseFloat(this.props.companyData.qEBIT.value) + parseFloat(this.props.companyData.qDandA.value);
                    let cashEquiv = parseFloat(this.props.companyData.qCash.value) + parseFloat(this.props.companyData.qAcctReceivable.value);
                    let totalDebt = parseFloat(this.props.companyData.totalLiabilitiesAndShareholdersEquity.value) - parseFloat(this.props.companyData.totalEquity.value);
                    let netDebt = parseFloat(totalDebt) - parseFloat(cashEquiv);

                    let currentRatio = this.props.companyData.qCurrentLiab.value === 0 ? Rmax : Math.max(0, this.props.companyData.qCurrentAssets.value / this.props.companyData.qCurrentLiab.value);
                    let cashRatio = this.props.companyData.qCurrentLiab.value === 0 ? Rmax : Math.max(0, cashEquiv / this.props.companyData.qCurrentLiab.value)
                    let grossMargin = this.props.companyData.qSales.value === 0 ? Rmax : Math.max(0, this.props.companyData.qGrossProfit.value / this.props.companyData.qSales.value);
                    let netMargin = this.props.companyData.qSales.value === 0 ? Rmax : Math.max(0, this.props.companyData.qNetIncome.value / this.props.companyData.qSales.value);
                    let debtToEquity = this.props.companyData.totalEquity.value === 0 ? Rmax : Math.max(0, totalDebt / this.props.companyData.totalEquity.value);
                    let debtRatio = this.props.companyData.qTotalAssets.value === 0 ? Rmax : Math.max(0, totalDebt / this.props.companyData.qTotalAssets.value);
                    let interestCov = this.props.companyData.qGrossInterest.value <= 0 ? Rmax : Math.max(0, this.props.companyData.qEBIT.value / this.props.companyData.qGrossInterest.value);
                    let leverage = EBITDA <= 0 ? Rmax : Math.max(0, (netDebt + parseFloat(this.props.companyData.qCurrentLiab.value)) / EBITDA);

                    companyRawRatios.currentRatio = Math.min(currentRatio, maxCurrentRatio);
                    companyRawRatios.cashRatio = Math.min(cashRatio, maxCashRatio);
                    companyRawRatios.grossMargin = Math.min(grossMargin, maxGrossMargin);
                    companyRawRatios.netMargin = Math.min(netMargin, maxNetMargin);
                    companyRawRatios.LTDRatio = Math.min(Math.max(0, this.props.companyData.qLongTermDebt.value / totalDebt), maxLTDRatio);
                    companyRawRatios.debtToEqRatio = Math.min(debtToEquity, maxDebtToEqRatio);
                    companyRawRatios.debtRatio = Math.min(debtRatio, maxDebtRatio);
                    companyRawRatios.interestCov = Math.min(interestCov, maxInterestCov);
                    companyRawRatios.leverage = Math.min(leverage, maxLeverage);         

                } else companyRawRatios = ratios;
            }
            peerCount++;

            peerScore += this.props.companyData.roboR8peers[i - 1].score;
            for (var key of Object.keys(peerRawRatios)) {
                peerRawRatios[key] += ratios[key];
            }
        }
        //Get average ratios
        peerScore /= peerCount;
        for (var key2 of Object.keys(peerRawRatios)) {
            peerRawRatios[key2] /= peerCount;
        }        

        companyRatios.currentRatio = companyRawRatios.currentRatio / peerRawRatios.currentRatio;
        if (companyRatios.currentRatio < 0.25) currRatioQ = 1;
        else if (companyRatios.currentRatio < 0.75) currRatioQ = 2;
        else if (companyRatios.currentRatio < 1.25) currRatioQ = 3;
        else if (companyRatios.currentRatio < 1.75) currRatioQ = 4;
        else currRatioQ = 5;
        companyRatios.cashRatio = companyRawRatios.cashRatio / peerRawRatios.cashRatio;
        if (companyRatios.cashRatio < 0.25) cashRatioQ = 1;
        else if (companyRatios.cashRatio < 0.75) cashRatioQ = 2;
        else if (companyRatios.cashRatio < 1.25) cashRatioQ = 3;
        else if (companyRatios.cashRatio < 1.75) cashRatioQ = 4;
        else cashRatioQ = 5;
        companyRatios.grossMargin = companyRawRatios.grossMargin / peerRawRatios.grossMargin;
        if (companyRatios.grossMargin < 0.25) grossMarQ = 1;
        else if (companyRatios.grossMargin < 0.75) grossMarQ = 2;
        else if (companyRatios.grossMargin < 1.25) grossMarQ = 3;
        else if (companyRatios.grossMargin < 1.75) grossMarQ = 4;
        else grossMarQ = 5;
        companyRatios.netMargin = companyRawRatios.netMargin / peerRawRatios.netMargin;
        if (companyRatios.netMargin < 0.25) netMarQ = 1;
        else if (companyRatios.netMargin < 0.75) netMarQ = 2;
        else if (companyRatios.netMargin < 1.25) netMarQ = 3;
        else if (companyRatios.netMargin < 1.75) netMarQ = 4;
        else netMarQ = 5;        
        companyRatios.debtToEqRatio = companyRawRatios.debtToEqRatio / peerRawRatios.debtToEqRatio;
        if (companyRatios.debtToEqRatio < 0.25) debtEqQ = 1;
        else if (companyRatios.debtToEqRatio < 0.75) debtEqQ = 2;
        else if (companyRatios.debtToEqRatio < 1.25) debtEqQ = 3;
        else if (companyRatios.debtToEqRatio < 1.75) debtEqQ = 4;
        else debtEqQ = 5;
        companyRatios.debtRatio = companyRawRatios.debtRatio / peerRawRatios.debtRatio;
        if (companyRatios.debtRatioQ < 0.25) debtRatioQ = 1;
        else if (companyRatios.debtRatioQ < 0.75) debtRatioQ = 2;
        else if (companyRatios.debtRatioQ < 1.25) debtRatioQ = 3;
        else if (companyRatios.debtRatioQ < 1.75) debtRatioQ = 4;
        else debtRatioQ = 5;
        companyRatios.interestCov = companyRawRatios.interestCov / peerRawRatios.interestCov;
        if (companyRatios.interestCov < 0.25) intCovQ = 1;
        else if (companyRatios.interestCov < 0.75) intCovQ = 2;
        else if (companyRatios.interestCov < 1.25) intCovQ = 3;
        else if (companyRatios.interestCov < 1.75) intCovQ = 4;
        else intCovQ = 5;
        companyRatios.leverage = companyRawRatios.leverage / peerRawRatios.leverage;
        if (companyRatios.leverage < 0.25) leverageQ = 1;
        else if (companyRatios.leverage < 0.75) leverageQ = 2;
        else if (companyRatios.leverage < 1.25) leverageQ = 3;
        else if (companyRatios.leverage < 1.75) leverageQ = 4;
        else leverageQ = 5;
        
        if (this.props.autoCorrectData === true) {
            data = [
                { item: 'current ratio', user: companyID, score: currRatioQ, raw: Math.round(companyRawRatios.currentRatio * 100) },
                { item: 'current ratio', user: 'Industry Mean', score: 0, raw: Math.round(peerRawRatios.currentRatio * 100) },
                { item: 'cash ratio', user: companyID, score: cashRatioQ, raw: Math.round(companyRawRatios.cashRatio * 100) },
                { item: 'cash ratio', user: 'Industry Mean', score: 0, raw: Math.round(peerRawRatios.cashRatio * 100) },
                { item: 'gross margin', user: companyID, score: grossMarQ, raw: Math.round(companyRawRatios.grossMargin * 100) },
                { item: 'gross margin', user: 'Industry Mean', score: 0, raw: Math.round(peerRawRatios.grossMargin * 100) },
                { item: 'net margin', user: companyID, score: netMarQ, raw: Math.round(companyRawRatios.netMargin * 100) },
                { item: 'net margin', user: 'Industry Mean', score: 0, raw: Math.round(peerRawRatios.netMargin * 100) },          
                { item: 'debt to equity', user: companyID, score: debtEqQ, raw: Math.round(companyRawRatios.debtToEqRatio * 100) },
                { item: 'debt to equity', user: 'Industry Mean', score: 0, raw: Math.round(peerRawRatios.debtToEqRatio * 100) },
                { item: 'debt ratio', user: companyID, score: debtRatioQ, raw: Math.round(companyRawRatios.debtRatio * 100) },
                { item: 'debt ratio', user: 'Industry Mean', score: 0, raw: Math.round(peerRawRatios.debtRatio * 100) },
                { item: 'leverage', user: companyID, score: leverageQ, raw: Math.round(companyRawRatios.leverage * 100) },
                { item: 'leverage', user: 'Industry Mean', score: 0, raw: Math.round(peerRawRatios.leverage * 100) },
                { item: 'interest coverage', user: companyID, score: intCovQ, raw: Math.round(companyRawRatios.interestCov * 100) },
                { item: 'interest coverage', user: 'Industry Mean', score: 0, raw: Math.round(peerRawRatios.interestCov * 100) },
            ];
        } else {
            data = [
                { item: "ratio", user: "unknown", score: 0, raw: 0  }
            ];
        }

        const cols = {
            score: {
                tickInterval: 1,
                min: 0,
                max: 5,                
            },
        };
        console.log("rendered AGAIN!");
        return (
            <div className="radarSimple">
                <Chart
                    height={window.innerHeight*this.props.height}
                    padding="auto" data={data} autoFit
                    scale={cols}
                    visible={this.props.activeTabKey === "1"}
                >
                    <Tooltip shared={true}/>
                    <Legend visible={false} />
                    <Coordinate type="polar" innerRadius={0.2} radius={0.8} />
                    <Axis
                        name="item"
                        line={null}
                        tickline={null}
                        grid={{
                            line: {
                                style: {
                                    stroke:"#f2d9d9",
                                    lineWidth: 0.5,
                                    lineDash: null,
                                }
                            },
                            hideFirstLine: false
                        }}
                        label={{
                            style: {
                                fill: this.props.darkMode ? '#A3ABBD' : '#192A3E', // 文本的颜色
                                fontSize: convertRemToPx(this.props.rootFontSize), // 文本大小
                            }
                        }}
                    />
                    <Axis
                        name="score"
                        line={null}
                        tickLine={null}
                        grid={{
                            type: 'circle',
                            line: {
                                style: {
                                    stroke:"#e5e5e5",
                                    lineWidth: 1,
                                    lineDash: [0, 0],
                                }
                            },
                        }}
                        label={null}
                        title={false}
                    />
                    <Geom
                        type="interval"
                        position="item*score"
                        color={['item*user', (item,user)=>{
                            let index = data.findIndex(elem => elem.item === item);
                            index = Math.floor(index/2);
                            return COLOR_PLATE_8[index];
                        }]}
                        style={{
                            fillOpacity: 0.75,
                        }}
                        tooltip={['item*user*raw', (item, user, raw) => {
                            return {
                                title: item,
                                name: user,
                                value: raw + '%',
                            };
                        }]}
                    />
                </Chart>
            </div>
        );
    }
}

const mapStateToProps = (state) =>{
  return{
      darkMode: state.darkModeReducer.darkMode,
      headerSelected: state.headerSelectedReducer.headerSelected,
      companyData: state.companyDataReducer.companyData,
      autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
      customisedData: state.customisedDataReducer.customisedData,
      radarPeers: state.radarPeersReducer.radarPeers,
      rootFontSize: state.globalStyleReducer.rootFontSize,
      activeTabKey: state.activeTabReducer.key,
  }
}

export default connect(mapStateToProps,null)(RadarSimple);