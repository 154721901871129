import React from "react";
import { connect } from 'react-redux';
import {
    Chart,
    Axis,
    Tooltip,
    View, Coordinate, Legend, Interval
} from "bizcharts";

class CapStructurePie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            width: 0,
            height: 0,
            chartSize: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);        
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("capStruct pie receive new props!");
        this.setState({
            custom: false,
            data: nextProps
        });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let winHeight = window.innerHeight;

        this.setState({ width: window.innerWidth, height: window.innerHeight, chartSize: (winHeight * this.props.height) });
    }
       
    
    render() {
        let data, data2, total, totEq, totLiab, totCLiab, totNCLiab;

        if (this.props.autoCorrectData === true) {
            total = Math.max(0, parseFloat(this.props.companyData.totalEquity.value) + parseFloat(this.props.companyData.qCurrentLiab.value) + parseFloat(this.props.companyData.nonCurrentLiab.value));
            
            if (parseFloat(total) <= 0.0) {
                totEq = 1.0; totLiab = 0.0; totCLiab = 0.0; totNCLiab = 0.0;
            } else {
                totEq = Math.max(0, parseFloat(this.props.companyData.totalEquity.value) / parseFloat(total));
                totLiab = 1.0 - parseFloat(totEq);
                totCLiab = Math.max(0, parseFloat(this.props.companyData.qCurrentLiab.value) / parseFloat(total));
                totNCLiab = parseFloat(totLiab) - parseFloat(totCLiab);
            }

            data = [
                { score: totEq, user: "Equity", item: "Total Equity" },
                { score: totCLiab, user: "Liabilties", item: "Current Liabilities" },
                { score: totNCLiab, user: "Liabilties", item: "Non-Current Liabilities" },
            ];
            data2 = [
                { scoreFull: totEq, user: "Equity"},
                { scoreFull: totLiab, user: "Liabilties"},                
            ];
        } else {
            data = [
                { item: "ratio", user: "unknown", score: 1 }
            ];
            data2 = [
                { user: "unknown", scoreFull: 1 }
            ];
        }

        const cols = {
            score: {
                formatter: val => {
                    val = (val * 100).toFixed(2) + '%';
                    return val;
                },
            },
            scoreFull: {
                formatter: val => {
                    val = (val * 100).toFixed(2) + '%';
                    return val;
                },
            },
        };        

        return (
            <div className="capStructurePie">
                <Chart
                    height={window.innerHeight * this.props.height}
                    data={data} autoFit
                    scale={cols}
                    visible={this.props.activeTabKey===this.props.underTabKey && this.props.activeSubTabKey===this.props.underSubTab}
                >
                    <Coordinate type="theta" radius={0.5} />
                    <Axis visible={false}/>
                    <Legend visible={false} />
                    <Tooltip showTitle={false} />
                    <Interval
                        position="score"
                        adjust="stack"
                        color={["item", ['#99da9b', '#6E94F4', '#5876C3']]}
                        element-highlight
                        style={{
                            stroke: '#E5E5E5',
                            lineWidth: 1,
                        }}
                        label={['type', {
                            offset: -15,
                        }]}
                    />
                    <View data={data2}>
                        <Coordinate type="theta" innerRadius={0.5/0.75} radius={0.75} />
                        <Interval
                            position="scoreFull"
                            adjust="stack"
                            color={["user", ['#99da9b', '#99B4F7']]}
                            element-highlight
                            style={{
                                lineWidth: 1,
                                stroke: '#E5E5E5',
                            }}
                            label={'user'}
                        />
                    </View>
                </Chart>
            </div>
        );
    }
}

const mapStateToProps = (state) =>{
  return{
      darkMode: state.darkModeReducer.darkMode,
      headerSelected: state.headerSelectedReducer.headerSelected,
      companyData: state.companyDataReducer.companyData,
      autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
      customisedData: state.customisedDataReducer.customisedData,
      radarPeers: state.radarPeersReducer.radarPeers,
    activeTabKey: state.activeTabReducer.key,
    activeSubTabKey: state.activeTabReducer.subTabKey
  }
}

export default connect(mapStateToProps, null)(CapStructurePie);