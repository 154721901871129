import React, {useEffect, useRef, useState} from 'react';


export default function Reading(props) {
    const counter = useRef();
    if (!counter.current) counter.current = 0;
    let [num, setNum] = useState(0);
    useEffect(() => {
        const timeout = 300/Math.abs(props.value-num);
        const interval = setInterval(() => {
            if(counter.current !== props.value){
                let increment = props.value > counter.current ? 1: -1;
                counter.current += increment;
                setNum(counter.current);
            }
            else
                clearInterval(interval);
        }, timeout);
        return () => clearInterval(interval);
    }, [props.value]);

    return (
        <span className={props.className}>{num}</span>
    )
}