import * as types from '../constants';


export const darkMode = (darkMode) => {
    return {
        type: types.DARK_MODE,
        darkMode: darkMode,
    }
}

export const loading = (loading) => {
    return {
        type: types.LOADING,
        loading: loading
    }
}

export const companyIdIndex = (index) => {
    return {
        type: types.COMPANY_ID_INDEX,
        index: index
    }
}

export const headerSelected = (headerSelected) => {
    return {
        type: types.HEADER_SELECTED,
        headerSelected: headerSelected
    }
}

export const companyData = (companyData) => {
    return {
        type: types.COMPANY_DATA,
        companyData: companyData
    }
}

export const yearlyReport = (yearlyReport) => {
    return {
        type: types.YEARLY_REPORT,
        yearlyReport: yearlyReport
    }
}

export const autoCorrectData = (autoCorrectData) => {
    return {
        type: types.AUTO_CORRECT_DATA,
        autoCorrectData: autoCorrectData
    }
}

export const customisedData = (customisedData) => {
    return {
        type: types.CUSTOMISED_DATA,
        customisedData: customisedData
    }
}

export const bubbleGraph = (bubbleGraph) => {
    return {
        type: types.BUBBLE_GRAPH,
        bubbleGraph: bubbleGraph
    }
}

export const radarPeers = (radarPeers) => {
    return {
        type: types.RADAR_PEERS,
        radarPeers: radarPeers
    }
}

export const changeActiveTab = (key) => {
    return {
        type: types.CHANGE_ACTIVE_TAB,
        activeTabKey: key
    }
}

export const changeActiveSubTab = (key) => {
  return {
    type: types.CHANGE_ACTIVE_SUB_TAB,
    payload: key
  }
}

export const rootFontSize = (fontSize) => {
    return {
        type: types.ROOT_FONT_SIZE,
        payload: fontSize,
    }
}