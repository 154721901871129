export function getQuartersFromYear(year, results) {
    let quarterYears = Object.keys(results);
    let particularYear = quarterYears.filter((data)=>{
        return data.includes(year);
    });
    let quarters = particularYear.map(data=>{
        let i = data.indexOf('Q');
        return data.slice(i, i+2);
    });
    return [...new Set(quarters)].sort()
}

export function getYearsFromResults(results) {
    let quarterYears = Object.keys(results);
    let years = quarterYears.map((data)=>{
        return data.slice(data.length-4);
    });
    return [...new Set(years)].sort()
}

export function getClusterOptions(companyList, region){
    let result = Object.keys(companyList[region]);
    result.push('ALL');
    return result;
}

export function getIndustryOptions(companyList, region, cluster){
    if (cluster === 'ALL'){
        let result = [];
        for (let c of Object.values(companyList[region])) {
            result = result.concat(Object.keys(c));
        }
        return result;
    }
    let result = Object.keys(companyList[region][cluster]);
    result.push('ALL');
    return result;
}

export function getAllCompanyOptions(companyList) {
    let companyOptions = [];
    for (let regionKVal of Object.values(companyList))
        for (let clusterVal of Object.values(regionKVal))
            for (let industryVal of Object.values(clusterVal)) {
                companyOptions = companyOptions.concat(Object.entries(industryVal))
            }
    return companyOptions;
}

export function getCompanyOptions(companyList, region, cluster, industry, isRekikiOnly){
    let companyOptions = [];
    // if region, cluster, industry are all set, filter the company list, otherwise give the full company list
    if(region&&cluster&&industry){
        if(cluster==='ALL'){
            for (let clusterVal of Object.values(companyList[region]))
                if(clusterVal[industry])
                    companyOptions = companyOptions.concat(Object.entries(clusterVal[industry]));
        }
        else if(industry==='ALL'){
            for (let industryVal of Object.values(companyList[region][cluster]))
                companyOptions = companyOptions.concat(Object.entries(industryVal));
        }
        else{
            let companies = companyList[region][cluster][industry];
            companyOptions = Object.entries(companies);
        }
    }

    if(isRekikiOnly){
        companyOptions = companyOptions.filter(data=>{
            return data[1]['hasRekikiResult'];
        })
    }

    return companyOptions.sort(function(a, b){
        if(a[0] < b[0]) { return -1; }
        if(a[0] > b[0]) { return 1; }
        return 0;});
}

export function getCompanyData(companyList, code){
    for (let regionData of Object.values(companyList))
        for (let clusterData of Object.values(regionData))
            for (let industryData of Object.values(clusterData))
                for (let [companyId, companyData] of Object.entries(industryData))
                    if (companyId===code)
                        return companyData;
}

export function findDefaultCompanyId(companyList, region, cluster, industry, hasRekikiResult=false) {
    let regionData = companyList[region];
    let clusterData;
    let industryData;
    if (!cluster)
        clusterData = Object.values(regionData)[0];
    else
        clusterData = regionData[cluster];
    if (!industry)
        industryData = Object.values(clusterData)[0];
    else
        industryData = clusterData[industry];
    // TODO add the top region layer to this recursive logic once we have more regions
    return findFirstMatchingCompanyIdInRegion(regionData, clusterData, industryData, hasRekikiResult);
}

export function findFirstMatchingCompanyIdInRegion(regionData, clusterData, industryData, hasRekikiResult){
    let result = findFirstMatchingCompanyIdInCluster(clusterData, industryData, hasRekikiResult);
    if (!result){
        for (let data of Object.values(regionData)){
            let result = findFirstMatchingCompanyIdInCluster(data, null, hasRekikiResult);
            if (result)
                break;
        }
    }
    return result;
}

export function findFirstMatchingCompanyIdInCluster(clusterData, industryData, hasRekikiResult){
    let result = industryData? findFirstMatchingCompanyId(industryData, hasRekikiResult): null;
    if (!result){
        for (let data of Object.values(clusterData)){
            let result = findFirstMatchingCompanyId(data, hasRekikiResult);
            if (result)
                break;
        }
    }
    return result;
}

export function findFirstMatchingCompanyId(industryData, hasRekikiResult){
    let entries = Object.entries(industryData);
    if (hasRekikiResult)
        entries = entries.filter(entry=>{
            return entry[1]['hasRekikiResult']
        });
    for (let [companyId, companyData] of entries)
        return companyId;
    return null
}