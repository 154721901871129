import { CUSTOMISED_DATA } from '../constants';

let initialState={
    customisedData:false,
}

const customisedDataReducer = (state = initialState, action) => {

    switch (action.type) {
        case CUSTOMISED_DATA:
            console.log('Customise Data Reducer firing');
            return {
                ...state,
                customisedData: action.customisedData,
            }
        default:
            return state;
    }
}

export default customisedDataReducer;