import React from "react";
import {connect} from 'react-redux';
import {
    Chart,
    Geom,
    Axis,
    Tooltip, Legend,
} from "bizcharts";
import Brush from "@antv/g2-brush";
import {convertRemToPx, mapScoreToRating} from '../utils';
import {getCompanyData} from "./Header/utils";
import {
    companySelected,
    darkMode,
    fetchPeerDataForCompanyQuarter,
    fetchRatingsForCompanyQuarter,
    headerSelected, logout, rootFontSize
} from "../redux/actions";
import {fetchMacroForRegionDate} from "../redux/actions/macroEconomy";

let chart;

class Jitter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            chartSize: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let winHeight = window.innerHeight;

        this.setState({ width: window.innerWidth, height: window.innerHeight, chartSize: (winHeight*this.props.height) });
    }

    handlePointSelect = (e) => {
        if (e.data){
            const companyId = e.data.data.Class;
            // let companyData = getCompanyData(this.state.companyList, companyId);
            this.props.companySelected(companyId)
        }
    }

    handleGetG2Instance = g2Chart => {
        chart = g2Chart;

    }

    render() {
        const cols = {
            score: {
                //tickInterval: 10,
                //min: 1,
                //max: 100,
                alias: "rating score",
                nice: true,
            },
            Prob: {
                alias: "rating prob (%)"
            },
            Rating: {
                alias: "rating equivalent"
            }
        };
        let bubbleSize;
        if (this.props.bubbleGraph === true) {
            bubbleSize = "Prob";
        }
        else bubbleSize = 4;
        
        var i;
        let data = [];
        let dataEntry;

        if (this.props.autoCorrectData === true) {
            for (i = 1; i <= this.props.companyData.roboR8peers.length; i++) {
                dataEntry = {
                    "Class": this.props.companyData.roboR8peers[i - 1].name,
                    "grade": this.props.companyData.roboR8peers[i - 1].grade,
                    "score": this.props.companyData.roboR8peers[i - 1].score,
                    "Rating": mapScoreToRating(this.props.companyData.roboR8peers[i - 1].rating).migo,
                    "Prob": Math.round(this.props.companyData.roboR8peers[i - 1].ratingProb * 100)
                }
                data.push(dataEntry);
            }
        } else {
            data[0] = [{
                "Class": "EMPTY",
                "grade": "selected company",
                "score": 100,
                "Rating": "AAA",
                "Prob": 100
            }]
        }

        const colorMap = this.props.darkMode ? {
            /*
            "selected company": "#3f7dd9",
            "match agency rating": "#77C199",
            "within +/-1 notch": "#FCD596",
            "differs from agency": "#EC837A",
            "unrated by agency": "#A4ADBA",
            */
            "selected company": "#6e94f4",
            "match agency rating": "#2ED47A",
            "within +/-1 notch": "#FFB946",
            "differs from agency": "#F7685B",
            "unrated by agency": "#D1D1D1",
            
        } :
            {
                "selected company": "#1b62cc",
                "match agency rating": "#2ED47A",
                "within +/-1 notch": "#FFB946",
                "differs from agency": "#F7685B",
                "unrated by agency": "#D1D1D1",
            };

        return (
            <div className="scatterplot-chart">
                <Chart
                    height={window.innerHeight * this.props.height}
                    onGetG2Instance={this.handleGetG2Instance}
                    padding="auto" data={data} autoFit
                    onPointClick={this.handlePointSelect }
                    scale={cols}
                    visible={this.props.activeTabKey === this.props.underTabKey}
                >
                    <Legend visible={false} />
                    <Tooltip
                        showMarkers={true}
                        showCrosshairs={true}
                        crosshairs={{
                            type: "xy",
                            style: {
                                lineDash: [1, 1]
                            }
                        }}
                    />
                    <Axis
                        name="score"
                        grid={{
                            line: {
                                style: {
                                    stroke: this.props.darkMode ? '#4F4F4F' : '#E5E5E5',
                                    lineWidth: 1,
                                    lineDash: [0, 0]
                                }
                            }
                        }}
                        label={{
                            style: {
                                fill: this.props.darkMode ? '#A3ABBD' : '#192A3E', // 文本的颜色
                                fontSize: convertRemToPx(this.props.rootFontSize), // 文本大小
                            }
                        }}
                    />
                    <Axis
                        name="Class"
                        tickLine={null}
                        grid={null}
                        label={null}
                        title={false}
                    />
                    <Geom
                        type="point"
                        position="Class*score"
                        color={[
                            "grade",
                            function (grade) {
                                return colorMap[grade];
                            }
                        ]}
                        size={bubbleSize}
                        tooltip="grade*score*Rating" /* *Prob"*/
                        opacity={0.65}
                        shape="circle"
                        adjust="jitter"
                        style={['grade', (val) => {
                            return {
                                cursor: 'pointer',
                                fillOpacity: 0.65,
                                opacity: 0.65,
                                stoke: colorMap[val],
                            };
                        }]}
                        /*
                        select={[true, {
                            mode: 'single',
                            style: { fill: "red" },
                            cancelable: true,
                            animate: true
                        }]}
                        */
                    />
                </Chart>
            </div>
        );
    }
}

const mapStateToProps = (state) =>{
  return{
    darkMode: state.darkModeReducer.darkMode,
    companyData: state.companyDataReducer.companyData,
    autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
    rootFontSize: state.globalStyleReducer.rootFontSize,
    bubbleGraph: state.bubbleGraphReducer.bubbleGraph,
    activeTabKey: state.activeTabReducer.key
  }
}

const mapDispatchToProps = dispatch => {
    return {
        companySelected: (company) => {
            dispatch(
                companySelected(company)
            )
        }
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Jitter);