import {
    COMPANY_LIST,
    COMPANY_RATINGS_LOADED,
    COMPANY_RESULTS,
    COMPANY_PEERDATA_LOADED,
    COMPANY_RESULT_ENTRIES, RUN_BATCH_SCORE_FINISH, UPLOAD_TAB_DELETE_ROWS
} from '../constants';
import {mapDataToCompanyResultFormat} from "../../components/UploadSheet/service";
import {createRoboR8RatingObjectFromRoboR8Results} from "../../utils";
import {
    tryDeleteCompanyLists, tryDeleteCompanyResults,
    tryDeleteDataStoreItem,
    tryDeleteqDataStoreItem,
    tryUpdateDataStoreScore
} from "../../utils/reduxUtils";


let initialState={
    companyList: {},
    companyResults: {},
};

const companyListReducer = (state = initialState, action) => {

    switch (action.type) {
        case COMPANY_LIST:
            console.log('Company List action firing');
            return {
                ...state,
                companyList: action.companyList,
            };
        case COMPANY_RESULTS:
            console.log('Company Results action firing');
            let newCompanyResults = {
                ...state.companyResults
            };
            newCompanyResults[action.company] = action.results;
            return {
                ...state,
                companyResults: newCompanyResults
            };
        case COMPANY_RATINGS_LOADED:
            console.log('Company quarter ratings loaded action firing');
            let oneCompanyData = state.companyResults[action.company];
            if (!oneCompanyData[action.quarter].roboR8Results)
                oneCompanyData[action.quarter].roboR8Results = action.data.robor8_results;
            if (!oneCompanyData[action.quarter].agencyRatings)
                oneCompanyData[action.quarter].agencyRatings = action.data.agency_ratings;
            return {
                ...state
            };
        case COMPANY_PEERDATA_LOADED:
            console.log('Company peerdata loaded action firing');
            let quarterData = state.companyResults[action.company];
            quarterData[action.quarter] = {
                ...quarterData[action.quarter],
                peerData: action.data.peer_data,
                peerRatings: action.data.peer_ratings
            };
            return {
                ...state
            };
        case COMPANY_RESULT_ENTRIES:
            console.log('New Company Entry action firing');
            // update companyList
            const companyList = {...state.companyList};
            for (const item of action.data){
                const q1data = item.qData[0];
                companyList[item.region] = companyList[item.region] || {};
                companyList[item.region][item.cluster] = companyList[item.region][item.cluster] || {};
                const clusterData = companyList[item.region][item.cluster];
                clusterData[q1data.industry] = clusterData[q1data.industry] || {};
                const industryData = clusterData[q1data.industry];
                const companyMeta = {
                    RekikiSector: 6,
                    region: item.region,
                    cluster: item.cluster,
                    industry: q1data.industry,
                    name: q1data.companyName,
                    hasRekikiResult: false
                };
                industryData[q1data.companyId] = companyMeta;

                //update companyResults
                state.companyResults[q1data.companyId] = state.companyResults[q1data.companyId]||{};
                const companyData = {...state.companyResults[q1data.companyId]};
                for (const qdata of item.qData){
                    companyData[qdata.quarter] = mapDataToCompanyResultFormat(qdata);
                }

                state.companyResults[q1data.companyId] = companyData;
            }

            return {
                ...state,
                companyList: companyList,
                companyResults: {...state.companyResults}
            };
        case UPLOAD_TAB_DELETE_ROWS:
            tryDeleteCompanyLists(state.companyList, action.region, action.cluster, action.idList);
            tryDeleteCompanyResults(state.companyResults, action.idList);
            return {
                ...state,
                companyList: {...state.companyList},
                companyResults: {...state.companyResults}
            };
        case RUN_BATCH_SCORE_FINISH:
            const {data} = action;
            for (let item of data){
                if(state.companyResults[item.companyId]!==undefined){
                    const quarterData = state.companyResults[item.companyId][item.lastQuarter];
                    state.companyResults[item.companyId][item.lastQuarter] = {...quarterData, roboR8Results: item.roboR8Results}
                }
            }
            return {
                ...state,
                companyResults: {...state.companyResults}
            };
        default:
            return state;
    }
};

export default companyListReducer;