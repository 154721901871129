import { HEADER_SELECTED } from '../constants';

let initialState={
    headerSelected:{
        companyID:"",
        reportingPeriod: "",
        companyGIC:""
    }
}

const headerSelectedReducer = (state = initialState, action)=>{

    switch(action.type){
        case HEADER_SELECTED:
          return{
            ...state,
            headerSelected:action.headerSelected,
          }
      default:
          return state;
      }
}

export default headerSelectedReducer;