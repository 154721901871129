import React, { Component } from 'react'
import { Typography, Divider } from 'antd';

import { connect } from 'react-redux';

const { Title, Paragraph, Text, Link } = Typography;

class ReadMe extends Component {

    componentDidMount() {
        this.setState({ data: this.props })
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            custom: false,
            data: nextProps,
        });
    };
  
    render() {
        
        return (
            <div className="help-readme">
                <div className="col-12" style={{ paddingRight: '2.5px' }}>
                    <div className="data-entry-wrapper">
                       
                            <Typography className="data-title" style={{ textAlign: "justify", textJustify: "inter-word" }}>
                                <Title> <span style={{ fontSize: "2rem" }}>INTRODUCTION</span> </Title>
                                <Paragraph style={{ fontSize: "1.5rem" }}>
                                    roboR8 is a company credit scoring system which uses a proprietary machine learning algorithm called 
                                    <font face="arial"> MIGO&trade; </font> (Machine Intelligence via Graph Optimization). The output of 
                                    roboR8 is congruent to the classification used by the major rating agencies. <font face="arial"> MIGO&trade; </font>
                                    combines a classification tree approach (which can be proven mathematically to be "optimal") with 
                                    graph-theoretic algorithms (for factor selection) and evolutionary systems (for forest aggregation). The
                                    result is an engine that we believe to be superior and more robust than existing automated scoring systems.                                   
                                </Paragraph>
                                <Paragraph style={{ fontSize: "1.5rem" }}>
                                    roboR8's mission is to democratize credit ratings; creating a minimal cost, centralized information repository
                                    of unbiased, consistent company credit scores for the majority of public and private companies worldwide, accessible to all.     
                                </Paragraph>
                            </Typography>
                        
                        
                            <Typography className="data-title" style={{ textAlign: "justify", textJustify: "inter-word" }}>
                                <Title> <span style={{ fontSize: "2rem" }}>THE ROBOR8 CALCULATOR </span> </Title>
                                
                                <Paragraph style={{ fontSize: "1.5rem" }}>
                                    The roboR8 calculator is split into two sections: THE SELECTOR, where companies are selected for analysis, and THE BODY,
                                    where financial statements and outputs are displayed. 
                                </Paragraph>
                                <div className="data-title">
                                    <Title> <span style={{ fontSize: "2rem" }}>The Selector </span></Title>
                                    <Paragraph style={{ fontSize: "1.5rem", textAlign: "justify", textJustify: "inter-word" }}>
                                        The upper portion of the calculator consists of a <i>Region</i> selector, which represents the geographic region of the company and 
                                         a <i>Cluster</i> selector, which contains group names for a collection of companies that the user is interested in. Once the Region-Cluster 
                                        pair is chosen, all the available industries and company IDs/Names will be displayed. In order to only show those companies 
                                        for which a rating exists, the <i>Company ID</i> check-box should be ticked. Companies may be selected via the Company ID or the Company Name.
                                        The <i>Reporting Period</i> drop-down selectors list the relevant reporting period for which the ratings apply.        
                                </Paragraph>
                                </div>
                                <div className="data-title">
                                    <Title> <span style={{ fontSize: "2rem" }}>The Body </span></Title>
                                    <Paragraph style={{ fontSize: "1.5rem", textAlign: "justify", textJustify: "inter-word" }}>
                                        Once a company and reporting period has been chosen via the Selector, the Body of the calculator is filled with the relevant data. The Body
                                        consists of 1 of 4 different Views: The <b><i>Summary View</i></b>, the <b><i>Detailed View</i></b>,
                                        the <b><i>vs Industry Mode</i></b> and the <b><i>Upload Data</i></b>. 

                                        <Divider />
                                        <div className="data-title">
                                            <Title> <span style={{ fontSize: "1.5rem" }}>Summary View </span></Title>
                                            <Paragraph style={{ fontSize: "1.5rem", textAlign: "justify", textJustify: "inter-word" }}>
                                                The Summary View is the most concise view of the roboR8 scoring system. On the left hand side of the Body are
                                                the <i>Macro-Economic Data</i> which <font face="arial"> MIGO&trade; </font> has determined to be relevant for the
                                                company's industry, plus a summary of the <i>Financial Report</i>. The right hand side shows 2 outputs: the first 
                                                has key ratios for financial health, efficiency and profitability of a company, quantized versus the industry's average. 
                                                The second gives an assessment of the company's ability to honour its financial commitments in the medium term, with a 
                                                probability of each category being presented in the <i>Credit Quality Distribution</i> chart.                
                                            </Paragraph>
                                        </div>
                                        <div className="data-title">
                                            <Title> <span style={{ fontSize: "1.5rem" }}>Detailed View </span></Title>
                                            <Paragraph style={{ fontSize: "1.5rem", textAlign: "justify", textJustify: "inter-word" }}>
                                                The Detailed View gives a detailed breakdown of the roboR8 output. On the left hand side of the Body
                                                are the <i>Macro-Economic Data</i> which <font face="arial"> MIGO&trade; </font> has determined to be relevant for the
                                                company's industry, 
                                                plus the company's consolidated financial statement (CFS). The CFS can be toggled between a quarterly statement (if 
                                                the the report is at a different frequency to quarterly, then a simple adjustment is performed) and a trailing 12 
                                                month statement. Further, the user may toggle between the original, filed data, and the auto-corrected data using roborR8 
                                                underlying statement consistency engine. If the <i>Customised</i> check-box is ticked, users may perturb any of the statement 
                                                entries (the Calculator will ensure the financial report is consistent). The CFS may also be viewed graphically by
                                                selecting the Summary tab, where 4 graphs give the <i>Profitability</i>, <i>Cash Generation</i>, <i>Assets</i> and <i>Capital 
                                                Structure</i> breakdowns.
                                            </Paragraph>
                                            <Paragraph style={{ fontSize: "1.5rem", textAlign: "justify", textJustify: "inter-word" }}>
                                                On the right hand side of the Detailed View's Body are some frequently used financial ratios plus 3 outputs charts.
                                                The <i>Rating Score Distribution</i> histogram gives the probability distribution of the full credit rating spectrum for 
                                                the company. Above that is a <i>Credit Bar</i> which summarises the rating distribution into its mode and the probability 
                                                of being above and below its assigned rating. Finally, the top right hand side gives a <i>Credit Score</i> from 1-100, 1 
                                                being the lowest credit worthiness, 100 being the highest. The <i>+/- 1 notch confidence</i> interval as well as the roboR8 
                                                comparison versus rating agencies completes the outputs.
                                            </Paragraph>
                                            <Paragraph style={{ fontSize: "1.5rem", textAlign: "justify", textJustify: "inter-word" }}>
                                                If a user has perturbed the financial statement via the <i>Customised</i> feature in the Detailed View, then she has
                                                the ability to re-run the roboR8 engine on the new statement to ascertain the company's new credit scores. This is done 
                                                in real-time by pressing the <i>Run Credit Score</i> button on the top right hand side of the Body, which only becomes available 
                                                once a customisation has occurred.
                                            </Paragraph>
                                        </div>
                                        <div className="data-title">
                                            <Title> <span style={{ fontSize: "1.5rem" }}>vs Industry Mode </span></Title>
                                            <Paragraph style={{ fontSize: "1.5rem", textAlign: "justify", textJustify: "inter-word" }}>
                                                The vs Industry Mode displays the company relative to its industry. On the left hand side of the Body is a summary of the
                                                Detailed View's Body. On the right hand side, 4 views are presented which compare the company's credit quality to
                                                its industry. The first is the <i>Ratios vs Industry</i> radar which shows 9 key financial ratios covering profitability, liquidity 
                                                and leverage. The ratios are compared to the company's industry, with a comparison to rating available via ticking the <i>Same Rating</i> check-box.
                                            </Paragraph>
                                            <Paragraph style={{ fontSize: "1.5rem", textAlign: "justify", textJustify: "inter-word" }}>
                                                The next chart, positioned at the top right hand, is the <i>Peer Rating Score Skews</i> which gives an ordering (in terms of credit 
                                                quality) of all the company's peers with the same industry and rating. The ordering is in terms of overall credit risk, taking into account 
                                                the rating probability distribution output from roboR8, with each peer's <i>Credit Bar</i> being presented.
                                            </Paragraph>
                                            <Paragraph style={{ fontSize: "1.5rem", textAlign: "justify", textJustify: "inter-word" }}>
                                                The final two charts are scatter plots of all companies within the industry. The y-axis is the <i>Credit Score</i> for the company.
                                                The first plot shows the current, selected, reporting period with each dot representing a company in the industry.
                                                The Blue dot signifies the company that is selected, the Green dots show a company that has a roboR8 rating equal to that of 
                                                the rating agencies, the Orange dot being a company that has a roboR8 rating within 1 notch of the agencies and a Red dot having a 
                                                roboR8 rating more than 1 notch away from an agency. Finally, the Grey dots are those companies that are unrated by the agencies 
                                                but rated by roboR8. Additionally, by ticking the <i>Confidence</i> check-box, all dots will change their diameter to be proportional
                                                to the rating probability assigned by roboR8's engine. The last plot, at the bottom right hand side of the Body, shows
                                                the ratings of all companies for the last 4 financial quarters. The colour coding of the dots follows from the scatter plot above.
                                            </Paragraph>
                                        </div>
                                        <div className="data-title">
                                            <Title> <span style={{ fontSize: "1.5rem" }}>Upload Data </span></Title>
                                            <Paragraph style={{ fontSize: "1.5rem", textAlign: "justify", textJustify: "inter-word" }}>
                                            The Upload Data view allows users to both view an existing <i>Region</i>-<i>Cluster</i> pair or to upload their own
                                            cluster locally for analysis. The Body comprises of a table which displays the financial statements row-wise for each company in a cluster. 
                                            Each row corresponds to a single company, with each column in that row showing the company's descriptive details, plus a 
                                            roboR8 <i>Credit Score</i> (if available), and the financial report entries (set to a trailing 12-month value where applicable). 
                                            </Paragraph>
                                        <Paragraph style={{ fontSize: "1.5rem", textAlign: "justify", textJustify: "inter-word" }}>
                                            Users select an existing <i>Region</i>-<i>Cluster</i> via the two drop-down selectors on the top left hand of the Body. Alternatively, the user may download
                                            the required template by pressing the <i>Get Template Sheet</i> button. Once this template is downloaded, it can be filled with any
                                            companies of interest (real or fictional) in the indicated format and uploaded via the <i>Upload Sheet</i> button. The uploaded companies
                                            and their respective details are private to the user. Once uploaded, the new <i>Region</i>-<i>Cluster</i> will be displayed in the
                                            table and the user may then select companies for scoring via the check-boxes on the left hand side. Pressing the <i>Run Credit Score</i> button
                                            will preform a roboR8 scoring on all companies whose associated check-box is ticked, the scores being displayed in the table once calculated.
                                        </Paragraph>
                                    </div>
                                </Paragraph>
                            </div>
                        </Typography>                            
                    </div>                    
                </div>                
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadMe);
