import { YEARLY_REPORT } from '../constants';

let initialState={
    yearlyReport:3,
}

const yearlyReportReducer = (state = initialState, action)=>{

    switch(action.type){
        case YEARLY_REPORT:
          return{
            ...state,
            yearlyReport:action.yearlyReport,
          }
      default:
          return state;
      }
}

export default yearlyReportReducer;