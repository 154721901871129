import React, { Component } from 'react'
import {Input, Tooltip,Divider} from 'antd';
import errorRed from '../assets/images/icons8-error-red.png'
import errorOrange from '../assets/images/icons8-error-orange.png'
import checkedIcon from '../assets/images/icons8-checked.png'
import editIcon from '../assets/images/icons-edit-flat.png'
import {customisedData, companyData } from '../redux/actions';

import {
    shiftCashFromOp, shiftCapex, shiftCashFromInv, shiftCashFromFin
} from '../utils';

import { connect } from 'react-redux';


const size = "small";

class cashflowStatementSimple extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editCompanyData: this.props.companyData
        }
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("cashflow statement receive new props!");
        this.setState({
            custom: false,
            data: nextProps,
            editCompanyData: nextProps.companyData
        });
    };

    componentDidMount() {
        this.setState({ data: this.props })
    };

    editCompanyDataOnChange = (data) => {
        this.props.updateCompanyData(data);
    }

    checkIssueIcon(status) {
        switch (status) {
            case 'warning':
                return errorOrange;
            case 'error':
                return errorRed;
            case 'fixed':
                return checkedIcon;
            case 'custom':
                return editIcon;
            default:
                return '';
        }
    };

    handleOpCashFlowChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qOpCashFlow.value);
        const shiftAll = shiftCashFromOp(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            increaseDecreaseInOtherItems: shiftAll.editIncreaseOthers,
            qOpCashFlow: shiftAll.editCashFromOp,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleCapexChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qCapex.value);
        const shiftAll = shiftCapex(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            qCapex: shiftAll.editCapex,
            qCashFromInvest: shiftAll.editCashFromInv,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleCashFromInvChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qCashFromInvest.value);
        const shiftAll = shiftCashFromInv(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            investmentsInOtherAssets: shiftAll.editInvestOthers,
            qCashFromInvest: shiftAll.editCashFromInv,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,        
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleCashFromFinChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.qCashFromFinance.value);
        const shiftAll = shiftCashFromFin(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            otherFinancingActivities: shiftAll.editOtherFinance,
            qCashFromFinance: shiftAll.editCashFromFin,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }

    handleCashIncreaseChange = (e) => {
        const shift = parseFloat(e.target.value) - parseFloat(this.state.editCompanyData.netIncreaseInCashAndEquivalents.value);
        const shiftAll = shiftCashFromFin(shift, this.state.editCompanyData);

        let newData = {
            custom: true,
            otherFinancingActivities: shiftAll.editOtherFinance,
            qCashFromFinance: shiftAll.editCashFromFin,
            netIncreaseInCashAndEquivalents: shiftAll.editCashIncrease,
        }
        let dataMerge = { ...this.state.editCompanyData, ...newData };

        this.editCompanyDataOnChange(dataMerge);
    }
    
    render() {

       const {
            
            qDandA,
            qNetIncome,
            
            increaseDecreaseInOtherItems, //extra
            qOpCashFlow,
            qCapex,
            investmentsInOtherAssets, //extra      
            qCashFromInvest,
            qDividend,
            qSaleOfEquity,
            qIssueLTDebt,
            qRepayLTDebt,
            otherFinancingActivities, //extra
            qCashFromFinance,
            netIncreaseInCashAndEquivalents, //extra            

        } = this.state.editCompanyData;
            //= this.props.companyData;
        
        return (            
            <div className="cash-flow-statement input-style-class">
                <div className="input-wrapper">
                    <div className="title">
                        <span>CASH FLOW STATEMENT</span>
                    </div>
                    <div className={`${qOpCashFlow.status} field`}>
                        <span className="medium">Net Cash From Operating Activities</span>
                        <div className="input-box-wrapper">
                            {
                                qOpCashFlow.status === "" ? null : (
                                    <Tooltip title={qOpCashFlow.log}>
                                        <img src={this.checkIssueIcon(qOpCashFlow.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qOpCashFlow.value}
                                onChange={this.props.customisedData ? this.handleOpCashFlowChange : false} />
                        </div>
                    </div>
                    <div className={`${qCapex.status} field`}>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;Capital Expenditure</span>
                        <div className="input-box-wrapper">
                            {
                                qCapex.status === "" ? null : (
                                    <Tooltip title={qCapex.log}>
                                        <img src={this.checkIssueIcon(qCapex.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qCapex.value}
                                onChange={this.props.customisedData ? this.handleCapexChange : false} />
                        </div>
                    </div>
                    <div className={`${qCashFromInvest.status} field`}>
                        <span className="medium">Net Cash From Investing Activities</span>
                        <div className="input-box-wrapper">
                            {
                                qCashFromInvest.status === "" ? null : (
                                    <Tooltip title={qCashFromInvest.log}>
                                        <img src={this.checkIssueIcon(qCashFromInvest.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qCashFromInvest.value}
                                onChange={this.props.customisedData ? this.handleCashFromInvChange : false} />
                        </div>
                    </div>
                    <div className={`${qCashFromFinance.status} field`}>
                        <span className="medium">Net Cash From Financing Activities</span>
                        <div className="input-box-wrapper">
                            {
                                qCashFromFinance.status === "" ? null : (
                                    <Tooltip title={qCashFromFinance.log}>
                                        <img src={this.checkIssueIcon(qCashFromFinance.status)} alt="tooltip" className="tooltip-icon" />
                                    </Tooltip>
                                )
                            }
                            <Input size={size} value={qCashFromFinance.value}
                                onChange={this.props.customisedData ? this.handleCashFromFinChange : false} />
                        </div>
                    </div>
                </div>
                <div className={`${netIncreaseInCashAndEquivalents.status} result`}>
                    <span>Net Increase in Cash &amp; Equivalents</span>
                    <div className="input-box-wrapper">
                        {
                            netIncreaseInCashAndEquivalents.status === "" ? null : (
                                <Tooltip title={netIncreaseInCashAndEquivalents.log}>
                                    <img src={this.checkIssueIcon(netIncreaseInCashAndEquivalents.status)} alt="tooltip" className="tooltip-icon" />
                                </Tooltip>
                            )
                        }
                        <Input size={size} value={netIncreaseInCashAndEquivalents.value}
                            onChange={this.props.customisedData ? this.handleCashIncreaseChange : false} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) =>{
    return {
        companyData: state.companyDataReducer.companyData,
        customisedData: state.customisedDataReducer.customisedData,
    }
  }


const mapDispatchToProps = dispatch => {
    return {
        updateCustomisedData: (check) => {
            dispatch(
                customisedData(check)
            )
        },
        updateCompanyData: (data) => {
            dispatch(
                companyData(data)
            )
        }     
    }
}
  
export default connect(mapStateToProps,mapDispatchToProps)(cashflowStatementSimple);




