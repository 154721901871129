import React from 'react'
import {Input, Table, Select, Button, Upload, message, Form, Space, Spin, Tooltip} from 'antd';
import * as XLSX from 'xlsx'

import { connect } from 'react-redux';

import "./index.css";
import {toCamelCaseVarName} from "../../utils";
import { UploadOutlined, DownloadOutlined, DeleteOutlined, CloudDownloadOutlined, CloudUploadOutlined, SearchOutlined} from '@ant-design/icons'
import Papa from "papaparse";
import {
    flattenDataStore,
    getRegionClusterMapFromCompanyList,
    loadCompanyData, mergeDeep,
    mergeRegionClusterMaps
} from "./service";
import {
    deleteUploadTabRows,
    ensureServerT12mData,
    newUploadSheetEntries,
    setRegionCluster,
    updateSelectedCompanyIds
} from "../../redux/actions/uploadSheet";
import {newCompanyResultEntries} from "../../redux/actions";
import Highlighter from 'react-highlight-words';

class UploadSheet extends React.Component {
    regionInput = React.createRef();
    clusterInput = React.createRef();

    constructor(props) {
        super(props);
        let incomeStatementList = ['Sales', 'Gross Profit', 'Depreciation & Amortisation', 'EBIT', 'Interest Expense', 'Net Income'];
        let cashFlowStatementList = ['Net Cash From Operating', 'Capex', 'Net Cash From Investing', 'Cash and Equivalents'];
        let assetsList = ['Account Receivable', 'Inventory', 'Total Current Assets', 'Accumulated Depreciation',
            'Net Fixed Asset', 'Intangibles', 'Total Assets'];
        let liabAndEquityList = ['Account Payable', 'Short Term Debt', 'Total Current Liabilities', 'Long Term Debt',
            'Total Liabilities', 'Retained Earnings', 'Total Equity'];
        this.columns = [
            {
                title: 'Company ID',
                dataIndex: 'companyId',
                fixed: 'left',
                width: 130,
                ...this.getColumnSearchProps('companyId')
            },
            {
                title: 'Score',
                dataIndex: ['roboR8', 'roboR8Score'],
                fixed: 'left',
                width: 70,
                sorter: (a, b) => a.score - b.score,
                render: (score, record) => {
                    let intScore = parseInt(score);
                    let color = 'black';
                    if(intScore>=25)
                        color = 'red';
                    if (intScore>=50)
                        color = 'orange';
                    if (intScore>=75)
                        color = 'green';
                    return {
                        props: {
                            style: {color: color}
                        },
                        children: record.roboR8?
                            <Tooltip title={<div><p>{`Rated on: ${record.roboR8.roboR8date}`}</p><p>{`roboR8 rating: ${record.roboR8.roboR8Result[record.roboR8.roboR8Rating].rating}`}</p></div>}>
                                {score? Math.round(score): score}
                            </Tooltip> :
                            null
                    };
                }
            },
            {
                title: 'Income Statement',
                children: incomeStatementList.map(title=>{
                    let titleCamel = toCamelCaseVarName(title);
                    return {
                        title: title,
                        dataIndex: titleCamel,
                        key: titleCamel,
                        sorter: (a, b) => a[titleCamel] - b[titleCamel],
                        render: value => value? parseFloat(value).toFixed(1): value
                    }
                }),
            },
            {
                title: 'Cash Flow Statement',
                children: cashFlowStatementList.map(title=>{
                    let titleCamel = toCamelCaseVarName(title);
                    return {
                        title: title,
                        dataIndex: titleCamel,
                        key: titleCamel,
                        sorter: (a, b) => a[titleCamel] - b[titleCamel],
                        render: value => value? parseFloat(value).toFixed(1): value
                    }
                }),
            },
            {
                title: 'Balance Sheet / Assets',
                children: assetsList.map(title=>{
                    let titleCamel = toCamelCaseVarName(title);
                    return {
                        title: title,
                        dataIndex: titleCamel,
                        key: titleCamel,
                        sorter: (a, b) => a[titleCamel] - b[titleCamel],
                        render: value => value? parseFloat(value).toFixed(1): value
                    }
                }),
            },
            {
                title: 'Balance Sheet / Liabilities and Equity',
                children: liabAndEquityList.map(title=>{
                    let titleCamel = toCamelCaseVarName(title);
                    return {
                        title: title,
                        dataIndex: titleCamel,
                        key: titleCamel,
                        sorter: (a, b) => a[titleCamel] - b[titleCamel],
                        render: value => value? parseFloat(value).toFixed(1): value
                    }
                }),
            },
            {
                title: 'Free Cash Flow',
                dataIndex: 'freeCashFlow',
                key: 'freeCashFlow',
                fixed: 'right',
                width: 130,
                sorter: (a, b) => a.freeCashFlow - b.freeCashFlow,
                render: value => value? parseFloat(value).toFixed(1): value
            },
        ];
        this.state = {
            selectedRowKeys: [],
            mergedDataStore: {...this.props.t12mDataStore},
            // region input
            regionInputMode: 0, // 0 is dropdown selection mode, 1 is text input mode
            newRegion: {
                value: "",
                validateStatus: 'error',
                errorMsg: "Special characters are not allowed."
            },
            //cluster input
            clusterInputMode: 0, // same as above
            newCluster: {
                value: "",
                validateStatus: 'error',
                errorMsg: "Special characters are not allowed."
            },

            selectedRegion: undefined,
            selectedCluster: undefined,
            regionClusterMap: getRegionClusterMapFromCompanyList(this.props.companyList)
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.regionInputMode){
            this.regionInput.current.focus();
        }
        if(this.state.clusterInputMode){
            this.clusterInput.current.focus();
        }
        if (prevProps.companyList !== this.props.companyList){
            const regionClusterMap = getRegionClusterMapFromCompanyList(this.props.companyList);
            const merged = mergeRegionClusterMaps(regionClusterMap, this.state.regionClusterMap);
            this.setState({regionClusterMap: merged})
        }
        if (prevProps.t12mDataStore !== this.props.t12mDataStore || prevProps.t12mDataStoreServer !== this.props.t12mDataStoreServer){
            // update the datasource for table
            this.setState({
                mergedDataStore: mergeDeep({}, this.props.t12mDataStore, this.props.t12mDataStoreServer)
            });
            // save to t12m data browser local storage
            if(prevProps.t12mDataStore !== this.props.t12mDataStore)
            localStorage.setItem('t12mDataStore', JSON.stringify(this.props.t12mDataStore))
        }
        if (prevProps.qDataStore !== this.props.qDataStore){
            // save to 4 quarterly datas to browser local storage
            localStorage.setItem('qDataStore', JSON.stringify(this.props.qDataStore));
        }
        if (prevState.selectedRegion!==this.state.selectedRegion||prevState.selectedCluster!==this.state.selectedCluster){
            if(typeof this.state.selectedCluster!=='undefined'){
                this.props.ensureServerT12mData(this.state.selectedRegion, this.state.selectedCluster)
            }
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text, record) =>
            this.state.searchedColumn === dataIndex ? (
                <Tooltip title={record.companyName}>
                    <a className='upload-sheet-company-id'><Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[this.state.searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    /></a>
                </Tooltip>
            ) : (
                <Tooltip title={record.companyName}><a className='upload-sheet-company-id'>{text}</a></Tooltip>
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
        this.props.updateSelectedCompanyIds(selectedRowKeys);
    };

    validateRegionOrCluster = value => {
        if (value === "")
            return {
                validateStatus: 'error',
                errorMsg: null,
            };
        if (value.match(/[^\w\.\-]/))
            return {
                validateStatus: 'error',
                errorMsg: null,
            };
        return {
            validateStatus: 'success'
        };
    };

    onRegionChange = region => {
        this.setState({
            selectedRegion: region,
            selectedCluster: undefined
        });
        this.props.setRegionCluster(region, undefined);
        this.setState({
            selectedRowKeys: []
        });
        this.props.updateSelectedCompanyIds([]);
    };

    newRegionTyped = e => {
        if (this.state.newRegion.validateStatus === 'success'){
            const updates = {
                ...this.state.regionClusterMap
            };
            updates[this.state.newRegion.value] = [];

            this.setState({
                regionInputMode: 0,
                regionClusterMap: {
                    ...updates
                }},
                () => {
                    this.setState({selectedRegion: this.state.newRegion.value})
            });
        }
        else
            this.setState({
                regionInputMode: 0
            })
    };

    setNewRegion = e => {
        this.setState({
            newRegion: {
                ...this.validateRegionOrCluster(e.target.value),
                value: e.target.value
            }
        });
    };

    onClusterChange = cluster => {
        if (cluster === "NEW") {
            this.setState({
                newCluster: {
                    ...this.state.newCluster,
                    validateStatus: 'error'
                },
                clusterInputMode: 1})
        }
        else {
            this.setState({
                selectedCluster: cluster,
                selectedRowKeys: []});
            this.props.setRegionCluster(this.state.selectedRegion, cluster);
            this.props.updateSelectedCompanyIds([]);
        }
    };

    newClusterTyped = e => {
        if (this.state.newCluster.validateStatus === "success"){
            const newCluster =e.target.value;
            const updates = {...this.state.regionClusterMap};
            if (!updates[this.state.selectedRegion].includes(newCluster))
                updates[this.state.selectedRegion] = [...updates[this.state.selectedRegion], newCluster];
            this.setState({
                    clusterInputMode: 0,
                    regionClusterMap: {
                        ...updates
                    }},
                () => {
                    this.setState({selectedCluster: newCluster});
                    this.props.setRegionCluster(this.state.selectedRegion, newCluster);
                    this.props.updateSelectedCompanyIds([]);
                })
        }
        else
            this.setState({
                clusterInputMode: 0
            })
    };

    setNewCluster = e => {
        this.setState({
            newCluster: {
                ...this.validateRegionOrCluster(e.target.value),
                value: e.target.value
            }
        });
    };

    beforeUpload = file => {
        const reader = new FileReader();
        reader.onload = (evt) => { // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type:'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, {header:1});
            /* parse csv string */
            Papa.parse(data, {
                dynamicTyping: true,
                complete: this.onCsvLoaded
            });
        };
        reader.readAsBinaryString(file);
        return false;
    };

    onCsvLoaded = results => {
        console.log(JSON.stringify(results.data));
        const loadedResult = loadCompanyData(results.data);
        const accepted = loadedResult.accepted;
        const entryWord = accepted.length === 1? "entry": "entries";
        message.success(`${accepted.length} ${entryWord} accepted.`);
        const t12mListByCompany = [];
        const qListByCompany = [];
        for (let entry of accepted){
            t12mListByCompany.push(
                {
                    region: this.state.selectedRegion, cluster: this.state.selectedCluster, t12mData: entry.t12mData
                }
            );
            qListByCompany.push({
                region: this.state.selectedRegion,
                cluster: this.state.selectedCluster,
                qData: [entry.quarter1, entry.quarter2, entry.quarter3, entry.quarter4]
            })
        }
        // upsert t12m data to new store
        this.props.addNewEntriesToSheet(t12mListByCompany);
        // upsert company meta and 4 quarterly datas to orig store
        this.props.addNewEntriesToCompanyResults(qListByCompany);
    };

    deleteRows = () => {
        this.props.deleteRows(this.props.selectedCompanyIds, this.props.selectedRegion, this.props.selectedCluster);
        this.setState({
            selectedRowKeys: []});
        this.props.updateSelectedCompanyIds([]);
    };

    render() {
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            selections: true
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        const uploadSheetButton = <Button disabled={this.state.selectedCluster===undefined} className="upload-sheet-button" icon={<CloudUploadOutlined />}>Upload Sheet</Button>
        return (
            <div className="upload-sheet">
                <div className="upload-sheet-select-group">
                    {this.state.regionInputMode===0?
                        <Select className="upload-sheet-select"
                                placeholder="Select a region"
                                value={this.state.selectedRegion}
                                onChange={this.onRegionChange}
                        >
                            {Object.keys(this.state.regionClusterMap).map(data => {
                                return <Select.Option key={data} value={data}>{data}</Select.Option>
                            })}
                        </Select> :
                        <Form>
                            <Form.Item
                                validateStatus={this.state.newRegion.validateStatus}
                            >
                                <Input className="upload-sheet-select"
                                       placeholder="Type a new region"
                                       onPressEnter={()=> {this.regionInput.current.blur()}}
                                       onBlur={this.newRegionTyped}
                                       onChange={this.setNewRegion}
                                       ref={this.regionInput} />
                            </Form.Item>
                        </Form>
                    }
                    {this.state.clusterInputMode===0?
                        <Select className="upload-sheet-select"
                                placeholder="Select a cluster"
                                value={this.state.selectedCluster}
                                onChange={this.onClusterChange}
                                disabled={!this.state.selectedRegion}
                        >
                            {
                                this.state.selectedRegion?
                                    [...this.state.regionClusterMap[this.state.selectedRegion], 'NEW'].map(data => {
                                        return <Select.Option key={data} value={data}>{data}</Select.Option>
                                    }):
                                    null
                            }
                        </Select> :
                        <Form>
                            <Form.Item
                                validateStatus={this.state.newCluster.validateStatus}
                            >
                                <Input className="upload-sheet-select"
                                       placeholder="Type a new cluster"
                                       onPressEnter={()=> {this.clusterInput.current.blur()}}
                                       onBlur={this.newClusterTyped}
                                       onChange={this.setNewCluster}
                                       ref={this.clusterInput} />
                            </Form.Item>
                        </Form>
                    }
                    {this.state.selectedCluster===undefined?
                    <Tooltip trigger={'hover'} title="Select a Cluster before uploading.">
                        {uploadSheetButton}
                    </Tooltip> :
                    <Upload className="upload-sheet-button" showUploadList={false} beforeUpload={this.beforeUpload}>
                        {uploadSheetButton}
                    </Upload>}
                    <Button className="upload-sheet-button" href="download/roboR8Upload.xlsx"
                        icon={<CloudDownloadOutlined />}>Get Template Sheet</Button>
                    <Button className="upload-sheet-button"
                            icon={<DeleteOutlined />}
                            disabled={this.props.selectedCompanyIds.length===0}
                            onClick={()=>this.deleteRows()} >Delete Row(s)</Button>
                </div>
                <Spin spinning={this.props.tableLoading || this.props.batchScoring} tip={this.props.tableLoading? "Loading Data...":"Running Batch Score..."}>
                    <Table
                        size='small'
                        bordered
                        dataSource={this.props.tableLoading? []:
                            flattenDataStore(this.state.mergedDataStore).filter((data)=>{
                                return (data.region===this.state.selectedRegion && data.cluster === this.state.selectedCluster)
                            })
                        }
                        rowSelection={rowSelection}
                        pagination={{ defaultPageSize: 15}}
                        columns={columns}
                        scroll={{ x: 3500 , y: 500 }}
                    />
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = (state) =>{
    return{
        headerSelected:state.headerSelectedReducer.headerSelected,
        companyData: state.companyDataReducer.companyData,
        yearlyReport:state.yearlyReportReducer.yearlyReport,
        autoCorrectData: state.autoCorrectDataReducer.autoCorrectData,
        customisedData: state.customisedDataReducer.customisedData,
        loadingCreditScore: state.migoReducer.loadingCreditScore,
        t12mDataStore: state.uploadTabReducer.t12mDataStore,
        t12mDataStoreServer: state.uploadTabReducer.t12mDataStoreServer,
        companyList: state.companyListReducer.companyList,
        qDataStore: state.uploadTabReducer.qDataStore,
        tableLoading: state.uploadTabReducer.loading,
        selectedCompanyIds: state.uploadTabReducer.selectedCompanyIds,
        selectedRegion: state.uploadTabReducer.selectedRegion,
        selectedCluster: state.uploadTabReducer.selectedCluster,
        batchScoring: state.uploadTabReducer.batchScoring
    }
};



const mapDispatchToProps = dispatch => {
    return {
        addNewEntriesToSheet: (t12mListByCompany) => {
            dispatch(newUploadSheetEntries(t12mListByCompany))
        },
        addNewEntriesToCompanyResults: (qListByCompany) => {
            dispatch(newCompanyResultEntries(qListByCompany))
        },
        ensureServerT12mData: (region, cluster) => {
            dispatch(ensureServerT12mData(region, cluster))
        },
        updateSelectedCompanyIds: (selectedCompanyIds) => {
            dispatch(updateSelectedCompanyIds(selectedCompanyIds))
        },
        setRegionCluster: (region, cluster) => {
            dispatch(setRegionCluster(region, cluster))
        },
        deleteRows: (idList, region, cluster) => {
            dispatch(deleteUploadTabRows(idList, region, cluster))
        }
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(UploadSheet);




