export const DARK_MODE = 'DARK_MODE';
export const LOADING = 'LOADING';
export const COMPANY_DATA = 'COMPANY_DATA';
export const HEADER_SELECTED = 'HEADER_SELECTED';
export const COMPANY_ID_INDEX = 'COMPANY_ID_INDEX';
export const YEARLY_REPORT = 'YEARLY_REPORT';
export const AUTO_CORRECT_DATA = 'AUTO_CORRECT_DATA';
export const CUSTOMISED_DATA = 'CUSTOMISED_DATA';
export const BUBBLE_GRAPH = 'BUBBLE_GRAPH';
export const RADAR_PEERS = 'RADAR_PEERS';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// added by Wang Chen
export const COMPANY_LIST = 'COMPANY_LIST';
export const COMPANY_RATINGS_LOADED = 'COMPANY_RATINGS_LOADED';
export const COMPANY_PEERDATA_LOADED = 'COMPANY_PEERDATA_LOADED';
export const COMPANY_RESULTS = 'COMPANY_RESULTS';
export const MACRO_DATA_LOADED = 'MACRO_DATA_LOADED';
export const RUN_CREDIT_SCORE_START = 'RUN_CREDIT_SCORE_START';
export const RUN_CREDIT_SCORE_FINISH = 'RUN_CREDIT_SCORE_FINISH';
export const RUN_CREDIT_SCORE_ERROR = 'RUN_CREDIT_SCORE_ERROR';
export const CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB';
export const CHANGE_ACTIVE_SUB_TAB = 'CHANGE_ACTIVE_SUB_TAB';
export const NEW_UPLOAD_ENTRIES = 'NEW_UPLOAD_ENTRIES';
export const TOGGLE_LOADING_T12M = 'TOGGLE_LOADING_T12M';
export const SERVER_T12M_ENTRIES = 'SERVER_T12M_ENTRIES';
export const COMPANY_RESULT_ENTRIES = 'COMPANY_RESULT_ENTRIES';
export const SELECTED_COMPANY_IDS = 'SELECTED_COMPANY_IDS';
export const RUN_BATCH_SCORE_START = 'RUN_BATCH_SCORE_START';
export const RUN_BATCH_SCORE_FINISH = 'RUN_BATCH_SCORE_FINISH';
export const RUN_BATCH_SCORE_ERROR = 'RUN_BATCH_SCORE_ERROR';
export const UPLOAD_TAB_SET_REGION_CLUSTER = 'UPLOAD_TAB_SET_REGION_CLUSTER';
export const ROOT_FONT_SIZE = 'ROOT_FONT_SIZE';
export const TILE_BOUNDS = 'TILE_BOUNDS';
export const CURRENT_COMPANY = 'CURRENT_COMPANY';

// delete uploaded rows
export const UPLOAD_TAB_DELETE_ROWS = 'UPLOAD_TAB_DELETE_ROWS';
