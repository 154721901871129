import React from "react";
import {connect} from 'react-redux';
import {
  Chart,
  Geom,
  Axis,
  Tooltip,
  Label,
} from "bizcharts";
import {convertRemToPx} from "../utils";

class Basiccolumn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            ganttSize: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let winHeight = window.innerHeight;

        this.setState({ width: window.innerWidth, height: window.innerHeight, ganttSize: (winHeight * this.props.height) });
    }


    render() {
        const data = [];

        for (let i = 5; i <= 24; i++) {
            data.push(this.props.companyData.roboR8[i - 1])
        }

        for (let i = 1; i <= 4; i++) {
            data[0].prob += this.props.companyData.roboR8[i - 1].prob
        }

        const cols = {
            prob: {
                tickInterval: 10,
                min: 0, // 定义数值范围的最小值
                max: 100, // 定义数值范围的最大值
            }
        };

        const label = {
            style: {
                textAlign: 'center', // 文本对齐方向，可取值为： start center end
                fill: this.props.darkMode ? '#A3ABBD' : '#192A3E', // 文本的颜色
                //tickInterval: 2,
                fontSize: convertRemToPx(this.props.rootFontSize), // 文本大小
            }
        }

        const grid = {
            type: 'line',
            line: {
                style: {
                    stroke: this.props.darkMode ? '#4F4F4F' : '#f2d9d9', //'#4F4F4F' : '#E5E5E5',
                    lineWidth: 1,
                    lineDash: [0, 0]
                }
            },
        }

        return (
            <div className="gantt-chart">
                <Chart height={this.state.ganttSize} padding={'auto'} data={data} scale={cols} autoFit
                       visible={this.props.activeTabKey === this.props.underTabKey}>
                    <Axis name="rating"
                        label={
                            label
                        }                        
                        grid={grid}
                    />
                    <Axis
                        name="prob"    
                        label={null}
                       // label={
                       //     label
                       // }
                        grid={grid}
                    />
                    <Tooltip
                        showCrosshairs={true}
                        crosshairs={{
                            type: "x",
                            follow: true,
                        }}
                    />
                    <Geom type="interval" position="rating*prob"
                        color='#6E94F4'
                        opacity={1}
                        size={20}
                        label={[
                            "prob",
                            (value) => {
                                return {
                                    content: value <= 0.5 ? "": Math.round(value) + "%",
                                    style: {
                                        rotate: 0,
                                        textAlign: "center",
                                        fill: this.props.darkMode ? '#A3ABBD' : '#000000',
                                        fontSize: 11,
                                        fontFamily: 'font1 light'
                                    },
                                    offset: 10,
                                }
                            }
                        ]}
                    >
                    </Geom>
                </Chart>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        darkMode: state.darkModeReducer.darkMode,
        companyData: state.companyDataReducer.companyData,
      rootFontSize: state.globalStyleReducer.rootFontSize,
      activeTabKey: state.activeTabReducer.key
    }
}

export default connect(mapStateToProps,null)(Basiccolumn);