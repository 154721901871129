import {MACRO_DATA_LOADED} from '../constants';


let initialState={
    data: {}
};

const macroEconomyReducer = (state = initialState, action) => {
    switch (action.type) {
        case MACRO_DATA_LOADED:
            console.log('Macro economy data loaded action firing');
            let regionData = state.data[action.region]||{};
            regionData[action.date] = action.data;
            let newData = {
                ...state.data
            };
            newData[action.region] = regionData;
            return {
                data: newData
            };
        default:
            return state;
    }
};

export default macroEconomyReducer;